
.webinar-hero-left-subheading2{
    font-size: 3rem;
    font-weight: 500;
    margin-top: 1rem;
   
   
    color: white;
    
   
  

}
.summer-training-desc{
    font-size: 1.3rem;
   line-height: 2.5rem;
    margin-top: 1rem;
    text-align: justify;
    color: white;
    width: 80%;
}
.summer-training-feat{
display:flex;
flex-wrap: wrap;
gap:2rem;
margin-top: 2rem;
width: 80%;
}

.summer-training-feat p{
   display: inline;
    font-size: 1.5rem;
    align-items: center;
    display: flex;
    gap: 0.5rem;
    background-color: #ad2643;
    color: white;
    padding-left:2.6rem;
    padding-right: 2.6rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    border-radius: 30px;
}
.herosection-icon{
    height: 12px;
    width: 12px;
    color: yellow;
}
.herosection-calicon{
    height: 12px;
    width: 12px;
    color: white;
    margin-bottom: 0.4rem;
}
.herosection-aws{
    height: 22px;
    width: 22px;
    color: white;
   margin-right:1rem;}
.herosection-place{
    height: 20px;
    width: 20px;
    color: white;
    margin-bottom: 0.3rem;
}
.herosection-book{
    height: 16px;
    width: 16px;
    color: white;
   
}
.web-register-btn3{
    padding-left: 3rem;
    padding-right: 3rem;
    background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 1.2rem;
    font-size: 2rem;
    border-radius: 5px;
    color: white;
    font-weight: 700;

}
.course-highlights-body{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.course-highlights-body-feat{
    display: flex;
    align-items: center;
    gap:10px;
}
.course-highlights-icon-cont{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    background-color: #ad2643;
    padding: 0.5rem;
    border-radius: 50px;
}
.course-highlights-icon{
width: 2rem;
height:2rem;
color: black;
}
.course-highlights-body-title{
    font-size: 1.5rem;
    font-weight: 600;
}
.call-to-action-section{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: radial-gradient(926px at 2.7% 11%, rgb(17, 29, 94) 0%, rgb(178, 31, 102) 90%);
    height: 15rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    gap: 10rem;
   

}
.call-to-action-section div{
    font-size: 1.6rem;
    width: 50%;
}
.call-to-action-section p{
    font-size: 1.7rem;
    background-color: white;
    color:#ad2643;
    font-weight: 600;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
    border-radius: 10px;
    cursor: pointer;
}
.lab-section2{
    display: flex;
    justify-content:center;
    font-family: 'Saira', sans-serif;
    margin-top: 4rem;
    color:aliceblue;
}
.lab-section-header{
text-align: center;
font-size: 3.5rem;
margin-bottom: 2rem;

}
.lab-card2{   
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 75%;  
    border-radius: 10px;
    background-color: #071122;
}
.lab-card2{
    font-size: 1.3rem;
    text-align: justify;
}
.lab-card2 ul{
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 1.3rem;
    text-align: justify;
}
.job-roles-card{
    width: 30%;
}


.why-stack-section-card2{
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:#071122;
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 25%;
    border-radius: 15px;
    cursor: pointer;
 


    
}
.why-stack-section-card2:hover{
    filter: drop-shadow(0px 0px 7px #e82930);
    box-shadow:  -2px 2px 2px 3px 	#e32f1c,-2px 2px 2px 3px #e32f1c; 
}


.why-stack-section-card-wrapper{
    display: flex;
    gap: 3.5rem;
    flex-wrap: wrap;
   justify-content: center;

}
.why-stack-section-card-header{
    font-size: 1.7rem;
    margin-top: 2.5rem;
    font-weight: 650;


}
.why-stack-section-desc{
    font-size: 1.3rem;
    margin-top: 0.2rem;
    width: 85%;
    text-align: center;
}
.summer-training-modal{
    position:fixed;
    top: 0px;
    bottom: 0px;
    left:0px;
    right:0px;
    opacity: 0;
  animation: fadeIn 0.5s forwards;
    background-color: rgba(0, 0, 0, 0.6);
}
@keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
.summer-training-cont{
    position:fixed;
    width: 40rem;
  padding-bottom: 2rem;
    background-color: white;
    top: 50%;
left: 50%;
transform: translate(-50%, -50%) translateY(-50px);
animation: slideIn 0.5s forwards ease-out;
    z-index: 200;
}
.summer-training-modal-heading{
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    margin-top: 3rem;
}
.summer-training-icon{
    cursor: pointer;
    width: 1.7rem;
    height: 1.7rem;
    position: absolute;
    right: 10px;
    top: 5px;
}
.message-text{
    text-align: center;
    margin-top: 3rem;
    font-size: 1.5rem;
    color: #03A84E;
}
.summer-modal-form{
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 2rem;
    padding-right:2rem;
   gap: 1rem;
}
.summer-modal-input-field{
    width: 100%;
    font-size: 15px;
    padding: 0.5rem;
}
.summer-modal-label-field{
    font-size: 12px;
    font-weight: 500;
}
.summer-modal-error{
    font-size: 10px;
    margin-top: 0.3rem;
    font-weight: 400;
    color: #e82930;

}
.whatsapp-icon-wrapper{
    position: fixed;
    bottom: 25px;
    left: 25px;
    z-index: 600;
    background-color: #03A84E;
    padding: 0.8rem;
    border-radius: 50px;
    cursor: pointer;
}
.whatsapp-icon{
    
  
    color: white;
    width: 3rem;
    height: 3rem;
}
.callback-btn{
    position: fixed;
    font-size: 1.75rem;
    top:50%;
    left: -5.2rem;
    padding-right: 1.3rem;
    padding-left: 1.3rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    width: fit-content;
    rotate: 90deg;
    cursor: pointer;
}
@media screen and (max-width: 1240px){
    .summer-training-feat{
      
        padding-bottom: 5rem;
        }
    .webinar-key-list{
        width: 47%;
        background-color: #1a2332;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 3rem;
        padding-bottom: 3rem;
        font-size: 1.7rem;
        border-radius: 10px;
    }
   
}
@media screen and (max-width: 500px) {
    .summer-training-cont{
        position:fixed;
        width: 40rem;
       padding-bottom: 2rem;
        background-color: white;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) translateY(-50px);
        animation: slideIn 0.5s forwards ease-out;
        z-index: 200;
    }
}
@media screen and (max-width: 500px) {
    .summer-training-cont{
        position:fixed;
        width: 40rem;
       padding-bottom: 2rem;
        background-color: white;
        top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) translateY(-50px);
    animation: slideIn 0.5s forwards ease-out;
        z-index: 200;
    }
    .call-to-action-section p{
        font-size: 1rem;
        background-color: white;
        color:#ad2643;
        font-weight: 600;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
        border-radius: 10px;
        cursor: pointer;
    }
}
@keyframes slideIn {
    to {
      opacity: 1;
      transform: translate(-50%, -50%) translateY(0); /* Slide into place */
    }
  }