.summer-highlights{
    padding-left: 8rem;
    padding-right: 8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background: radial-gradient(926px at 2.7% 11%, rgb(17, 29, 94) 0%, rgb(178, 31, 102) 90%);
        padding-top: 3rem;
        padding-bottom: 3rem;
        gap: 2rem;
}
.background-color-how-training{
    background: radial-gradient(926px at 2.7% 11%, rgb(17, 29, 94) 0%, rgb(178, 31, 102) 90%); 
}
.training-access-heading{
    padding-top: 2rem;
    font-size: 2rem;
    font-weight: 700;
}

.summer-highlights-section{
    width: 17rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:1rem;
    
}
.summer-highlights-icon{
    width: 3rem;
    height:3rem
}
.summer-highlights-icon2{
    width: 3.5rem;
    height:3.5rem
}
.summer-highlights-desc{
    font-size: 1.5rem;
    text-align: center;
   
}
@media screen and (max-width:3000px){
    .summer-highlights{
        padding-left: 15%;
        padding-right: 15%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            background: radial-gradient(926px at 2.7% 11%, rgb(17, 29, 94) 0%, rgb(178, 31, 102) 90%);
            padding-top: 3rem;
            padding-bottom: 3rem;
            gap: 2rem;
    }
    .summer-highlights-section{
        width: 15rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:1rem;
        
    }
    .summer-highlights-icon{
        width: 2.5rem;
        height:2.5rem
    }
    .summer-highlights-desc{
        font-size: 1.25rem;
        text-align: center;
       
    }   
}
@media screen and (max-width:1800px){
    .summer-highlights{
        padding-left: 15rem;
        padding-right: 15rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            background: radial-gradient(926px at 2.7% 11%, rgb(17, 29, 94) 0%, rgb(178, 31, 102) 90%);
            padding-top: 3rem;
            padding-bottom: 3rem;
            gap: 2rem;
    }
    .summer-highlights-section{
        width: 15rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:1rem;
        
    }
    .summer-highlights-icon{
        width: 2.5rem;
        height:2.5rem
    }
    .summer-highlights-desc{
        font-size: 1.25rem;
        text-align: center;
       
    }   
}
@media screen and (max-width:1000px){
    .summer-highlights-section{
        width: 15rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:1rem;
        
    }
    .summer-highlights-icon{
        width: 2.5rem;
        height:2.5rem
    }
    .summer-highlights-desc{
        font-size: 1.25rem;
        text-align: center;
       
    }   
}
@media screen and (max-width:750px){
    .summer-highlights{
        padding-left: 2rem;
        padding-right: 2rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            background: radial-gradient(926px at 2.7% 11%, rgb(17, 29, 94) 0%, rgb(178, 31, 102) 90%);
            padding-top: 3rem;
            padding-bottom: 3rem;
            gap: 2rem;
    }
    .summer-highlights-section{
        width: 17rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:1rem;
        
    }
    .summer-highlights-icon{
        width: 2.5rem;
        height:2.5rem
    }
    .summer-highlights-desc{
        font-size: 1.25rem;
        text-align: center;
       
    }   
}
@media screen and (max-width:500px){
    .summer-highlights{
        padding-left: 2rem;
        padding-right: 2rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            background: radial-gradient(926px at 2.7% 11%, rgb(17, 29, 94) 0%, rgb(178, 31, 102) 90%);
            padding-top: 3rem;
            padding-bottom: 3rem;
            gap: 1rem;
    }
    .summer-highlights-section{
        width: 20rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:1rem;
        
    }
    .summer-highlights-icon{
        width: 2.5rem;
        height:2.5rem
    }
    .summer-highlights-desc{
        font-size: 1.25rem;
        text-align: center;
       
    }   
}