@font-face {
    font-family: 'Custom';
    src: url('../../neo.otf') format('opentype');
  }
  .binary-wrap{
    display: flex;
    justify-content: center;

  }
  .binary-wrapper{
    display: flex;
    width:60%;
   

  }
  .binaryhr{
    background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    height: 0.6rem;
    margin-bottom: 7rem;
    border-radius: 10px;
    width: 23rem;
  }
  .binaryhr5{
    background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    height: 0.6rem;
    margin-bottom: 7rem;
    border-radius: 10px;
    width: 35rem;
  }
.binary-container{
    background-color: #06011d;
    font-family: 'Saira', sans-serif;
    padding-bottom: 15rem;
    color: white;
    width: 100%;
    min-height: 100vh;
    margin-bottom: 5rem;
}
.heading-hero{
  
    font-size: 80px;
    background: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    -webkit-background-clip: text;
    color: transparent;
    background-clip: text;
}
.hero-section{
    width: 100%;
    font-family: 'Saira', sans-serif;
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding-left: 5rem;
    padding-right: 5rem;
    gap:5%;
    background-color: #060218;
    padding-bottom: 12rem;
 

}
.hero-header{
    font-size: 6rem;

}
.hero-para{
    margin-top: 5.5rem;
    font-size: 1.7rem;
    text-align: justify;
    margin-bottom: 5.5rem;
}
.hero-section-btn{
    display: inline;
    background-color: #EE3756;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1.3rem;
    border-radius: 5px;
    cursor: pointer;

}
.hero-desc{
width: 55%;
}

.img-container{
    width: 30%;
    display: flex;
    justify-content: center;
    height: 50rem;
    /* Set the background image to cover the entire viewport */
    
    /* Center the background image */
   
    /* Ensure the background image doesn't repeat */
    background-repeat: no-repeat;
    background-size: cover;

    background-image: url('../../Assets/binary/sxpbg2.png');
}
.binary-logo{
    margin-top: 13rem;
    width:35rem;
    height:35rem;
    padding-bottom: 5rem;
}
.lab-section{
    display: flex;
    justify-content:center;
    font-family: 'Saira', sans-serif;
    margin-top: 4rem;
    color:aliceblue;
}
.lab-section-header{
text-align: center;
font-size: 3.5rem;
margin-bottom: 2rem;

}
.lab-card{
    display: flex;
    justify-content: space-around;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 6rem;
    padding-bottom: 6rem;
    width: 85%;  
    border-radius: 10px;
    gap:3rem;
    background-color: #071122;
   

}
.color-red{
    color:#EE3756;
    font-weight: 600;
}
.lab-card-left-header{
    text-align: center;
    margin-top:5rem;
    margin-bottom: 1rem;
    color: white;
    font-size: 40px;
    font-weight:600;

}
.lab-card-left{
width: 45%;
}
.lab-card-right{
    background-image: url(../../Assets/Transaction/Rectangle\ 1134.svg);

    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow:  2px 10px 20px 5px 	rgba(12,5,18,.85),8px -5px 20px 5px rgb(3, 28, 57,1);
 background-size: cover;

 border-radius: 2rem;
width: 40%;
height: 30rem;
}
.lab-card-right2{
    background-image: url(../../Assets/Transaction/Rectangle\ 1134.svg);

    filter: drop-shadow(4px 0px 3px rgba(255,255,255,0.03));
    box-shadow:  2px 2px 4px 5px 	rgba(12,5,18,.85),2px -5px 5px 5px rgb(3, 28, 57,1);
 background-size: cover;
 padding: 0rem;
 border-radius: 1rem;
width: 45%;
}
.lab-card-left-desc{
    font-size: 16.5px;
    text-align: justify;
    margin-top: 1.2rem;
}
.lab-list-container{
    margin-top: 3.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.lab-desc-list{
    display: flex;

    gap: 1rem;
    margin-top: 1.3rem;
    width: 25rem;
}
.lab-desc-list-icon-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
background-color:rgb(82 36 36);
height: 20px;
width: 20px;
border-radius: 50%;

}

.lab-desc-list-icon{
color: red;
width: 35px;
height: 35px;
padding: 0.1rem;

}
.lab-list-text{
    font-size: 14px;
}


/* Why Stack Section */
.why-stack-section{
    margin-top: 1rem;
    background-color: #06011d;
}
.why-stack-section-header{
    text-align: center;
    font-size: 4.5rem;
    margin-top: 3rem;
    font-weight: 600;
    

}
.why-stack-section-header span{
    background: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    -webkit-background-clip: text;
    color: transparent;
    background-clip: text;
}
.why-stack-section-card{
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:#071122;
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 35%;
    border-radius: 15px;
    cursor: pointer;
 


    
}
.why-stack-section-card:hover{
    filter: drop-shadow(0px 0px 7px #e82930);
    box-shadow:  -2px 2px 2px 3px 	#e32f1c,-2px 2px 2px 3px #e32f1c; 
}
.why-stack-section-icon-wrapper{
    background-color:rgb(82 36 36);
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.why-stack-section-icon{
    color: #EE3756;
    width: 3rem;
    height: 3rem;
    flex: none;
}
.why-stack-section-card-wrapper{
    display: flex;
    gap: 3.5rem;
    flex-wrap: wrap;
   justify-content: center;

}
.why-stack-section-card-header{
    font-size: 2rem;
    margin-top: 2.5rem;
    font-weight: 650;


}
.why-stack-section-desc{
    font-size: 1.5rem;
    margin-top: 0.2rem;
    width: 65%;
    text-align: center;
}
.pricing-section{
    margin-top: 4.5rem;
    display: flex;
    justify-content: center;
}
.pricing-header{
    
    text-align: center;
    font-size: 40px;
    margin-top: 6rem;
    font-weight: 600;
}
.pricing-section-card{
    display: flex;
    justify-content: space-around;
    align-items: start;
    background-color:#1a2332 ;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 75%;  
    border-radius: 15px;
}
.pricing-section-card-head{
    font-size: 2.1rem;
    background: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    -webkit-background-clip: text;
    color: transparent;
    background-clip: text;
}
.pricing-section-card-left{
    padding-top: 2.5rem;
    width: 60%;
}
.pricing-section-card-left-desc{
    font-size:1.5rem;
  

}
.pricing-section-card-left-list-head{
    font-size:1.4rem;
margin-top: 4.5rem;
margin-bottom: 2rem;
padding-left: 1.6rem;
font-weight: 500;
}
.price-feature-list{
    display: flex;
    justify-content: space-between;
    width: 90%;
  margin-top: 2.5rem;
    flex-wrap: wrap;
    align-items: left;
    gap: 2rem;
}
.price-list-item{
    
    width: 40%;
    display: flex;
    align-items: top;
    justify-content:left;
    gap: 1rem;
    font-size: 1.5rem;
    
}
.price-list-icon{
    color: red;
    width: 18px;
    height:18px;
    flex: none;
}
.pricing-section-card-right{
    
    height: 100%;
    width: 28%;
    border-radius: 15px;
background-color: #161e2c;
padding-top: 1rem;
padding-bottom: 1rem;
padding-left: 6rem;
padding-right: 6rem;
box-shadow: #070121;
}
.pricing-inside-card-text{
    text-align: center;
    font-size: 1.4rem;
    margin-top: 2rem;
}
.pricing-card-price{
    text-align: center;
    font-size: 4rem;
    margin-top: 2rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
}
.pricing-card-price2{
    text-align: center;
    font-size: 3.5rem;
    margin-top: 0.3rem;
    font-family: 'Roboto', sans-serif;
}
.btn-wrapper{
    display: flex;
    justify-content: center;
}
.card-price-button{
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    font-size: 1.7rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-top: 0.8rem;
    padding-left: 4.2rem;
    padding-right: 4.2rem;
    background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    border-radius: 3px;
}
.course-overview-head{
    text-align: center;
    margin-top: 6rem;
    font-size: 4.2rem;
    margin-bottom: 5rem;
}
.course-overview-desc{
    display: flex;
    
    gap: 10;
    justify-content: space-between;
    padding-right: 10rem;
    padding-left: 10rem;
    
}
.course-first-card{
    border-radius: 10px;
  flex-direction: column;
    background-color: #071122;
   
    width: 30rem;
    height: 36rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    -webkit-box-shadow: 10px 10px 97px 0px rgba(2,0,18,1);
    -moz-box-shadow: 10px 10px 97px 0px rgba(2,0,18,1);
    box-shadow: 10px 10px 97px 0px rgba(2,0,18,1);
  
}

.assembly{
  
   width: 16rem;
   font-size: 3rem;
   margin-top: 1.2rem;
   text-align: center;
    font-weight: 900;
    color: white;
      
}
.course-card-logo{
  
    width: 25rem;
    height: 24rem;
}
.course-card-text{
    text-align: center;
    color: white;
    font-size: 2.4rem;
    margin-top: 1rem;
    gap: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 200px;
    
   
}
.certificate-img{
    width: 100%;;
    height: 100%;
}
.course-card-right{
    width: 2rem;
    height:2rem;
    color: #EE3756;

}
.binary-modal-container{
    color:white;
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
  
    backdrop-filter: blur(60px);
}
.binary-modal-wrapper{
position: relative;
top: 25%;
left: 20%;
    background-color: #071122;
    width: 60%;
    height: 44%;  
    border-radius: 20px; 
    display: flex;
    flex-wrap: wrap;
   gap:5rem;
    align-items: top;
    overflow: hidden;
}

.modal-img{
    width: 100%;
    height: 32rem;
}
.modal-left{
width: 35%;
}
.modal-right{
    width: 45%;
margin-top: 2rem;

}
.modal-heading{
    font-size: 3rem;
    text-align: center;
    font-weight: 600;
}
.modal-description{
    font-size: 15px;
}
.modal-img2{
    width: 20rem;
    height: 50rem;
    opacity: 0.2;
}
.play-icon{
    color: #EE3756;
    width: 1.5rem;
    height: 1.5rem;
}
.list-wrap{
    display: flex;

}
.list-icon{
    width: 200%;
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 18px;
    font-size: 13.5px;
}
.close-button{
    position: absolute;
    color: white;
    left:95% ;
    width: 15px;
    height: 15px;
    cursor: pointer;
}
.binary-heading-div{
   
    display: flex;
    justify-content: center;
    margin-top: 12rem;
    font-weight: 600;
}
.binary-table-component{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 5rem;
}
/* Binary Difference Section */
.binary-heading{
    width: 60%;
    text-align: center;
   
    font-size: 4rem;
    margin-top: 4.5rem;
}
.binary-col-desc{
    display: flex;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    justify-content: center;
    padding-left: 1.5rem;
    align-items: center;
    text-align: center;
   
   
  
   width: 30rem;
   height: 8rem;
   font-size: 1.5rem;
}
.binary-col-desc3{
    display: flex;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    justify-content: center;
    padding-left: 1.5rem;
    align-items: center;
    text-align: center;
   
    background-color: #06011d;
  
   width: 30rem;
   height: 8rem;
   font-size: 1.5rem;
}
.border-top-class{
    font-size: 2.5rem;
    font-weight: 600;
    background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    border-top:3px solid #EE3756;
    overflow: hidden;
}
.border-bottom-class{
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    border-bottom:3px solid green;
}
.binary-col-desc-border{
    display: flex;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    justify-content: center;
    padding-left: 1.5rem;
    align-items: center;
    text-align: center;
    
    border-right:3px solid #EE3756;
    border-left:3px solid #EE3756;
    
   width: 30rem;
   height: 8rem;
   font-size: 1.5rem;
}
.binary-row-desc{
    display: flex;
   
}
.binary-row-desc2{
    display: flex;
    background-color: #1a2332;
}
.hero-section-btn-icon{
    width: 1.7rem;
    height: 1.7rem;
    cursor: pointer;
}
.video-responsive-cont{
    display: flex;
    justify-content: center;
    
  }
  .video-resp{
    width: 90%;
    height: 50rem;
    display: flex;
    justify-content: center;

  }
  .video-resp iframe{
  
    width: 70%;
    height:100%

  }
@media only screen and (max-width: 3550px){
    .binary-wrap{
        display: flex;
        justify-content: center;
    
      }
      .binary-wrapper{
        display: flex;
        width:60%;
       
    
      }
    .price-list-item{
        display: flex;
        align-items: top;
        justify-content: left;
        gap: 0.3rem;
        font-size: 1.6rem;
        
    }
    .price-list-icon{
        color: red;
        margin-top: 0.5rem;
        width: 18px;
        height:18px;
    }
    .pricing-section-card{
        display: flex;
        justify-content: space-around;
        align-items: start;
        background-color:#1a2332 ;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
        width: 55%;  
        border-radius: 15px;
       
    }
    .lab-card{
        width:50%
    }
    .hero-para{
        margin-top: 5.5rem;
        font-size: 3rem;
        text-align: justify;
        margin-bottom: 5.5rem;
    }
    .binary-heading-div{
       margin-top: 12rem;
        display: flex;
        justify-content: center;
    }
    .binary-table-component{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 5rem;
    }
    /* Binary Difference Section */
    .binary-heading{
        width: 60%;
        text-align: center;
       
        font-size: 4rem;
        margin-top: 4.5rem;
    }
    .binary-col-desc-border5{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        border-right:3px solid #EE3756;
        border-left:3px solid #EE3756;
        
       width: 50rem;
       height: 25rem;
       font-size: 1.5rem;
    }
    .binary-col-desc{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
        padding-top: 0.5rem;
       
       
      
       width: 50rem;
       height: 25rem;
       font-size: 2.5rem;
    }
    .binary-col-desc3{
        display: flex;
        padding-top: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
       
        background-color: #06011d;
      
       width: 50rem;
       height: 25rem;
       font-size: 2.5rem;
    }
    .border-top-class{
        font-size: 2.5rem;
        font-weight: 600;
        background-color: #EE3756;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        border-top:3px solid #EE3756;
    }
    .border-bottom-class{
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;
        border-bottom:3px solid #EE3756;
    }
    .binary-col-desc-border{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
        padding-top: 0.5rem;
        
        border-right:3px solid #EE3756;
        border-left:3px solid #EE3756;
        
       width: 50rem;
       height: 25rem;
       font-size: 2.5rem;
    }
    .binary-row-desc{
        display: flex;
       
    }
    .binary-row-desc2{
        display: flex;
        background-color: #1a2332;
    }
    .video-responsive-cont{
        display: flex;
        justify-content: center;
        
      }
      .video-resp{
        width: 98%;
        height: 40rem;
        display: flex;
        justify-content: center;
    
      }
      .video-resp iframe{
      
        width: 50%;
        height:100%
    
      }

}



@media only screen and (max-width: 2340px){
    .binary-wrap{
        display: flex;
        justify-content: center;
    
      }
      .binary-wrapper{
        display: flex;
        width:60%;
       
    
      }
    .pricing-section-card{
        display: flex;
        justify-content: space-around;
        align-items: start;
        background-color:#1a2332 ;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
        width: 55%;  
        border-radius: 15px;
    }
    .lab-card{
        width:60%
    }
    .hero-para{
        margin-top: 5.5rem;
        font-size: 2.5rem;
        text-align: justify;
        margin-bottom: 5.5rem;
    }
    .binary-heading-div{
        margin-top: 12rem;
        display: flex;
        justify-content: center;
    }
    .binary-table-component{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 5rem;
    }
    /* Binary Difference Section */
    .binary-heading{
        width: 60%;
        text-align: center;
       
        font-size: 4rem;
        margin-top: 4.5rem;
    }
    .binary-col-desc-border5{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        border-right:3px solid #EE3756;
        border-left:3px solid #EE3756;
        
       width: 40rem;
       height: 15rem;
       font-size: 1.5rem;
    }
    .binary-col-desc{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
        padding-top: 0.5rem;
       
       
      
       width: 40rem;
       height: 15rem;
       font-size: 2.5rem;
    }
    .binary-col-desc3{
        display: flex;
        padding-top: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
       
        background-color: #06011d;
      
       width: 40rem;
       height: 15rem;
       font-size: 2.5rem;
    }
    .border-top-class{
        font-size: 2.5rem;
        font-weight: 600;
        background-color: #EE3756;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        border-top:3px solid #EE3756;
overflow: hidden;    }
    .border-bottom-class{
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;
        border-bottom:3px solid #EE3756;
    }
    .binary-col-desc-border{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
        padding-top: 0.5rem;
        
        border-right:3px solid #EE3756;
        border-left:3px solid #EE3756;
        
       width: 40rem;
       height: 15rem;
       font-size: 2.5rem;
    }
    .binary-row-desc{
        display: flex;
       
    }
    .binary-row-desc2{
        display: flex;
        background-color: #1a2332;
    }

}
@media only screen and (max-width: 1800px){
    .binary-wrap{
        display: flex;
        justify-content: center;
    
      }
      .binary-wrapper{
        display: flex;
        width:60%;
       
    
      }
    .pricing-section-card{
        display: flex;
        justify-content: space-around;
        align-items: start;
        background-color:#1a2332 ;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
        width: 65%;  
        border-radius: 15px;
    }
    .lab-card{
        width:75%
    }
    .lab-list-container{
        margin-top: 3.5rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .lab-desc-list{
        display: flex;
    
        gap: 1rem;
        margin-top: 1.3rem;
        width: 45%;
    }
    .hero-para{
        margin-top: 5.5rem;
        font-size: 2rem;
        text-align: justify;
        margin-bottom: 5.5rem;
    }
.binary-heading-div{
    margin-top: 12rem;
    display: flex;
    justify-content: center;
}
.binary-table-component{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 5rem;
}
/* Binary Difference Section */
.binary-heading{
    width: 60%;
    text-align: center;
   
    font-size: 4rem;
    margin-top: 4.5rem;
}
.binary-col-desc{
    display: flex;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    justify-content: center;
    padding-left: 1.5rem;
    align-items: center;
    text-align: center;
   
   
  
   width: 30rem;
   height: 8rem;
   font-size: 1.5rem;
}
.binary-col-desc3{
    display: flex;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    justify-content: center;
    padding-left: 1.5rem;
    align-items: center;
    text-align: center;
   
    background-color: #06011d;
  
   width: 30rem;
   height: 8rem;
   font-size: 1.5rem;
}
.border-top-class{
    font-weight: 600;
    background-color: #EE3756;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    border-top:3px solid #EE3756;
}
.border-bottom-class{
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    border-bottom:3px solid #EE3756;
}
.binary-col-desc-border{
    display: flex;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    justify-content: center;
    padding-left: 1.5rem;
    align-items: center;
    text-align: center;
    
    border-right:3px solid #EE3756;
    border-left:3px solid #EE3756;
    
   width: 30rem;
   height: 8rem;
   font-size: 1.5rem;
}
.binary-col-desc-border5{
    display: flex;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    justify-content: center;
    padding-left: 1.5rem;
    align-items: center;
    text-align: center;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    border-right:3px solid #EE3756;
    border-left:3px solid #EE3756;
    
   width: 30rem;
   height: 8rem;
   font-size: 1.5rem;
}
.binary-row-desc{
    display: flex;
    
}
.binary-row-desc2{
    display: flex;
    background-color: #1a2332;
}

.video-responsive-cont{
    display: flex;
    justify-content: center;
    
  }
  .video-resp{
    width: 98%;
    height: 40rem;
    display: flex;
    justify-content: center;

  }
  .video-resp iframe{
  
    width: 60%;
    height:100%

  }


}


@media only screen and (max-width: 1300px) {
    
    .lab-card{
        width:80%
    }
    .hero-para{
        margin-top: 5.5rem;
        font-size: 1.7rem;
        text-align: justify;
        margin-bottom: 5.5rem;
    }
    /* Styles for small screens */
   
    .pricing-section{
        margin-top: 4.5rem;
        display: flex;
        justify-content: center;
    }
    .pricing-header{
        text-align: center;
        font-size: 40px;
        margin-top: 16rem;
        font-weight: 600;
    }
    .pricing-section-card{
        display: flex;
        justify-content: center;
        gap:10%;
        align-items: center;
        background-color:#1a2332 ;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        width: 90%;
        border-radius: 15px;
    }
    .pricing-section-card-head{
        font-size: 2.1rem;
        color: #EE3756;
    }
    .pricing-section-card-left{
        width:70%;
    }
    .pricing-section-card-left-desc{
        font-size:1.5rem;
      
    
    }
    .pricing-section-card-left-list-head{
        font-size:1.9rem;
    margin-top: 4.5rem;
    margin-bottom: 2rem;
    padding-left: 1.6rem;
    font-weight: 500;
    }
    .price-feature-list{
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
    }
    .price-list-item{
        display: flex;
        align-items: top;
        justify-content: left;
        gap: 0.3rem;
        font-size: 1.3rem;
        
    }
    .price-list-icon{
        margin-top: 0.5rem;
        color: red;
        width: 18px;
        height:18px;
    }
    .pricing-section-card-right{
        
        height: 100%;
        width: 38%;
        border-radius: 15px;
    background-color: #161e2c;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 6rem;
    padding-right: 6rem;
    box-shadow: #070121;
    }
    .pricing-inside-card-text{
        text-align: center;
        font-size: 1.4rem;
        margin-top: 2rem;
    }
    .pricing-card-price{
        text-align: center;
        font-size: 4rem;
        margin-top: 2rem;
        font-family: 'Roboto', sans-serif;
    }
    .pricing-card-price2{
        text-align: center;
        font-size: 3.5rem;
        margin-top: 0.3rem;
        font-family: 'Roboto', sans-serif;
    }
    .btn-wrapper{
        display: flex;
        justify-content: center;
    }
    .card-price-button{
        cursor: pointer;
        text-align: center;
        font-weight: 600;
        font-size: 1.7rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        margin-top: 0.8rem;
        padding-left: 4.2rem;
        padding-right: 4.2rem;
        background-color: #EE3756;
        border-radius: 3px;
    }









    .binary-heading-div{
        margin-top: 12rem;
        display: flex;
        justify-content: center;
    }
    .binary-table-component{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 5rem;
    }
    /* Binary Difference Section */
    .binary-heading{
        width: 70%;
        text-align: center;
        font-size: 4rem;
        margin-top: 4.5rem;
    }
    .binary-col-desc{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
       width: 35rem;
       height: 8rem;
       font-size: 1.5rem;
    }
    .binary-col-desc-border5{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        border-right:3px solid #EE3756;
        border-left:3px solid #EE3756;
        
       width: 35rem;
       height: 8rem;
       font-size: 1.5rem;
    }
    .binary-col-desc3{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
       
        background-color: #06011d;
      
       width: 35rem;
       height: 8rem;
       font-size: 1.5rem;
    }
    .border-top-class{
        font-size: 2.5rem;
        font-weight: 600;
        background-color: #EE3756;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        border-top:3px solid #EE3756;
        overflow: hidden;  
    }
    .border-bottom-class{
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;
        border-bottom:3px solid #EE3756;
    }
    .binary-col-desc-border{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
        
        border-right:3px solid #EE3756;
        border-left:3px solid #EE3756;
        
       width: 35rem;
       height: 8rem;
       font-size: 1.5rem;
    }
    .binary-row-desc{
        display: flex;
       
    }
    .binary-row-desc2{
        display: flex;
        background-color: #1a2332;
    }
    .video-responsive-cont{
        display: flex;
        justify-content: center;
        
      }
      .video-resp{
        width: 95%;
        height: 35rem;
        display: flex;
        justify-content: center;
    
      }
      .video-resp iframe{
      
        width: 80%;
        height:100%
    
      }

  }

@media only screen and (max-width: 1144px){
    .lab-card{
        width: 90%;
    }
    .lab-list-container{
        margin-top: 3.5rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .lab-desc-list{
        display: flex;
    
        gap: 1rem;
        margin-top: 1.3rem;
        width: 35%;
    }
    .hero-para{
        margin-top: 5.5rem;
        font-size: 1.7rem;
        text-align: justify;
        margin-bottom: 5.5rem;
    }
    .hero-section{

        width: 100%;
        font-family: 'Saira', sans-serif;
        padding-top: 1rem;
        display: flex;
        justify-content:space-between;
        align-items: center;
        color: white;
        padding-left: 5rem;
        padding-right: 5rem;
        gap:5rem;
        background-color: #060218;
     
    
    }
    .hero-desc{
        width: 65%;
        }
        
    .lab-card{
        display: flex;
        justify-content: space-between;
        padding-left: 3rem;
        padding-right: 3rem;
        padding-top: 5rem;
        padding-bottom: 5rem;
        width: 90%;  
        border-radius: 10px;
        gap:6rem;
        background-color: #071122;
    }  


    .lab-card-left-header{
        text-align: center;
        margin-top:15rem;
        margin-bottom: 1rem;
        color: white;
        font-size: 40px;
    
    }
    .lab-card-left{
    width: 60%;
    }
    .lab-card-right{
    background-color: #000000;
    width: 45%;
    }
    .lab-card-left-desc{
        font-size: 16.5px;
        text-align: justify;
        margin-top: 1.2rem;
    }
    .lab-list-container{
        margin-top: 3.5rem;
    }
    .lab-desc-list{
        display: flex;
        gap: 1rem;
        margin-top: 1.3rem;
    }
    .lab-desc-list-icon-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
    background-color:rgb(82 36 36);
    height: 20px;
    width: 20px;
    border-radius: 50%;
    
    }
    .lab-desc-list-icon{
    color: red;
    width: 35px;
    height: 35px;
    padding: 0.1rem;
    
    }
    .lab-list-text{
        font-size: 14px;
    }
    .video-responsive-cont{
        display: flex;
        justify-content: center;
        
      }
      .video-resp{
        width: 95%;
        height: 35rem;
        display: flex;
        justify-content: center;
    
      }
      .video-resp iframe{
      
        width: 80%;
        height:100%
    
      }
}
@media only screen and  (max-width:1054px){
    .hero-para{
        margin-top: 5.5rem;
        font-size: 1.7rem;
        text-align: justify;
        margin-bottom: 5.5rem;
    }
    .hero-desc{
        width: 70%;
        }  
    .hero-section{
        width: 100%;
        font-family: 'Saira', sans-serif;
        padding-top: 1rem;
        display: flex;
        justify-content:space-between;
        align-items: center;
        color: white;
        padding-left: 2rem;
        padding-right: 2rem;
        gap:3rem;
        background-color: #060218;
    }
    .img-container{
        width: 50%;
        display: flex;
        justify-content: center;
        height: 45rem;
        /* Set the background image to cover the entire viewport */
        
        /* Center the background image */
       
        /* Ensure the background image doesn't repeat */
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('../../Assets/binary/sxpbg2.png');
    } 
    .why-stack-section-card{
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color:#071122;
        padding-top: 2rem;
        padding-bottom: 2rem;
        width: 47%;
        border-radius: 15px;
    
    
        -webkit-box-shadow: 10px 10px 97px 0px rgba(2,0,18,1);
        -moz-box-shadow: 10px 10px 97px 0px rgba(2,0,18,1);
        box-shadow: 10px 10px 97px 0px rgba(2,0,18,1);
        
    }
    .lab-card-left-header{
        text-align: center;
        margin-top:15rem;
        margin-bottom: 1rem;
        color: white;
        font-size: 40px;
    
    }
    .lab-card-left{
    width: 48%;
    }
   
    .lab-card-right{
    background-color: #000000;
    width: 45%;
    height: 33rem;
    }
    .lab-card-left-desc{
        font-size: 16.5px;
        text-align: justify;
        margin-top: 1.2rem;
    }
    .lab-list-container{
        margin-top: 3.5rem;
    }
    .lab-desc-list{
        display: flex;
        gap: 1rem;
        margin-top: 1.3rem;
    }
    .lab-desc-list-icon-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
    background-color:rgb(82 36 36);
    height: 20px;
    width: 20px;
    border-radius: 50%;
    
    }
    .lab-desc-list-icon{
    color: red;
    width: 35px;
    height: 35px;
    padding: 0.1rem;
    
    }
    .lab-list-text{
        font-size: 14px;
    }
    .lab-section{
        display: flex;
        justify-content:center;
        font-family: 'Saira', sans-serif;
        margin-top: 4rem;
        color:aliceblue;
    }
    .lab-section-header{
    text-align: center;
    font-size: 3.5rem;
    margin-bottom: 2rem;
    
    }
    .lab-card{
        display: flex;
        justify-content: space-between;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 6rem;
        padding-bottom: 6rem;
        width: 95%;  
        border-radius: 10px;
        gap:3rem;
        background-color: #071122;
    }
    .binary-heading-div{
        margin-top: 12rem;
        display: flex;
        justify-content: center;
    }
    .binary-table-component{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    /* Binary Difference Section */
    .binary-heading{
        width: 60%;
        text-align: center;
       
        font-size: 4rem;
        margin-top: 4.5rem;
    }
    .binary-col-desc{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
       
       
      
       width: 27rem;
       height: 8rem;
       font-size: 1.5rem;
    }
    .binary-col-desc-border5{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        border-right:3px solid #EE3756;
        border-left:3px solid #EE3756;
        
       width: 27rem;
       height: 8rem;
       font-size: 1.5rem;
    }
    .binary-col-desc3{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
       
        background-color: #06011d;
      
       width: 27rem;
       height: 8rem;
       font-size: 1.5rem;
    }
    .border-top-class{
        font-size: 2.5rem;
        font-weight: 600;
        background-color: #EE3756;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        border-top:3px solid #EE3756;
        overflow: hidden;  
    }
    .border-bottom-class{
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;
        border-bottom:3px solid #EE3756;
    }
    .binary-col-desc-border{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
        
        border-right:3px solid #EE3756;
        border-left:3px solid #EE3756;
        
       width: 27rem;
       height: 8rem;
       font-size: 1.5rem;
    }
    .binary-row-desc{
        display: flex;
        
    }
    .binary-row-desc2{
        display: flex;
        background-color: #1a2332;
    }
}
@media only screen and  (max-width:850px){
    .hero-para{
        margin-top: 5.5rem;
        font-size: 1.7rem;
        text-align: justify;
        margin-bottom: 5.5rem;
    }
    .hero-desc{
        width: 70%;
        }  
    .hero-section{
        width: 100%;
        font-family: 'Saira', sans-serif;
        padding-top: 1rem;
        display: flex;
        justify-content:space-between;
        align-items: center;
        color: white;
        padding-left: 2rem;
        padding-right: 2rem;
        gap:3rem;
        background-color: #060218;
    }
    .img-container{
        width: 50%;
        display: flex;
        justify-content: center;
        height: 45rem;
        /* Set the background image to cover the entire viewport */
        
        /* Center the background image */
       
        /* Ensure the background image doesn't repeat */
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('../../Assets/binary/sxpbg2.png');
    } 
    .lab-card-right{
        background-color: #000000;
        width: 45%;
        height: 33rem;
        }
    .why-stack-section-card{
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color:#071122;
        padding-top: 2rem;
        padding-bottom: 2rem;
        width: 80%;
        border-radius: 15px;
    
    
        -webkit-box-shadow: 10px 10px 97px 0px rgba(2,0,18,1);
        -moz-box-shadow: 10px 10px 97px 0px rgba(2,0,18,1);
        box-shadow: 10px 10px 97px 0px rgba(2,0,18,1);
        
    }
   
    .binary-heading-div{
        margin-top: 12rem;
        display: flex;
        justify-content: center;
    }
    .binary-table-component{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    /* Binary Difference Section */
    .binary-heading{
        width: 90%;
        text-align: center;
       
        font-size: 4rem;
        margin-top: 4.5rem;
    }
    .binary-col-desc{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
       
       
      
       width: 27rem;
       height: 8rem;
       font-size: 1.5rem;
    }
    .binary-col-desc3{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
       
        background-color: #06011d;
      
       width: 27rem;
       height: 8rem;
       font-size: 1.5rem;
    }
    .border-top-class{
        font-size: 2.5rem;
        font-weight: 600;
        background-color: #EE3756;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        border-top:3px solid #EE3756;
    }
    .border-bottom-class{
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;
        border-bottom:3px solid #EE3756;
    }
    .binary-col-desc-border{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
        
        border-right:3px solid #EE3756;
        border-left:3px solid #EE3756;
        
       width: 27rem;
       height: 8rem;
       font-size: 1.5rem;
    }
    .binary-row-desc{
        display: flex;
        
    }
    .binary-row-desc2{
        display: flex;
        background-color: #1a2332;
    }
    .lab-desc-list{
        display: flex;
    
        gap: 1rem;
        margin-top: 1.3rem;
        width: 50%;
    }
    .video-responsive-cont{
        display: flex;
        justify-content: center;
        
      }
      .video-resp{
        width: 95%;
        height: 30rem;
        display: flex;
        justify-content: center;
    
      }
      .video-resp iframe{
      
        width: 90%;
        height:100%
    
      }
}
@media only screen and  (max-width:750px){
    .hero-para{
        margin-top: 5.5rem;
        font-size: 1.7rem;
        text-align: justify;
        margin-bottom: 5.5rem;
    }
    .lab-section{
        display: flex;
        justify-content:center;
        font-family: 'Saira', sans-serif;
        margin-top: 4rem;
        color:aliceblue;
    }
    .lab-section-header{
    text-align: center;
    font-size: 3.5rem;
    margin-bottom: 2rem;
    
    }
    .lab-card{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding-left: 4rem;
        padding-right: 4rem;
        padding-top: 6rem;
        padding-bottom: 6rem;
        width: 90%;  
        border-radius: 10px;
        gap:6rem;
        background-color: #071122;
       
    
    }
    .lab-card-left-header{
        text-align: center;
        margin-top:15rem;
        margin-bottom: 1rem;
        color: white;
        font-size: 40px;
    
    }
    .lab-card-left{
    width: 98%;
    }
    .lab-card-right{
    background-color: #000000;
    width: 90%;
    }
    .lab-card-left-desc{
        font-size: 16.5px;
        text-align: justify;
        margin-top: 1.2rem;
    }
    .lab-list-container{
        margin-top: 3.5rem;
    }
    .lab-desc-list{
        display: flex;
        gap: 1rem;
        margin-top: 1.3rem;
    }
    .lab-desc-list-icon-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
    background-color:rgb(82 36 36);
    height: 20px;
    width: 20px;
    border-radius: 50%;
    
    }
    .lab-desc-list-icon{
    color: red;
    width: 35px;
    height: 35px;
    padding: 0.1rem;
    
    }
    .lab-list-text{
        font-size: 14px;
    }
   
    .pricing-section-card{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color:#1a2332 ;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        padding-top: 2.5rem;
     
        width: 80%;
        border-radius: 15px;
        height: 65rem;
        padding-bottom: 35rem;
    }
  
    .pricing-section-card-left{
        width: 90%;
    }

    .price-feature-list{
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
    }
    .price-list-item{
        display: flex;
        align-items: top;
        justify-content: left;
        gap: 0.3rem;
        font-size: 1.3rem;
        
    }
    .price-list-icon{
        margin-top: 0.5rem;
        color: red;
        width: 18px;
        height:18px;
    }
    .pricing-section-card-right{
        
      
        width: 90%;
        border-radius: 15px;
    background-color: #161e2c;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 6rem;
    padding-right: 6rem;
    box-shadow: #070121;
    }
    .pricing-inside-card-text{
        text-align: center;
        font-size: 1.4rem;
        margin-top: 2rem;
    }
    .pricing-card-price{
        text-align: center;
        font-size: 4rem;
        margin-top: 2rem;
        font-family: 'Roboto', sans-serif;
    }
    .pricing-card-price2{
        text-align: center;
        font-size: 3.5rem;
        margin-top: 0.3rem;
        font-family: 'Roboto', sans-serif;
    }
    .btn-wrapper{
        display: flex;
        justify-content: center;
    }
    .card-price-button{
        cursor: pointer;
        text-align: center;
        font-weight: 600;
        font-size: 1.7rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        margin-top: 0.8rem;
        padding-left: 4.2rem;
        padding-right: 4.2rem;
        background-color: #c81c1c;
        border-radius: 3px;
    }
    .video-responsive-cont{
        display: flex;
        justify-content: center;
        
      }
      .video-resp{
        width: 95%;
        height: 35rem;
        display: flex;
        justify-content: center;
    
      }
      .video-resp iframe{
      
        width: 90%;
        height:100%
    
      }
    
    
}


@media only screen and  (max-width:685px){
    .hero-para{
        margin-top: 5.5rem;
        font-size: 1.7rem;
        text-align: justify;
        margin-bottom: 5.5rem;
    }
    .heading-hero{
        font-size: 80px;
    }
    .hero-section{
        width: 100%;
        font-family: 'Saira', sans-serif;
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        padding-left: 5rem;
        padding-right: 5rem;
        gap:10rem;
        background-color: #060218;
     
    
    }
    .hero-header{
        font-size: 6rem;
    }
    .hero-para{
        margin-top: 3.5rem;
        font-size: 1.7rem;
        text-align: justify;
        margin-bottom: 3.5rem;
    }
    .hero-section-btn{
        display: inline;
        background-color: #EE3756;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        font-size: 1.3rem;
        border-radius: 5px;
        cursor: pointer;
    
    }
    .hero-desc{
    width: 95%;
    }
    .pricing-section-card{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color:#1a2332 ;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        padding-top: 2.5rem;
     
        width: 80%;
        border-radius: 15px;
        height: 70rem;
        padding-bottom: 35rem;
    }
    .img-container{
        width: 70%;
        display: flex;
        justify-content: center;
        height: 45rem;
        /* Set the background image to cover the entire viewport */
        
        /* Center the background image */
       
        /* Ensure the background image doesn't repeat */
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('../../Assets/binary/sxpbg2.png');
    }
    .binary-logo{
        margin-top: 6rem;
        width:35rem;
        height:35rem;
    }
    .binary-heading-div{
        margin-top: 12rem;
        display: flex;
        justify-content: center;
    }
    .binary-table-component{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 5rem;
    }
    /* Binary Difference Section */
    .binary-heading{
        width: 60%;
        text-align: center;
       
        font-size: 4rem;
        margin-top: 4.5rem;
    }
    .binary-col-desc{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
        padding-top: 0.5rem;
       
       
      
       width: 25rem;
       height: 10rem;
       font-size: 1.5rem;
    }
    .binary-col-desc-border5{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        border-right:3px solid #EE3756;
        border-left:3px solid #EE3756;
        
       width: 25rem;
       height: 10rem;
       font-size: 1.5rem;
    }
    .binary-col-desc3{
        display: flex;
        padding-top: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
       
        background-color: #06011d;
      
       width: 25rem;
       height: 10rem;
       font-size: 1.5rem;
    }
    .border-top-class{
        font-size: 2.5rem;
        font-weight: 600;
        background-color: #EE3756;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        border-top:3px solid #EE3756;
    }
    .border-bottom-class{
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;
        border-bottom:3px solid #EE3756;
    }
    .binary-col-desc-border{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
        padding-top: 0.5rem;
        
        border-right:3px solid #EE3756;
        border-left:3px solid #EE3756;
        
       width: 25rem;
       height: 10rem;
       font-size: 1.5rem;
    }
    .binary-row-desc{
        display: flex;
       
    }
    .binary-row-desc2{
        display: flex;
        background-color: #1a2332;
    }
    .lab-card-left-header{
        text-align: center;
        margin-top:15rem;
        margin-bottom: 1rem;
        color: white;
        font-size: 30px;
    
    }
    .video-responsive-cont{
        display: flex;
        justify-content: center;
        
      }
      .video-resp{
        width: 95%;
        height: 40rem;
        display: flex;
        justify-content: center;
    
      }
      .video-resp iframe{
      
        width: 95%;
        height:100%
    
      }
}
@media screen and (max-width:500px) {
   
    .binary-container-section{
        display: none;
    }
    .lab-card-right{
        background-color: #000000;
        width: 98%;
        height: 200px;
        }
        .lab-card{
            padding-left: 1rem;
            padding-right: 1rem;
        }
    .hero-para{
        margin-top: 5.5rem;
        font-size: 1.7rem;
        text-align: justify;
        margin-bottom: 5.5rem;
    }
    .binary-col-desc-border5{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        border-right:3px solid #EE3756;
        border-left:3px solid #EE3756;
        
       width: 20rem;
       height: 15rem;
       font-size: 1.5rem;
    }
     .binary-heading{
        width: 90%;
        text-align: center;
       
        font-size: 3rem;
        margin-top: 4.5rem;
    }
    .binary-col-desc{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
        padding-top: 1.5rem;
       
       
      
       width: 20rem;
       height: 15rem;
       font-size: 1.5rem;
    }
    .binary-col-desc3{
        display: flex;
        padding-top: 1.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
       
        background-color: #06011d;
      
       width: 20rem;
       height: 15rem;
       font-size: 1.5rem;
    }
    .border-top-class{
        font-size: 2.5rem;
        font-weight: 600;
        background-color: #EE3756;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        border-top:3px solid #EE3756;
    }
    .border-bottom-class{
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;
        border-bottom:3px solid #EE3756;
    }
    .binary-col-desc-border{
        display: flex;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        justify-content: center;
        padding-left: 1.5rem;
        align-items: center;
        text-align: center;
        padding-top: 1.5rem;
        
        border-right:3px solid #EE3756;
        border-left:3px solid #EE3756;
        
       width: 20rem;
       height: 15rem;
       font-size: 1.5rem;
    }
    .binary-row-desc{
        display: flex;
       
    }
    .binary-row-desc2{
        display: flex;
        background-color: #1a2332;
    }
    .video-responsive-cont{
        display: flex;
        justify-content: center;
        
      }
      .video-resp{
        width: 98%;
        height: 40rem;
        display: flex;
        justify-content: center;
    
      }
      .video-resp iframe{
      
        width: 95%;
        height:100%
    
      }
}
@media screen and (max-width:400px) {
    .hero-para{
        margin-top: 5.5rem;
        font-size: 1.7rem;
        text-align: justify;
        margin-bottom: 5.5rem;
    }
    .pricing-section-card{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color:#1a2332 ;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        padding-top: 2.5rem;
     
        width: 80%;
        border-radius: 15px;
        height: 80rem;
        padding-bottom: 35rem;
    } 
    .hero-desc{
        width: 98%;
        } 
        .hero-section{
            width: 100%;
            font-family: 'Saira', sans-serif;
            padding-top: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: white;
            padding-left: 3rem;
            padding-right: 3rem;
            gap:10rem;
            background-color: #060218;
         
        
        }
}


  
@media screen and (max-width:324px) {
   
    .hero-para{
        margin-top: 5.5rem;
        font-size: 1.7rem;
        text-align: justify;
        margin-bottom: 5.5rem;
    }
    .pricing-section-card{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color:#1a2332 ;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        padding-top: 2.5rem;
     
        width: 98%;
        border-radius: 15px;
        height: 95rem;
        padding-bottom: 35rem;
    } 
    .hero-desc{
        width: 99%;
        } 
        .hero-section{
            width: 100%;
            font-family: 'Saira', sans-serif;
            padding-top: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: white;
            padding-left: 1rem;
            padding-right: 1rem;
            gap:10rem;
            background-color: #060218;
         
        
        }
        .binary-logo{
            margin-top: 6rem;
            width:35rem;
            height:35rem;
        }
        .binary-heading-div{
            margin-top: 12rem;
            display: flex;
            justify-content: center;
        }
        .binary-table-component{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 5rem;
        }
        /* Binary Difference Section */
        .binary-heading{
            width: 60%;
            text-align: center;
           
            font-size: 4rem;
            margin-top: 4.5rem;
        }
        .binary-col-desc{
            display: flex;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            justify-content: center;
            padding-left: 1.5rem;
            align-items: center;
            text-align: center;
            padding-top: 0.5rem;
           
           
          
           width: 20rem;
           height: 10rem;
           font-size: 1rem;
        }
        .binary-col-desc3{
            display: flex;
            padding-top: 0.5rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            justify-content: center;
            padding-left: 1.5rem;
            align-items: center;
            text-align: center;
           
            background-color: #06011d;
          
           width: 20rem;
           height: 10rem;
           font-size: 1rem;
        }
        .border-top-class{
            font-size: 2.5rem;
            font-weight: 600;
            background-color: #EE3756;
            border-top-left-radius: 40px;
            border-top-right-radius: 40px;
            border-top:3px solid #EE3756;
        }
        .border-bottom-class{
            border-bottom-left-radius: 40px;
            border-bottom-right-radius: 40px;
            border-bottom:3px solid #EE3756;
        }
        .binary-col-desc-border{
            display: flex;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            justify-content: center;
            padding-left: 1.5rem;
            align-items: center;
            text-align: center;
            padding-top: 0.5rem;
            
            border-right:3px solid #EE3756;
            border-left:3px solid #EE3756;
            
           width: 20rem;
           height: 10rem;
           font-size: 1rem;
        }
        .binary-row-desc{
            display: flex;
           
        }
        .binary-row-desc2{
            display: flex;
            background-color: #1a2332;
        }
}
.card-price-link{
    text-decoration: none;
    color: white;
}
.card-price-link:hover{
    text-decoration: none;
    color: white;
}



.video-section{
    padding-bottom: 10rem;
}
.why-stack-section-header2{
    text-align: center;
    font-size: 4.5rem;
    margin-top: 1rem;
    font-weight: 600;
    

}
.why-stack-section-header2 span{
    background: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    -webkit-background-clip: text;
    color: transparent;
    background-clip: text;
}







