@import url('https://fonts.googleapis.com/css2?family=Saira&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@600&display=swap');
.footer-top{
    background-image:url(/src/Assets/footer/Rectangle\ 1144.svg);
background-position: center;
background-repeat: no-repeat;
background-size: cover;
    height: auto;
    width: 100%;
}
.footerbtn{
    background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    color: #EAECEC;
    font-family: 'Saira', sans-serif;
  letter-spacing: .2rem;
  
  height: auto;
  margin-top: 5rem;
  border-radius: 1rem;
  font-weight: 300;
  font-size: 1.4rem;
  padding: 2.5rem 4rem;
  margin-bottom: 10rem;
}
.footerspans{
    background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    padding:.1rem .3rem;
    border-radius: .1rem;
    margin-right: 1rem;
}
ul li{
    color: #EAECEC;
    list-style: none;
    font-family: 'Saira', sans-serif;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 5rem;


}
#Footerlinks{
text-decoration: none!important;
color: white;
}
.footerlogo{
    height:8rem;
    width: 8rem;
}
.footerspan{
    font-family: 'Advent Pro', sans-serif;
    color: white;
    font-size: 3.2rem;
    font-weight: 600;
    margin-left: 1.8rem;
    letter-spacing: .5rem;
}

.footerpara{
    font-family: 'Saira', sans-serif;
    color: white;
    font-size: 1.8rem;
    transform: translateX(85px);
    font-weight: 600;
    margin-left: 1rem;
    letter-spacing: .3rem;
}
.footermailpara{
    font-family: 'Saira', sans-serif;
    color: white;
    font-size: 1.9rem;
    transform: translateX(85px);
    font-weight: 500;
    margin-left: 1rem;
    
    margin-top: 1.5rem;
    text-decoration: underline;
}
@media (max-width: 575px) {
  
    .footerpara{
        transform: translateX(0rem);
        margin-top: 4rem;
    }
    .footermailpara{
        transform: translateX(0rem);
    }
  }
  
  
  @media (max-width: 768px) {
    .footerpara{
        transform: translateX(0rem);
        margin-top: 4rem;
    }
    .footermailpara{
        transform: translateX(0rem);
    }
     
}
      @media (max-width:640px) {
        .footerpara{
            transform: translateX(0rem);
            margin-top: 4rem;
        }
        .footermailpara{
            transform: translateX(0rem);
        }
        
        }
  