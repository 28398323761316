.marqueue-wrapper{
    color:white;
    padding: 4.8rem 3rem;
    color: white;
    font-size: 4rem;
  
}
.marqueue-text{
 overflow: clip;

    
}
.fadeout-horizontal{
    mask-image: linear-gradient(to right,transparent,black 2rem,black calc(100% - 2rem),transparent);
}
.marqueue-text p{
    flex-grow: 0;
    flex-shrink: 0;

border-radius: 999px;
padding: 1rem 2.5rem;
font-size: 2rem;
cursor:default;
transition: all 0.3s ease-in-out;
}
.marqueue-text-track{
    display: flex;
    animation: marqueue-move-text 30s linear infinite;
    width: max-content;
    gap:2rem;
    padding-left: 2rem;  
   
}
.marqueue-text-item-1{
    color: #b3e3f7;
    border:1px solid #b3e3f7;
}
.marqueue-text-item-1:hover{
    background-color: #b3e3f7;
    color: black;
}
.marqueue-text-item-2{
    color: #FdF0C8;
    border:1px solid #FdF0C8;
}
.marqueue-text-item-2:hover{
    background-color: #FdF0C8;
    color: black;
}
.marqueue-text-item-3{
    color: #AAE5CC;
    border:1px solid #AAE5CC;
}
.marqueue-text-item-3:hover{
    background-color: #AAE5CC;
    color: black;
}
.marqueue-text-item-4{
    color: #C2B2E5;
    border:1px solid #C2B2E5;
}
.marqueue-text-item-4:hover{
    background-color: #C2B2E5;
    color: black;
}


@keyframes marqueue-move-text {
    to{
        transform: translate(-50%);
    }
}


@media screen and (min-width:600px){
    .marqueue-wrapper{
        color:white;
        padding: 4.8rem 8rem;
        color: white;
        font-size: 4rem;
      
    }
    .fadeout-horizontal{
        mask-image: linear-gradient(to right,transparent,black 3rem,black calc(100% - 3rem),transparent);
    }
}






@media screen and (min-width:1300px) {
    .marqueue-wrapper{
        color:white;
        padding: 4.8rem 15rem;
        color: white;
        font-size: 4rem;
      
    }
    .marqueue-text{
     overflow: clip;
    
        
    }
    .fadeout-horizontal{
        mask-image: linear-gradient(to right,transparent,black 5rem,black calc(100% - 5rem),transparent);
    }
    .marqueue-text p{
        flex-grow: 0;
        flex-shrink: 0;
    
    border-radius: 999px;
    padding: 1rem 2.5rem;
    font-size: 2rem;
    cursor:default;
    transition: all 0.3s ease-in-out;
    }
    .marqueue-text-track{
        display: flex;
        animation: marqueue-move-text 30s linear infinite;
        width: max-content;
        gap:4.8rem;
        padding-left: 4.8rem;  
       
    }
    .marqueue-text-item-1{
        color: #b3e3f7;
        border:1px solid #b3e3f7;
    }
    .marqueue-text-item-1:hover{
        background-color: #b3e3f7;
        color: black;
    }
    .marqueue-text-item-2{
        color: #FdF0C8;
        border:1px solid #FdF0C8;
    }
    .marqueue-text-item-2:hover{
        background-color: #FdF0C8;
        color: black;
    }
    .marqueue-text-item-3{
        color: #AAE5CC;
        border:1px solid #AAE5CC;
    }
    .marqueue-text-item-3:hover{
        background-color: #AAE5CC;
        color: black;
    }
    .marqueue-text-item-4{
        color: #C2B2E5;
        border:1px solid #C2B2E5;
    }
    .marqueue-text-item-4:hover{
        background-color: #C2B2E5;
        color: black;
    }
    
    
    @keyframes marqueue-move-text {
        to{
            transform: translate(-50%);
        }
    }
    
    
}
