
.course-cur-head{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    font-size: 4rem;
    font-weight: 600;
}
.binaryhr2{
     background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    height: 0.6rem;
 
    border-radius: 10px;
    width: 23rem;
  }
  .binaryhr3{
     background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    height: 0.6rem;
    border-radius: 10px;
    width: 11rem;
  }
.bg-curriculum{
    width: 90%;
    background-color:#071122;
font-size: 1.7rem;
border-radius: 20px;
margin-top: 2rem;
}
.curriculum-container{
    padding-top: 5rem;
    padding-bottom: 5rem;
    display: flex;
    flex-wrap: wrap;
  justify-content: center;
    gap: 2rem;
}
.section-name{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 4rem;
    text-align: left;
  
    background-image: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
   
    filter: drop-shadow(0px 0px 7px rgba(255,255,255,0.03));
box-shadow:  -5px 10px 15px 3px 	rgba(12,5,18,.85),-4px 5px 10px 3px rgb(3, 28, 57,1); 
    padding-left: 2rem;
    padding-right:2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    cursor: pointer;
    font-family: 'Saira', sans-serif;
    font-weight: 600;
}
.section-container{
    width: 45%;
   
}
.section-description{
    color: rgb(220, 220, 245);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-right: 4rem;
    padding-left: 2rem;
    padding-right:2rem;
    text-align: justify;
    font-size: 1.5rem;
    background-image: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
   border-radius: 1rem;
    filter: drop-shadow(0px 0px 3px rgba(255,255,255,0.03));
box-shadow:  -5px 5px 5px 3px 	rgba(12,5,18,.85),-4px 5px 7px 3px rgb(3, 28, 57,1); 
    font-family: 'Saira', sans-serif;
 margin-top: 1rem;
    font-weight: 500;
    background-size: cover;
    
}
.download-btn-cont{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 6rem; 
  
}

.download-btn-cont .download-btn{
    text-decoration: none;
    color: white;
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
font-size: 1.5rem;
    padding-top: 1rem;
    background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    padding-bottom:1rem ;
    font-family: 'Saira', sans-serif;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    
}
.download-btn:hover{
    color: white;
    text-decoration: none;
}
.margin-class{
    margin-top: 4rem;
}

@media screen and (max-width: 718px) {
    .course-cur-head {
        margin-top: 8rem;
        font-size: 2.5rem;
    }

    .bg-curriculum {
        margin-top: 8rem;
        font-size: 1.5rem;
    }

    .curriculum-container {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .section-name {
        margin-right: 2rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .section-container {
        width: 100%;
    }

    .section-description {
        margin-right: 2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .download-btn-cont {
        margin-bottom: 3rem;
    }

    .download-btn {
        font-size: 1.4rem;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .margin-class {
        margin-top: 2rem;
    }
}