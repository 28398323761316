@import url('https://fonts.googleapis.com/css2?family=Saira&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Saira:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Saira:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@700&family=Saira:wght@600&display=swap');

.commontopper{
    background:url(../../../Assets/Dashboard/Dashboard\ Bg.svg);
    width: 101%;
    height: auto;
   margin-left: -.5rem;
    margin-top: 1rem!important;
    border-top-left-radius: 4rem;
    border-top-right-radius: 4rem;
  
  overflow: hidden;
    
  filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
  box-shadow:  0px -8px 20px 1px 	rgba(12,5,18,.85),0px -8px 10px 1px rgb(3, 28, 57,.5);
}
.form-input .feedbacklabel{

    color:white;
    font-family: 'Saira', sans-serif;
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 3rem;
    margin-left: 1rem;
    
}
.form-input input::placeholder {
  font-size: 14px; /* Adjust the font size as needed */
  /* Add any other styles you want for the placeholder */
}
.form-input select {
  width: 100%;
  border-radius: 1rem;
  height: auto;
  padding: 1.6rem;
  margin-bottom: 2rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../../../Assets/Dashboard/down-arrow.png) 9% / 2% no-repeat
    #eee;
  color: white!important;
  background-color: transparent;
  border: none;
  cursor: pointer;

  font-family: "Saira", sans-serif;
  box-shadow: inset -1.5px -0.7px 4.5px #332b82, inset 4px 4px 4px black;
}
select:focus,
select:hover {
  outline: none;
  background-color: transparent !important;
  border: none;
}
.select option {
  border: none;
  letter-spacing: 1.2px;
  font-weight: 400;
  font-size: 18px;
  border-radius: 2rem;
  background: rgba(255,255,255,0.15);
  background-size: cover;
}
.select .options {
  font-size: 1rem;
}
.commonhead{
    color: white;
      padding-top: 4rem;
      padding-left: 0rem;
      font-family: 'Advent Pro', sans-serif;
      font-weight: 700;
      font-size: 4rem;
      letter-spacing: .4rem;
      padding-bottom: 2rem;
      text-transform: uppercase;
  } 
  .commonhead2{
    color: white;
      padding-top: 2rem;
      padding-left: 2rem;
      font-family: 'Saira', sans-serif;
      font-weight: 500;
      font-size: 3rem;
      letter-spacing: .2rem;
      padding-bottom: 2rem;
      text-transform:capitalize;

  }
  .commonheadhr{
    background-color: red;
    height: .2rem;
   justify-content: center;
    width: 12rem;
  }
  .Commonpara{
    color:gray;
   
    font-family: 'Saira', sans-serif;
    font-weight: 600;
   
    font-size: 1.5rem;
  padding-bottom: 2rem;
    text-align: center;
   justify-content: flex-start;
    letter-spacing: .05rem;
    line-height: 2.5rem;
  }
  .ticketbtn{
    background-image: radial-gradient(circle, #e32f12, #e9282b, #ed233f, #ee2451, #ee2963);
    padding: 1.3rem 4rem;
    color: whitesmoke;
    font-family: 'Saira', sans-serif;
    font-weight: 600;
    font-size: 1.3rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
    border-radius: .7rem;
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow:  2px 4px 20px 5px 	rgba(12,5,18,.85),8px -2px 20px 5px rgb(3, 28, 57,1);
  }
  .feedbackbtn{
    background-image: radial-gradient(circle, #e32f12, #e9282b, #ed233f, #ee2451, #ee2963);
    padding: 1.3rem 8rem;
    color: whitesmoke;
    font-family: 'Saira', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 5rem;
    margin-top: 5rem;
    border-radius: .7rem;
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow:  2px 4px 20px 5px 	rgba(12,5,18,.85),8px -2px 20px 5px rgb(3, 28, 57,1);
  }
  /*notification start */
  .notificationbtns{
    background-image: radial-gradient(circle, #e32f12, #e9282b, #ed233f, #ee2451, #ee2963);
    padding: 1.3rem 8rem;
    color: whitesmoke;
    font-family: 'Saira', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-top: 5rem;
    border-radius: .7rem;
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow:  2px 4px 20px 5px 	rgba(12,5,18,.85),8px -2px 20px 5px rgb(3, 28, 57,1);
  }
  #notificationdiv {
    background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
    background-size: contain;
    height: auto;
    background-repeat: repeat;
    width: 100%;
    border-radius: 1.2rem;
   
    padding: 1.3rem;
    margin-top: 4rem;
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow:  2px 10px 20px 10px 	rgba(12,5,18,.85),8px -5px 10px 5px rgb(3, 28, 57,1);
  }
  .quertypepara{
    font-family: "Saira", sans-serif;
    font-weight: 600;
    font-size: 1.3rem;
    transform: translateY(50%);
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

  }
  .quertypepara:hover{
    overflow: visible;
  }
  .quertydescpara{
    font-family: "Saira", sans-serif;
    font-weight: 400;
    font-size: 1.3rem;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
    transform: translateY(50%);
    color: white;
    text-transform: capitalize;
  }
  .quertydescpara:hover{
    overflow: visible;
  }
  .entitytypepara{
    color: grey;
    font-size: 1.1rem;
    padding-top: 1.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: italic;
    font-family: "Saira", sans-serif;
    font-weight: 500;
  }
  .entitytypepara:hover{
    overflow: visible; 
  }
  #notificationdiv .para3 {
    color: grey;
    font-size: 1.1rem;
    padding-top: 1.8rem;
    font-style: italic;
    font-family: "Saira", sans-serif;
    font-weight: 500;
  }
  @media screen and (max-width: 640px){
    .notificationbtn{
      display: none;
    }
    .Faq_question{
      font-size:1.2rem;
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  overflow: hidden;
    }
    #faqcard .Labbtn{
      display: none;
    }
  }
 
  .notificationbtn{
    background: url("../../../Assets/Transaction/Rectangle\ 1134.svg");
    height: 2rem;
    width: 2rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    position: relative;
    
    border-radius: 50%;
    text-align: center;

    filter: drop-shadow(0px 0px 5px rgba(255,255,255,0.03));
      box-shadow:  5px 0px 5px .1px 	#020d24,2px -2px 5px .1px #031c39;
  }
  .notificationbtn .icon{
    color: wheat;
    font-size: 1.8rem;
    position: absolute;
    margin-left: -.9rem;
    margin-top: .2rem;
  }
  /* notification ends */

  .form-input input[type="radio"]{
    position: relative;
    width: 2rem;
    height: 2rem;
  display: none;
    border-radius: 50%;
    box-shadow:inset -4px -4px 6px #000715,
    inset 6px 6px 6px  black;
    background-color: #000715;
  }
  .form-input input[type="radio"]::before,
  .form-input input[type="radio"]::after{
    content: '';
    position: absolute;
    top: 10%;
    left: 10%;
    width: 100%;
    height: 80%;
    border-radius: 50%;
  
  }
  .form-input input[type="radio"]:after{
    background-color: #000715;
    box-shadow:inset -4px -4px 2px #000715,
    inset 4px 4px 4px  black;
  }
  .form-input input[type="radio"]:checked:after{
    background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    box-shadow:inset -4px -4px 6px #e32f1c,
    inset 6px 6px 6px  #ec2542;
    width: 50%;
    height: 50%;
   margin-top: 10%;
   margin-left: 10%;
  }
  .courdserad {
    font-family: "Saira", sans-serif;
    font-weight: 500;
    height: 3.9rem;
    background: url(/src/Assets/Transaction/Rectangle\ 1134.svg);
    background-size: cover;
    width: 14rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    cursor: pointer;
    padding-top: 1.2rem;
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow:  2px 10px 20px 7px 	rgba(12,5,18,.85),8px -5px 10px 10px rgb(3, 28, 57,1);
    color: white;
    font-size: 1.1rem;
    border-radius: 0.2rem;
    text-align: center !important;
    transition: 0.5s;
  }
  input[type="radio"]:checked + .courdserad {
    color: #ffffff;
    
    background: linear-gradient(
      60deg,
      #e32f1c,
      #e62b28,
      #e92833,
      #eb263d,
      #ed2547,
      #ee2550,
      #ee265a,
      #ee2963
    );
  }
  /* ticket start */

  .ticketdiv{
    background:url(../../../Assets/Dashboard/AuthFormBg_1.svg);
    background-position: center;
    background-repeat: repeat;
    height:auto;
    width: 100%;
    background-size: cover;
   margin-top: 7rem;
    border-radius: 1rem!important;
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow:  2px 10px 20px 5px 	rgba(12,5,18,.85),8px -5px 20px 5px rgb(3, 28, 57,1);
  }
 
.tickertpendiv{
  background:url(../../../Assets/Dashboard/AuthFormBg_1.svg);
  background-position: center;
  background-repeat: no-repeat;
  height:auto;
padding: 2rem;
margin: 0rem;
margin-top: -4rem;
  filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
  box-shadow:  2px 10px 20px 5px 	rgba(12,5,18,.85),8px -5px 20px 5px rgb(3, 28, 57,1);

  border-radius: 1rem;
  background-size: cover;
}
  .tickertresoldiv{
    background:url(../../../Assets/Dashboard/AuthFormBg_1.svg);
    background-position: center;
    background-repeat: no-repeat;
    height:auto;
padding: 2rem;
margin: 0rem;
margin-top: -4rem;
    filter: drop-shadow(0px 0px 6px rgba(255,255,255,0.03));
    box-shadow:  2px 10px 20px 2px 	rgba(12,5,18,.85),8px -5px 20px 2px rgb(3, 28, 57,1);
    border: 2px solid red;
    border-radius: 1rem;
    background-size: cover;


  }
  #tickethead{
    color: white;
    font-size: 1.2rem;
    font-family: "Saira", sans-serif;
    font-weight: 700;
    padding-left: .5rem;
    padding-top: .5rem;
  }
  #tickethead2{
    color: white;
    font-size: .8rem;
    font-family: "Saira", sans-serif;
    font-weight: 400;
   
  
padding-left: 6rem;
    padding-top: 1rem;
  }
  .ticketpara{
    color: gray;
    font-size: 1.1rem;
    font-family: "Saira", sans-serif;
    font-weight: 500;
  }
  .tickettypehead{
    color: white;
    font-size: 1rem;

    font-family: "Saira", sans-serif;
    font-weight: 500;
    letter-spacing: 0rem;
    margin-left: -1rem;
  }
  .tickettypehead2{
    color: gray;
    white-space: nowrap;
    font-size: 1rem;
    font-family: "Saira", sans-serif;
    font-weight: 400;
    font-style: italic;
    text-overflow:ellipsis;
    letter-spacing: 0rem;
    overflow: auto;
  }
  /* subscription portion starts */
  .curentplanhead{
    color: white;
    margin-top: 4rem;
    margin-bottom: 3rem;
    font-family: "Saira", sans-serif;
    font-size: 2.5rem;
    font-weight: 500;
    letter-spacing: .1rem;
  }
  .subsplanpara{
    color: gray;
    font-family: "Saira", sans-serif;
    font-weight: 400;
    font-size: 1.3rem;
    transform: translateY(1.5rem);
  }
  #subscard{
   
    background: #01152b;
    background: -webkit-radial-gradient(top left, #01152b, #040b18);
    background: -moz-radial-gradient(top left, #01152b, #040b18);
    background: radial-gradient(to bottom right, #01152b, #040b18);
    height: auto;
    width: 100%;
    border: 1px solid red;
    border-radius: 1rem;
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow:  0px -4px 15px 10px 	rgba(12,5,18,.85),0px -4px 15px 10px rgb(3, 28, 57,.5);
  }

  #subscard2{
   
    margin-bottom: 5rem;
  margin-top:9rem;
    background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
    height: auto;
    width: 100%;
    border-radius: 1rem;
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    background-position: center;
    background-repeat: no-repeat;
 transform: scale(1.2);
    background-size: cover;
    box-shadow:  0px -2px 15px 7px 	rgba(12,5,18,.85),0px -2px 15px 7px rgb(3, 28, 57,.5);
  }
  
  
  .subplanhead{
    color: white;
    font-family: "Saira", sans-serif;
    font-size: 2.4rem;
    font-weight: 600;
 text-transform: capitalize;
    justify-content: center;

  }

  .subplanpara{
    color: white;
    font-family: "Saira", sans-serif;
    font-weight: 600;
    letter-spacing: .1rem;
    font-size: 2rem;
  }
  .subplanparadspan{
    color: gray;
    font-size: 1.2rem;
    margin-right: .2rem;
    letter-spacing: 0!important;
   margin-top: -.5rem;

   
   
  }
  .subplanhr{
    background-color: gray;
    width:100%;
    height: .08rem;
  }

  .subplanfeaturepara{
    color: white;
    font-family: "Saira", sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 4rem;
 
    margin-bottom: none!important;
    letter-spacing: 0!important;
  }
  .subplanfeaturepara2{
    color: white;
    font-family: "Saira", sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 4rem;
 
    margin-bottom: none!important;
    letter-spacing: 0!important;
  }
  .subsplansubscribebtn{
    background-image: radial-gradient(circle, #e32f12, #e9282b, #ed233f, #ee2451, #ee2963);
   width: 100%;
    color: white;
    font-family: "Saira", sans-serif;
    padding: 1rem;
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow:  2px 4px 20px 5px 	rgba(12,5,18,.85),8px -2px 20px 5px rgb(3, 28, 57,1);
    border-radius: .6rem;
  }
  .subsplansubscribebtn2{
    background-image:url(../../../Assets/Transaction/Rectangle\ 1134.svg);
    border: 2px solid red;
    width: 100%;
     color: white;
     font-family: "Saira", sans-serif;
     padding: 1rem;
     filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
     box-shadow:  2px 4px 20px 5px 	rgba(12,5,18,.85),8px -2px 20px 5px rgb(3, 28, 57,1);
     border-radius: .6rem;
  }
  #scorecards{
    background:url(../../../Assets/Scorecard/net3\ 2.png);
   
 background-position: center;
 background-repeat: repeat;
 background-size: cover;
 margin-top: 2.5rem;
 margin-bottom: 2.5rem;
 border-radius: 3.8rem;
height: auto;
 filter: drop-shadow(0px 0px 7px rgba(255,255,255,0.03));
 box-shadow:  -5px 10px 15px 3px 	rgba(12,5,18,.85),-4px 5px 15px 3px rgb(3, 28, 57,1); 
  }
  #userhomeslide{
    background:url(../../../Assets/Transaction/Rectangle\ 1134.svg);
   
 background-position: center;
 background-repeat: repeat;
 background-size: cover;
 margin-top: 2.5rem;
 margin-bottom: 2.5rem;
 border-radius: 3.8rem;
height: auto;
 filter: drop-shadow(0px 0px 7px rgba(255,255,255,0.03));
 box-shadow:  -5px 10px 15px 3px 	rgba(12,5,18,.85),-4px 5px 15px 3px rgb(3, 28, 57,1);  
  }
  #userhomeslide2{
    background:url(../../../Assets/Transaction/Rectangle\ 1134.svg);
    padding: 0rem;
    height:auto;
    width:100%;
    filter: drop-shadow(0px 0px 7px rgba(255,255,255,0.03));
 box-shadow:  -5px 10px 15px 3px 	rgba(12,5,18,.85),-4px 5px 15px 3px rgb(3, 28, 57,1); 
    background-repeat: repeat;
    background-size: cover;
    margin: 2.5rem;
    border-radius: 3rem;
  }
  #userhomeslide2 h2{
    color: white;
    font-size: 1.15rem;
    text-align: center;
    font-family: "Saira", sans-serif;
    font-weight: 500;
    margin-top: 2rem;
    letter-spacing: 0rem;
    margin-bottom: 1.4rem;
  }
  #userhomeslide2 h1{
    color: white;
    font-size: 2.5rem;
    text-align: center;
    font-family: "Saira", sans-serif;
    font-weight: 600;
    margin-top: 1.5rem;
    margin-bottom: 1.2rem;
  }
  #userhomeslide3{
    background:url(../../../Assets/Transaction/Rectangle\ 1134.svg);
 width: 100%;
    margin: .9rem;
  }
  #userhomeslide4{
    background:url(../../../Assets/Dashboard/Mask_Group_31_bdl.png);
    background-repeat: no-repeat;
 height: auto;
 position: relative;
    background-size: contain;
    background-position: right;
    padding-bottom: 0rem;
    margin-top: -2.5rem;
 width: 100%;

  }
  #userhomeslide5{
    background:url(../../../Assets/Dashboard/Mask_Group_31_bd.png);
    background-repeat: no-repeat;
 height: auto;
 position: relative;
    background-size: contain;
    background-position: right;
    padding-bottom: 0rem;
    margin-top: -2.5rem;
 width: 100%;

  }

  #userhomeslide6{
    background:url(../../../Assets/Dashboard/Mask_Group_31_byr.png);
    background-repeat: no-repeat;
 height: auto;
 position: relative;
    background-size: contain;
    background-position: right;
    padding-bottom: 0rem;
    margin-top: -2.5rem;
 width: 100%;

  }
 .activityloghead{
   color: white;
   text-align: center;
   padding: 3rem;
   font-size: 1.7rem;
   font-family: "Saira", sans-serif;
   font-weight: 500;
   padding-bottom: 0rem;
   letter-spacing: 0rem;
 }
 .activityloghead2{
  color: white;
  text-align:start;

  font-size: 2.1rem;
  font-family: "Saira", sans-serif;
  font-weight: 500;
  padding-top:3rem;
  padding-left: 2rem;
  padding-bottom: 0rem;
  letter-spacing: 0.1rem;
}
 .activityloghr{
   background-color: red;
   text-align: center!important;
   justify-content: center;
   height: .03rem;
   width: 80%;
 }
 .activityheds{
   color: white;
   font-family: "Saira", sans-serif;
   font-weight: 500;
   font-size: 1.4rem;
 padding: 1rem;
   padding-bottom: 0rem;
 }
 .activityheds2{
  color: lightgrey;
  font-family: "Saira", sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
padding-left: 1rem;
text-align:justify;
margin-top: .2rem;
  padding-bottom: 0rem;
 }
 
 .dashboardsubbtn{
  background-image: radial-gradient(circle, #e32f12, #e9282b, #ed233f, #ee2451, #ee2963);
  width: 100%;
   color: white;
   font-family: "Saira", sans-serif;
   padding: .6rem;
   filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
   box-shadow:  2px 4px 20px 5px 	rgba(12,5,18,.85),8px -2px 20px 5px rgb(3, 28, 57,1);
   border-radius: .6rem;
   border: none;
 }
 .dashboardsubbtn p{
   color: white;
   font-size: 1.2rem;
   font-family: "Saira", sans-serif;
   font-weight: 500;
   margin-top: .4rem;
   text-transform: uppercase;
 }
 .activityspans{
   color: grey;
   font-style: italic;
   text-transform: capitalize;
   position: absolute;
   font-family: "Saira", sans-serif;
   font-weight: 400;
   font-size: 1rem;
   margin-top: -2.3rem;
   padding: 1rem;
 }
 .userrankhead{
   color: white;
  font-size:1.9rem;
  letter-spacing: 0;
  position: absolute;
  font-family: "Saira", sans-serif;
  font-weight: 400;
 padding-top: 4rem;
 padding-left: 1rem;
 }
 .userrankhead2{
   color: white;
   font-family: "Saira", sans-serif;
   font-weight: 600;
position: absolute;
font-size: 2.2rem;
padding-top: 3.5rem;
 padding-right: 2rem;

 }
 .notickethead{
  font-family: "Saira", sans-serif;
  font-weight: 600;
  letter-spacing: 0rem;
  text-align: center;
padding: 1rem;


 }
 .scorehead{
   color: white;
   font-family: "Saira", sans-serif;
   font-weight: 600;
 }
 .scorespans{
   color: #e62b28;
   font-size: .5rem;
   position:absolute;
   margin-top: 3rem;
 }
 .di{
  background: url(../../../Assets/Dashboard/Mask_Group_32@2x.png);
 float:right;
 border-radius: 2rem;
background-repeat: no-repeat;
  background-size: cover;

 }
 .di2{
  background: url(../../../Assets/Dashboard/Mask_Group_41_cyk@2x.png);
 float:right;
 border-radius: 2rem;
background-repeat: no-repeat;
  background-size: contain;

 }
 .di3{
  
  background: url(../../../Assets/Dashboard/Mask_Group_31_bdl.png);
 float:right;
 border-radius: 2rem;
background-repeat: no-repeat;
  background-size: cover;
 }
 .usernamechallhead{
   color: white;
   font-family: "Saira", sans-serif!important;
   font-size: 1.35rem!important;
 }
 /*verufypayment css*/
 .verifysubcriptionhead{
  font-family: "Saira", sans-serif!important;
  color: white;
  font-weight: 600;
  font-size: 3rem;
  text-transform: capitalize;
 }
 .verifysubcriptionhead2{
  font-family: "Saira", sans-serif!important;
  color: white;
  font-weight: 600;
  font-size: 2rem;
  text-transform: capitalize;
 }
 .verifysubcriptionhead3{
  font-family: "Saira", sans-serif!important;
  color: white;
  font-weight: 600;
  font-size: 2.5rem;
  text-transform: capitalize;
 }
 .verifycardhead{

   font-family: "Saira", sans-serif!important;
   color: white;
   font-weight: 600;
   font-size: 1.5rem;
   text-transform: capitalize;
   float: left;
 }
 .verifycardhead2{
  color: grey;
  font-family: "Saira", sans-serif!important;

  font-weight: 500;
  font-size: 1.5rem;
  text-transform: capitalize;
  float: right;
}
.card-basic {

  width: 100%;
border:1px solid #EF7F1A;
  border-radius: 1rem;
   
  
  background-image: url(../../../Assets/subsplan/basic.png);
    

  /* Set your desired color for basic plans */
}

.card-vip {
  width: 100%;
  border:1px solid #3FA9F4;
  border-radius: 1rem;
   
  
  background-image: url(../../../Assets/subsplan/vip.png);
   /* Set your desired color for VIP plans */
}

.card-enterprise {
  width: 100%;
  border:1px solid #009846;
  border-radius: 1rem;
  background-image: url(../../../Assets/subsplan/enterprise.png);
    
 /* Set your desired color for enterprise plans */
}
.yellowcolor{
  color: #EF7F1A;
}
.bluecolor{
  color: #3FA9F4;
}
.greencolor{
  color: #009846;
}
.basicplanbutton{
  background:#EF7F1A;
  width: 100%;
   color: white;
   font-family: "Saira", sans-serif;
   padding: 1rem;
   filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
   box-shadow:  2px 4px 20px 5px 	rgba(12,5,18,.85),8px -2px 20px 5px rgb(3, 28, 57,1);
   border-radius: .6rem;
   font-size: 1.5rem;
}
.vipplanbutton{
  background:#3FA9F4;
  width: 100%;
   color: white;
   font-family: "Saira", sans-serif;
   padding: 1rem;
   filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
   box-shadow:  2px 4px 20px 5px 	rgba(12,5,18,.85),8px -2px 20px 5px rgb(3, 28, 57,1);
   border-radius: .6rem;
   font-size: 1.5rem;
}
.enterpriseplanbutton{
  background:#009846;
  width: 100%;
   color: white;
   font-family: "Saira", sans-serif;
   padding: 1rem;
   filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
   box-shadow:  2px 4px 20px 5px 	rgba(12,5,18,.85),8px -2px 20px 5px rgb(3, 28, 57,1);
   border-radius: .6rem;
   font-size: 1.5rem;
}