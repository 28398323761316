
@import url('https://fonts.googleapis.com/css2?family=Saira&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Saira:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@700&family=Saira:wght@600&display=swap');
.modalBackground {
    width: 100%;
   min-height:100vh;
    background: rgba( 249, 245, 245, 0.05 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 20.0px );
    -webkit-backdrop-filter: blur( 20.0px );
    border-radius: 10px;
    position:fixed;
  cursor: pointer;
  
   overflow-y: hidden;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
    z-index: 1000;

  }
  .modalBackground5 {
  position: fixed;
    
    border-radius: 10px;
 
  cursor: pointer;
  
   overflow-y: hidden;
   top: -10;
   left: 0;
   right: 0;
   bottom: 0;
    z-index: 1000;

  }
  .modalBackground2{
    width: 100%;
    cursor: pointer;
    min-height:100vh;
     background: rgba( 249, 245, 245, 0.05 );
     box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
     backdrop-filter: blur( 20.0px );
     -webkit-backdrop-filter: blur( 20.0px );
     border-radius: 10px;
     position:fixed;
   
   
    overflow-y: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
     z-index: 10200;

  }
  .modalBackground3{
    width: 100%;
    cursor: pointer;
    min-height:100vh;
     background: rgba( 249, 245, 245, 0.05 );
 
     backdrop-filter: blur( 60.0px );
     -webkit-backdrop-filter: blur( 60.0px );
   
     position:fixed;
   
   
    overflow-y: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
     z-index: 10200;

  }
  .modalBackground5{
    position: absolute;
    cursor: pointer;
  top:0;
  bottom: 0;
  right:0;
  left:0;

     background: rgba( 249, 245, 245, 0.05 );
 
     backdrop-filter: blur( 60.0px );
     -webkit-backdrop-filter: blur( 60.0px );
   
     position:fixed;
   
   
    overflow-y: hidden;
  
     z-index: 10200;

  }
  
  .modalContainer {
 width: 100%;
    height: auto;
    border-radius: 2.1rem;
    background:url("../../../Assets/Transaction/Rectangle\ 1134.svg");
    background-size: cover;
  
 margin-top: 15%;
 filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.03));
    box-shadow: 2px 10px 28px 10px rgba(12, 5, 18, 0.95),
      8px -5px 10px 10px rgb(3, 28, 57, 1);
position: relative;
    background-repeat: repeat;
  
  }
  .modalContainer2 {
    width: 100%;
       height: auto;
       border-radius: 2.1rem;
       background:url("../../../Assets/Transaction/Rectangle\ 1134.svg");
       background-size: cover;
     
    margin-top: 10%;
    filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.03));
       box-shadow: 2px 10px 28px 10px rgba(12, 5, 18, 0.95),
         8px -5px 10px 10px rgb(3, 28, 57, 1);
   position: relative;
       background-repeat: repeat;
     
     }
  #sorrydiv{
    background:url(../../../Assets/Modals/Mask_Group_50.png);
    height:auto;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    z-index: 55;
  
    top:0;
    left: 0;
    
    

  }
  #passchangeddiv{
    background:url(../../../Assets/Modals/Mask_Group_52.png);
    height:auto;
    background-size: 100% 100%;
    background-repeat: no-repeat;
 
    width: 100%;
   
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow:  2px 10px 20px 10px 	rgba(12,5,18,.85),8px -5px 10px 10px rgb(3, 28, 57,1);
    margin-top: 15%;
    top:0;
    border-radius: 3rem;
    left: 0;
    bottom: 0;
    right: 0;
    

  }
  #congratsdiv{
    background:url(../../../Assets/Modals/Mask_Group_49.png);
    height: auto;
    background-size: 100% 100%;
    overflow:hidden;
    background-repeat:no-repeat;
 
    width: 100%;
   
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow:  2px 10px 20px 10px 	rgba(12,5,18,.85),8px -5px 10px 10px rgb(3, 28, 57,1);
    margin-top: 15%;
    top:0;
    border-radius: 3.4rem;
    left: 0;
    bottom: 0;
    right: 0;
  }
  #tryagaindiv{
    background:url(../../../Assets/Modals/Mask_Group_51.png);
    height:auto;
    background-size: 100% 100%;
    background-repeat: no-repeat;
 
    width: 100%;
   
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow:  2px 10px 20px 10px 	rgba(12,5,18,.85),8px -5px 10px 10px rgb(3, 28, 57,1);
    margin-top: 15%;
    top:0;
    border-radius: 3rem;
    left: 0;
    bottom: 0;
    right: 0;

  }
  #somethingwrongdiv{
    background:url(../../../Assets/Modals/Mask_Group_55.png);
    height:auto;
    background-size:100% 100%;
    background-repeat: no-repeat;
 min-height: 50vh;
    width: 100vw;
   
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow:  2px 10px 20px 10px 	rgba(12,5,18,.85),8px -5px 10px 10px rgb(3, 28, 57,1);
    margin-top: 15%;
    top:0;
    border-radius: 3rem;
    left: 0;
    bottom: 0;
    right: 0;

  }
  .oopsdiv{
    background:url(../../../Assets/Dashboard/Dashboard\ Bg.svg);
    height: auto;
    width: 100%;
    color:gray;
    border-radius: 2rem;
    font-family: 'Saira', sans-serif;
    font-weight: 600;
    padding: 2rem;
    font-size: 1.5rem;
  
    text-align: center;
   justify-content: flex-start;
    letter-spacing: .05rem;
    line-height: 2rem;
  }
  .oopsdiv3{
   background: #01152b;
   height: auto;
   width: 100%;
   color:gray;
   border-radius: 2rem;
   font-family: 'Saira', sans-serif;
   font-weight: 600;
   padding: 2rem;
   font-size: 1rem;
 
   text-align: center;
  justify-content: flex-start;
   letter-spacing: .05rem;
   line-height: 2rem;
  }
  .oopsdiv2{
    background:url(../../../Assets/Dashboard/Dashboard\ Bg.svg);
    height: auto;
    width: 100%;
    color:gray;
    border-radius: 2rem;
    font-family: 'Saira', sans-serif;
    font-weight: 600;
    padding: 2rem;
    font-size: 1rem;
  transform:translateY(50%);
    text-align: center;
   justify-content: flex-start;
    letter-spacing: .05rem;
    line-height: 2rem;
  }
  .rating{
    border: 3px solid red;
    padding: 1rem 3rem;
    border-radius: 1rem;
    color: white;
    font-family: 'Saira', sans-serif;
    font-weight: 600;
    font-size: 1.25rem;
    letter-spacing: .1rem;
  }
  
 
  
  .modalContainer .title {
    display: inline-block;
    text-align: center;
    font-size: 3rem!important;
    margin-top: 10px;
    color:white;
    font-family: 'Saira', sans-serif;
    letter-spacing: .3rem;
   
  }
  .modalContainer .title2 {
    
    text-align: center;
    margin-top: 1rem;
  
 
   
  }
  .modalContainer2 .title {
    display: inline-block;
    text-align: center;
    font-size: 3rem!important;
    margin-top: 10px;
    color:white;
    font-family: 'Saira', sans-serif;
    letter-spacing: .3rem;
   
  }
  .modalContainer2 .title2 {
    
    text-align: center;
    margin-top: 1rem;
  
 
   
  }
  .title2 h1{
    color: white;
    font-size: 3.2rem;
    font-family: 'Saira', sans-serif;
    font-family: 300!important;
  }
  
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
 .crossbtnarrow button {
   
display: flex;
justify-content: flex-end;
    border: none;
    font-size:.8rem;
  float: right;
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  
height: 2rem;
width: 2rem;
filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow:  1px 2px 6px .5px 	rgba(12,5,18,.85),1px -2px 6px .5px rgb(3, 28, 57,1);
    border-radius: 50%;
    cursor: pointer;
    margin-left: 2rem;
    text-align: center;
    color: white;
  }
  
 
  
  
  .Arrowbtn{
    
    background-image: radial-gradient(circle, #e32f12, #e9282b, #ed233f, #ee2451, #ee2963);
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    border: none;
    border-radius: 50%;
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow:  1px 2px 6px .5px 	rgba(12,5,18,.85),1px -2px 6px .5px rgb(3, 28, 57,1);
  }
  
  .wentwrnbtn{
    background-image: radial-gradient(circle, #e32f12, #e9282b, #ed233f, #ee2451, #ee2963);
    padding: 1.5rem;
    border: none;
    border-radius: 50%;
    transform: translateY(55%);
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow:  2px 10px 20px 5px 	rgba(12,5,18,.85),8px -5px 20px 5px rgb(3, 28, 57,1);
  }
  
  #cancelBtn {
    background-color: crimson;
  }
.alertmainhead{
  color: white;
    padding-top: 2rem;
    padding-left: 2rem;
    font-family: 'Advent Pro', sans-serif;
    font-weight: 700;
    font-size: 3rem;
    letter-spacing: .4rem;
    padding-bottom: 2rem;
    text-transform: uppercase;
}  
.alertmainhead2{
  color: white;
    padding-top:3rem;
    padding-left: 2rem;
    font-family: 'Advent Pro', sans-serif;
    font-weight: 700;
    font-size: 3rem;
    letter-spacing: .3rem;

    text-transform: uppercase;
} 
.alertmainhead3{
  color: white;
    padding-top: 6rem;
    margin-left: -3.3rem;
    font-family: 'Saira', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
  

    text-transform: uppercase;

} 
.alertcommonhr{
  background-color: red;
  height: .2rem;
 justify-content: center;
  width: 12rem;
  border-radius: 2rem;
}
.resetpara{
  color:gray;
  border-radius: 2rem;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  padding: 2rem;
  font-size: 1.4rem;

  text-align: center;
 justify-content: flex-start;
  letter-spacing: .05rem;
  line-height: 2.5rem;
}
.resetpara::selection {
  color: white;
  background-color:none;
}

.canclbtn{
  background:url(../../../Assets/Transaction/Transactions_Bg.svg);
  filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
  box-shadow:  2px 4px 20px 5px 	rgba(12,5,18,.85),8px -2px 20px 5px rgb(3, 28, 57,1);
  padding: 1.3rem 3rem;
  text-align: center;
  border: 2px solid red;
  cursor: pointer;
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: .8rem;
}
.canclbtn:hover{
  background-image: radial-gradient(circle, #e32f12, #e9282b, #ed233f, #ee2451, #ee2963);
}
.acceptbtn{
  cursor: pointer;
  background-image: radial-gradient(circle, #e32f12, #e9282b, #ed233f, #ee2451, #ee2963);
  filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
  box-shadow:  2px 4px 20px 5px 	rgba(12,5,18,.85),8px -2px 20px 5px rgb(3, 28, 57,1);
  padding: 1.3rem 3rem;
  text-align: center;
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: none;
  border-radius: .5rem;
}
.change-btn{
  text-decoration: none;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.change-btn:hover{
  text-decoration: none;
}
.saveratebtn{
  background-image: radial-gradient(circle, #e32f12, #e9282b, #ed233f, #ee2451, #ee2963);
  padding: 1.3rem 4rem;
  color: whitesmoke;
  font-family: 'Saira', sans-serif;
  font-weight: 700;
  font-size: 1.1rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  border-radius: .7rem;
  filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
  box-shadow:  2px 4px 20px 5px 	rgba(12,5,18,.85),8px -2px 20px 5px rgb(3, 28, 57,1);
}
.form-input textarea{
  background-color: transparent;
  box-shadow: inset -1.5px -1px 6px .5px rgba(51, 43, 130,.7), inset 4px 4px 4px rgba(0,0,0,0.9);
border-radius: .8rem;
  border: none;
  color: white;
  padding: 2.1rem;
  font-family: 'Saira', sans-serif;
resize: none;


}
.form-input textarea:focus{
  background-color: transparent;

  color: white;
}
.ratinghead{
  color: white;
  font-family: 'Saira', sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  margin-top: 1rem;

}
.ratinghead1{
  color: gray;
  font-family: 'Saira', sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  

}
.passchangepara{
  color: lightgray;
  border-radius: 2rem;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  
  font-size: 1.6rem;
  margin-top: 2rem;
margin-bottom: 6rem;
  text-align: center;
 justify-content: flex-start;
  letter-spacing: .05rem;
  
}
#recentchalldiv{
  background: url("../../../Assets/Transaction/Rectangle\ 1134.svg");
  margin-top: 2rem;
  padding: 1rem;
  border-radius: 1rem;
  background-size: cover;
  background-repeat: no-repeat;
  filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
  box-shadow:  2px 5px 20px 1px 	rgba(12,5,18,.85),2px -5px 20px 1px rgb(3, 28, 57,1);
}
.recentchallhead{
  font-family: 'Saira', sans-serif;
  color: white;
  font-size: 1.8rem;
  padding-top: 1rem;
}
#recentchallimgdiv{
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  margin-top: 2rem;
padding: 8rem 3rem;
text-align: center;
background-size: cover;
  border-radius: 2rem;
  filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
  box-shadow:  2px 10px 20px 2px 	rgba(12,5,18,.85),8px -5px 20px 2px rgb(3, 28, 57,1);
}
.recentchallpara{
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 2rem;
}

.RecentchalArrowbtn{
  background: url("../../../Assets/Transaction/Rectangle\ 1134.svg");
  padding: 1.5rem;
  border: none;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
  box-shadow:  2px 10px 20px 2px 	rgba(12,5,18,.85),8px -5px 20px 2px rgb(3, 28, 57,1);
    
}
.RecentchalArrowbtn .icon{
  color: wheat;
  font-size: 1.8rem;
  position: absolute;
  margin-left: -.9rem;
  margin-top: .2rem;
}
.ratehead{
  color: gray;
  font-size: 2rem;
  font-family: 'Saira', sans-serif;
   font-weight: 600;
}
.slot-heading{
  color: white;
 margin-left: auto;
 margin-right: auto;
  font-size: 24px;
}
.calendar-block{
  padding: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;

}
.time-block{
  color: white;
  font-size: 40px;
  font-weight: 500;
  font-family: 'Saira', sans-serif;
  margin-top: 40px;
}
.exam-btn{
  color: white;
  background-image:linear-gradient(
    60deg,
    #ee2963,
    #ee265a,
    #ee2550,
    #ed2547,
    #eb263d,
    #e92833,
    #e62b28,
    #e32f1c
  );
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  margin-top: 2rem;
  padding-left: 7px;
  padding-right: 7px;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
}
.react-calendar__tile--now {
background-image:linear-gradient(
  60deg,
  #ee2963,
  #ee265a,
  #ee2550,
  #ed2547,
  #eb263d,
  #e92833,
  #e62b28,
  #e32f1c
) !important;
}
  .react-calendar__navigation button {
    min-width: 44px;
  background-image:linear-gradient(
  60deg,
  #ee2963,
  #ee265a,
  #ee2550,
  #ed2547,
  #eb263d,
  #e92833,
  #e62b28,
  #e32f1c
) !important;
    color: white;
    font-size: 13px;
    font-weight: 400;

}
.react-calendar{
  width: 500px;
  font-size: 18px;
  font-weight: 700;
 
  border-radius: 5px;
}
.slot-heading{
  padding-top:3rem;
  font-weight: 700;
  text-align: center;
  font-family: 'Advent Pro', sans-serif;
   color: white;
    
    padding-left: 2rem;
    font-family: 'Advent Pro', sans-serif;
   
    font-size: 3rem;
    letter-spacing: .3rem;

    text-transform: uppercase;
  
}
.modal-heading{
  display: flex;
  justify-items: center;
  margin-top: 1rem;
  font-family: 'Saira', sans-serif;
}
.text-heading{
  color: white;
  font-size: 16px;
}
.view-result-content{
  margin-top: 2.5rem;
  margin-left: 2.5rem;
  color: white;
}
.course-heading{
  font-size: 28px;
  margin-top: 2rem;
}
.result-content{
  margin-top: 2rem;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5rem;
  padding-right: 5rem;

}
.certi-button{
  margin-top: 2rem;
}
.exam-text{
  font-size: 13px;
  color:white;
}
.react-calendar__month-view__days__day--weekend{
  color: #66bb6a !important;
}
.heading-div{
display: flex;
align-items: center;
justify-content: end;
gap: 100px;
padding-right: 24px;
padding-top: 12px;
color: white;
position: absolute;
right: 10px;
top: 2px;
}



#marksdiv{
  background: #01152b;
  width: 100%;
  margin-top: 3rem;
  border: .2px solid #e32f12;
  padding: 1rem;
  justify-content: center;
  border-radius: 1rem;
}
#marksdivhead{
  margin-top: 1rem;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
}
#marksdivhead1{
 justify-content: center;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 5.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
}
.ipdiv2{

  border: 2px solid #e32f1c;
  padding: 1rem;
  text-align: center;
  color: white;
  font-size: 1.5rem;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
}

.ipdiv2 p{
  width: 100%;
  height: 2.2rem;
  background-image: linear-gradient(to right, #170929, #2c2931);
  animation: pulse 1000ms linear infinite alternate;
}
@keyframes pulse {
  0% {
      opacity: 1;
  }
  100% {
      opacity: 0.5;
  }
}
.ipdiv{
  border: 2px solid #e32f1c;
  padding: 1rem;
  text-align: center;
  color: white;
  font-size: 1.5rem;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
}
.ipdiv span{
  color: #e9282b;
}

.display-flexing{
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: radial-gradient(circle, #e32f12, #e9282b, #ed233f, #ee2451, #ee2963);
  width:5rem;
  height: 5rem;
  border-radius: 100%;
}
.display-flexing2{
  margin-top: 2rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  
}