@import url('https://fonts.googleapis.com/css2?family=Saira&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Saira:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@700&family=Saira:wght@600&display=swap');
.challengeuiback{
    background: rgba( 249, 245, 245, 0.05 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 20.0px );
    -webkit-backdrop-filter: blur( 20.0px );
    min-height: 100vh;
}
#chaldiv{
 zoom: 90%;
 background-color: white!important;
}