@import url('https://fonts.googleapis.com/css2?family=Saira&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Saira+Semi+Condensed:wght@300&display=swap');


.commonbackground {
  background: url('/src/Assets/Landing\ page/Mask_Group_2.webp');
  height: auto;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 4px solid red;


}

.logo_common {
  height: auto;
  margin-top: 50px;
  margin-left: 100px;
}

/* faq css starts */


.faqlogospan {
  color: #EAECEC;
  font-family: "Saira", sans-serif;
  font-size: 32px;
  margin-left: 30px;
  position: absolute;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 70px;
}

.Faq_span {
  font-size: 31rem;
  top: 6rem;
  position: absolute;
  font-family: "Saira", sans-serif;
  letter-spacing: .8rem;
  color: white;
  font-weight: 600;
  opacity: .1;
  margin-bottom: 20%;

}

.Faq_head {
  font-size: 16.5rem;
  color: white;
  margin-top: 10.5rem;
  margin-bottom: 25%;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  letter-spacing: 8px;
  z-index: 3;
  position: relative;
}

.Faq_question {
  font-size: 1.8rem;
  color: white;
  font-family: "Saira", sans-serif;
  font-weight: 400;
  overflow: hidden;
}

.content_faq {
  background-image: radial-gradient(circle, #051025, #050e20, #030b1c, #020817, #000512);
  height: auto;
  letter-spacing: 0;
  width: 100%;
}

.faqanser {
  font-size: 1.4rem;
  color: gray;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  text-align: center;
}

#faqcard {
  background: url(../../Assets/Transaction/Rectangle\ 1134.svg);
  height: auto;
  padding: 3rem;
  margin-bottom: 3rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 4px 20px 1px rgba(12, 5, 18, .85), 2px -3px 20px 1px rgb(3, 28, 57, 1);
  border-radius: 1.5rem;
}

/* faq css endss */
/* privacy section starts */
.comingsoon_head {
  font-size: 14.5rem;
  color: white;
  margin-top: 6.5rem;
  margin-bottom: 25%;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  letter-spacing: 8px;
  z-index: 3;
  position: relative;
}

.Privacy_head {
  font-size: 12.5rem;
  color: white;
  margin-top: 6.5rem;
  margin-bottom: 25%;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  letter-spacing: 8px;
  z-index: 3;
  position: relative;
}

.Privacy_span {
  font-size: 20rem;
  top: 14rem;
  position: absolute;
  font-family: "Saira", sans-serif;
  letter-spacing: 0.8rem;
  color: white;
  font-weight: 600;
  opacity: .1;
  margin-bottom: 20%;

}

.disclaimerhead {
  color: white;
  opacity: 1;
  font-family: "Saira", sans-serif;
  font-size: 3rem;
  padding: 0 0rem;
  font-weight: 600;
}

.privacypolicybullets b {
  color: red;
  font-weight: bold;
}

.privacypolicybullets {
  color: white;
  font-size: 1.45rem;
  line-height: 1.8rem;
  font-family: "Saira", sans-serif;
  font-weight: 500;
  letter-spacing: 0 !important;
  text-indent: -43px;
  line-height: 3rem;
}

.disclaimerhead2 {
  color: white;
  opacity: 1;
  font-family: 'Saira', sans-serif;
  font-size: 2.4rem;
  padding: 0 0rem;
  font-weight: 500;

}

.disclaimerhead3 {
  color: white;
  opacity: 1;
  font-family: 'Advent Pro', sans-serif;
  font-size: 2.3rem;
  padding: 0 0rem;
  font-weight: 500;

}

.privacypara2 {
  color: white;
  opacity: 1;
  font-family: "Saira", sans-serif;
  font-weight: 300;
  padding: 0 2.5rem;

  line-height: 4rem;
  letter-spacing: 0.1rem;
  font-size: 1.8rem;
  text-align: justify;

  margin-left: 0%;
}

.privacypara {
  color: white;
  opacity: 1;
  font-family: 'Saira', sans-serif;
  font-weight: 300;

  margin-top: 2%;
  line-height: 4rem;
  letter-spacing: 0rem !important;
  font-size: 1.6rem;
  justify-content: center;
  text-align: justify;

}

.privacypara b {
  color: red;
  font-weight: bold;
}

.Privacypolicy_span {
  font-size: 20rem;
  top: 14rem;
  position: absolute;
  font-family: "Saira", sans-serif;
  letter-spacing: 0.8rem;
  color: white;
  font-weight: 600;
  opacity: .1;
  margin-bottom: 20%;

}



@media only screen and (max-width: 575px) {
  .embed video {
    height: 38vh !important;
  }


}


@media (max-width: 575px) {

  .Faq_span {
    font-size: 26.8rem;
  }

  .Privacy_span {
    font-size: 13.8rem;
    top: 8rem;
  }

  .Privacy_head {
    font-size: 12.8rem;

  }

  .comingsoon_head {
    font-size: 12.8rem;
  }

  .Privacypolicy_span {
    font-size: 11.8rem;
    top: 8rem;
  }

  .commonpagehr {
    display: none;
  }



}

@media (max-width: 1378px) {
  .standing_flag {
    transform: translateY(0rem);

  }

  .Faq_span {
    font-size: 26.8rem;
  }

  .Privacy_span {
    font-size: 13.8rem;
    top: 8rem;
  }

  .Privacy_head {
    font-size: 12.8rem;

  }

  .comingsoon_head {
    font-size: 12.8rem;
  }

  .Privacypolicy_span {
    font-size: 11.8rem;
    top: 8rem;
  }

  .commonpagehr {
    display: none;
  }

  .commonpagehr {
    display: none;
  }

  #dataprivacyimagediv img {
    transform: translateY(-10rem);
  }

  .aboutpara {
    transform: translateY(250px);
  }
}


@media (max-width: 768px) {
  .standing_flag {
    transform: translateY(0rem);

  }

  .Faq_span {
    font-size: 26.8rem;
  }

  .Privacy_span {
    font-size: 13.8rem;
    text-align: center;
    top: 10rem;
  }

  .Privacypolicy_span {
    font-size: 11.8rem;
    top: 8rem;
  }

  .Privacy_head {
    font-size: 12.8rem;
    text-align: center;

  }

  .comingsoon_head {
    font-size: 12.8rem;
    text-align: center;
  }

  .commonpagehr {
    display: none;
  }

  #dataprivacyimagediv img {
    transform: translateY(-10rem);
  }


}

@media (max-width:640px) {
  .standing_flag {
    transform: translateY(-18rem);

  }

  .Faq_span {
    font-size: 22.8rem;
  }

  .Privacy_span {
    font-size: 10.8rem;
    text-align: center;
    top: 10rem;
  }

  .Privacypolicy_span {
    font-size: 9.8rem;
    top: 8rem;
  }

  .Privacy_head {
    font-size: 9.8rem;
    text-align: center;

  }

  .comingsoon_head {
    font-size: 9.8rem;
    text-align: center;
  }

  .commonpagehr {
    display: none;
  }

  #dataprivacyimagediv img {
    transform: translateY(-10rem);
  }

  .dataprivacyimage {
    transform: translateY(45rem);
  }

  #dataprivacy-content {
    transform: translateY(38rem);

  }

  .aboutpara {
    margin-top: 70%;
  }

  .Contact_head {
    font-size: 10.5rem !important;

  }


}



.Privacydiv {
  background-image: radial-gradient(circle, #051025, #050e20, #030b1c, #020817, #000512);
  height: auto;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.privacypic {
  overflow: hidden;
  width: fit-content;
  height: fit-content;


}

.commonpagehr {
  height: .5rem;
  width: 30rem;
  background-color: #e32f1c;
  transform: translateY(-29rem);
  border-radius: 15px;
}

/* privacy section ends */
/* data privacy starts */
.datacontentdivpic {
  background-image: url(/src/Assets/About\ us/Path_4262.svg);
  height: auto;
  visibility: visible;
  width: 100vw;
  background-size: contain;
  background-position-x: left;
  background-position-y: top;
  background-repeat: no-repeat;





}

.datacontentdivpic2 {
  background-image: url(/src/Assets/About\ us/Path_1.svg);
  height: 30rem;
  background-size: contain;
  width: 100%;

  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;


}

.datacontentdivpic3 {
  background-image: url(/src/Assets/About\ us/Path_1.svg);
  height: 30rem;
  background-size: contain;
  width: 100%;
  transform: translateY(-350px);
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;


}


.Dataprivacy_span {
  font-size: 26rem;
  top: 16rem;
  position: absolute;
  font-family: "Saira", sans-serif;
  letter-spacing: 0.8rem;
  color: white;
  font-weight: 600;
  opacity: .1;
  margin-bottom: 20%;
}

.Dataprivacy_head {
  font-size: 12rem;
  color: white;
  margin-top: 14.5rem;
  margin-bottom: 10%;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  letter-spacing: 8px;
  z-index: 3;
  position: relative;
}

#dataprivacyimagediv {
  transform: translateY(-400px);
}

#datacontentdiv {
  transform: translateY(-120px);
}

@media (max-width: 575px) {

  .Contact_head {
    font-size: 5.8rem;
  }

  .Dataprivacy_head {
    font-size: 6.8rem;

  }

  .Dataprivacy_span {
    font-size: 8.8rem;

    z-index: -2;


  }

  .commonpagehr {
    display: none;
  }


}

@media (max-width: 1378px) {

  .Dataprivacy_head {
    font-size: 12.8rem;

  }

  .Contact_head {
    font-size: 11.8rem;
  }

  .Dataprivacy_span {
    font-size: 14.8rem;
    top: 8rem;
  }

  .commonpagehr {
    display: none;
  }

}


@media (max-width: 768px) {


  .Dataprivacy_head {
    font-size: 12.8rem;

  }

  .Contact_head {
    font-size: 10.8rem;
  }

  .Dataprivacy_span {
    font-size: 10.8rem;
    top: 8rem;
  }

  .commonpagehr {
    display: none;
  }

  #dataprivacyimagediv {
    transform: translateY(50px);
  }

  #dataprivacyimagediv img {
    width: 100%;
  }

  #datacontentdiv {
    margin-top: 10px;
  }

}

@media (max-width:640px) {

  .Dataprivacy_head {
    font-size: 13.8rem;

  }

  .Contact_head {
    font-size: 9.8rem;
  }

  .Dataprivacy_span {
    font-size: 14.8rem;
    top: 13rem;
  }

  .commonpagehr {
    display: none;
  }

  #dataprivacyimagediv {
    transform: translateY(50px);
  }

  #datacontentdiv {
    transform: translateY(10px);
  }

}

.datapolicyhr {
  height: .5rem;
  width: 30rem;
  background-color: #e32f1c;

  border-radius: 15px;
}

/* contact us start */
.Contact_span {
  font-size: 36.5rem;
  top: 12rem;
  position: absolute;
  font-family: "Saira", sans-serif;
  letter-spacing: 0.8rem;
  color: white;
  font-weight: 600;
  opacity: .1;
  margin-bottom: 20%;

}







.Contact_head {
  font-size: 16.5rem;
  color: white;
  margin-top: 11.5rem;
  margin-bottom: 25%;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  letter-spacing: 8px;

  position: relative;
}

#contactus_form {
  background-image: url(/src/Assets/register/Auth\ Form\ Bg.svg);
  height: auto;
  width: 100%;
  border-radius: 1rem;
  margin-top: 7rem;
  padding: 3rem 4rem;
  border-radius: 40px;
  margin-bottom: 14rem;
  filter: drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 10px 15px 3px rgba(12, 5, 18, .85), -4px 5px 15px 3px rgb(3, 28, 57, 1);
}

.contacthead {
  color: white;
  font-family: 'Saira', sans-serif;
  letter-spacing: 0rem;
  font-weight: 600;
  font-size: 2.5rem;
}

.contact_para {
  justify-content: center;

  font-size: 1.4rem;
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  line-height: 2.5rem;


  opacity: .4;
  margin-bottom: 1rem;
}

.contact_condiv {
  color: white;
  font-size: 2.0rem;
  font-family: 'Saira', sans-serif;
  padding: 2rem;
  line-height: 3rem;
}

.contact_icon_para {
  color: white;
  font-size: 1.4rem;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  padding: 2rem;
  line-height: 3rem;
}

.contact_icon {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  padding: .1rem .5rem;
  border-radius: .9rem;
  margin-right: 2rem;
}

.form-row label {
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  margin-top: 3rem;
  margin-left: 2.5rem;

  letter-spacing: 0rem;
}

.form-row input {
  display: block;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  background-color: #000715 !important;
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  font-family: 'Saira', sans-serif;


  padding: 3rem;
  margin: 2.0rem;
  box-shadow: inset -2px -2px 4px #332B82,
    inset 4px 4px 4px black;

  outline: none;
  border: none;
  border-radius: 10px;
  color: white !important;
}

.form-rows label {
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  margin-top: 3rem;
  margin-left: 2.5rem;
  margin-bottom: 0rem;

}

.form-rows input {

  appearance: none;
  cursor: pointer;
}

.form-rows .textarea {
  display: block;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  background-color: #000715 !important;
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  font-family: 'Saira', sans-serif;


  padding: 3rem;
  margin: 2rem;
  box-shadow: inset -2px -2px 4px #332B82,
    inset 4px 4px 4px black;

  outline: none;
  border: none;
  border-radius: 10px;
  color: white !important;
}

.form-rows input[type="radio"] {
  position: relative;
  width: 2rem;
  height: 2rem;

  border-radius: 50%;
  box-shadow: inset -4px -4px 6px #000715,
    inset 6px 6px 6px black;
  background-color: #000715;
}

.form-rows input[type="radio"]::before,
.form-rows input[type="radio"]::after {
  content: '';
  position: absolute;
  top: 10%;
  left: 10%;
  width: 100%;
  height: 80%;
  border-radius: 50%;

}

.form-rows input[type="radio"]:after {
  background-color: #000715;
  box-shadow: inset -4px -4px 6px #000715,
    inset 6px 6px 6px black;
}

.form-rows input[type="textarea"] {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  box-shadow: inset -4px -4px 6px #e32f1c,
    inset 6px 6px 6px #ec2542;
  width: 50%;
  height: 50%;
  margin-top: 10%;
  margin-left: 14%;
}

.form-rows input .form-check-label {
  color: #000512;
}

.contactbtn {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  color: white;
  font-size: 1.5rem;
  padding: 2rem 6rem;
  border-radius: 1rem;
  margin-top: 2rem;
  box-shadow: 3px 3px 4px #000D1C,
    -3px -3px 2px #000D1C;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
}

.form-check .form-check-label {
  color: white;
  transform: translateY(-1.5rem);
}

/*about us starts */
.standing_flag {
  height: 70vh;
  margin-bottom: 10rem;
  transform: translateY(-12rem);

}

.aboutpara {
  color: white;
  opacity: 1;
  font-family: 'Saira', sans-serif;
  font-weight: 300;
  padding: 0 2.5rem;

  line-height: 4rem;
  letter-spacing: 0.1rem;
  font-size: 1.8rem;
  text-align: justify;
  transform: translateY(-250px);
  margin-left: 0%;



}

.aboutpara1 {
  color: white;
  opacity: 1;
  font-family: 'Saira', sans-serif;
  font-weight: 300;
  padding: 0 2.5rem;
  margin-top: 5%;
  line-height: 4rem;
  letter-spacing: 0.1rem;
  font-size: 1.8rem;
  justify-content: center;
  transform: translateY(-300px);



}

.aboutpara2 {
  color: white;
  opacity: 1;
  font-family: 'Saira', sans-serif;
  font-weight: 300;
  padding: 0 2.5rem;
  margin-top: 5%;
  line-height: 4rem;
  letter-spacing: 0.1rem;
  font-size: 1.8rem;
  justify-content: center;
  text-align: justify;
  transform: translateY(-400px);



}

/* getStarted css start from here */
.embed video {
  width: 100%;
  height: 90vh;
  display: block;

}

.newhavocbtn3::after {
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #000512 15%, #e82930 60%, #000512 90%);
  /* background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963); */
  position: absolute;
  bottom: 35px;
  left: 0;

}

.enterpreyhead1 {
  cursor: pointer;
  font-size: 2rem;
  color: #fff;
  text-transform: capitalize;
  font-family: "Saira", sans-serif;
}

/* card css */
#card_top {
  position: relative;
  top: 1.5rem;
}

.cardresec {
  /* max-width: 350px; */
  margin: 20px auto;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #f4f0ff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: auto;
}

.cardresecse {
  margin: 20px auto;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #f4f0ff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 427px;
}


.card-content {
  padding: 20px;
  text-align: center;
}

.price {
  font-size: 3rem;
  color: black;
  margin-top: 20px;
  margin-left: 2rem;
  font-weight: 700;
  position: relative;
  bottom: 1rem;
}

.pricese {
  font-size: 3rem;
  color: black;
  margin-top: 20px;
  margin-left: 2rem;
  font-weight: 700;
  position: relative;
  bottom: 1rem;
}


.seats-left {
  color: black;
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-weight: 400;
  font-family: "Saira", sans-serif;

}

.buy-button {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  color: white;
  border: none;
  padding: 5px 9rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 20px;
  font-family: "Saira", sans-serif;
}

.course-details {
  text-align: left;
  margin-bottom: 20px;
}

.course-details h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  font-weight: 700;
  font-family: "Saira", sans-serif;
}



.course-details p {

  padding: 0px;
  font-size: 1.5rem;
  font-weight: 400;
  color: black;
  font-family: "Saira", sans-serif;
}

.training-info {
  text-align: left;
  margin-bottom: 20px;
}

.training-info h2 {
  color: black;
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.training-info p {
  padding: .5rem;
  font-size: 1.5rem;
  font-weight: 400;
  color: black;
  color: #757575;
  font-family: "Saira", sans-serif;
  text-align: justify;
}

.share-course {
  text-align: left;
}

.share-course h2 {
  margin-bottom: 10px;
  padding: .5rem;
  font-size: 2rem;
  font-weight: 700;
  color: black;
  font-family: "Saira", sans-serif;
}

.social-icons {
  font-size: 2.5rem;
  display: flex;
  justify-content: space-around;
  /* margin-right: 17rem; */
}

.social-icons a {
  cursor: pointer;

  color: #080808;
}

.social-icons a:hover {
  transform: scale(1.5);
  color: #e32f1c;

}

@media (min-width: 768px) {
  .cardresec {
    max-width: 600px;
  }
}

@media (max-width: 768px) {
  .cardresec {
    max-width: 100%;
    margin: 27px;
  }

  .price {
    font-size: 1.5rem;

  }

  .buy-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .instrutor p {
    position: relative;
    padding: 0rem;
    text-align: justify;
    width: 360px;
  }
}

.horizontalline {
  border: 0.5px solid #4d4d4e;
  width: 44.5rem;
  margin-left: 8px;
}

.newcareer {
  /* border: 1px solid #17131b; */
  /* background-color: #17131b; */
  border-radius: 3rem;
  /* background: url(../../Assets/Transaction/Rectangle\ 1134.svg); */
  background-repeat: no-repeat;
}




.rating-progress {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 4rem;
  margin-top: 2rem;
}

.rating-progress span {
  margin-right: 10px;
  min-width: 70px;
  font-size: 1.5rem;
  color: #fff;
  font-family: "Saira", sans-serif;
}

.topbox {
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 3rem;
  text-align: center;
  padding: 2rem 2rem;
  margin-left: 3rem;
  height: 30vh;
  position: relative;
  top: 5rem;
}


.topbox p {
  font-size: 2.3rem;
  font-weight: 500;
  padding: 1rem;
  margin-top: 2rem;
  color: #777580;
  letter-spacing: 2px;
  font-family: "Saira", sans-serif;
}

.progress {
  flex-grow: 1;
  margin-left: 10px;
  height: 10px;
}

.progress-bar {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
}

.icon_pics {
  font-size: 3rem;
  color: #fdb122;
  fill: #fdb122;
  border-radius: 50%;
}

.image-copture img {
  width: 50%;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 8rem;
}


.user_data span {
  font-size: 2.5rem;
  color: #fdb122;
  font-family: "Saira", sans-serif;
  margin-left: 0rem;

}

.user_data p {
  font-size: 1.5rem;
  color: #fffdf9;
  font-family: "Saira", sans-serif;
  margin-left: 0rem;
  position: relative;
  top: 15px;
}

.passageuser p {

  font-size: 1.5rem;
  color: #fffdf9;
  margin-left: 2rem;
  text-align: justify;
  direction: ltr;
  padding: 0rem;
  font-family: "Saira", sans-serif;
}

.horizon {
  color: #000512;
}

/* card css end here */
.container_info {
  border: 0px solid #17131b;
  /* background: url(../../Assets/Transaction/Rectangle\ 1134.svg); */
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  top: 3rem;
  border-radius: 2rem;
}

#ceritificstese {
  position: relative;
  /* bottom: 5rem; */
}





.cardresec {
  background-color: #fff;
  width: 332px;
}

.cardstyle img {
  padding: 1rem;
  border-radius: 2rem;
}



.beginner {
  text-align: end;
  position: relative;
  bottom: 5rem;
  color: #ee2963;
  font-family: "Saira", sans-serif;
  left: 20rem;
}

.itemdetails {
  padding: 0rem;
  position: relative;
  /* top: -4rem; */
  /* text-align: center; */
  /* text-align: justify; */
}


.itemdetails p {
  font-size: 1.5rem;
  font-weight: 500;
  font-family: "Saira", sans-serif;
  text-align: justify;
  margin: 2rem;
  position: relative;
  bottom: 0rem;
  letter-spacing: -1px;
  width: 268px;
}

.itemdetails .beginner {
  font-size: 2.3rem;
}

.linehorizon {
  border: 1px solid grey;
  color: #cd0b2e;
  /* text-align: end; */
}

.h2-left {
  border-radius: 2rem;
  padding: 1.5rem;
  font-size: 2rem;
  color: #fff;
  font-family: "Saira", sans-serif;
}

.physical_class {
  position: relative;
  /* left: 6rem;
  top: 3rem; */
  text-align: justify;
  direction: ltr;
}

.course-card {
  padding: 1rem;
}

.btn-course {
  text-align: end;
  border: 1px solid grey;
  border-radius: 2rem;
  padding: 1rem;
  font-size: 2rem;
  font-family: "Saira", sans-serif;
}



.physical_class p {
  font-size: 1.5rem;
  color: #fff;
  font-weight: 500;
  margin-left: 2rem;
  font-family: "Saira", sans-serif;
  letter-spacing: 1.5px;

}

.btn-physical {
  font-size: 1.5rem;
  color: #000000;
  font-weight: 500;
  padding: 1.5rem 2.5rem;
  border-radius: 3rem;
  margin-top: 2rem;
  margin-left: 1rem;
  font-family: "Saira", sans-serif;
}

.iconpoint {
  font-size: 1.9rem;
  color: #cd0b2e;
}

.physical_classimage img {
  border-radius: 1rem;
  position: relative;
  bottom: 0rem;

}

.instrutorcourse {
  position: relative;
  top: 4rem;
}

.physical_classimage12 img {
  border-radius: 3rem;
  position: relative;
  top: 5rem;

}

.physical_classimage12:hover {

  transform: rotate(15deg);
  cursor: pointer;
  transform: scale(1.1);
}

.topsecurity {
  margin-top: 1rem;
}

.physical_classacw img {
  border-radius: 3rem;
  position: relative;
  top: 5rem;
}

.physical_classacw img:hover {
  transform: rotate(15deg);
  cursor: pointer;
  transform: scale(1.1);
}

.instrutortitle h2 {
  font-size: 3rem;
  color: #fff;
  font-weight: 500;
  font-family: "Saira", sans-serif;
  margin-top: 3rem;
}

.activeduser {
  font-size: 3rem;
  color: #cd0b2e;
}

.activedusers {
  font-size: 1.5rem;
  color: white;
  font-weight: 400;

}

#contentimage {
  margin-top: 5rem;
}




.custom-bullet:before {
  content: '';
  color: #ffffff;
  font-weight: bold;
  position: absolute;
  left: 0;
  top: 0;
}

.list-unstyled {
  padding: 0rem;

}














.accordion {
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.accordion-item {
  /* border: 1px solid #ddd; */
  border-radius: 4px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  background-color: #01152b;
  color: white;
  border-radius: 2rem;
  height: 60px;
}

.accordion-title {
  margin: 0;
  flex: 1;
}

.accordion-button {
  border: none;
  background: #01152b;
  font-size: 2.2rem;
  cursor: pointer;
  padding: 0rem;
  color: #cd0b2e;
  padding: 1rem;
  font-weight: 700;
  border-radius: 3rem;
}

.accordion-button::after {
  color: red;
}

.scrollable-content {
  max-height: 400px;
  overflow-y: auto;
}

.listitems li {
  color: white;
  padding: 2px;
  font-size: 1.5rem;
  font-family: "Saira", sans-serif;
}

.image-capture img {
  /* margin-left: 6rem; */
  padding: 2rem;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.iconhead {
  font-size: 25px;
  margin: 1rem;

}

@media (max-width: 575px) {

  #tollers {
    position: relative;
    left: 0rem;

  }

  .listitem {
    position: relative;
    left: 3rem;
  }

}

.pragraph {
  display: flex;
  justify-content: center;
}

.cardtext {
  font-size: 1.5rem;
  color: white;
  letter-spacing: 3px;
  text-align: justify;
  direction: ltr;
}

.lists {
  font-size: 1.4rem;
  color: white;
  letter-spacing: 1px;
  margin: -6px;
  margin-left: 5px;
}

.listitem {
  /* position: relative; */
  /* left: 19rem; */
}

/* @media only screen and (max-width: 575px) { */

/* {
    position: relative !important;
    left: auto !important;
  } */
/* } */


#awccerti {
  /* background: url(../../Assets/Transaction/Rectangle\ 1134.svg); */
  background-repeat: no-repeat;
  margin-top: 3rem;

}

.listcareers {
  width: auto;
}



.careers {
  border: 1px solid gray;
  padding: 1rem;
  border-radius: 2rem;
  margin: 1rem;
  display: flex;
  justify-content: start;
  width: 200px;
  cursor: pointer;
}

.careerdata h2 {
  font-size: 3rem;
  color: #fff;
  text-align: justify;
  padding: 0rem;
  font-family: "Saira", sans-serif;
  margin-top: 2rem;
  letter-spacing: 1px;
}

.boksitem {
  border: 1px solid #e32f1c;
  border-radius: 3rem;
  text-align: center;
  width: 200px;
  cursor: pointer;
  color: white;
  font-weight: 300;
  padding: 1.2rem;
  margin-right: 20rem;
  margin-top: 2rem;
  background-color: #e32f1c;
  font-size: 1.5rem;
}

.careerdata p {
  color: white;
  font-size: 1.5rem;
  letter-spacing: 2px;
  text-align: justify;
  margin-top: 2rem;
}

.careerdata {
  text-align: center;
  /* padding: 3rem; */
  margin-top: 5rem;
}

.listsss {
  font-size: 1.5rem;
  font-weight: 400;
  margin: -6px;
  letter-spacing: 1px;
}

.listing {
  font-size: 1.5rem;
  font-weight: 400;
  margin: -20px;
  /* letter-spacing: 2px; */
  margin-left: 2rem;
  color: #fff;
}

.CareersDemand {
  display: flex;
  margin-top: 10rem;
}

.listsd {
  width: 720px;
  /* line-height: 10px; */
}

#hackcertificate {
  margin-top: 8rem;

}





