.ind-overview{
    overflow: hidden;
}
.industrial-overflow-hidden{
    overflow: hidden;
}
.industrial-training-checkbox{
    display: flex;
    align-items: center;   
    gap:2.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.industrial-training-checkbox-wrapper{
    display: flex;
    font-size: 1.5rem;
    gap: 5px;
}
.ind-training-highlight-section2{
    padding-right: 3rem;
    padding-left: 3rem;
    
    background: rgb(15, 2, 49);
    color:white;
 padding-top: 12rem;
 display: flex;
 justify-content: center;
 align-items: center;

 padding-bottom: 8rem;
 gap: 20rem !important;
}
.ind-training-highlight-section-left2{
    width: 37%;
    max-width: 650px;
}

.download-btn-cont-training{
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
    font-size: 1rem !important;
}
.download-btn-training{
    text-decoration: none;
    color: white;
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
font-size: 1.5rem;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right:1rem;
    background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    padding-bottom:1rem ;
    font-family: 'Saira', sans-serif;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    
}
.download-btn-training:hover{
    color:white;
    text-decoration: none;

}
.industrial-training-bar-container {
    display: flex; /* Changed to flex for center alignment */
    position: fixed;
    z-index: 100;
    bottom: 14px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .industrial-training-highlight-section2{
    padding-right: 8rem;
    padding-left: 8rem;
    
    background: rgb(15, 2, 49);
    color:white;
 padding-top: 12rem;
 display: flex;
 justify-content: space-between;
 padding-bottom: 8rem;
 gap: 5rem;
 
}
  .industrial-training-bar {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.6365) 100%);
    backdrop-filter: blur(17px);
    border-radius: 3.2rem;
    box-shadow: 0 0.8rem 3.2rem 0 #00000033;
    padding: 0.7rem 0.7rem;
    color: #616161;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    gap: 1px;
    position: relative;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  .training-cl {
    z-index: 1;
    width:12.5rem;
    text-align: center;
    padding: 0.2rem 1.2rem; /* Added padding for uniformity */
    border-radius: 3.2rem;
    background-color: transparent; /* Default background */
    transition: color 0.8s, font-weight 0.5s; /* Smooth transition for active state */
  }
  .training-cl:hover{
   
 font-weight: 500;
  }
  
  #indicator {
    position: absolute;
    border-radius: 3.2rem;
    height: 70%;
    top: 5px;
    background-color: black;
    color: white;
    transition: left 0.5s, width 0.5s; /* Smooth transition for movement */
    z-index: 0;
  }
  
  .active-bar-train {
    color: white;
    font-weight: 700;
  }
  
  .ind-train-chat-us {
    background-color: #ad2643;
    cursor: pointer;
    border-radius: 3.2rem;
    color: white;
    padding: 0.9rem 2rem;
    margin-left: 2rem;
    font-size: 16px;
    font-weight: 500;
  }
  



/* Training Benfits */
.industrial-benefits-container{
    background-color: #0F0231;
    padding-top: 2rem;
    padding-bottom: 5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    
}
.industrial-benefits-container .heading-container{
    width: 100%;
    margin:0px auto;
    font-size: 1rem;
    font-weight: 500;
   
}
.industrial-benefits-container .sub-heading{
    text-align: center;
    color: #e82930;
}
.industrial-benefits-container .heading{
    text-align: center;
    color: white;
    font-size: 3rem;
    margin-top: 2rem;
    font-weight: 500;
}
.industrial-benefits-container .description{
    text-align: center;
    margin:0px auto;
    width: 100%;
    color: white;
    font-size: 1.5rem;
    margin-top: 4rem;
    line-height: 2.6rem;
    text-align: justify;
}
.industrial-benefits-container .card-container{
    margin-top: 4.5rem;
display: flex;

flex-wrap: wrap;
gap: 2rem;
max-width: 2000px;
margin: 4.5rem auto;
}
.industrial-benefits-container .card{
    cursor: pointer;
   
    background-color: #0F0231;
   

width: 80%;

}
.industrial-benefits-container .bg-flip-purple{
    background-color: #431D60;
}
.industrial-benefits-container .bg-flip-red{
    background-color: #ad2643;
}
.industrial-benefits-container .card__content{
   
    padding: 8rem;
    
 position: relative;
 transition: transform 2s;
 transform-style: preserve-3d;
 border-radius: 1rem;
     }
.industrial-benefits-container .card:hover  .card__content{
    transform: rotateY(.5turn);
    
}

.industrial-benefits-container .card__front{
        position: absolute;
        top: 0;
        left:0;
        bottom: 0;
        right:0;
        transform: translateZ(50px);
        backface-visibility: hidden;
        padding:0rem 2rem;
    }
.industrial-benefits-container .card__back{
   
    overflow: hidden;
    border-radius: 1rem;
        position: absolute;
        top: 0;
        left:0;
        bottom: 0;
        right:0;
        transform: translateZ(50px);
        backface-visibility: hidden;
    }
    .industrial-benefits-container .card__body{
        position: absolute;
        top: 0;
        left:0;
        bottom: 0;
        right:0;
        transform: translateZ(50px);
        backface-visibility: hidden;
        text-align: justify;
    }
.industrial-benefits-container .card__subtitle{
color: white;
font-size: 3rem;
font-weight: 1000;
margin-top: 0.5rem;
}
.industrial-benefits-container .card__title{
    font-size: 1.75rem;
    
}
.industrial-benefits-container .card__back{
 transform: rotateY(.5turn);
 padding: 2rem;   
}
.industrial-benefits-container .card__body{
    font-size: 1.25rem;
    padding: 2rem;   
}












.why-hackersprey .training-heading2{
    font-size: 3rem;
    text-align: center;
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-weight: 700;
    margin: 0px auto;
    margin-top: 5rem;
}
.why-hackersprey .training-heading{
    font-size: 3rem;
    text-align: center;
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-weight: 700;
    margin: 0px auto;
    margin-top: 5rem;
}
.why-hackersprey .training-description{
    font-size: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    text-align: justify;
    width: 100%;
    margin: 0px auto;
    margin-top:4rem;
    
}
.why-hackersprey .comparison-box{
    margin-top: 6rem;
    margin-bottom: 6rem;
    display: flex;
    gap: 0px;
    justify-content: center;
    align-items: center;
    padding-left:1.5rem;
    padding-right: 1.5rem;


}
.why-hackersprey .hackersprey-card{
   width: 100%;
    border-color: #ad2643;
    border-style:solid;
    display: flex;
    flex-direction: column;
    background: linear-gradient(171.63deg, #171717 2%, rgba(102, 20, 20, 0.8) 100%);
    border-top-left-radius: 1rem;
  
}
.why-hackersprey .hackersprey-card .card-heading{
   
    font-size: 2rem;
    font-weight: 700;
    padding: 1.6rem 2rem;

    text-align: center;
    background: linear-gradient(90deg, #3d0d0d 0%, #661414 100%);
}
.why-hackersprey .hackersprey-card .card-points{
    font-size: 1.5rem;
    padding: 1rem 1.8rem;
    border-bottom-width:1px;
   height: 10.5rem;
    border-bottom-color: #ad2643;
    border-bottom-style:solid;
    
}


.why-hackersprey .comparison-card{
    width: 100%;
     border-color: #212121;
     border-style:solid;
     display: flex;
     flex-direction: column;
     background-color: #131313;
     border-top-right-radius:1rem ;
   
 }
 .why-hackersprey .comparison-card .card-heading{
     font-size: 2rem;
     font-weight: 700;
     padding: 1.6rem 2rem;
     text-align: center;
     background-color: #212121;
    
 }
 .why-hackersprey .comparison-card .card-points{
     font-size: 1.5rem;
     padding: 1rem 1.8rem;
     border-bottom-width:1px;
     height: 10.5rem;
     border-bottom-color: #212121;
     border-bottom-style:solid;
     
 }



 .why-hackersprey .heading-card{
    
    width: 20rem;
     display: none;
     flex-direction: column;
     gap:2rem
     
   
   
 }
 .why-hackersprey .heading-card .card-heading{
    content: '';
    font-size: 2.5rem;
    font-weight: 800;
    padding: 1.6rem 2rem;
    text-align: center;
 
   
    
 }
 .why-hackersprey .heading-card .card-points{
    
     font-size: 1.5rem;
     padding: 1rem 1.8rem;
     border-top-left-radius: 2rem;
     border-bottom-left-radius: 2rem;
     background-color:#212121;
     
     
 }




 .industrial-training-intro-section .training-highlight-section-left{
    width:100% !important;
 }
 .industrial-training-intro-section .training-highlight-section-right{
    background-color: rgba(255, 255, 255, 0.1);
    position: relative;
    height: 50rem;
    border-radius: 2rem;
    
 


 }
 .industrial-training-intro-section .training-highlight-section-right .img-1{
    width: 35rem;
        border-radius: 2rem;
       top: -35px;
        position: absolute;
        right:-35px;
        display: none;
        z-index: -1;

 }
 .industrial-training-intro-section .training-highlight-section-right .img-2{
    width: 35rem;
    bottom: -35px;
    position: absolute;
    left:-35px;
    border-radius: 2rem;
    display: none;
    z-index: -1;
   
 }
 .industrial-training-intro-section .training-highlight-section-left{
    width: 50%;
}
.industrial-training-intro-section{
    gap:2rem;
}

.internal-linking-training{
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
   
    margin: 0px auto;
}

.internal-linking-training .heading{
   text-align: center;
   font-size: 1.9rem;
   font-weight: 700;

}
.internal-linking-training .content{
display: flex;
align-items: center;
justify-content: space-between;
flex-wrap: wrap;
gap:0.5rem;
}
.internal-linking-training .link-title{
color: #bdbdbd;
font-size:1.5rem;
}
.training-video-5{
width:"100%" ;
height: 590rem;
}

@media (min-width: 50px) {
    .training-video-5{
        width:100% ;
        height: 75rem;
        }
    .training-heading{
        width: 100%;
    }
    .ind-training-highlight-section2{
        gap: 16rem !important;
        padding-top: 8rem;
        display: flex;
        flex-direction: column;
        padding-left: 2rem;
        padding-right: 2rem;
        font-size: 20px;

    }
    .ind-training-highlight-section-left2{
        width: 100%;
        max-width: 650px;
    }
    .industrial-training-bar-container{
        
        position: fixed;
        z-index: 100;
        bottom: 18px;
        display: none;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
       
        width: 100%;
    }
}
@media (min-width: 600px) {
    .training-video-5{
        width:100% ;
        height: 75rem;
        }
    .training-heading{
        width: 100%;
    }
    .ind-training-highlight-section-left2{
        width: 100%;
        max-width: 650px;
    }
    .industrial-training-bar-container{
        
        position: fixed;
        z-index: 100;
        bottom: 18px;
        display: none;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
       
        width: 100%;
    }
    .industrial-benefits-container{
        background-color: #0F0231;
        padding-top: 2rem;
        padding-bottom: 5rem;
        padding-left: 2rem;
        padding-right: 2rem;
    
        
    }
    .industrial-benefits-container .heading-container{
        width: 100%;
        margin:0px auto;
        font-size: 2.5rem;
        font-weight: 500;
       
    }
    .industrial-benefits-container .sub-heading{
        text-align: center;
        color: #e82930;
    }
    .industrial-benefits-container .heading{
        text-align: center;
        color: white;
        font-size: 3rem;
        margin-top: 2rem;
        font-weight: 500;
    }
    .industrial-benefits-container .description{
        text-align: center;
        margin:0px auto;
        width: 100%;
        color: white;
        font-size: 1.5rem;
        margin-top: 4rem;
        line-height: 2.6rem;
        text-align: justify;
    }
    .industrial-benefits-container .card-container{
        margin-top: 4.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0rem 6rem;
    gap: 2rem;
    max-width: 2000px;
   margin:4.5rem auto
    }
    .industrial-benefits-container .card{
        cursor: pointer;
       
        background-color: #0F0231;
    
    width: 30rem;
    
    }
    .industrial-benefits-container .bg-flip-purple{
        background-color: #431D60;
    }
    .industrial-benefits-container .bg-flip-red{
        background-color: #ad2643;
    }
    .industrial-benefits-container .card__content{
       
        padding: 9.5rem;
        
     position: relative;
     transition: transform 2s;
     transform-style: preserve-3d;
     border-radius: 1rem;
         }
    .industrial-benefits-container .card:hover .card__content{
        transform: rotateY(.5turn);
        
    }
    
    .industrial-benefits-container .card__front{
            position: absolute;
            top: 0;
            left:0;
            bottom: 0;
            right:0;
            transform: translateZ(50px);
            backface-visibility: hidden;
            padding:0rem 2rem;
        }
    .industrial-benefits-container .card__back{
       
        overflow: hidden;
        border-radius: 1rem;
            position: absolute;
            top: 0;
            left:0;
            bottom: 0;
            right:0;
            transform: translateZ(50px);
            backface-visibility: hidden;
        }
        .industrial-benefits-container .card__body{
            position: absolute;
            top: 0;
            left:0;
            bottom: 0;
            right:0;
            transform: translateZ(50px);
            backface-visibility: hidden;
            text-align: justify;
        }
    .industrial-benefits-container .card__subtitle{
    color: white;
    font-size: 3rem;
    font-weight: 1000;
    margin-top: 0.5rem;
    }
    .industrial-benefits-container .card__title{
        font-size: 1.75rem;
        
    }
    .industrial-benefits-container .card__back{
     transform: rotateY(.5turn);
     padding: 2rem;   
    }
    .industrial-benefits-container .card__body{
        font-size: 1.25rem;
        padding: 2rem;   
    }
    .why-hackersprey .training-heading{
        font-size: 3rem;
        text-align: center;
        width: 100%;
        font-weight: 700;
        margin: 0px auto;
        margin-top: 5rem;
       
    }
    .why-hackersprey .training-description{
        font-size: 1.5rem;
        text-align: justify;
        width: 100%;
        margin: 0px auto;
        margin-top:4rem;
        padding: 0rem 2rem
        
    }
    .why-hackersprey .comparison-box{
        margin-top: 6rem;
        margin-bottom: 6rem;
        display: flex;
        gap: 0px;
        justify-content: center;
        align-items: center;
        padding: 0rem 2rem;
    
    
    }
    .why-hackersprey .hackersprey-card{
       width: 100%;
        border-color: #ad2643;
        border-style:solid;
        display: flex;
        flex-direction: column;
        background: linear-gradient(171.63deg, #171717 2%, rgba(102, 20, 20, 0.8) 100%);
        border-top-left-radius: 1rem;
      
    }
    .why-hackersprey .hackersprey-card .card-heading{
       
        font-size: 2rem;
        font-weight: 700;
        padding: 1.6rem 2rem;
        text-align: center;
        background: linear-gradient(90deg, #3d0d0d 0%, #661414 100%);
    }
    .why-hackersprey .hackersprey-card .card-points{
        font-size: 1.5rem;
        padding: 1rem 1.8rem;
        border-bottom-width:1px;
      
        border-bottom-color: #ad2643;
        border-bottom-style:solid;
        
    }
    
    
    .why-hackersprey .comparison-card{
        width: 100%;
         border-color: #212121;
         border-style:solid;
         display: flex;
         flex-direction: column;
         background-color: #131313;
         border-top-right-radius:1rem ;
       
     }
     .why-hackersprey .comparison-card .card-heading{
         font-size: 2rem;
         font-weight: 700;
         padding: 1.6rem 2rem;
         text-align: center;
         background-color: #212121;
        
     }
     .why-hackersprey .comparison-card .card-points{
         font-size: 1.5rem;
         padding: 1rem 1.8rem;
         border-bottom-width:1px;
       
         border-bottom-color: #212121;
         border-bottom-style:solid;
         
     }
    
    
    
     .why-hackersprey .heading-card{
        width: 20rem;
         display: none;
         flex-direction: column;
         gap:2rem
         
       
       
     }
     .why-hackersprey .heading-card .card-heading{
        content: '';
        font-size: 2.5rem;
        font-weight: 800;
        padding: 1.6rem 2rem;
        text-align: center;
     
       
        
     }
     .why-hackersprey .heading-card .card-points{
        
         font-size: 1.5rem;
         padding: 1rem 1.8rem;
         border-top-left-radius: 2rem;
         border-bottom-left-radius: 2rem;
         background-color:#212121;
         
         
     }

     .industrial-training-intro-section .training-highlight-section-right{
        background-color: rgba(0, 0, 0,0.3);
        position: relative;
        height: 50rem;
        border-radius: 2rem;
    
       

    
     }
     .industrial-training-intro-section .training-highlight-section-right .img-1{
        width: 35rem;
        border-radius: 2rem;
       top: -35px;
        position: absolute;
        right:-30px;
   
       display: none;
    
    
     }
     .industrial-training-intro-section .training-highlight-section-right .img-2{
        width: 35rem;
        bottom: -50px;
        position: absolute;
        left:-30px;
        display: none;
        border-radius: 2rem;
       
     }
     .industrial-training-intro-section .training-highlight-section-left{
        width: 50%;
    }
    .industrial-training-intro-section{
        gap:2rem;
    }
    .internal-linking-training{
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
        width: 100%;
       
        margin: 0px auto;
    }
}

@media (min-width: 800px) {
   
    .training-video-5{
        width:100% ;
        height: 75rem;
        }
    .training-heading{
        width: 100%;
    }
    .ind-training-highlight-section2{
        display: flex;
        flex-direction: column;
    }
    .ind-training-highlight-section-left2{
        width: 100%;
        max-width: 650px;
    }
    .internal-linking-training{
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
        width: 100%;
       
        margin: 0px auto;
    }
    .industrial-training-bar-container{
        
        position: fixed;
        z-index: 100;
        bottom: 18px;
        display: none;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    
        width: 100%;
    }
    .industrial-benefits-container{
        background-color: #0F0231;
        padding-top: 2rem;
        padding-bottom: 5rem;
        
    }
    .industrial-benefits-container .heading-container{
        width: 100%;
        margin:0px auto;
        font-size: 2.5rem;
        font-weight: 500;
       
    }
    .industrial-benefits-container .sub-heading{
        text-align: center;
        color: #e82930;
    }
    .industrial-benefits-container .heading{
        text-align: center;
        color: white;
        font-size: 4.5rem;
        margin-top: 2rem;
        font-weight: 500;
    }
    .industrial-benefits-container .description{
        text-align: center;
        margin:0px auto;
        width: 90%;
        color: white;
        font-size: 1.5rem;
        margin-top: 4rem;
        line-height: 2.6rem;
        text-align: justify;
    }
    .industrial-benefits-container .card-container{
        margin-top: 4.5rem;
    display: flex;
    flex-wrap: wrap;
    max-width: 2000px;
    gap: 2rem;
    margin: 4.5rem auto;
    }
    .industrial-benefits-container .card{
        cursor: pointer;
       
        background-color: #0F0231;
    
    width: 37rem;
    
    }
    .industrial-benefits-container .bg-flip-purple{
        background-color: #431D60;
    }
    .industrial-benefits-container .bg-flip-red{
        background-color: #ad2643;
    }
    .industrial-benefits-container .card__content{
       
        padding: 7rem;
        
     position: relative;
     transition: transform 2s;
     transform-style: preserve-3d;
     border-radius: 1rem;
         }
    .industrial-benefits-container .card:hover  .card__content{
        transform: rotateY(.5turn);
        
    }
    
    .industrial-benefits-container .card__front{
            position: absolute;
            top: 0;
            left:0;
            bottom: 0;
            right:0;
            transform: translateZ(50px);
            backface-visibility: hidden;
            padding:0rem 2rem;
        }
    .industrial-benefits-container .card__back{
       
        overflow: hidden;
        border-radius: 1rem;
            position: absolute;
            top: 0;
            left:0;
            bottom: 0;
            right:0;
            transform: translateZ(50px);
            backface-visibility: hidden;
        }
        .industrial-benefits-container .card__body{
            position: absolute;
            top: 0;
            left:0;
            bottom: 0;
            right:0;
            transform: translateZ(50px);
            backface-visibility: hidden;
            text-align: justify;
        }
    .industrial-benefits-container .card__subtitle{
    color: white;
    font-size: 3rem;
    font-weight: 1000;
    margin-top: 0.5rem;
    }
    .industrial-benefits-container .card__title{
        font-size: 1.75rem;
        
    }
    .industrial-benefits-container .card__back{
     transform: rotateY(.5turn);
     padding: 2rem;   
    }
    .industrial-benefits-container .card__body{
        font-size: 1.25rem;
        padding: 2rem;   
    }
   
}
@media (min-width: 1000px) {
    .training-video-5{
        width:320px;
       
        }
    .ind-training-highlight-section2{
        display: flex;
        flex-direction: row;
    }
    .ind-training-highlight-section-left2{
        width: 67%;
        max-width: 650px;
    }
    .internal-linking-training{
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
        width: 80%;
       
        margin: 0px auto;
    }
    .industrial-training-bar-container{
        
        position: fixed;
        z-index: 100;
        bottom: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    
        width: 100%;
    }
    .industrial-benefits-container{
        background-color: #0F0231;
        padding-top: 2rem;
        padding-bottom: 5rem;
        
    }
    .industrial-benefits-container .heading-container{
        width: 100%;
        margin:0px auto;
        font-size: 2.5rem;
        font-weight: 500;
       
    }
    .industrial-benefits-container .sub-heading{
        text-align: center;
        color: #e82930;
    }
    .industrial-benefits-container .heading{
        text-align: center;
        color: white;
        font-size: 4.5rem;
        margin-top: 2rem;
        font-weight: 500;
    }
    .industrial-benefits-container .description{
        text-align: center;
        margin:0px auto;
        width: 70%;
        color: white;
        font-size: 1.5rem;
        margin-top: 4rem;
        line-height: 2.6rem;
        text-align: justify;
    }
    .industrial-benefits-container .card-container{
        margin-top: 4.5rem;
    display: flex;
    flex-wrap: wrap;
    max-width: 2000px;
    gap: 2rem;
    margin: 4.5rem auto;
    }
    .industrial-benefits-container .card{
        cursor: pointer;
       
        background-color: #0F0231;
    
    width: 37rem;
    
    }
    .industrial-benefits-container .bg-flip-purple{
        background-color: #431D60;
    }
    .industrial-benefits-container .bg-flip-red{
        background-color: #ad2643;
    }
    .industrial-benefits-container .card__content{
       
        padding: 7rem;
        
     position: relative;
     transition: transform 2s;
     transform-style: preserve-3d;
     border-radius: 1rem;
         }
    .industrial-benefits-container .card:hover  .card__content{
        transform: rotateY(.5turn);
        
    }
    
    .industrial-benefits-container .card__front{
            position: absolute;
            top: 0;
            left:0;
            bottom: 0;
            right:0;
            transform: translateZ(50px);
            backface-visibility: hidden;
            padding:0rem 2rem;
        }
    .industrial-benefits-container .card__back{
       
        overflow: hidden;
        border-radius: 1rem;
            position: absolute;
            top: 0;
            left:0;
            bottom: 0;
            right:0;
            transform: translateZ(50px);
            backface-visibility: hidden;
        }
        .industrial-benefits-container .card__body{
            position: absolute;
            top: 0;
            left:0;
            bottom: 0;
            right:0;
            transform: translateZ(50px);
            backface-visibility: hidden;
            text-align: justify;
        }
    .industrial-benefits-container .card__subtitle{
    color: white;
    font-size: 3rem;
    font-weight: 1000;
    margin-top: 0.5rem;
    }
    .industrial-benefits-container .card__title{
        font-size: 1.75rem;
        
    }
    .industrial-benefits-container .card__back{
     transform: rotateY(.5turn);
     padding: 2rem;   
    }
    .industrial-benefits-container .card__body{
        font-size: 1.25rem;
        padding: 2rem;   
    }
    .why-hackersprey .training-description{
        font-size: 1.5rem;
        text-align: justify;
        width: 96%;
        margin: 0px auto;
        margin-top:4rem;
        padding: 0rem 2rem
        
    }
    .why-hackersprey .hackersprey-card .card-points{
        font-size: 1.5rem;
        padding: 1rem 1.8rem;
        border-bottom-width:1px;
        height: 8rem;
      
        border-bottom-color: #ad2643;
        border-bottom-style:solid;
        
    }
    .why-hackersprey .comparison-card .card-points{
        font-size: 1.5rem;
        padding: 1rem 1.8rem;
        border-bottom-width:1px;
        height: 8rem;
        border-bottom-color: #212121;
        border-bottom-style:solid;
        
    }
   
}
@media(min-width:1200px){
   
    .ind-training-highlight-section2{
        display: flex;
        flex-direction: row;
    }
    .ind-training-highlight-section-left2{
        width: 87%;
        max-width: 800px;
    }
    .industrial-benefits-container .description{
        text-align: center;
        margin:0px auto;
        width: 70%;
        color: white;
        font-size: 1.5rem;
        margin-top: 4rem;
        line-height: 2.6rem;
        text-align: justify;
    }
    .why-hackersprey .training-heading{
        font-size: 3.5rem;
        text-align: center;
        width: 60%;
        font-weight: 700;
        margin: 0px auto;
        margin-top: 5rem;
       
    }
    .why-hackersprey .training-description{
        font-size: 1.5rem;
        text-align: justify;
        width: 80%;
        max-width: 1000px;
        margin: 0px auto;
        margin-top:4rem;
        padding: 0rem 2rem
        
    }
    .why-hackersprey .comparison-box{
        margin-top: 6rem;
        margin-bottom: 6rem;
        display: flex;
        gap: 0px;
        justify-content: center;
        align-items: center;
        padding: 0rem 2rem;
    
    
    }
    .why-hackersprey .hackersprey-card{
       width: 40rem;
        border-color: #ad2643;
        border-style:solid;
        display: flex;
        flex-direction: column;
        background: linear-gradient(171.63deg, #171717 2%, rgba(102, 20, 20, 0.8) 100%);
        border-top-left-radius: 1rem;
      
    }
    .why-hackersprey .hackersprey-card .card-heading{
       
        font-size: 2rem;
        font-weight: 700;
        padding: 1.6rem 2rem;
        text-align: center;
        background: linear-gradient(90deg, #3d0d0d 0%, #661414 100%);
    }
    .why-hackersprey .hackersprey-card .card-points{
        font-size: 1.5rem;
        padding: 1rem 1.8rem;
        border-bottom-width:1px;
      
        border-bottom-color: #ad2643;
        border-bottom-style:solid;
        
    }
    
    
    .why-hackersprey .comparison-card{
        width: 40rem;
         border-color: #212121;
         border-style:solid;
         display: flex;
         flex-direction: column;
         background-color: #131313;
         border-top-right-radius:1rem ;
       
     }
     .why-hackersprey .comparison-card .card-heading{
         font-size: 2rem;
         font-weight: 700;
         padding: 1.6rem 2rem;
         text-align: center;
         background-color: #212121;
        
     }
     .why-hackersprey .comparison-card .card-points{
         font-size: 1.5rem;
         padding: 1rem 1.8rem;
         border-bottom-width:1px;
       
         border-bottom-color: #212121;
         border-bottom-style:solid;
         
     }
    
    
    
     .why-hackersprey .heading-card{
     
        display: flex;
         flex-direction: column;
         gap:3.5rem
         
       
       
     }
     .why-hackersprey .heading-card .card-heading{
        content: '';
        font-size: 2.5rem;
        font-weight: 800;
        padding: 1.6rem 2rem;
        text-align: center;
     
       
        
     }
     .why-hackersprey .heading-card .card-points{
        
         font-size: 1.5rem;
         padding: 1rem 1.8rem;
         border-top-left-radius: 2rem;
         border-bottom-left-radius: 2rem;
         background-color:#212121;
         
         
     }
     .industrial-benefits-container .card-container{
        margin-top: 4.5rem;
    display: flex;
    flex-wrap: wrap;
    max-width: 2000px;
    padding: 0rem 15rem;
    gap: 2rem;
    margin:4.5rem auto;
    }
    .industrial-training-intro-section .training-highlight-section-left{
        width:50% !important;
     }
     .industrial-benefits-container .card{
        cursor: pointer;
       
        background-color: #0F0231;
    
    width: 28rem;
    
    }
}
@media(min-width:1350px){
    .industrial-benefits-container .description{
        width: 80%;
        max-width: 1100px;
    }
   
    .industrial-benefits-container .card-container{

        margin-top: 4.5rem;
    display: flex;
    flex-wrap: wrap;
    max-width: 1500px;
    padding: 0rem 10rem;
    gap: 2rem;
    margin:4.5rem auto;
    }
    .industrial-benefits-container .card{
        cursor: pointer;
       
        background-color: #0F0231;
    
    width: 35rem;
    
    }
}











