/*========== GOOGLE FONTS ==========*/
@import url("https://fonts.googleapis.com/css2?family=Saira&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Saira:wght@500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@700&display=swap');

.profile_dashboard{
    background: #01152b;
  background: -webkit-radial-gradient(top left, #01152b, #040b18);
  background: -moz-radial-gradient(top left, #01152b, #040b18);
  background: radial-gradient(to bottom right, #01152b, #040b18);
  height: auto;
  min-height: 100vh;
 
  border-radius: 8rem;
  width: 90%;

 
 
  background-repeat: no-repeat;
  
  position:relative;
  align-items: flex-start;

  font-size: 3rem;
margin-left: 7rem;
  margin-top: 10rem;
}

.profile_dashboard2{
    background: #01152b;
    background: -webkit-radial-gradient(top left, #01152b, #040b18);
    background: -moz-radial-gradient(top left, #01152b, #040b18);
    background: radial-gradient(to bottom right, #01152b, #040b18);
    height: auto;
   
  min-height:94vh;
    border-radius: 8rem;
    width: 96vw;
  margin-left:-5rem;
    margin-top: 2.2rem;
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
  box-shadow:  2px -5px 20px 8px 	rgba(12,5,18,.85),1px 15px 20px 2px rgb(12,5,18,.85);
  
 
   
    background-repeat: no-repeat;
    
    position:absolute;
    align-items: flex-start;
  
    font-size: 3rem;
 
  

}
.totalscore{
  color: #e32f1c;
  font-size: 2.2rem;
  margin-top: 1.5rem;
  letter-spacing: .1rem;
  text-align: center;
padding-bottom: 0rem;
text-align: center;
  font-weight: 600;
  font-family: "Saira", sans-serif;
}
.editprofileimage{
   width: 250px;
   height: 250px;
    border-radius:50%;
background-color: #01152b;
    background-color: none;
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow: 1px 15px 20px 2px  	rgba(12,5,18,.85),2px -5px 20px 8px rgb(3,28,57,.85);
}
.editprofileimage2{
  width: 300px;
   height: 300px;
    border-radius:50%;
   margin-left: 3rem;
    background-color: none;
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow: 1px 15px 20px 2px  	rgba(12,5,18,.85),2px -5px 20px 8px rgb(3,28,57,.85);
}

.useruploadimage{
    background: url(/src/Assets/Transaction/Rectangle\ 1134.svg);
  border-radius: 100%;
  text-align: center;
  filter: drop-shadow(0px 0px 5px rgba(255,255,255,0.03));
  box-shadow: 1px 5px 5px 2px rgba(12,5,18,.85),2px -5px 10px 5px rgb(3,28,57,.85);
  width:3rem;
  height: 3rem;

  color: white;
  text-align: center;
justify-content: center;
  float: right;
}
.useruploadimage .camera{
    font-size: 1.5rem;
    transform: translateY(-1rem);

}
#profilecards{
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1rem;
  margin-top: 1rem;
  height: auto;
  border-radius: 1rem;
  filter: drop-shadow(0px 0px 7px rgba(255,255,255,0.03));
  box-shadow:  -5px 5px 10px 3px 	rgba(12,5,18,.85),-4px 5px 10px 3px rgb(3, 28, 57,1); 
}
.totalchallengehead{
  color: #99ff00;
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  padding-top: 1rem;
}
#proratdiv{
  background: url(../../../Assets/prodile/Mask_Group_32_uz.png);
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
#profilecardshead{
  color: white;
  font-family: "Saira", sans-serif;
  font-size: 1.45rem;
  text-align: center;
  padding-left: 1rem;
  letter-spacing: 0;
  font-weight: 400;


}
.userplanhead{
  color: #fe875b;
  font-family: "Saira", sans-serif;
  font-weight: 500;
  text-align: center;
}
#profilecardshead2{
  color: white;
  font-family: "Saira", sans-serif;
  font-size: 1.45rem;
  text-align: center;

  letter-spacing: 0;
  font-weight: 400;

}
.changeprofilediv{
    background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
    margin-top: 7rem;
    padding: 1rem;
    border-radius: 1rem;
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow:  2px 10px 20px 5px 	rgba(12,5,18,.85),8px -5px 20px 5px rgb(3, 28, 57,1);
}
.editprofilepara{
    color: white;
    font-size: 1.6rem;
    font-family: "Saira", sans-serif;
    font-weight: 500;
   justify-items: center;
   margin-top: 1.3rem;
}
.arrowbutton{
  background: url("../../../Assets/Transaction/Rectangle\ 1134.svg");
  height: 2rem;
  width: 2rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
  position: relative;
  margin-top: 1rem;
  border-radius: 50%;
  text-align: center;
  filter: drop-shadow(0px 0px 5px rgba(255,255,255,0.03));
  box-shadow:  5px 0px 5px .2px 	#020d24,2px -2px 5px .2px #031c39;
    
}
.arrowbutton .icon{
  color: wheat;
  font-size: 1.8rem;
  position: absolute;
  margin-left: -.9rem;
  margin-top: .2rem;
}
#userprofile{
   background:url(../../../Assets/Transaction/Rectangle\ 1134.svg);
    background-position: center;
    background-repeat: repeat;
    height:auto;
    width: 100%;
    background-size: cover;
   margin-top: 7rem;
    border-radius: 2rem!important;
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow:  2px 10px 20px 5px 	rgba(12,5,18,.85),8px -5px 20px 5px rgb(3, 28, 57,1);
}
#userprofile2{
  background:url(../../../Assets/Transaction/Rectangle\ 1134.svg);
    background-position: center;
    background-repeat: repeat;
    height:auto;
    width: 100%;
    background-size: cover;
   margin-top: 7rem;
    border-radius: 2rem!important;
    transform: translateY(-25%);
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow:  2px 10px 20px 5px 	rgba(12,5,18,.85),8px -5px 20px 5px rgb(3, 28, 57,1);

}
.Editprofilehead{
    color: white;
    padding-top: 4rem;
    padding-left: 2rem;
    font-family: 'Advent Pro', sans-serif;
    font-weight: 700;
    font-size: 3rem;
    letter-spacing: .4rem;
    padding-bottom: 2rem;
    text-transform: uppercase;
}
.Editprofilehead2{
  color: white;
    padding-top: 2rem;
    padding-left: 2rem;
    font-family: 'Advent Pro', sans-serif;
    font-weight: 700;
    font-size: 3rem;
    letter-spacing: .1rem;
    padding-bottom: 2rem;
    text-transform: uppercase;
}

.commonhr{
    background-color: red;
    height: .2rem;
   justify-content: center;
    width: 12rem;
}
.form-input input[type="text"] {
    display: block;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    border: none;
  
    padding: 2.5rem;
    box-shadow: inset -1.5px -1px 6px .5px rgba(51, 43, 130,.7), inset 4px 4px 4px rgba(0,0,0,0.9);
    margin-bottom: 2rem;
    border-radius: 1rem;
    
    font-family: "Saira", sans-serif;
    color: white !important;
    font-weight: 500;
  }
  .form-input input[type="text"] :focus{
    font-size: 2rem!important;
  }
  .form-input input[type="password"] {
    display: block;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    border: none;
  
    padding: 2.5rem;
    box-shadow: inset -1.5px -1px 6px .5px rgba(51, 43, 130,.7), inset 4px 4px 4px rgba(0,0,0,0.9);
    margin-bottom: 2rem;
    border-radius: 1rem;
    
    font-family: "Saira", sans-serif;
    color: white !important;
    font-weight: 500;
  }
  .form-input input[type="password"] :focus{
    font-size: 2rem!important;
  }

  .form-input label {
    color: white;
    font-size: 1.1rem;
    font-family: "Saira", sans-serif;
    font-weight: 500;
  
    margin-left: 1rem;
  }
  .savebtn{
    background: linear-gradient(
        60deg,
        #ee2963,
        #ee265a,
        #ee2550,
        #ed2547,
        #eb263d,
        #e92833,
        #e62b28,
        #e32f1c
      );
      padding: 1.8rem 7rem;
      color: white;
      margin-top: 3rem;
      border-radius: 1rem;
      font-family: "Saira", sans-serif;
      font-weight: 600;
      font-size: 1.3rem;
  margin-bottom: 3rem;
      filter: drop-shadow(0px 0px 10px rgba(255,255,255,.03));
      box-shadow:  2px 10px 20px 5px 	rgba(12,5,18,.85),8px -5px 20px 5px rgb(3, 28, 57,1);
  
  }
  
  /*modaltest btn */
  .testbtn{
    background: linear-gradient(
      60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c
    );
    padding: 1.8rem 5rem;
    color: white;
    margin-top: 3rem;
    border-radius: 1rem;
    font-family: "Saira", sans-serif;
    font-weight: 600;
    font-size: 1.3rem;
margin-bottom: 3rem;
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,.03));
    box-shadow:  2px 10px 20px 5px 	rgba(12,5,18,.85),8px -5px 20px 5px rgb(3, 28, 57,1);

  }
  #profilediv{
    transform: translateY(-25%);
   
  }
  .Editdiv{
  background:url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  height: 4rem;
  width: 4rem;
  border-radius: .5rem;
  filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
  box-shadow:  1px 2px 6px 2px 	rgba(12,5,18,.85),1px -1px 6px 2px rgb(3, 28, 57,1);

  position: relative;
  text-align: center;
  }
  .smallname{
    font-size: .8rem;
    color: gray;
    font-family: "Saira", sans-serif;
    font-weight: 400;
    font-style: italic;
    
  }
  .profileheadname{
    color: white;
    font-family: "Saira", sans-serif;
    font-weight: 700;
    font-size: 1.6rem;
  }
  .profileheadname2{
    color: gray;
    font-family: "Saira", sans-serif;
    font-weight: 600;
    font-size: 1.6rem;
  }
  .vipdiv{
    border: 1px solid #F9BC2C;
    height: 5rem;
    width: 100%;
    padding: 1rem;
    margin-top: 5rem;
    border-radius: 2.5rem;
  }
  .goviphead{
    font-size: 1.5rem;
    color:#F9BC2C;
    display: inline;
    padding-left: 1rem;
    padding-top: .5rem;
    margin-left: .4rem;
  text-align: center;
   font-family: "Saira", sans-serif;
   font-weight: 600;
    position: absolute;
  }
  .validationdiv{
  padding:1.2rem 2rem;
  border: 2px solid red;
  border-radius: 1rem;
  color: white;
  }
  .validationdiv .success-message{
    font-family: "Saira", sans-serif;
    font-size: 1.2rem;
  }