@import url("https://fonts.googleapis.com/css2?family=Saira&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Saira:wght@500&display=swap");
.welcomedasboard{
    background: url(../../../Assets/Welcome/Entrance_Path-01.png);
    height:100vh;
    width: 100%;
   position: relative;
    background-position:center;
   background-size:cover;
overflow: hidden!important;


   

   left: 0rem;
   right: 0;
   bottom: 0;
    z-index: 333;
}
@media screen and (min-width:358px) {
    .welcomedasboard{
        background: url(../../../Assets/Welcome/Entrance_Path-01.png);
        height:100vh;
        width: 100%;
       position: relative;
        background-position:center;
       background-size:cover;
    overflow: hidden!important;
    }
}
@media screen and (max-width:768px) {
    .welcomedasboard{
 
        background-image: url(../../../Assets/Welcome/mobilewelcomescreen.jpg);
        height:100vh;
        width: 100%;
       position: relative;
        background-position:center;
       background-size:cover;
       background-repeat: no-repeat;
    overflow: hidden!important;
    
    
       
    
       left: 0rem;
       right: 0;
       bottom: 0;
        z-index: 333;
    }
    .welcomepara{
        color: white;
        font-size: 6.1rem;
        font-style: 500;
        padding-bottom: 1rem;
        font-family: "Saira", sans-serif;
    }
}

.welcomehead{
    color: white;
    font-size: 3.1rem;
    font-style: 700;
    padding-bottom: 1rem;
    text-transform: capitalize;
    font-family: "Saira", sans-serif;
}
.welcomepara{
    color: white;
    font-size: 2.1rem;
    font-style: 500;
    padding-bottom: 1rem;
    font-family: "Saira", sans-serif;
}