.container-sliders {
    max-width: 100%;
    height: auto;

    position: relative;
    overflow:visible;
top: 0;
   
  }
  @media screen and (min-width: 639px){
    .container-sliders {
      margin-bottom:  120px;
      height: 65px;
      position: relative;
        }
        .container-dots{
          top: 370%;
        }
  }
  @media screen and (max-width: 736px){
    .container-sliders {
      margin-bottom:  100px;
      height: 60px;
      position: relative;
    }
    .container-dots{
      top: 180%;
      position: absolute;
    }
    
    
  }
  .slide {
   
    position: absolute;
    background-size: cover;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
  }
  .slide img {
 

padding-bottom: 0;
   border-radius: 3rem;
  }
  .active-anim {
    opacity: 1;
  }
  
  .btn-slide {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #f1f1f1;
    border: 1px solid rgba(34, 34, 34, 0.287);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .btn-slide img {
    width: 25px;
    height: 25px;
    pointer-events: none;
  }

  
  
  .container-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateY(50%);
    transform: translateX(-50%);
    display: flex;
  }
  .dot {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    border: none;
    margin: 0 5px;
    cursor: pointer;
    background: #f1f1f1
  }
  .dot.active {
    background-image: radial-gradient(circle, #e32f12, #e9282b, #ed233f, #ee2451, #ee2963);
    transform: scale(1.7);
  }

  .levelnamediv{
    background: rgb(196,14,44);
    background: linear-gradient(90deg, rgba(196,14,44,1) 0%, rgba(71,1,1,1) 100%);
    width:100%;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    color: white;
    padding: .5rem;
    text-align: center;
    font-family: 'Saira', sans-serif;
    font-weight: 500;
    font-size: 2.1rem;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
  }
  .mainscorediv{
    margin-top: 1rem;
  }
  .mainscorediv2{
 
    background: url(../../../Assets/Scorecard/ring.svg);
   height:18rem;
   width: 100%;

   background-position: center;
   background-repeat: no-repeat;
   background-size:contain;
   color: whitesmoke;
   text-align: center;
   position: relative;
  
  }
   .mainscorediv2 h1{
     text-align: center;
     transform: translateY(8rem);
     font-family: 'Saira', sans-serif;
     font-weight: 600;
   }
   .mainscoredivleft{
     background: url(../../../Assets/Scorecard/arrow\ left.png);
     background-position: center;
     background-size:contain;
     background-repeat: no-repeat;
     height: auto;
     width:auto;
     
 
   }
   .mainscoredivright{
    background: url(../../../Assets/Scorecard/arrow\ right.png);
    background-position: center;
    background-size:contain;
    background-repeat: no-repeat;
    height: auto;
    width:auto;
    
   }
   .mainscorediv3{
     background:url(../../../Assets/Scorecard/box\ 3.png);
     padding: 2rem;
     margin-top: 1rem;
     border-radius: 2rem;
   }
   .scorecardspan{
     font-size: 1.5rem;
     color: white;
  transform: translateY(-10%)!important;
     text-align: center;
     font-family: 'Saira', sans-serif;
     text-transform: uppercase;
     font-weight: 600;
     letter-spacing: .2rem;
   }