:root{
    --body-left-right-padding:15rem;
    --body-top-bottom-padding:8rem;
    --body-smallscreen-left-right-padding:2rem;
    --body-smallscreen-top-bottom-padding:4rem;

}


.related-course-container{
    background-color: black !important;
    padding: var(--body-top-bottom-padding) 5rem;

}
.related-course-heading{
    text-align: center;
    font-size: 3.2rem;
    font-weight: 700;
}
.related-course-card-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:5rem;
}
.related-course-card{
    background-color: #221D34;
    width: 100%;
    padding: 2rem 2rem;
    border-radius: 10px;
}
.related-course-card img{
    border-radius: 10px;
    width: 100%;
    height: 20rem;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}
.related-course-card h1{
    color: hsla(0, 0%, 100%, .8);
  margin-top: 2rem;
  padding-left: 0.5rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 2rem;

}
.related-course-points{
    color: hsla(0, 0%, 100%, .8);
    padding-left: 0.5rem;
    width: 90%;
    text-align: justify;
    font-size: 1.40rem;
    height: 20rem;
    
}
.related-course-card button{
background: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
padding-left: 3rem;
padding-right: 3rem;
padding-top: 1rem;
padding-bottom: 1rem;
font-size: 1.5rem;
border-radius: 10px;
color: #FFFFFF;

    
}
@media screen and (min-width:450px){
    .related-course-container{
        background-color: black !important;
        padding: var(--body-top-bottom-padding) var(--body-left-right-padding);
    
    }
}
@media screen and (min-width:1000px){
    .related-course-container{
        background-color: #0F0231;
        padding: var(--body-top-bottom-padding) 4rem;
    
    }

    .related-course-card-container{
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        gap:5rem;
    }
    .related-course-card{
        background-color: #221D34;
        width: 40%;
        padding: 2rem 2rem;
        border-radius: 10px;
    }
}
@media screen and (min-width:1200px){
   

    .related-course-card{
        background-color: #221D34;
        width: 30%;
        max-width: 450px;
        padding: 2rem 2rem;
        border-radius: 10px;
    }
}