:root{
    --body-left-right-padding:15rem;
    --body-top-bottom-padding:3.5rem;

}
.cta-section-container{
    color:white;
   display: flex;
   align-items: center;
   justify-content: center;
    padding: var(--body-top-bottom-padding) 2rem;
}
.cta-section-wrapper{
    position: relative;
    width:100%;
    padding:1.5rem 1.5rem;
    background-color: #071122;
display: flex;
align-items: center;
justify-content: space-between;
font-size: 2rem;
border-radius: 10px;
}
.cta-section-wrapper button{
    padding:1rem 1.5rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:5px;
    font-weight: 600;
    transition: all 0.2s linear;
}
.cta-section-wrapper button:hover{
    transform: scale(0.95);
}
.acwp-cta-call-icon{
    font-size: 2.25rem;
}
.acwp-whatsapp-icon{
    height: 4rem;
    width: 4rem;
    color:green;
    cursor: pointer;

}
.acwp-design-lines{
    z-index: -1;
    position: absolute;
    width: 23rem;
    transform: rotate(149.58deg);
    right: -5rem;
    bottom: -5rem;
}
.cta-section-text{
    font-size: 2rem;
}
@media screen and (min-width:1200px) {
    .acwp-whatsapp-icon{
        height: 3.5rem;
        width: 3.5rem;
        color:green;
        cursor: pointer;
    
    }
    .cta-section-wrapper{
        position: relative;
        width:100%;
        padding:2rem 4rem;
        background-color: #071122;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 2rem;
    border-radius: 10px;
    }

    .cta-section-container{
        color:white;
       display: flex;
       align-items: center;
       justify-content: center;
        padding: var(--body-top-bottom-padding) var(--body-left-right-padding);
    }
    .cta-section-wrapper{
        position: relative;
        width:70%;
        padding:2rem 4rem;
        background-color: #071122;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 2rem;
    border-radius: 10px;
    }
}