:root{
    --body-left-right-padding:15rem;
    --body-top-bottom-padding:8rem;
    --small-body-left-right-padding:5rem;
    --small-body-top-bottom-padding:3rem
    
}

.acwp-hero-section-container{
    background-image: url('../../Assets/acwassets/hero-section-bg.svg');
    background-color: #060218;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    padding: var(--small-body-top-bottom-padding) var(--small-body-left-right-padding);
    color:white;
    width: 100%;
    gap:8rem;
    
}
.hero-section-left-container{
    width: 100%;
}
.acwp-hero-section-container h3{
    display: block;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
    background: linear-gradient(#ACE7FF, #FFF);
    background-clip: text;
    color: transparent;
}
.acwp-hero-section-container hr{
  margin: 2rem 0rem;
  color: #565151;
  width: 100%;
  border-top: 1px solid #565151;
}
.acwp-hero-section-container h1{
 font-size: 3.5rem;
 font-weight: 700;
  }
  .acwp-hero-section-container .acwp-hero-section-desc{
    font-size: 2rem;
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
    letter-spacing: 2px;
    line-height: 30px;
    text-align: justify;

     }
     .acwp-hero-section-container button{
        position: relative;
        overflow: hidden;
        font-size: 1.55rem;
        width: 100%;
        margin-top: 3rem;
        font-weight: 700;
        border-radius: 10px;
        padding: 0.75rem 8rem;
        transition: all 0.2s linear;
    
         }
.acwp-hero-line{
    display: block;
}
.hero-section-points{
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
}
.hero-section-points-wrapper{
    display: flex;
    width: 100%;
    align-items: center;
    border:1px solid #c8a2fb;
    padding: 0.75rem 2rem;
    border-radius: 20px;
    justify-content: center;
    gap:5px;
    

}
.hero-section-points-wrapper p{
   font-size: 1.5rem;
   margin: 0px !important;
   padding: 0px !important;


}
.hero-section-right-container{
    display:relative;
width: 100%;
}
.hero-section-right-container-card-container{
    width:100%;
    border:2px solid #221d34;  
  padding: 2rem 2rem;
  border-radius: 15px;
  color: hsla(0, 0%, 100%, .8);
  background-color: rgba(12, 3, 21, 0.2);
  backdrop-filter: blur(10px);
  box-shadow: 5px 5px 15px 5px #221d34;
  
}
.hero-section-right-container .card-img{
    width:100%; 
}

.hero-section-right-container .hero-section-price{
    display: flex;
    align-items:center ;
    font-weight: 700;
    font-size: 2.75rem;
    gap:1px;
    
}
.hero-section-right-container .hero-section-price span{
    font-weight: 700;
    font-size: 2rem;
}
.hero-whats-included h1{
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 1.75rem;
    margin-top: 2rem;
}
.hero-whats-included-points{
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 0.5rem;
    font-size: 1.5rem;
}
.hero-whats-included-points p{
margin: 0px 0px !important;
}
.purple-hue{
    top: 80px;
    right: 0px;
    position: absolute;
    height: 32rem;
    width: 32rem;
    background: rgba(104, 63, 190, 0.2);
    filter: blur(120px);
}
.yellow-hue{
    top: 80px;
    left: 0px;
    position: absolute;
    height: 32rem;
    width: 32rem;
    background: rgba(104, 63, 190, 0.2);
    filter: blur(120px);
}
.acwp-hero-section-container button:hover{
    transform: scale(0.95);
}


@keyframes slide {
    0% {
        transform: rotate(-10deg) translateX(-100px);
    }
    100% {
        transform: rotate(-10deg) translateX(500px);
    }
}

.hero-section-metric-points{
    display: flex;
    gap:2rem;
    margin-top: 3rem;
}
.hero-section-metric-points-first{
    font-size: 2rem;
    background: linear-gradient(180deg, #15528b, rgba(21, 82, 139, .1));
    padding: 1rem;
    text-align: center;
    border-radius: 20px;
    width: 14rem;
}
.hero-section-metric-points-second{
    font-size: 2rem;
    background: linear-gradient(180deg, #387d5d, rgba(56, 125, 93, .1));
    padding: 1rem;
    text-align: center;
    border-radius: 20px;
    width: 14rem;
}
.hero-section-metric-points-third{
    font-size: 2rem;
    background: linear-gradient(180deg, #d96e2d, rgba(217, 110, 45, 0.1));
    padding: 1rem;
    text-align: center;
    border-radius: 20px;
    width: 14rem;
}
.hero-section-metric-points-fourth{
    font-size: 2rem;
  
    background: linear-gradient(180deg, #7d3838, rgba(125, 56, 56, 0.1));
    padding: 1rem;
    text-align: center;
    border-radius: 20px;
    width: 14rem;
}
.hero-section-metric-points-metric{
font-weight: 800;
font-size: 2.25rem;
}


@media screen and (min-width:500px){
   
    .hero-section-right-container{
        display:relative;
    width: 80%;
    }
    .hero-section-left-container{
        width: 80%;
    }
}
@media screen and (min-width:500px){
    .hero-section-points-wrapper{
        display: flex;
        justify-content: center;
        width: 80%;
        align-items: center;
        border:1px solid #c8a2fb;
        padding: 0.75rem 2rem;
        border-radius: 20px;
        justify-content: center;
        gap:5px;
        
    
    }
   
    .hero-section-right-container{
        display:relative;
    width: 60%;
    }
    .hero-section-left-container{
        width: 68%;
    }
}
@media screen and (min-width:1000px){
    .hero-section-points-wrapper{
        display: flex;
        justify-content: center;
        width: 47.5%;
        align-items: center;
        border:1px solid #c8a2fb;
        padding: 0.75rem 2rem;
        border-radius: 20px;
        justify-content: center;
        gap:5px;
        
    
    }
    .acwp-hero-section-container{
        background-image: url('../../Assets/acwassets/hero-section-bg.svg');
        background-color: #060218;
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content:center;
        padding: var(--small-body-top-bottom-padding) var(--small-body-left-right-padding);
        color:white;
        width: 100%;
        gap:8rem;
      
        
    }
    .hero-section-right-container{
        display:relative;
    width: 40%;
    max-width: 350px;

    }
 
    .hero-section-left-container{
        width: 50%;
        max-width:566px;
    
    }
}





@media screen  and (min-width:1300px) {
   
    .acwp-hero-section-container{
        flex-direction: row;
        background-image: url('../../Assets/acwassets/hero-section-bg.svg');
        background-color: #060218;
        display: flex;
        align-items: start;
        justify-content:center;
        padding: var(--body-top-bottom-padding) var(--body-left-right-padding);
        color:white;
        width: 100%;
        gap:8rem;
        
    }
    .hero-section-left-container{
        width: 50%;
        max-width: 800px;
    }
    .acwp-hero-section-container h3{
        display: block;
        text-transform: uppercase;
        font-size: 28px;
        font-weight: 700;
        background: linear-gradient(#ACE7FF, #FFF);
        background-clip: text;
        color: transparent;
    }
    .acwp-hero-section-container hr{
      margin: 2rem 0rem;
      color: #565151;
      width: 100%;
      border-top: 1px solid #565151;
    }
    .acwp-hero-section-container h1{
     font-size: 4rem;
     font-weight: 700;
      }
      .acwp-hero-section-container .acwp-hero-section-desc{
        font-size: 2rem;
        margin-top: 3.5rem;
        margin-bottom: 3.5rem;
        letter-spacing: 2px;
        line-height: 30px;
    
         }
         .acwp-hero-section-container button{
            position: relative;
            overflow: hidden;
            font-size: 2.25rem;
            width: 100%;
            margin-top: 3rem;
            font-weight: 700;
            border-radius: 10px;
            padding: 0.75rem 8rem;
        
             }
    .acwp-hero-line{
        display: block;
    }
    .hero-section-points{
        margin-top: 1.5rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;
    }
    .hero-section-points-wrapper{
        display: flex;
        align-items: center;
        border:1px solid #c8a2fb;
        padding: 0.75rem 2rem;
        border-radius: 20px;
        justify-content: center;
        gap:5px;
        
    
    }
    .hero-section-points-wrapper p{
       font-size: 1.5rem;
       margin: 0px !important;
       padding: 0px !important;
    
    
    }
    .hero-section-right-container{
        display:relative;
    width: 40%;
    max-width: 500px;
    }
    .hero-section-right-container-card-container{
        width:100%;
        border:2px solid #221d34;  
      padding: 2rem 2rem;
      border-radius: 15px;
      color: hsla(0, 0%, 100%, .8);
      background-color: rgba(12, 3, 21, 0.2);
      backdrop-filter: blur(10px);
      
    }
    .hero-section-right-container .card-img{
        width:100%;
        
    }
    
    .hero-section-right-container .hero-section-price{
        display: flex;
        align-items:center ;
        font-weight: 700;
        font-size: 2.75rem;
        gap:1px;
        
    }
    .hero-section-right-container .hero-section-price span{
        font-weight: 700;
        font-size: 2rem;
        
    }
    .hero-whats-included h1{
     
        margin-bottom: 1rem;
        font-weight: 600;
        font-size: 1.75rem;
        margin-top: 2rem;
    }
    .hero-whats-included-points{
        display: flex;
        align-items: center;
        gap: 5px;
        margin-top: 0.5rem;
        font-size: 1.5rem;
    }
    .hero-whats-included-points p{
    margin: 0px 0px !important;
    }
    .purple-hue{
        top: 80px;
        right: 0px;
        position: absolute;
        height: 32rem;
        width: 32rem;
        background: rgba(104, 63, 190, 0.2);
        filter: blur(120px);
    }
    .yellow-hue{
        top: 80px;
        left: 0px;
        position: absolute;
        height: 32rem;
        width: 32rem;
        background: rgba(104, 63, 190, 0.2);
        filter: blur(120px);
    }
    
    
   
    @keyframes slide {
        0% {
            transform: rotate(-10deg) translateX(-150px);
        }
        100% {
            transform: rotate(-10deg) translateX(300px);
        }
    }



}
.hero-section-registration-desc{
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    margin-top: 1rem;
    color: #0b3d91; 
    background: linear-gradient(90deg, #ffcc00, #ff6347);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.hero-section-registration-desc span{
    font-size: 1.45rem;
    text-align: center;
}
@keyframes gradientAnimation {
    to {
      background-position: 200%;
  
  }
}
  
.coming-soon-text{
    text-align: center;
    margin-top: 1.5rem;
    font-size: 2.5rem;
    font-weight: 800;
    background-size: 50%;
    background: linear-gradient(90deg, #ffcc00, #ff6347);
    animation: gradientAnimation 3s infinite linear;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
