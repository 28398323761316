/*Main home css  starts */
.Main_home {
  background-image: url(/src/Assets/Landing\ page/Mask_Group_1.webp);
  width: 100%;
  height: auto;
  min-height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  padding: 0;

}

.testhead {
  color: white;
  padding-top: 15rem;
  font-family: 'Saira', sans-serif;
  font-weight: 700;
  font-size: 7rem;
  text-transform: uppercase;
  letter-spacing: .4rem;
}


@media(max-width:768px) {
  .testhead {
    color: white;
    padding-top: 30rem;
    font-family: 'Saira', sans-serif;
    font-weight: 700;
    font-size: 6rem;
    text-transform: uppercase;
    letter-spacing: .4rem;
  }


  .Main_home {
    background-image: url(../../Assets/Landing\ page/phonebg.webp);
    height: 100vh;
    width: 100%;
  }

  #Mainhomediv #Mainhomepicdiv img {
    width: 70%;
  }

  .Commonlogospan {
    margin-top: 10%;
    font-size: 14pt;
  }
}

.btn-explore {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  color: white;
  padding: 1rem 3rem;
  margin-top: 2rem;
  font-size: 2rem;
  font-family: 'Saira', sans-serif;
  border-radius: 4rem;
}

.testpara {
  color: white;
  opacity: .8;
  font-family: 'Saira', sans-serif;
  text-transform: capitalize;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
}

#Mainhomediv {

  background: none;

  border: none;

  height: auto;
  background-repeat: no-repeat;
  background-size: cover;

  min-height: 100vh;

  width: 100%;

}

@media (max-width: 1378px) {


  #Mainhomediv #Mainhomepicdiv {
    text-align: center;
  }

  #Mainhomediv #Mainhomepicdiv img {
    width: 100%;
  }

  .howitworksimage {
    width: 25%;
    transform: translateX(-100px);
  }
}



@media (max-width: 575px) {
  #Mainhomediv #Mainhomepicdiv img {
    width: 80%;
  }

  .howitworksimage {
    width: 80%;
    margin-top: 4rem;
    transform: translateX(-200px);
  }
}

/* Main home css ends */

/* whoweare css starts */
.Commonbackground {
  background: url(/src/Assets/Landing\ page/Mask_Group_2.webp);
  height: auto;
  width: 100%;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
}

.Commonlogospan {
  color: White;
  position: absolute;
  font-family: 'Advent Pro', sans-serif;
  font-size: 19pt;
  text-align: center;
  margin-top: 3%;
  font-weight: 600;
  letter-spacing: 2px;
}

@media (max-width: 1378px) {


  .Commonlogospan {
    margin-top: 15%;
    font-size: 14pt;
  }

}



@media (max-width: 575px) {
  .Commonlogospan {
    margin-top: 8%;
    font-size: 14pt;
  }
}

/* whoweare starts */
#whowe h1 {
  color: white;
  font-family: 'Advent Pro', sans-serif;
  font-size: 7.5rem;
}

#whowe h2 {
  color: white;
  font-family: 'Advent Pro', sans-serif;
  font-size: 7.5rem;
}

#whowe p {
  color: white;
  font-family: 'Saira', sans-serif;
  font-size: 1.8rem;
  justify-content: left;
  letter-spacing: .1rem;
  font-weight: 400;
  margin-top: 2rem;

}

#whoweareline {
  background-repeat: no-repeat;
  background-image: url(/src/Assets/Landing\ page/Group406.svg);
  height: auto;
  width: 100%;


  background-position-x: right;
  background-position-y: bottom;
}

#whoweareline2 {
  background-repeat: no-repeat;
  background-image: url(/src/Assets/Landing\ page/Group6548.svg);
  height: auto;
  width: 100%;


  background-position-x: left;
  background-position-y: bottom;

}

.whowearehr {
  background-color: #e32f1c;
  height: 0.4rem;
  border-radius: 10px;
  width: 24rem;
}

/*whoweare ends  */
/*common heading Satrts */
.Landpage_heading {
  align-items: center;
  text-align: center;
  position: relative;

}

.Landpage_heading h1 {
  color: white;
  font-family: 'Advent Pro', sans-serif;
  font-size: 7.5rem;
  letter-spacing: 0.7rem;




}

.Landpage_heading h3 {
  color: white;
  font-family: 'Advent Pro', sans-serif;
  font-size: 7.5rem;
  letter-spacing: 0.7rem;

}


.Landpage_heading h2 {
  color: white;
  font-family: 'Advent Pro', sans-serif;
  font-size: 6.0rem;
  margin: 1rem;
  letter-spacing: 0.7rem;

}


/* common heading ends */
/* features start */
.featureshr {
  background-color: #e32f1c;
  height: 0.6rem;

  border-radius: 10px;
  width: 23rem;
}

.featurebackground {
  background: url(/src/Assets/Landing\ page/Mask_Group_2.webp);

  height: auto;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#card4 {
  background-image: linear-gradient(to right bottom, #101b29, #101b29, #101b29, #101b29, #101b29);
  height: 200px;


  border-radius: 15px;
}

#card5 {
  background-image: linear-gradient(to right bottom, #b6d031, #b6d031, #b6d031, #b6d031, #b6d031);
  height: 200px;
  white-space: normal;
  border-radius: 15px;
}

#card6 {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  height: 200px;

  border-radius: 15px;
}

#card1 {

  background-image: linear-gradient(to right bottom, #199a60, #199a60, #199a60, #199a60, #199a60);
  height: 200px;

  text-align: center;
  justify-content: center;
  border-radius: 15px;
}

#card3 {
  background-image: linear-gradient(to right bottom, #1e50d3, #1e50d3, #1e50d3, #1e50d3, #1e50d3);
  height: 200px;

  margin-top: -250px !important;
  display: flex;
  justify-content: flex-start;
  border-radius: 15px;
}

#card2 {
  background-image: linear-gradient(to right bottom, #2574b0, #2574b0, #2574b0, #2574b0, #2574b0);
  height: 450px;

  width: 250px;
  border-radius: 15px;
}

#card-topdiv h6 {
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  font-size: 3rem;
  margin-top: 1rem !important;

}

#card-topdiv p {
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  margin-top: -1rem;
  font-size: 1.5rem;
}

.featurepara {
  color: white;
  font-family: 'Saira', sans-serif;
  font-size: 1.7rem;
  margin-top: 25%;
  margin-top: .6rem;
  padding: 1.5rem;
  font-weight: 400;
  text-align: center;

}

.featurepara2 {
  color: white;
  font-family: 'Saira', sans-serif;
  font-size: 1.8rem;
  margin-top: 7%;
  text-align: justify;
  font-weight: 400;
  justify-content: left;


}

.featureheaad {
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  font-size: 4rem;
  color: white;
  text-align: start;
}

#featurepara3 {
  color: white;
  font-family: 'Saira', sans-serif;
  font-size: 1.4rem;
  margin-left: 2rem;
  margin-top: 2rem;
  justify-content: flex-start;
  display: flex;
}

#featurepara4 {
  color: white;
  font-family: 'Saira', sans-serif;
  font-size: 2.8rem;
  font-weight: 600;
  z-index: 2;
  margin-top: -1rem;
  letter-spacing: .2rem;
  margin: .5rem;
  margin-left: 2rem;
  justify-content: flex-start;
  display: flex;
}

#featurepara2 {
  color: white;
  font-family: 'Saira', sans-serif;
  font-size: 2rem;
  font-weight: 600;
  margin-top: -1rem;
  letter-spacing: .2rem;
  margin: .5rem;
  margin-left: 2rem;
  justify-content: flex-start;
  display: flex;
}

#featurepara6 {
  color: white;
  font-family: 'Saira', sans-serif;
  font-size: 2rem;
  font-weight: 600;
  margin-top: -1rem;
  letter-spacing: .2rem;
  margin: .5rem;

  justify-content: flex-start;
  display: flex;
}

#featurepara7 {
  color: white;
  font-family: 'Saira', sans-serif;
  font-size: 3rem;
  font-weight: 600;

  letter-spacing: .2rem;
  margin: .5rem;

  justify-content: center;
  display: flex;
}

#featurepara8 {
  color: white;
  font-family: 'Saira', sans-serif;
  font-size: 1.5rem;
  font-weight: 400;

  letter-spacing: .2rem;
  margin: .5rem;

  justify-content: center;
  display: flex;
}

#featurepara5 {
  color: white;
  font-family: 'Saira', sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: -1rem;
  letter-spacing: .2rem;
  margin: .5rem;
  margin-left: 2rem;
  margin-top: 60%;
  justify-content: flex-end;
  display: flex;
}

#card-bottomdiv img {

  height: auto;
}

.featurepic {
  width: 90%;
  overflow: hidden;
}

.featurepic1 {
  width: 100%;
  float: left;

}

.featurepic2 {
  width: 60%;
  margin-top: -4rem;
  float: right;
  overflow: hidden;
}

.featurepic3 {
  width: 90%;
  margin-top: -4rem;
  float: left;
  overflow: hidden;
}

.featurepic6 {
  width: 100%;


}

#featurecontainer {
  text-align: center;
  align-items: center;

}

@media (max-width: 1378px) {

  #card2 {
    height: 250px;
    margin-top: 50px;
    width: 100%;
  }

  .featurepic6 {
    width: 20%;
    height: auto;
  }

  #card3 {
    margin-top: -20px !important;
  }

  #card4 {
    margin-top: 50px !important;
  }

  #card5 {
    margin-top: 50px !important;
  }

  .featurepic {
    width: 60%;
  }

}

@media (max-width: 768px) {


  #card3 {
    margin-top: 50px !important;
  }

  #card4 {
    margin-top: 50px !important;
  }

  #card5 {
    margin-top: 50px !important;
  }

  .featurepic6 {
    width: 20%;
    height: auto;
  }

}

@media (max-width: 575px) {
  #card3 {
    margin-top: 50px !important;
  }

  #card4 {
    margin-top: 50px !important;
  }

  #card5 {
    margin-top: 50px !important;
  }

  .commonlogoimage {
    width: 7.4rem;
  }

  .featurepic6 {
    width: 20%;
    height: auto;
  }

}

/* features ends  */

/* spacing starts */
.spacingdiv {
  background-color: #000715;
  height: auto;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;


}

.spacingdiv2 {
  background: url(../../Assets/Landing\ page/Waves.svg);
  height: auto;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
}

.spacingpic {
  height: auto;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}

/* spacing portion ends */
/*course starts */

#coursepicdiv {
  height: auto;
  width: 100%;

  border-radius: 20px;


  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 5px 15px 5px rgba(12, 5, 18, 0.95),
    2px -5px 15px 5px rgb(3, 28, 57, 1);
  background: url(../../Assets/Transaction/Rectangle\ 1134.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.courseh6 {
  color: white;
  font-family: 'Advent Pro', sans-serif;
  font-size: 2.6rem;
  letter-spacing: .2rem;
}

.courseh3 {
  color: white;
  font-family: 'Advent Pro', sans-serif;
  font-size: 4.2rem;
  letter-spacing: 0.8rem;
}

.courseh4 {
  color: white;
  font-family: 'Advent Pro', sans-serif;
  font-size: 4.2rem;
  letter-spacing: 0.5rem;
}

.coursebtn {
  border: none;
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  padding: 20px 60px;
  border-radius: 10px;
  font-size: 1.2rem;
  letter-spacing: .2rem;
  text-align: start;
}

/* course ends */
/*how works start */

.Howitheading h2 {
  color: white;
  font-size: 2.0rem;

}

.howitworksimage {
  transform: translateX(100px);
  align-items: center;

}

.howitpara {
  color: gray;
  opacity: .1;
  font-size: 1.5rem;

}

/* ends nut pending */
/* newletter starts */
.Newsletter_back {
  background: url(/src/Assets/Landing\ page/Mask_Group_14.webp);
  height: auto;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.newsletterpara {
  color: white;
  font-family: 'Advent Pro', sans-serif;
  font-size: 2.6rem;
  letter-spacing: 0.7rem;

}

.newsletter-form input {
  border-radius: 1.2rem;
  font-family: 'Saira', sans-serif !important;
  font-weight: 500 !important;

  font-size: 1.3rem;
  color: white;

  letter-spacing: 0.2rem;
  padding: 3.0rem;
  box-shadow: inset -2px -2px 4px #332B82,
    inset 4px 4px 4px black;
  border: none;
  background: transparent;
}

.newsletter-form input:focus {
  background: transparent;
  color: white;
}

.newsletter-form textarea {
  border-radius: 1.2rem;
  font-family: 'Saira', sans-serif !important;
  font-weight: 500 !important;
  font-weight: bold;
  font-size: 1.3rem;
  color: white;

  letter-spacing: 0.2rem;
  padding: 3.0rem;
  box-shadow: inset -2px -2px 4px #332B82,
    inset 4px 4px 4px black;
  border: 0 none;
  background: transparent;
}

.newsletter-form textarea:focus {
  background: transparent;
  color: white;
}

.Newsletterbtn {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  padding: 1.5rem 6.0rem;
  border-radius: 1.0rem;
  font-size: 1.2rem;
  letter-spacing: .2rem;
  margin-top: 2rem;
  text-align: center;
}

#box1 {
  animation: wheel 3s linear infinite;
  transform-origin: center;
  transform-box: fill-box;

}



@keyframes wheel {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}


#cylinder {
  animation: cylind 2s infinite ease-in-out alternate;

}

@keyframes cylind {

  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100px);
  }
}

#box2 {
  animation: cylind 2s infinite ease-in-out alternate;

}

/* #box2{
        animation: wheel 5s linear  infinite ;
        transform-origin: center;
        transform-box: fill-box;
    }
    @keyframes box-2 {
            0% {
                transform: rotate(0deg);
            }
        
            100% {
                transform: rotate(360deg);
            }
         
        } */


/* box3 rotation css */

#box3 {

  animation: box3 3s linear infinite;
  transform-origin: center;
  transform-box: fill-box;

}

@keyframes box3 {

  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }

}


/* box4 rotation css */
#box4 {
  /* animation: box4 2s linear infinite ;
        transform-origin: center; */

  animation: box4 3s linear infinite;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes box4 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#_105553518201664 {

  animation: groupIcon 3s linear infinite;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes groupIcon {

  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }

}


/*accordian starts */
.accordback {
  background: url(../../Assets/Transaction/Rectangle\ 1134.svg);
  border-radius: 1rem !important;
  margin-top: 2rem;
  color: #EAECEC;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  padding: 2rem;

  align-items: center;
  height: auto;
  width: auto;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 4px 10px 1px rgba(12, 5, 18, .85), 2px -3px 10px 1px rgb(3, 28, 57, 1);
}

.accordback h1 {
  color: white;
  font-size: 1.6rem;
  text-align: center;
  font-family: 'Saira', sans-serif;
  font-weight: 600;

}

.spans {
  background: url("../../Assets/Transaction/Rectangle\ 1134.svg");
  background-position: center;
  border: none;
  height: 2rem;
  width: 2rem;
  float: right;
  padding: .5rem;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.03));
  box-shadow: 1px 2px 7px 1px rgba(12, 5, 18, .85), 1px -2px 7px 1px rgb(3, 28, 57, 1);

}

.accordans {
  color: grey;
  margin-top: 2rem;
  font-size: 1.5rem;
  text-align: center;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
}

/* slider start */
.slider {
  position: relative;
  height: 100vh;
  display: grid;
  justify-content: center;
  align-items: center;
}

.image {
  width: 1000px;
  height: 1000px;
  border-radius: 10px;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}


.btn-explore {
  color: white;
  font-size: 1.5rem;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
}

.btn-explore:hover {
  color: white;
  transform: scale(1.3);
  transition: 2s;
}

/*testimonail page */

.mySwiper {
  background-image: url(/src/Assets/Transaction/Rectangle\ 1134.svg);
  border-radius: 2.4rem;
  height: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  filter: drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 10px 15px 13px rgba(12, 5, 18, .85), -4px 5px 15px 13px rgb(3, 28, 57, 1);
  padding: 4rem;
}

#swipediv {
  background-image: url(/src/Assets/Transaction/Rectangle\ 1134.svg);
  height: auto;
  width: 100%;
  color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;





}

.quoteimage2 {
  height: 100px;
  width: 100px;
  transform: rotate(180deg);
}




.instrutor {
  display: inline;
  text-align: start;
}

.instrutor h2 {
  font-size: 3rem;
  color: #fff;
  font-weight: 500;
  padding: 3rem;
  font-family: "Saira", sans-serif;
}

.instrutor p {
  font-size: 1.5rem;
  font-weight: 400;
  color: #fff;
  font-family: "Saira", sans-serif;
  position: relative;
  left: 3rem;
}

#paragraphs {
  letter-spacing: 1px;
  color: #fff;
  text-align: justify;
}