.card-top-container{
  display: flex;  
  flex-direction: column;
  align-items: center;
  padding-left: 2.5rem;
}
.card-container{
    display:flex;
    flex-direction: column;
    gap: 7rem;
    margin-top: 50px;
    align-items: center;
    justify-content: center;
}

.card-image{
    position: absolute;
    left: -8rem;
    width: 14.5rem;
    height: 14.5rem;
    top:1rem
}
.card-header{
  position: relative;
  bottom: 6.5px;
  margin-left:64px;
  border-radius: 0.5rem;
  display: inline;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom:64px;
  font-size: 1.5rem; /* 24px */
line-height: 2rem; /* 32px */
color: white;
}
.card-paragraph{
border-width: 4px;
padding-left: 50px;
padding-right: 20px;
padding-top: 16px;
padding-bottom: 16px;
border-color: white;
font-size: 1.25rem; /* 20px */
line-height: 1.75rem; /* 28px */
border-radius: 9999px;
margin-bottom: 8px;
text-align: justify;
color: white;
border-style: solid;
}
.card-inner{
    width: 70%;
    position:relative;
    cursor:pointer;
}
@media screen and (min-width:574px){
  .card-image{
    position: absolute;
    left: -8rem;
    width: 12.5rem;
    height: 12.5rem;
    top:1rem
}
}
@media screen and (min-width: 768px) {
    .card-paragraph{
        border-width: 4px;
        padding-left: 60px;
        padding-right: 20px;
        padding-top: 16px;
        padding-bottom: 16px;
        border-color: white;
        font-size: 1.25rem; /* 20px */
        line-height: 1.75rem; /* 28px */
        border-radius: 9999px;
        margin-bottom: 8px;
        text-align: justify;
        color: white;
        border-style: solid;
        }
    .card-container{
        display:flex;
        flex-direction: column;
        gap: 7rem;
        margin-top: 128px;
        align-items: center;
        justify-content: center;
    
    }
    .card-inner{
        position:relative;
        cursor:pointer;
        width: 70%;
    }
    .card-image{
        position: absolute;
        left: -9rem;
        width: 14.5rem;
        height: 14.5rem;
        top:-1.6rem;
    }
  }

  @media screen and (min-width: 1024px) {
    .card-paragraph{
        border-width: 4px;
        padding-left: 100px;
        padding-right: 20px;
        padding-top: 16px;
        padding-bottom: 16px;
        border-color: white;
        font-size: 1.25rem; /* 20px */
        line-height: 1.75rem; /* 28px */
        border-radius: 9999px;
        margin-bottom: 8px;
        text-align: justify;
        color: white;
        border-style: solid;
        }
    .card-inner{
        position:relative;
        cursor:pointer;
        width: 40%;
    }
    .card-container{
        display:flex;
        flex-direction: row;
        gap: 10rem;
        margin-top: 160px;
        justify-content: center;
        align-items: center;
    
    }
    .card-image{
        position: absolute;
        left: -7rem;
        top:-1.6rem;
        width: 16.5rem;
        height: 16.5rem;

    }
  }
  @media screen and (min-width: 1280px) {
    .card-paragraph{
        border-width: 4px;
        padding-left: 100px;
        padding-right: 20px;
        padding-top: 16px;
        padding-bottom: 16px;
        border-color: white;
        font-size: 1.25rem; /* 20px */
        line-height: 1.75rem; /* 28px */
        border-radius: 9999px;
        margin-bottom: 8px;
        text-align: justify;
        color: white;
        border-style: solid;
        }
    .card-inner{
        position:relative;
        cursor:pointer;
        width: 25%;
    }
    .card-container{
        display:flex;
        flex-direction: row;
        gap: 18rem;
        margin-top: 112px;
        align-items: center;
        justify-content: center;
    
    }
  }
  .card1-back{
    background: linear-gradient(to right, #6A5AFA, #1F1763);
  }
  .card2-back{
    background: linear-gradient(to right, #3949ab, #1a237e);
  }
  .card3-back{
    background: linear-gradient(to right, #FFB83D, #C26C0F);
  }
  .card4-back{
    background: linear-gradient(to right, #9DFF00, #274A00);
  }
