.webinar-course-component{
    background-color:#1a2332;
    
    padding-bottom: 1rem;
    border-radius: 10px;
   
    
}
.webinar-course-card-header{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

}
.webinar-course-card-img{
    height: 10rem;
    width: 18rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 20px;
    overflow: hidden;
    
    
}
.webinar-course-card-body{
  
    padding-left: 2rem;
  
}
.webinar-course-card-title{
    font-weight:600;
    font-size: 3.5rem;
    margin-top: 1rem;
}
.webinar-course-features{
    padding-right: 3rem;
    text-align: justify;
    margin-top: 1.5rem;
    height: 17rem;
}
.webinar-courses-features-list{
   display: flex;
   font-size: 1.5rem;
   gap: 5px;
   
}
.webinar-link2{
    text-decoration: none;
    color:white;
    margin-bottom: 20px;
}
.webinar-link2:hover{
    text-decoration: none;
    color: white;
    margin-bottom: 20px;
}
.webinar-circle-icon{
    height: 0.8rem;
    width: 0.8rem;
    margin-top: 0.65rem;
}
.webinar-course-card-btn-wrap{
    margin-top: 2rem;
    display: flex;
    justify-content: center;

}
.webinar-course-card-btn{
    padding-left: 3rem;
    padding-right: 3rem;
    background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    display:inline;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 1.2rem;
    font-size: 1.5rem;
    border-radius: 5px;
    cursor: pointer;
}
@media screen and (max-width: 4000px){
    .webinar-course-component{
        background-color:#1a2332;
        width: 19%;
        padding-bottom: 1rem;
        border-radius: 10px;
       
        
    }
    .webinar-course-card-body{
  
        width: 95%;
      
    }
}
@media screen and (max-width: 2200px){
    .webinar-course-component{
        background-color:#1a2332;
        width: 20%;
        padding-bottom: 1rem;
        border-radius: 10px;
       
        
    }
    .webinar-course-card-body{
  
        width: 98%;
      
    }
}
@media screen and (max-width: 2000px){
    .webinar-course-component{
        background-color:#1a2332;
        width: 25%;
        padding-bottom: 1rem;
        border-radius: 10px;
       
        
    }
    .webinar-course-card-body{
  
        width: 98%;
      
    }
}
@media screen and (max-width: 1800px){
    .webinar-course-component{
        background-color:#1a2332;
        width: 30%;
        padding-bottom: 1rem;
        border-radius: 10px;
       
        
    }
    .webinar-course-card-body{
  
        width: 98%;
      
    }
}
@media screen and (max-width: 1200px){
.webinar-course-card-btn-wrap{
    margin-top: 4rem;
    display: flex;
    justify-content: center;

}
}
@media screen and (max-width: 1100px){
    .webinar-course-card-btn-wrap{
        margin-top: 7.5rem;
        display: flex;
        justify-content: center;
    
    }
    }

@media screen and (max-width: 1020px){
   
    .webinar-course-component{
        background-color:#1a2332;
        width: 50%;
        padding-bottom: 1rem;
        border-radius: 10px;
       
        
    }
    .webinar-course-card-btn{
        padding-left: 3rem;
        padding-right: 3rem;
        background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
        display:inline;
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-top: 1.2rem;
        font-size: 1.5rem;
        border-radius: 5px;
        cursor: pointer;
    }
}

@media screen and (max-width: 660px){
    .webinar-course-component{
        background-color:#1a2332;
        width: 70%;
        padding-bottom: 1rem;
        border-radius: 10px;
       
        
    }
}


