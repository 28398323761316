:root{
    --body-left-right-padding:15rem;
    --body-top-bottom-padding:8rem;

}
.acwphr2{
    background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
   height: 0.3rem;
   border-radius: 10px;
   width: 23rem;
 }
.acwp-enroll-btn-inside2,.acwp-enroll-btn-inside{
    transition:all 0.2s linear;
}
.acwp-enroll-btn-inside:hover{
    transform: scale(0.95);
}
.acwp-enroll-btn-inside2:hover{
    transform: scale(0.95);
}
.acwp-right-section-point-wrapper{
    background-color: #35297D;
    padding: 1.25rem 1.25rem;
    border-radius: 5px;
    border-color:2px solid #0F0231;
 
}
.acwp-right-section-point-section img{

    width: 4rem;
    height: 4rem;
}
.acwp-overview-section{
    padding:4rem 5rem;
    color: white;
    background-image: linear-gradient(133deg,#100d25,#190b69);
   
}
.acwp-sub-heading{
   color: #E82931;
    text-align: center;
}
.acwp-heading{
    margin-top: 1rem;
    text-align: center;
    font-size: 3.2rem;
    font-weight: 700;
  
}
.acwp-heading2{
    margin-top: 1rem;
    
    font-size: 3.2rem;
    font-weight: 700;
  
}
.acwp-overview-section-content{
    display: flex;
    flex-direction:column;
    align-items: start;
    justify-content: space-between;

}
.acwp-overview-left-section{
width: 100%;
text-align: justify;

font-size: 1.6rem;
line-height: 2.5rem;
margin-top: 3rem;
}
.acwp-overview-right-section{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.6rem;
    line-height: 2.5rem;
    margin-top: 3rem;
    flex-wrap: wrap;
    gap:1.5rem;
}
.acwp-right-section-point-section{
    display: flex;
    gap:1rem;
    width: 30%;
    justify-content: center;
   
    align-items: center;
    flex-direction: column;

}

.acwp-right-section-point-section{
    text-align: center;
}
.acwp-right-section-point-section p{
    height: 7rem;
    font-weight: 700;
}
.acwp-cta-btn{
    background-color: #E82931;
    color: white;
    margin: 0px auto;
    padding:1rem 2rem;
    border-radius: 10px;
}








.acwp-certificate-section{
    background-color:#0F0231;
    color: white;
   
    padding:1rem 1rem
}
.acwp-curriculum-head{
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:0rem;
    margin-top: 3rem;
    width: 100%;
 
}
.acwp-curriculum-head-topic-wrapper{
    padding-bottom:4rem;
    width: 33%;
    margin: 0px auto;
   
}
.acwp-curriculum-head-topic-wrapper-active{
    background-color: #060112;
}
.acwp-curriculum-head-topic p{
    width:10rem;
    text-align: center;
}
.acwp-curriculum-head-topic{
    width: 100%;
    border-radius: 10px;
    display: flex;
    gap:1rem;
    flex-direction: column-reverse;
    align-items: center;
    background-color: #060112;
    font-size: 2rem;
    padding: 1.5rem 2rem;
    justify-content: center;
    cursor: pointer;
}
.acwp-curriculum-head-circle{
   
   
    padding: 0.5rem;
    border-radius: 100%;
    padding: 0.5rem;
    border: 1px solid #b54040;
    display: flex;
    align-items: center;
    justify-content: center;
}
.acwp-curriculum-head-circle-inner{
    background-color: #b54040 !important;
    border-radius: 100%;
    width: 2.75rem;
    height: 2.75rem;
}
.acwp-curriculum-head-circle-inner2{
    background-color: #060112 !important;
    border-radius: 100%;
    width: 2.75rem;
    height: 2.75rem;
}

.acwp-curriculum-description{
    width: 100%;
    margin:0px auto;
    background-color: #060112;
    flex-direction: column;
    display: flex;
    align-items: center;
    padding: 3rem 2rem;
    gap: 3rem;

}

.acwp-curriculum-description-left-module{
    width: 100%;
    padding: 0.25rem 0.5rem;
    background-color: white;
    color: #060112;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    margin-bottom: 2rem;
    cursor: pointer;
    border: 3px solid black;
    
}
.acwp-curriculum-description-left-module-active{
    width: 100%;
    padding: 0.25rem 0.5rem;
    background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    margin-bottom: 2rem;
    cursor: pointer;
    border: 3px solid black;
    
}
.acwp-curriculum-description-left-module .module-number{
font-size: 1.3rem;
font-weight: 600;
text-transform: uppercase;
}
.acwp-curriculum-description-left-module .module-name{
    font-size: 2rem; 
}
.acwp-curriculum-description-left-module .module-time{
    font-size: 2rem; 
}



.acwp-curriculum-description-left-module-active .module-number{
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: uppercase;
    }
    .acwp-curriculum-description-left-module-active .module-name{
        font-size: 2rem; 
    }
    .acwp-curriculum-description-left-module-active .module-name{
        font-size: 2rem; 
    }
.acwp-curriculum-description-left-module-active .module-time{
    font-size: 2rem;
    font-weight: 500;
}
.acwp-curriculum-description-right-wrapper{
    width: 100%;
}
.acwp-curriculum-description-right{
    background-color: white;
    color: #060112;
    width: 100%;
    padding: 2rem 3rem;
    border-radius: 8px;
   

}
.acwp-curriculum-description-right-table h3{

background-color: #221d32;
color: white;
padding: 0.75rem 2rem;
}
.acwp-curriculum-description-right-table{
    margin-top: 2rem;
    border: 2px solid #221d32;
    border-radius: 5px;
}
.acwp-curriculum-description-right-table-points{
    padding: 0.75rem 2rem;
    color: #060112;
    margin-left:1rem ;
}
.acwp-curriculum-description-right-table-points li{
    font-size: 1.5rem;
    color: #060112;
    line-height: 3rem;
    list-style-type: circle;
    font-weight: 500;
}
.acwp-download-curriculum-btn{
    background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    color: white;
    margin-top: 1.5rem;
    border-radius: 5px;
   text-align: center;
   width: 25rem;
   font-size: 2rem;
   font-weight: 500;
   padding-top: 0.6rem;
   padding-bottom: 0.6rem;
   cursor: pointer;
   transition: all 0.2s linear;
   margin-bottom: 3rem;
}
.acwp-download-curriculum-btn:hover{
transform: scale(0.95);
}

.acwp-header-desc{
    margin: 1.5rem auto;
    margin-top: 1.5rem;
    text-align: center;
    font-size: 1.5rem;
    width: 100%;
    text-align: justify;
 

}
.acwp-curriculum-section{
    margin-top:8rem;
    display: flex;
    justify-content: center;
    gap:5rem;


}

 
.acwp-module-name-section{
    padding:1rem 2.3rem;
    font-size: 1.6rem;
    background: linear-gradient(171.63deg, #b54040 2%, rgba(80, 17, 17, 0.8) 100%);
    border-radius: 8px;
    margin-bottom: 2.5rem;
   
}
.acwp-module-subdesc{
    color: #bdbdbd;
   
}
.acwp-right-curriculum-section{
    width: 50%;
    border-radius: 10px;
    border:#E82931;
    border-width: 1px;
    border-style: solid;
    padding: 2rem 4rem;
}
.acwp-curriculum-line{
    height: 1px;
    background-color: #ff4d4d;
    width: 100%;
    margin-top: 2rem;
}

.acw-curriculum-certification-tools{
    width: 73%;
    margin: 6rem auto;
    border-radius: 20px;
    position: relative;
   overflow: hidden;
    
    padding: var(--body-top-bottom-padding) var(--body-left-right-padding);
    background-color: #2a194c;
}
.acw-curriculum-certification-tools .left-img{
    position:absolute;
    left: -180px;
    width: 80rem;
    bottom: -195px;
    z-index: 0;
}
.acw-curriculum-certification-tools .right-img{
    position:absolute;
    right: -180px;
    width: 80rem;
    bottom: -195px;
    transform: scaleX(-1);
    z-index: 0;
}
.acw-curriculum-certification-tools-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.acw-curriculum-certification-tools-btn{
    cursor: pointer !important;
    margin: 0px auto;
    color:#2a194c;
    padding-left: 5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 5rem;
    border-radius: 20px;
    font-size: 1.6rem;
    font-weight: 700;
    background-color: white;
    transition: all 0.3s ease-in;
}
.acw-curriculum-certification-tools-btn:hover{
    background-color:#d9c5fd;
}

.acw-curriculum-heading{
    text-align: center;
    font-size: 3.5rem;
    font-weight: 700;
}
.acw-curriculum-sub-heading{
    text-align: center;
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 2.6rem;
}
.acw-curriculum-tools-desc{
    position: relative;
    z-index: 4;
}





.acwp-course-for-section{
    background-color: #221d32;
    position: relative;
 overflow: hidden;
   padding: 4rem 1.5rem;
}

.acwp-course-for-section-wrapper{
    gap:8rem;
    display: flex;
    flex-direction:column ;
    align-items: center;
    justify-content: center;  
}
.acwp-course-for-section-left{
    color: white;
    width: 100%;
}
.acwp-course-for-section-left h1{
    font-size: 30px;
    line-height: 130%;
    font-weight: 800;
    margin-bottom: 2.3rem;
}
.acwp-course-for-section-left .acwp-course-for-section-left-desc{
font-size: 2rem;
text-align: justify;
}
.acwp-enroll-btn{
    color:white;
    text-decoration: none;
    background-color: #683fbe !important; 
}
.acwp-enroll-btn:hover{
    color:white;
    text-decoration: none;
    background-color: #683fbe !important; 
}
.acwp-enroll-btn-inside{
    font-size: 1.8rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: justify;
    width: 100%;
    text-align: center;
    margin-top: 3.5rem;
    background-color: #683fbe;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    }
.acwp-course-for-section-ripple{
    position: absolute;
    top:0;
    left:0;
    bottom: 0;
    height: 100%;
    display: none;
}

  .acwp-course-for-section-right{
    background-color: linear-gradient(254.87deg, #412872 -8.21%, #241b43 104.84%);
    width: 100%;
  }
  .acwp-course-for-section-right-points-pre{
    display: flex;
    align-items: center;
border-radius: 10px;
margin-bottom: 1.75rem;
    gap:1rem;
  
   
    color: white;
  }
  .acwp-course-for-section-right-points-pre img{
    width: 2rem;
    height:2rem;
    
   }
   .acwp-course-for-section-right-points-pre .acwp-course-for-section-right-points-desc{
     margin: 0px !important;
     padding:0px !important;
     font-size: 2rem;
     
   }






  .acwp-course-for-section-right-points{
    display: flex;
    align-items: center;
border-radius: 10px;
margin-bottom: 1.75rem;
    gap:2rem;
    background: linear-gradient(258.73deg, rgba(65, 40, 114, 0) 8.37%, #36235f 91.76%);
    padding: 2.5rem 5.5rem 2.5rem 2.5rem;
    color: white;
  }

  .acwp-course-for-section-right-points img{
   width: 2rem;
   height:2rem;
   
  }
  .acwp-course-for-section-right-points .acwp-course-for-section-right-points-desc{
    margin: 0px !important;
    padding:0px !important;
    font-size: 2rem;
    
  }
  .acwp-curriculum-description-left{
    width: 100%;
 
 }

.topics-covered{
    margin-top: 2rem;
    font-size: 1.5rem;
}  
.acwp-right-curriculum-section{
    height: auto;
    overflow-y: scroll;
}
.hero-header-heading{
    font-size:4rem;
}
.img-container{
    width: 30%;
    display: flex;
    justify-content: center;
    height: 50rem;
    background-repeat: no-repeat;
    background-size: cover;

    
}
.acwp-enroll-btn-inside2 {
    font-size: 1.8rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: justify;
    width: 100%;
    text-align: center;
    margin-top: 2.5rem;
    background-color: #171421;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid #533298;
    
  }






  .acwp-certificate-points-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
}
.acwp-certificate-points{
    flex-shrink: 0;
    display: flex;
   gap:10px;
    align-items: center;
    font-size: 15px;
    width:100%;
   
}
.acwp-certificate-icon-wrapper{
background-color: rgb(82 36 36);
border-radius: 100%;
color:red;
width: 20px;
height: 20px;
display: flex;
align-items: center;
justify-content: center;


}
.acwp-certificate-icon{
    width: 20px;
    height: 20px;
    padding: 0.1rem;
}
.acwp-certificate-points-para{
    margin-bottom: 0rem !important;
}
.acwp-certificate-desc{
    display: relative;
    z-index: 100;
    margin-top: 2rem;
    font-size: 15px;
    margin-bottom: 2rem;
    text-align: justify;
    width: 100%;
}
.acwp-certification-btn{
    background-color: rgb(128, 36, 36);
   text-align: center;
   display:flex;
   align-items: center;
   gap:5px;
   
  padding: 1rem 4rem;
  font-size: 1.5rem;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
  transition:all 0.2s linear;
}
.acwp-certification-btn:hover{
    transform: scale(0.95);
}
.acwp-certification-btn-icon{
    height: 25px;
    width: 25px;
}
.acwp-certification-btn-container{
display:flex;
justify-content:center;
width:100%;
margin-top: 3rem;

}






  @media screen and (min-width:500px){
    .acwp-right-section-point-section p{
        height: 3.5rem;
        font-weight: 700;
    }
    .acwp-certificate-points-wrapper{
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .acwp-certificate-points{
        flex-shrink: 0;
        display: flex;
       gap:10px;
        align-items: center;
        font-size: 15px;
        width:48%;
       
    }



}

@media screen and (min-width:700px){
    .acwp-certificate-desc{
        display: relative;
        z-index: 100;
        margin-top: 2rem;
        font-size: 15px;
        margin-bottom: 2rem;
        text-align: justify;
        width: 95%;
    }
    .acwp-certification-btn-container{
        display:flex;
        justify-content:center;
        width:95%;
        margin-top: 3rem;
        
        }
    .acwp-curriculum-description{
     
        padding: 3rem 8rem;
    
    }
    .acwp-course-for-section-left{
        color: white;
        width: 70%;
    }
    .acwp-course-for-section-right{
        color: white;
        width: 70%;
    }
    .acwp-right-section-point-section p{
        height: 3.75rem;
        font-weight: 700;
    }
}
@media screen and (min-width:700px){
.acwp-curriculum-description{
     
    padding: 3rem 15rem;

}
}
@media screen and (min-width:1000px){
    .acwp-right-section-point-section p{
        height: 4rem;
        font-weight: 700;
    }
    .acwp-course-for-section-wrapper{
        gap:8rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;  
    }
}
@media screen and (min-width:1200px) {
    .acwp-right-section-point-section p{
        height: 7rem;
        font-weight: 700;
    }
    .acwp-curriculum-description-right-wrapper{
        width: 45%;
    }
    .acwp-curriculum-description{
        width: 100%;
        margin:0px auto;
        background-color: #060112;
        flex-direction: row;
        display: flex;
        align-items: start;
        padding: 3rem 6rem;
        gap: 3rem;
    
    }
    .acwp-curriculum-description-left{
        width: 50%;
     
     }
 
    .acwp-curriculum-head-topic{
        width: 100%;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #060112;
        font-size: 2rem;
        padding: 1.5rem 2rem;
        justify-content: space-between;
        cursor: pointer;
    }
    .acwp-curriculum-head{
        margin: 0px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
       
        margin-top: 3rem;
        width: 100%;
     
    }
    
.acwp-header-desc{
    margin: 1.5rem auto;
    margin-top: 1.5rem;
    text-align: center;
    font-size: 1.5rem;
    width: 75%;
    
}
    
.acwp-certificate-section{
    background-color:#0F0231;
    color: white;
   
    padding: var(--body-top-bottom-padding) var(--body-left-right-padding);
}
    .acwp-course-for-section{
        background-color: #221d32;
        position: relative;
     overflow: hidden;
       padding: var(--body-top-bottom-padding) var(--body-left-right-padding);
    }
    
    .acwp-course-for-section-wrapper{
        gap:8rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;  
    }
    .acwp-course-for-section-left{
        color: white;
        width: 50%;
    }
    .acwp-course-for-section-left h1{
        font-size: 40px;
        line-height: 130%;
        font-weight: 800;
        margin-bottom: 2.3rem;
    }
    .acwp-course-for-section-left .acwp-course-for-section-left-desc{
    font-size: 2rem;
    text-align: justify;
    }
 
    .acwp-course-for-section-ripple{
        position: absolute;
        top:0;
        left:0;
        bottom: 0;
        height: 100%;
    }
    .acwp-enroll-btn-inside2 {
        font-size: 1.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        text-align: justify;
        width: 100%;
        text-align: center;
        margin-top: 2.5rem;
        background-color: #171421;
        border-radius: 10px;
        font-weight: 600;
        cursor: pointer;
        border: 1px solid #533298;
        
      }
      .acwp-course-for-section-right{
        background-color: linear-gradient(254.87deg, #412872 -8.21%, #241b43 104.84%);
        width: 50%;
      }
      .acwp-course-for-section-right-points{
        display: flex;
        align-items: center;
    border-radius: 10px;
    margin-bottom: 1.75rem;
        gap:2rem;
        background: linear-gradient(258.73deg, rgba(65, 40, 114, 0) 8.37%, #36235f 91.76%);
        padding: 2.5rem 5.5rem 2.5rem 2.5rem;
        color: white;
      }
      .acwp-course-for-section-right-points img{
       width: 2rem;
       height:2rem;
       
      }
      .acwp-course-for-section-right-points .acwp-course-for-section-right-points-desc{
        margin: 0px !important;
        padding:0px !important;
        font-size: 2rem;
        
      }
    .acwp-overview-section-content{
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content: space-between;
    
    }
    .acwp-overview-section{
        padding:var(--body-top-bottom-padding) var(--body-left-right-padding);
        color: white;
        background-image: linear-gradient(133deg,#100d25,#190b69);
       
    }
    .acwp-overview-left-section{
        width: 55%;
        text-align: justify;
        
        font-size: 1.9rem;
        line-height:3rem;
        margin-top: 3rem;
        }
        .acwp-overview-right-section{
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 1.6rem;
            line-height: 2.5rem;
            margin-top: 3rem;
            flex-wrap: wrap;
        }
}
@media screen and (min-width:1550px) {
    .acwp-right-section-point-section p{
        height: 4.75rem;
        font-weight: 700;
    }














    .acwp-certificate-points-wrapper{
        display: flex;
        width: 100%;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .acwp-certificate-points{
        flex-shrink: 0;
        display: flex;
       gap:10px;
        align-items: center;
        font-size: 15px;
        width:45%;
       
    }
    .acwp-certificate-icon-wrapper{
    background-color: rgb(82 36 36);
    border-radius: 100%;
    color:red;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    
    }
    .acwp-certificate-icon{
        width: 20px;
        height: 20px;
        padding: 0.1rem;
    }
    .acwp-certificate-points-para{
        margin-bottom: 0rem !important;
    }
    .acwp-certificate-desc{
        display: relative;
        z-index: 100;
        margin-top: 2rem;
        font-size: 15px;
        margin-bottom: 2rem;
        text-align: justify;
        width: 85%;
    }
    .acwp-certification-btn{
        background-color: rgb(128, 36, 36);
       text-align: center;
       display:flex;
       align-items: center;
       gap:5px;
       
      padding: 1rem 4rem;
      font-size: 1.5rem;
      font-weight: 700;
      border-radius: 5px;
      cursor: pointer;
      transition:all 0.2s linear;
    }
    .acwp-certification-btn:hover{
        transform: scale(0.95);
    }
    .acwp-certification-btn-icon{
        height: 25px;
        width: 25px;
    }
    .acwp-certification-btn-container{
    display:flex;
    justify-content:center;
    width:80%;
    margin-top: 3rem;
    
    }
    





}

.acwp-call-to-action-fixed{
    position: fixed;
    top:50vh;
    right: 0px;
    background-color: red;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.75rem;
    border-top-left-radius:20px ;
    border-bottom-left-radius:20px ;
    cursor: pointer;
    animation: scaleAnimation 2s infinite ease-in-out;
}
.acwp-call-to-action-fixed-icon{
    height: 3rem;
    width: 3rem;
}


@keyframes scaleAnimation {
    0%, 100% {
      transform: scale(1); /* Original size */
    }
    50% {
      transform: scale(1.1); /* Scaled up size */
    }
  }
.acwp-call-to-action-fixed-modal{
    display: flex;
    align-items: center;
    gap:1rem;
    justify-content:space-between;
    font-size: 1.5rem;
}
.acwp-call-to-action-fixed2{
    position: fixed;
    top:50vh;
    right: 0px;
    width: 28rem;
    background-color: white;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-top-left-radius:20px ;
    border-bottom-left-radius:20px ;
    cursor: pointer;
    font-weight: 700;
    transform: translate(50%);
    animation: slideIn2 0.5s forwards ease-out;
   
}
@keyframes slideIn2 {
    to {
      opacity: 1;
      transform: translate(0%,0%)
    }
  }
.acwp-call-to-action-fixed-icon2{
    height: 3rem;
    width: 3rem;
}
.acwp-call-to-action-fixed-icon2-call{
    display: flex;
    justify-content: space-between;
  
}
.acwp-call-to-action-fixed-closeicon{
    position: absolute;
    right:0px;
    top:-7px;
    
    height: 2.25rem;
    width: 2.25rem;
}
.acwp-call-to-action-fixed-form{
    position: fixed;
    top:65vh;
    right: 0px;
    background-color:#35297D;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.75rem;
    border-top-left-radius:20px ;
    border-bottom-left-radius:20px ;
    cursor: pointer;
    animation: scaleAnimation 2s infinite ease-in-out;
}

.acwp-form-grp-modal{
    display: flex;
    gap:2rem;
}
.summer-training-cont2{
    border-radius: 6px;
    position:fixed;
    width: 50rem;
   padding-bottom: 2rem;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) translateY(-50px);
    animation: slideIn 0.5s forwards ease-out;
    z-index: 200;
  
}
.message-text2{
    text-align: center;
    margin-top: 3rem;
    font-size: 1.5rem;
    color: red;
    
}
@media (max-height: 650px) {
    .summer-training-cont2 {
        max-height: 90vh;
        overflow-y: auto;;
    }

    }

.acwp-call-to-action-fixed-icon2-call-link{
    color: black;
    text-decoration: none;
}
.acwp-call-to-action-fixed-icon2-call-link:hover{
    color: black;
    text-decoration: underline
}














