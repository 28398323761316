@keyframes slide2 {
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(-100%);
    }
    
}
.summer-highlights-industrial{
    padding-left: 2rem;
    padding-right: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
    
        
        padding-top: 1.5rem;
        padding-bottom: 1rem;
        gap: 2rem;
        animation: 30s slide2 infinite linear;
}

.course-access-new{
    
    display: flex;
    margin: 0px auto;
    max-width: 1500px;
    overflow: hidden;
}

.training-access-heading{
    padding-top: 2rem;
    font-size: 2rem;
    font-weight: 700;
}

.training-highlights-section{
    width: 25rem;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:1rem;
    
}
.summer-highlights-icon{
    width: 3rem;
    height:3rem
}
.summer-highlights-icon2{
    width: 4.5rem;
    height:4.5rem
}
.training-highlights-desc{
    height:8rem;
    font-size: 2rem;
    text-align: center;
   
}
@media screen and (max-width:3000px){
    .summer-highlights-industrial{
        padding-left: 2rem;
        padding-right: 2rem;
            display: flex;
            justify-content: space-between;
            flex-shrink: 0;
            align-items: center;
         
            animation: 30s slide2 infinite linear;
            padding-top: 1.5rem;
            padding-bottom: 1rem;
            gap: 2rem;
    }
    .training-highlights-section{
        width: 25rem;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:1rem;
        
    }
    .summer-highlights-icon{
        width: 2.5rem;
        height:2.5rem
    }
    .training-highlights-desc{
        height:8rem;
        font-size: 2rem;
        text-align: center;
       
    }   
}
@media screen and (max-width:1800px){
    .summer-highlights-industrial{
        padding-left: 2rem;
        padding-right: 2rem;
        flex-shrink: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
           
            animation: 30s slide2 infinite linear;
            padding-top: 1.5rem;
            padding-bottom: 1rem;
            gap: 2rem;
    }
    .training-highlights-section{
        width: 25rem;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:1rem;
        
    }
    .summer-highlights-icon{
        width: 2.5rem;
        height:2.5rem
    }
    .training-highlights-desc{
        height:8rem;
        font-size: 2rem;
        text-align: center;
       
    }   
}
@media screen and (max-width:1000px){
    .training-highlights-section{
        width: 25rem;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:1rem;
        
    }
    .summer-highlights-icon{
        width: 2.5rem;
        height:2.5rem
    }
    .training-highlights-desc{
        height:8rem;
        font-size: 2rem;
        text-align: center;
       
    }   
}
@media screen and (max-width:750px){
    .summer-highlights-industrial{
        padding-left: 2rem;
        padding-right: 2rem;
        flex-shrink: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
           
            animation: 30s slide2 infinite linear;
            padding-top: 1.5rem;
            padding-bottom: 1rem;
            gap: 2rem;
    }
    .training-highlights-section{
        width: 25rem;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:1rem;
        
    }
    .summer-highlights-icon{
        width: 2.5rem;
        height:2.5rem
    }
    .training-highlights-desc{
        height:8rem;
        font-size: 2rem;
        text-align: center;
       
    }   
}
@media screen and (max-width:500px){
    .summer-highlights-industrial{
        padding-left: 2rem;
        padding-right: 2rem;
        flex-shrink: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            animation: 30s slide2 infinite linear;
       
            padding-top: 1.5rem;
            padding-bottom: 1rem;
            gap: 1rem;
    }
    .training-highlights-section{
        width: 25rem;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:1rem;
        
    }
    .summer-highlights-icon{
        width: 2.5rem;
        height:2.5rem
    }
    .training-highlights-desc{
        height:8rem;
        font-size: 2rem;
        text-align: center;
       
    }   
}