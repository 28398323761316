
.course-cur-head{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
    font-size: 4rem;
}
.bg-faq{
    margin-top: 15rem;
    width: 70%;
    background-color:#071122;
font-size: 1.7rem;
border-radius: 20px;
}
.faq-container{
    padding-top: 5rem;
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  justify-content: center;
  align-items: center;
    gap: 2rem;
}
.section-faq-name{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 4rem;
    text-align: left;
  
    background-image: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
   
    filter: drop-shadow(0px 0px 7px rgba(255,255,255,0.03));
box-shadow:  -5px 10px 15px 3px 	rgba(12,5,18,.85),-4px 5px 10px 3px rgb(3, 28, 57,1); 
    padding-left: 2rem;
    padding-right:2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    cursor: pointer;
}
.section-faq-container{
    width: 85%;
}
.section-faq-description{
    color: rgb(220, 220, 245);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-right: 4rem;
    padding-left: 2rem;
    padding-right:2rem;
    text-align: justify;
    margin-top: 1rem;
    font-size: 1.4rem;
    background-image: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
   border-radius: 1rem;
    filter: drop-shadow(0px 0px 3px rgba(255,255,255,0.03));
box-shadow:  -5px 5px 5px 3px 	rgba(12,5,18,.85),-4px 5px 7px 3px rgb(3, 28, 57,1); 
}
.download-btn-cont{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem; 
}

.download-btn{
    text-decoration: none;
    color: white;
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 2.5rem;
    padding-top: 1rem;
    background-color: #EE3756;
    padding-bottom:1rem ;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    border-radius: 5px;
    cursor: pointer;
    
}
.download-btn:hover{
    color: white;
    text-decoration: none;
}
.margin-class{
    margin-top: 2rem;
}






/* Existing styles above this line */

@media screen and (max-width: 718px) {
    .course-cur-head {
        margin-top: 8rem;
        font-size: 2.5rem;
    }

    .bg-faq {
        margin-top: 8rem;
        width: 90%; /* Adjust width for smaller screens */
        font-size: 1.5rem;
    }

    .faq-container {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .section-faq-name {
        margin-right: 2rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .section-faq-container {
        width: 100%;
    }

    .section-faq-description {
        margin-right: 2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .download-btn-cont {
        margin-bottom: 3rem;
    }

    .download-btn {
        font-size: 1.8rem;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .margin-class {
        margin-top: 2rem;
    }
}

