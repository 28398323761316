:root{
    --body-left-right-padding:15rem;
    --body-top-bottom-padding:8rem;
    --body-smallscreen-left-right-padding:2rem;
    --body-smallscreen-top-bottom-padding:4rem;

}
.module-number{
    width: 100%;
    background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
}
.program-overview-section-left-blocks p{
    color: white;
}
.main-heading{
    font-size: 3.2rem;
    font-weight: 700;
    color: white;
    text-align: center;
   
}

.program-overview-section-left-blocks{
    border: 1px solid rgba(237, 232, 247, 0.17);
    padding-top:1.5rem ;
   overflow: hidden;
   position: relative;
   
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 2.65rem;
    transition: all 0.75s ease-in;
}
.active-program-block{
    border:1px solid #683fbe !important;
    background-color: #221d32;
  }
.program-overview-section-left-blocks:hover{
    background-color: #221d32;

}
.program-overview-section-left-blocks h3{
    width: 33%;
    padding-left: 2rem;
    padding-right: 2rem;
   color: #ffffff;
    font-size: 2rem;
    margin-bottom: 0.85rem;

}
.program-overview-section-left-blocks p{
    padding-left: 2rem;
    padding-right: 2rem;

}




.program-overview-section-right-card{
    background-color: #221d32;
    width: 100%;
    padding: 4rem 2rem;
    border-radius: 20px;
}
.program-overview-section-right-card h2{
    color: #A48bd9;
    text-align: center;
    font-size: 2.45rem;
    font-weight: 700;
    margin-bottom: 1.75rem;

   

}
.program-overview-section-right-card p{
    color:white;
    text-align: center;
    font-size:1.65rem;
    line-height: 160%;
    text-align: justify;

}
.program-overview-section-right-card ul{
    margin-top: 1.75rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
    flex-wrap: wrap;
}
.program-overview-section-right-card li{
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 0.75rem;
    gap: 1.2rem;
    line-height: 3.25rem !important;
    font-weight: 400 !important;
}
.bottom-line-animation {
    position:absolute;
    bottom: 0;
    width: 100%;
    height: 6px;
    background-color: #683fbe; /* Adjust color as needed */
    animation: bottomLine 5s infinite;
  }
  
  @keyframes bottomLine {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }











@media screen and (min-width:300px) {
    .program-overview-main-section{
        display: flex;
        flex-direction: column;
        margin-top: 6rem;
        align-items: start;
        justify-content: center;
        gap: 5rem;
    }
    .program-overview-left-section{
        color: #ffffff;
        font-size: 1.5rem;
        width: 100%;
        display:flex ;
        flex-direction: row;
        gap:5px;
        
    }
    .program-overview-section-left-blocks{
        width: 33%;
    }
    .program-overview-section-left-blocks p{
        display: none;
    }
    .program-overview-container{
        background-color: #171421 I !important;
        padding: var(--body-smallscreen-top-bottom-padding) var(--body-smallscreen-left-right-padding);
     }
     .program-overview-right-section{
        color: #ffffff;
        font-size: 1.5rem;
        width: 100%;
        cursor: pointer;
    
    }
    .program-overview-section-left-blocks p{
        color: white;
    }
    
}
@media screen and (min-width:450px){

    .program-overview-section-right-card ul{
        margin-top: 1.75rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:center;
        flex-wrap: wrap;
    }
    .program-overview-section-right-card li{
        display: flex;
        width: 50%;
        align-items: center;
        margin-bottom: 0.75rem;
        gap: 1.2rem;
        line-height: 3.25rem !important;
        font-weight: 400 !important;
    }
}
@media screen and (min-width:500px){
    .program-overview-section-left-blocks h3{
        width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
       color: #ffffff;
        font-size: 2rem;
        margin-bottom: 0.85rem;
    
    }
}

@media screen and (min-width:682px){
    .program-overview-main-section{
        display: flex;
        flex-direction: column;
        margin-top: 6rem;
        align-items: center;
        justify-content: center;
        gap: 5rem;
    }
    .program-overview-section-left-blocks p{
        display: block;
        text-align: justify;
    }
    .program-overview-right-section{
        color: #ffffff;
        font-size: 1.5rem;
        width: 80%;
        cursor: pointer;
    
    }
    .program-overview-left-section{
        color: #ffffff;
        font-size: 1.5rem;
        width: 80%;
        display:flex ;
        gap:5px;
        
    }
}









@media screen and (min-width:1200px) {
    .program-overview-section-left-blocks{

    }
    .program-overview-left-section{
        color: #ffffff;
        font-size: 1.5rem;
        width: 35%;
        display:flex ;
        flex-direction: column;
        gap:5px;
        
    }
    .program-overview-section-left-blocks p{
        display: block;
    }
    .program-overview-right-section{
        color: #ffffff;
        font-size: 1.5rem;
        width: 60%;
        cursor: pointer;
    
    }
    .program-overview-container{
        background-color: #171421 I !important;
        padding: var(--body-top-bottom-padding) var(--body-left-right-padding);
     }
    .program-overview-main-section{
        display: flex;
        flex-direction: row;
        margin-top: 6rem;
        align-items: start;
        justify-content: center;
        gap: 5rem;
    }
    
    .main-heading{
        font-size: 3.2rem;
        font-weight: 700;
        color: white;
        text-align: center;
       
    }
    
    .program-overview-section-left-blocks{
        border: 1px solid rgba(237, 232, 247, 0.17);
        padding-top:1.5rem ;
       overflow: hidden;
       width: 100%;
        border-radius: 10px;
        cursor: pointer;
        margin-bottom: 2.65rem;
        transition: all 0.75s ease-in;
    }
    .active-program-block{
        border:1px solid #683fbe !important;
        background-color: #221d32;
      }
    .program-overview-section-left-blocks:hover{
        background-color: #221d32;
    
    }
    .program-overview-section-left-blocks h3{
        padding-left: 2rem;
        padding-right: 2rem;
       color: #ffffff;
        font-size: 2rem;
        margin-bottom: 0.85rem;
    
    }
    .program-overview-section-left-blocks p{
        padding-left: 2rem;
        padding-right: 2rem;
    
    }
    
    
    
    
    .program-overview-section-right-card{
        background-color: #221d32;
        width: 100%;
        padding: 4rem 2rem;
        border-radius: 20px;
    }
    .program-overview-section-right-card h2{
        color: #A48bd9;
        text-align: center;
        font-size: 2.45rem;
        font-weight: 700;
        margin-bottom: 1.75rem;
    
       
    
    }
    .program-overview-section-right-card p{
        color:white;
        text-align: center;
        font-size:1.65rem;
        line-height: 160%;
        text-align: justify;
    
    }
    .program-overview-section-right-card ul{
        margin-top: 1.75rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .program-overview-section-right-card li{
        display: flex;
        width: 50%;
        align-items: center;
        margin-bottom: 0.75rem;
        gap: 1.2rem;
        line-height: 3.25rem !important;
        font-weight: 400 !important;
    }
    .bottom-line-animation {
        
        width: 100%;
        height: 6px;
      
        background-color: #683fbe; /* Adjust color as needed */
        animation: bottomLine 5s infinite;
      }
      
      @keyframes bottomLine {
        from {
          width: 0;
        }
        to {
          width: 100%;
        }
      }
    
}
@media screen and (min-width:1500px) {
    .program-overview-right-section{
        color: #ffffff;
        font-size: 1.5rem;
        width: 50%;
        cursor: pointer;
    
    }
}
