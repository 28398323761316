
@import url('https://fonts.googleapis.com/css2?family=Saira&display=swap');
.authbackground{
    background: url(/src/Assets/register/Mask_Group_2.png);
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
  
    
    background-position: center;
}
.invalid {
    border:2px solid #e32f1c !important;
   
   
   }
   .errormes{
       color:red;
       font-family: 'Saira', sans-serif;
       text-align: center;
     font-size: 1.2rem!important;
     font-style: bold;
     text-transform:none;
    position:absolute;
   }
   .errormes2{
    color:red;
    font-family: 'Saira', sans-serif;
    text-align: center;
  font-size: 1.2rem!important;
  font-style: bold;
  margin-top: -2rem;
  text-transform:none;
 position:absolute;
 margin-bottom: 1rem;
}
.authlogospan{
    color: White;

    font-family: 'Advent Pro', sans-serif;
    font-size: 19pt;
  text-align: center;

margin-left: 1.5%;
    font-weight: 600;
    letter-spacing: 0.2rem;
}

.Authpagespan{
    font-size: 32.5rem;
    top: 5rem;
        position: absolute;
        font-family: 'Advent Pro', sans-serif;
        letter-spacing: 0.8rem;
        color:white;
        font-weight: 600;
        opacity: .1;

}
.Authpagespan1{
    font-size: 21.5rem;
    top: 6rem;

        position: absolute;
        font-family: 'Advent Pro', sans-serif;
        letter-spacing: 0.8rem;
        color:white;
        font-weight: 600;
        opacity: .1;
}
.formdiv{
    background-image:url(/src/Assets/register/Auth\ Form\ Bg.svg);
    height: auto;
    width:100%;
    border-radius: 1rem;
    margin-top:7rem;
    margin-bottom: 7rem;
   
    padding: 60px 35px 35px 35px;
    border-radius: 40px;
    filter: drop-shadow(0px 0px 7px rgba(255,255,255,0.03));
    box-shadow:  -5px 10px 15px 3px 	rgba(12,5,18,.85),-4px 5px 15px 3px rgb(3, 28, 57,1);  
  
}
.authhead{

    color:white;
    font-family: 'Saira', sans-serif;
    letter-spacing:0.1rem;
    font-size: 1.8rem;
}
#authpagelinediv{
    background-repeat: no-repeat;
    background-image: url(/src/Assets/register/Corner\ Waves.svg);
    height:auto;
    width: 100%;
    background-position-x: left;
    background-position-y: bottom;

}
.signuphr{
    height: .2rem;
    width:10rem;
    background-color: #e32f1c;
    align-items: center;
    border: 1rem;
}
.signuphr1{
    height: .2rem;
    width:10rem;
    background-color: #e32f1c;
    align-items: center;
    border: 1rem;
    margin-bottom: 6rem;
}


.input-group label{
    color:white;
    font-family: 'Saira', sans-serif;
    font-size: 1.3rem;
    margin-top: 2rem;
    margin-left: 1rem;
    letter-spacing: .1rem;


}
.acceptagrrehead{
    color:white;
    font-family: 'Saira', sans-serif;
    font-size: 1.2rem;
    margin-top: 1.8rem;
    margin-left: 0.3rem;
    letter-spacing: 0rem;

}
.input-group_field input[type="text"]:-webkit-autofill {    background:#000715!important;}
.input-group_field input[type="text"]:focus{
    background:#000715!important;
    
    box-shadow:inset -2px -2px 4px    #332B82,
  inset 4px 4px 4px   black;
}
.input-group_field input[type="password"]:focus{
    background:#000715!important;
    box-shadow:inset -2px -2px 4px    #332B82,
  inset 4px 4px 4px   black;
}
.input-group_field .icon{
    position: absolute;
float: right;
    padding: 5px;
min-width: 20px;
justify-content: end;

 
}


.input-group_field input[type="text"]{
    display: block;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
		width: 100%;
        
        background-color: #000715;
		font-size: 1.1rem;
		letter-spacing: 0.1em;
        font-family: 'Saira', sans-serif;


		padding: 14px;
        box-shadow:inset -2px -2px 4px    #332B82,
  inset 4px 4px 4px   black;

		outline: none;
		border: none;
		border-radius: 10px;
        color:white;

    }
    
    .input-group_field input[type="password"]{
    display: block;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
		width: 100%;
        background-color: #000715;
		font-size: 1.1rem;
		letter-spacing: 0.1em;
        font-family: 'Saira', sans-serif;


		padding: 14px;
    box-shadow:inset -2px -2px 4px    #332B82,
  inset 4px 4px 4px   black;

		outline: none;
		border: none;
		border-radius: .9rem;
        color:white;

    }

    .authbutn{
        background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    color: #EAECEC;
    font-family: 'Saira', sans-serif;


  height: auto;
  margin-top: 5rem;
  border-radius: 1rem;
  font-weight: 400;
  font-size: 1.4rem;
  padding: 1.3rem 5rem;

    }
    .hr1{
        height: .2rem;
        width:10rem;
        background-color: #e32f1c;
        align-items: center;
        border: 1rem;
    }
    .hr2{
        height: .2rem;
        width:10rem;
        background-color: #e32f1c;
        align-items: center;
        border: 1rem;
    }
    .spanhrtext{
        color:white;
        font-family: 'Saira', sans-serif;
        margin-left:2rem;
        font-size: 1.2rem;
        letter-spacing: .1rem;
        margin-right:2rem;
    }
    .spanhrtext2{
        color:white;
        font-family: 'Saira', sans-serif;
        margin-left:2rem;
        font-weight:500;
      
        text-align: center;
        padding: 1rem;
        font-size: 1.2rem;
        letter-spacing: .1rem;
        margin-right:2rem;
        cursor: pointer;
    }
    .spanhrtext3:hover{
        color: yellowgreen;
    }
    .spanhrtext3{
        color:gray;
        font-family: 'Saira', sans-serif;
     
        font-weight:500;
      
        text-align: center;
        padding: 1rem;
        
        font-size: 1.4rem;
        letter-spacing: .1rem;
     
        cursor: pointer;
    }
    .spanhrtext2:hover{
        color:#e32f1c;
        text-decoration: none;
    }
    .signupbtns{
        background-color:  #3b5998;
        padding: 1.5rem 2rem;
        border-radius: 1rem;
   


    }
    .signupbtns1{
        background-color: white;
        padding: 1.5rem 2rem;
        border-radius: 1rem;

    }
#btnsignup{
    border:none;
    text-decoration: none;
    border-radius: 2rem;
background-color: white;
    margin: 1.7rem;
    margin-bottom: 2rem;
}
@media (max-width: 575px) {


    .Authpagespan{
        font-size: 13.8rem;
        top: 12rem;

    }
    .Authpagespan1{
        font-size: 13.8rem;
        top: 12rem;

    }





  }
  @media (max-width: 1378px) {

    .Authpagespan{
        font-size: 13.8rem;
        top: 12rem;

    }
    .Authpagespan1{
        font-size: 13.8rem;
        top: 12rem;

    }


  @media (max-width: 768px) {
    .Authpagespan{
        font-size: 13.8rem;
        top: 12rem;

    }
    .Authpagespan1{
        font-size: 11.8rem;
        top: 12rem;

    }

}
      @media (max-width:640px) {
        .Authpagespan{
            font-size: 9.8rem;
            top: 12rem;

        }
        .Authpagespan1{
            font-size:4.5rem;
            top: 15rem;

        }
       }
    }

    .currentpasswordeye{
        position: absolute;
        display:flex;
        
        overflow: visible;
      top: 6.4rem;
        right: 7%;
        bottom: 20%;
        color:gray;
        font-size: 1.3rem;
        cursor: pointer;
    }
    .confirmpasswordeye{
        position: absolute;
        display:flex;
        
        overflow: visible;
      top: 6.4rem;
        right: 7%;
        bottom: 20%;
        color:gray;
        font-size: 1.3rem;
        cursor: pointer;
    }
    .newpasswordeye{
        position: absolute;
        display:flex;
        
        overflow: visible;
      top: 6.4rem;
        right: 7%;
        bottom: 20%;
        color:gray;
        font-size: 1.3rem;
        cursor: pointer;

    }
    .eye {
        position: fixed;
        display:flex;
        
        overflow: visible;
        top: 50%;
        right: 16%;
        cursor: pointer;
        bottom: 20%;
        color:gray;
        font-size: 1.3rem;
      }
      .eye2{
        position: fixed;
        display:flex;
        
        overflow: visible;
        top: 49%;
        right: 16%;
        cursor: pointer;
        bottom: 20%;
        color:gray;
        font-size: 1.3rem;   
      }
      .eye3{
        position: fixed;
        display:flex;
        
        overflow: visible;
        top: 61.5%;
        right: 16%;
        cursor: pointer;
        bottom: 20%;
        color:gray;
        font-size: 1.3rem;   
      }
      .eye:hover {
        color: lightgray;
        cursor: pointer;
      }
      #registerdiv{
        background: rgba( 249, 245, 245, 0.05 );
        border:.5px solid red;
        border-radius: 2rem;
        backdrop-filter: blur( 20.0px );
        -webkit-backdrop-filter: blur( 20.0px );
        min-height: auto;
      }

      #registrationdivwaves{
        background-repeat: no-repeat;
        background-image: url(/src/Assets/Landing\ page/Waves.svg);
        height:auto;
        width: 100%;
        background-position-x: left;
        background-position-y: bottom;
      }