.webinar-container{
    color: white;
    font-family: 'Saira', sans-serif;
}

.webinar-hero{
    display: flex;
    justify-content: center;
    align-items: center;
  gap: 5rem;
    background-color: #000323;
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  
   
   
}
.webinar-link2{
    text-decoration: none;
}
.webinar-link2:hover{
    text-decoration: none;
}
.webinar-hero-left{
  width: 40%;
  display: flex;
  flex-direction: column;
 

}

.webinar-hero-left-heading{
    font-size: 2.5rem;
    font-weight: 400;
}
@keyframes bg-animation {
    0%{
        background-position: left;
    }
    100%{
        background-position: right;
    }

    
}
.webinar-hero-left-subheading{
    font-size: 8rem;
    width: 70%;
    font-weight: 700;
   
   
    color: white;
    
   
  

}
.play-container{
    background-image: radial-gradient(circle, #e32f12, #e9282b, #ed233f, #ee2451, #ee2963);
    
}
.play-icon{
    padding: 2rem;
    color: white;
    height: 2.5rem;
    width: 2.5rem;
}
.text-red{
    color: #e32f12;
    font-weight: 500;
}
.calendar-icon{
    height: 2rem;
    width: 2rem;
    color: #e32f1c;
}
.calendar-flex{
    display: flex;
   align-items: center;
   gap: 10px;
   font-size: 1.8rem;
   margin-top: 2rem;
   margin-bottom: 1rem;
   background-color: #ad2643;
   width: 65%;
   padding-top: 1rem;
   padding-bottom: 1rem;
   border-radius: 25px;
   padding-left: 2rem;
   text-align: center;
}
.calendar-flex3{
    display: flex;
    
   align-items: center;
   justify-content: center;
   gap: 10px;
   font-size: 2rem;
   margin-top: 2rem;
   margin-bottom: 1rem;
   background-color: #ad2643;
   width: 65%;
   padding-top: 2rem;
   padding-bottom: 2rem;
   border-radius: 10px;
   padding-left: 2rem;
   text-align: center;
}
.web-register-btn{
    padding-left: 3rem;
    padding-right: 3rem;
    background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    display:inline;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 4rem;
    font-size: 1.5rem;
    border-radius: 5px;

}
.w-input-field{
background-color: #000715;
color: white;
width: 100%;
font-size: 1.9rem;
margin-bottom: 1.4rem;
padding-left: 0.3rem;
padding-right: 1.2rem;
padding-bottom: 0.6rem;
padding-top: 0.6rem;
border-radius: 5px;
border-color: #000715;
box-shadow: inset -2px -2px 4px #332B82, inset 4px 4px 4px black;
outline: none;
}
.webinar-hero-right{
    width: 30%;
   
}
.w-input-field-cont{
    display: flex;
    gap: 4%;
}
.w-select-field{
    outline: none;
    border: none;
    border-color: #000715;
    box-shadow: inset -2px -2px 4px #332B82, inset 4px 4px 4px black;
    appearance: none;
}
.w-select-field:hover{
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: none;
    border-color: #000715;
    box-shadow: inset -2px -2px 4px #332B82, inset 4px 4px 4px black;
    appearance: none;
}
.w-select-field:focus{
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: none;
    border-color: #000715;
    box-shadow: inset -2px -2px 4px #332B82, inset 4px 4px 4px black;
    appearance: none;
}
.w-select-field{
    border-color: #000715; /* Change to desired background color */
    color: white; /* Change to desired text color */
}
.web-register-btn2{
    padding-left: 3rem;
    padding-right: 3rem;
    background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    display:inline;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 1.2rem;
    font-size: 1.5rem;
    border-radius: 5px;
    color: white;

}
.form-heading{
    font-size: 3.5rem;
    font-weight: 900;
    margin-bottom: 1.5rem;
}
.text-red{
    font-size: 3.2rem;
    font-weight: 900;
    color: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
}
.webinar-form-terms{
  
    font-size: 1.1rem;

}
.webinar-courses-card-section{
padding-top: 15rem;
}
.webinar-courses-card-section2{
    padding-top: 8rem;
    background-color:#0F0231;
    }
.webinar-section-heading{
    text-align: center;
    font-size:  3.5rem;
    font-weight: 500;
}
.red-line-class{
    display: flex;
    justify-content: center;
}
.webinar-card-container{
    padding-bottom: 5rem;
display: flex;
align-items: center;
justify-content: center;
gap: 5rem;
padding-left: 2rem;
padding-right: 2rem;
}
.about-us-container-wrapper{
    display: flex;
    justify-content: center;
    gap: 7rem;
    padding-bottom: 4rem;

}
.about-us-container{
    width:40%;
    font-size: 1.9rem;
    text-align: justify;
    align-items: start;
}
.webinar-img{
    width: 40%;
    height: 47rem;
}
.margintop-4{
    margin-top: 1.5rem;
}
.webinar-key{
    padding-right: 8rem;
    padding-left: 8rem;
    display: flex;
    gap: 5rem;
    flex-wrap: wrap;
   justify-content: center;
   align-items: center;
}
.webinar-key-list{
    width: 30%;
    background-color: #1a2332;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    font-size: 1.7rem;
    border-radius: 10px;
}
.text-red2{
    color: #e32f12;
    font-weight: 500;
    
}
.webinar-error-component{
    color: #ec2542;
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 1rem;
    margin-top: -1rem;
}
.webinar-success-component{
    color: green;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
    margin-top: -1rem;
    text-align: center;
}
.webinar-state{
    width: 48%;
}
.webinar-img-course{
    width:100% !important;
    height: 20rem !important;
}
@media screen and (max-width: 1150px){
    .webinar-card-container{
        padding-bottom: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    }
    .webinar-key-list{
        width: 47%;
        background-color: #1a2332;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 3rem;
        padding-bottom: 3rem;
        font-size: 1.7rem;
        border-radius: 10px;
    }

}
@media screen and (max-width: 1020px){
    .webinar-hero{
        display: flex;
        flex-direction: column;
        align-items: center;
      gap: 5rem;
        background-color: #000323;
        padding-top: 4.5rem;
        padding-bottom: 4.5rem;   
    }
    .webinar-hero-left{
        width: 80%;
        display: flex;
        flex-direction: column;
       
      
      }
      .webinar-hero-left-subheading{
        font-size: 5rem;
        width: 90%;
        font-weight: 700; 
        color: white;
    }
    .webinar-hero-right{
        width: 80%;
       
    }
    .webinar-card-container{
        padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 7rem;
    }
    .webinar-key-list{
        width: 70%;
        background-color: #1a2332;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 3rem;
        padding-bottom: 3rem;
        font-size: 1.7rem;
        border-radius: 10px;
    }

}
@media screen and (max-width: 660px){
    .webinar-key-list{
        width: 85%;
        background-color: #1a2332;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 3rem;
        padding-bottom: 3rem;
        font-size: 1.7rem;
        border-radius: 10px;
    }
}



