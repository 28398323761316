@import url("https://fonts.googleapis.com/css2?family=Saira&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Saira:wght@500&display=swap");

.navnav {
    background: rgba(249, 245, 245, 0.05);
    backdrop-filter: blur(10.0px);
    -webkit-backdrop-filter: blur(10.0px);
    color: white;
    cursor: pointer;
    position:relative;
    z-index: 30 !important;
}

.nav-link-menu-container {
    position: relative;
    z-index: 30 !important;
 
}

.navlink-nav-menu {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    position: absolute;
    top: 81px;
    z-index: 30 !important;
    background-color: #0D111D
}

.homenavlink {
    background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    color: white;
}

.navbar .navbar-nav .nav-link {
    color: lightgray;
    font-size: 1.3rem;
    text-transform: uppercase;
    font-family: "Saira", sans-serif;
    font-weight: 500;
    padding: 2.3rem;
    padding-left: 6rem;
    padding-right: 6rem;
    padding-top: 3rem;
    cursor: pointer;
}

.dropdown-menu {
    background: rgba(249, 245, 245, 0.05);
    backdrop-filter: blur(10.0px);
    -webkit-backdrop-filter: blur(10.0px);
    width: 100%;
    cursor: pointer;
    border-radius: 1rem;
    margin-top: 2px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.dropdown-menu::before {
    position: absolute;
    top: -9px;
    left: 45%;
    display: inline-block;
    border-right: 9px solid transparent;
    border-bottom: 9px solid rgba(249, 245, 245, 0.05);
    border-left: 9px solid transparent;
    content: '';
}

.dropdown-menu .dropdown-item {
    color: white !important;
    font-size: 18px;
    font-family: "Saira", sans-serif;
    font-weight: 500;
    text-align: center;
    padding: 0.5rem;
}

.dropdown-menu .dropdown-item:hover {
    background-image: radial-gradient(circle, #e32f12, #e9282b, #ed233f, #ee2451, #ee2963);
    color: white;
    width: 100%;
}

.navbar .navbar-nav .nav-link2 {
    color: #000000;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-family: "Saira", sans-serif;
    font-weight: 600;
    padding-top: 2rem;
}

.navbar .navbar-nav .nav-link:hover {
    border-bottom: 2px solid #ee2553;
    color: white;
}

.d-none img {
    height: 8rem;
    padding-left: 6rem;
    padding-right: 6rem;
    padding-top: 1rem;
}

.d-none img:hover {
    transform: scale(1.2);
    transition: all 1s ease-in-out;
}

.navbar-toggler > .close {
    display: inline;
}

.navbar-toggler > .close img {
    z-index: 54545;
    height: 4rem;
}

.navbar-toggler > .close img:hover {
    z-index: 989;
}

.navbar-toggler.collapsed > .close,
.navbar-toggler:not(.collapsed) > .navbar-toggler-icon {
    display: none;
}

.navbar-toggler.collapsed > .close,
.navbar-toggler:not(.collapsed) > .navbar-toggler-icon .Dataprivacy_span {
    position: relative;
}

/* Navbar2 styles */
.navbar2-container {
    font-family: 'Saira', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(249, 245, 245, 0.05);
    backdrop-filter: blur(10.0px);
    -webkit-backdrop-filter: blur(10.0px);
    color: white;
    cursor: pointer;
}

.navbar2-link {
    color: white;
    font-size: 13px;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.navbar2-container{
  font-family: 'Saira', sans-serif;
display: flex;
justify-content: center;
align-items: center;
background: rgba( 249, 245, 245, 0.05 );
    
backdrop-filter: blur( 10.0px );
-webkit-backdrop-filter: blur( 10.0px );
color: white;

cursor: pointer;

color: white;
}
.navbar2-link{
  color: white;
  font-size: 13px;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 30px;
  padding-bottom: 30px;
}


.navbar2-img {
    /* Add necessary styles if needed */
}

/* Responsive adjustments for mobile view */
@media (max-width: 991px) {
    /* Adjust padding for better mobile layout */
    .navbar .navbar-nav .nav-link {
        padding: 1rem 2rem;
        font-size: 1.1rem;
        cursor: pointer;
    }

    /* Adjust the dropdown menu positioning for mobile */
    .navlink-nav-menu {
        position: relative; /* Remove absolute positioning */
        top: auto; /* Reset the top for mobile view */
        z-index: 30 !important; /* Lower z-index so it doesn't overlap the next navbar */
        width: 100%; /* Full width for better UX */
        margin-top: 0.5rem; /* Space between dropdown and 'Training' */
    }

    .navlink-nav-menu .dropdown-item {
        padding: 0.8rem 1rem;
        text-align: center;
    }
}
