.summer-training-main-container{

    color:white;
    background-color:#0b121f;
}
.hero-section-wrapper{
    background: radial-gradient(926px at 2.7% 11%, rgb(17, 29, 94) 0%, rgb(178, 31, 102) 90%);
}
.meeting-button{
    margin-bottom: 2rem;
    padding-top: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 1rem;
    background-image: radial-gradient(circle, #e32f12, #e9282b, #ed233f, #ee2451, #ee2963);
    text-decoration: none;
    color:white;
    font-size: 18px;
    border-radius: 10px;
    cursor: pointer;
}
.meeting-button{
    margin-left: auto;
    margin-right: auto;
}
.meeting-button:hover{
    scale: 1.2;
    text-decoration: none;
    color:white;
}

.text-color-white{
    color: white;
}
.training-highlight-section-left-new{
width: 60%;
}
.certi-image{
height:36rem
}
.training-cont3{
    background-color:rgb(15, 2, 49); 
}
.training-hero-section{
    margin: 0px auto;
    max-width: 2000px;
    padding-right: 8rem;
    padding-left: 8rem;
display: flex;
align-items: center;
padding-top: 3rem;
padding-bottom: 3rem;
}
.hero-section-left{
width: 50%;
}
.webinar-hero-left-heading{
    font-size: 1.75rem;
    background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 1.7rem;
    width: fit-content;
    border-radius: 10px;
    font-weight: 600;
    margin-left: 12px;
    
}
.hero-heading{
    font-size: 30px;
    width: 80%;
    font-weight: 800;
}
.hero-subheading{
    font-size: 20px;
    width: 80%;
    font-weight: 500;  
    color: white;
}
.check-icon-cont{
    background-color: #ec2542;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    
}
.feat-checkbox{
    display: flex;
    align-items: center;
    gap:6px;
    font-size: 12px;
}
.hero-section-right{
    width: 45%;
    border-radius: 20px;
    padding-right: 4rem;
    padding-left: 4rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  background:transparent;  
  background-color:rgb(15, 2, 49); 
  backdrop-filter: blur(50px);
  
}
.w-input-field2{
    
   background-color: #031841;
   color: white;
    width: 100%;
    font-size: 1.5rem;
    margin-bottom: 1.4rem;
    padding-left: 0.3rem;
    padding-right: 1.2rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    border-radius: 5px;
   
    outline: none;
    }

.training-cont{
    background: linear-gradient(76.8deg, rgb(121, 45, 129) 3.6%, rgb(36, 31, 98) 90.4%);
}

.training-highlight-section{
    padding-right: 8rem;
    padding-left: 8rem;
   
    background: rgb(15, 2, 49);
    color:white;
 padding-top: 12rem;
 display: flex;
 justify-content: space-between;
 padding-bottom: 8rem;
 gap:5rem;

}
.training-highlight-section4{
   
   
   
    color:white;
 padding-top: 5rem;
 display: flex;
 justify-content: center;
 padding-bottom: 5rem;
 background: linear-gradient(76.8deg, rgb(121, 45, 129) 3.6%, rgb(36, 31, 98) 90.4%);

}
.training-highlight-sectionin{
   
   
   
    color:white;
 padding-top: 5rem;
 display: flex;
 justify-content: center;
 padding-bottom: 5rem;
 background:rgb(15, 2, 49);

}
.training-highlight-section2{
    padding-right: 8rem;
    padding-left: 8rem;
    
    background: rgb(15, 2, 49);
    color:white;
 padding-top: 12rem;
 display: flex;
 justify-content: space-between;
 align-items: start !important;
 padding-bottom: 8rem;
 gap: 5rem;
 
}
.acw-certificate-container-wrapper{
    padding-right: 8rem;
    padding-left: 8rem;
    position: relative;
    color:white;
 padding-top: 12rem;
 display: flex;
 justify-content: space-between;
 padding-bottom: 8rem;
 gap:4rem;
}
.training-highlight-section3{
    padding-right: 8rem;
    padding-left: 8rem;
    position: relative;
    color:white;
 padding-top: 12rem;
 display: flex;
 justify-content: space-between;
 padding-bottom: 8rem;
 gap:4rem;
 
}


.training-headings{
    text-align: center;
    font-size:35px;
    font-weight: 700;
}
.training-heading{
    font-size: 40px;
 
}
.training-heading5{
    font-size: 30px;
    padding-top: 2rem;
    text-align: center;
    font-weight: 700;
 
}
.training-heading2{
    font-size: 30px;
}
.acw-right-section-container-cert{
    width: 50%;
}
.training-highlight-section-left{
    width: 50%;
}
.training-highlight-section-left2{
    width: 50%;
}
.training-desc{
    display: relative;
    z-index: 100;
    margin-top: 3rem;
    font-size: 18px;
    text-align: justify;
    
}
.training-desc4{
    display: relative;
    z-index: 100;
    margin-top: 3rem;
    font-size: 15px;
    margin-bottom: 3rem;
    text-align: justify;
}

.display-flex{
    display: flex;
    gap:6px;
    margin-bottom: 5px;
  

}
.padding-top-2{
    padding-top: 8rem;
}
.training-list{
    font-size: 15px;
}

.training-syl{
    background-color: rgb(15, 2, 49);
    padding-left: 8rem;
    padding-right: 8rem;
}
.training-syl-padding{
   padding-bottom: 6rem;
}
.train-job{
    margin-top: 2rem;
}
.train-job-title{
    font-size: 1.5rem;
    font-weight: 500;
}
.margin-bottom{
    margin-bottom: 5rem;
}
.margin-bottom-2{
    margin-bottom: 2rem;
}
.training-desc-week:hover{
 font-weight: 600;   
}
.acwp-certificate-right-section-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.training-highlight-section-right{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.training-highlight-section-right4{
    width:50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.hero-section-price{
    font-size: 20px;
    font-weight: 800;
    margin-top: 1.5rem;
    display: flex;
    gap:12px
}
.hero-section-price strike{
    color:#a4b1cd;
    font-weight: 600;
  
}
.training-desc-week{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-top: 2rem;
    border-radius: 5px;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
   padding-top: 1rem;
    background-color: #e82930;
  width: 80%;
  font-size: 1.6rem;
}
.training-desc-week-click{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 800;
    cursor: pointer;
    margin-top: 2rem;
    border-radius: 5px;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
   padding-top: 1rem;
   background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  width: 80%;
  font-size: 1.6rem;
}
.training-list span{
    color:#e82930;
}




@media screen and (max-width:3000px) {
    .bg-image-left{
        position: absolute;
        top: 0;
        left: 0;
        width: 24rem;
        height: auto;
    }
    .bg-image-right{
        position: absolute;
        z-index: 0;
        bottom: 0;
        right: 0;
        width: 24rem;
        height: auto;
    }
    .training-highlight-section4{
        padding-right: 15%;
        padding-left: 15%;
       
       
        color:white;
     padding-top: 5rem;
     display: flex;
     justify-content: center;
     padding-bottom: 5rem;
     background: linear-gradient(76.8deg, rgb(121, 45, 129) 3.6%, rgb(36, 31, 98) 90.4%);
    
    }
    .training-highlight-sectionin{
   
   
   
        padding-right: 15%;
        padding-left: 15%;
       
       
        color:white;
     padding-top: 5rem;
     display: flex;
     justify-content: center;
     padding-bottom: 5rem;
     background:rgb(15, 2, 49);
    
    }
    .webinar-hero-left-heading{
        font-size: 1.75rem;
        
    }
    .hero-heading{
        font-size: 4rem;
    }
    .hero-subheading{
        font-size:3rem
    }
    .summer-training-feat p{
        font-size:2rem
    }
    .herosection-book{
        width: 2rem;
        height: 2rem;
    }
    .herosection-place{
        width: 2rem;
        height: 2rem;
    }
    .herosection-calicon{
        width: 2rem;
        height: 2rem;
    }

    .training-hero-section{
        margin: 0px auto;
        max-width: 2000px;
     padding-left: 15%;
     padding-right: 15%;
    }
     
    .hero-section-left{
        width: 60%;
        }
    .hero-section-right{
     width:45rem
    }
    .training-highlight-section2{
        padding-left: 15%;
        padding-right: 15%;
        
        background: rgb(15, 2, 49);
        color:white;
     padding-top: 12rem;
     display: flex;
     align-items: start !important;
     justify-content: space-between;
     padding-bottom: 8rem;
     gap: 5rem;
     
    }
    .acw-right-section-container-cert{
        width: 60%;
    }
    .training-highlight-section-left{
        width: 60%;
    }
    .acwp-certificate-right-section-container{
        width:60rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .training-highlight-section-right{
        width:60rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .training-highlight-section{
        padding-right: 15%;
        padding-left: 15%;
       
        background: rgb(15, 2, 49);
        color:white;
     padding-top: 12rem;
     display: flex;
     justify-content: space-between;
     padding-bottom: 8rem;
     gap:5rem;
    
    }
    .training-highlight-section4{
        padding-right: 15%;
        padding-left: 15%;
       
       
        color:white;
     padding-top: 5rem;
     display: flex;
     justify-content: center;
     padding-bottom: 5rem;
    
    }
    .training-highlight-sectionin{
   
   
   
        color:white;
     padding-top: 5rem;
     display: flex;
     justify-content: center;
     padding-bottom: 5rem;
     background:rgb(15, 2, 49);
    
    }
    .acw-certificate-container-wrapper{
        padding-right: 15%;
        padding-left: 15%;
        
       
        color:white;
     padding-top: 12rem;
     display: flex;
     justify-content: space-between;
     padding-bottom: 8rem;
     gap:4rem;
    }
    .training-highlight-section3{
        padding-right: 15%;
        padding-left: 15%;
        
       
        color:white;
     padding-top: 12rem;
     display: flex;
     justify-content: space-between;
     padding-bottom: 8rem;
     gap:4rem;
     
    }
    .training-cont{
        background: linear-gradient(76.8deg, rgb(121, 45, 129) 3.6%, rgb(36, 31, 98) 90.4%);
    }
     
 }




@media screen and (max-width:1800px) {
    .bg-image-left{
        position: absolute;
        top: 0;
        left: 0;
        width: 24rem;
        height: auto;
    }
    .bg-image-right{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 24rem;
        height: auto;
    }
    
    .training-highlight-section-left-new{
        width: 40%;
        }
        .training-highlight-section4{
        padding-right: 15rem;
        padding-left: 15rem;
       
       
        color:white;
     padding-top: 5rem;
     display: flex;
     justify-content: center;
     padding-bottom: 5rem;
     background: linear-gradient(76.8deg, rgb(121, 45, 129) 3.6%, rgb(36, 31, 98) 90.4%);
    
    }
    .training-highlight-sectionin{
        padding-right: 15rem;
        padding-left: 15rem;
       
       
        color:white;
     padding-top: 5rem;
     display: flex;
     justify-content: center;
     padding-bottom: 5rem;
     background: rgb(15, 2, 49);
    
    }

    .acw-certificate-container-wrapper{
        padding-right: 15rem;
        padding-left: 15rem;
        
        
        color:white;
     padding-top: 12rem;
     display: flex;
     justify-content: space-between;
     padding-bottom: 8rem;
     gap:4rem;
    }


    .training-highlight-section3{
        padding-right: 15rem;
        padding-left: 15rem;
        
        
        color:white;
     padding-top: 12rem;
     display: flex;
     justify-content: space-between;
     padding-bottom: 8rem;
     gap:4rem;
     
    }
    .training-hero-section{
        margin: 0px auto;
        max-width: 2000px;
     padding-left: 15rem;
     padding-right: 15rem;
    } 
    .hero-section-left{
        width: 60%;
        }
    .hero-section-right{
     width:40%
    }
    .training-highlight-section{
        padding-left: 15rem;
        padding-right: 15rem;
        
        background: rgb(15, 2, 49);
        color:white;
     padding-top: 12rem;
     display: flex;
     justify-content: space-between;
     padding-bottom: 8rem;
     gap: 5rem;
     
    }
    .training-highlight-section2{
        padding-left: 15rem;
        padding-right: 15rem;
        
        background: rgb(15, 2, 49);
        color:white;
     padding-top: 12rem;
     display: flex;
     align-items: start !important;
     justify-content: space-between;
     padding-bottom: 8rem;
     gap: 5rem;
     
    }
    .acw-right-section-container-cert{
        width: 80%;
    }
    .training-highlight-section-left{
        width: 80%;
    }
    .acwp-certificate-right-section-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .training-highlight-section-right{
       
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .training-highlight-section{
        padding-right: 15rem;
        padding-left: 15rem;
       
        background: rgb(15, 2, 49);
        color:white;
     padding-top: 12rem;
     display: flex;
     justify-content: space-between;
     padding-bottom: 8rem;
     gap:5rem;
    
    }
    .training-highlight-section4{
        padding-right: 15rem;
        padding-left: 15rem;
       
       
        color:white;
     padding-top: 5rem;
     display: flex;
     justify-content: center;
     padding-bottom: 5rem;
    
    }
    .training-highlight-sectionin{
        padding-right: 15rem;
        padding-left: 15rem;
       
       
        color:white;
     padding-top: 5rem;
     display: flex;
     justify-content: center;
     padding-bottom: 5rem;
     background-color: rgb(15, 2, 49);;
    
    }
    
    .training-cont{
        background: linear-gradient(76.8deg, rgb(121, 45, 129) 3.6%, rgb(36, 31, 98) 90.4%);
    }
 }
















 @media screen and (max-width:1500px){
    .bg-image-left{
        position: absolute;
        top: 0;
        left: 0;
        width: 24rem;
        height: auto;
    }
    .bg-image-right{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 24rem;
        height: auto;
    }
    .training-highlight-section-left-new{
        width: 70%;
        }
    .summer-training-main-container{

        color:white;
        background-color:#0b121f;
    }
    .certi-image{
    height:36rem
    }
    .training-hero-section{
        margin: 0px auto;
        max-width: 2000px;
        
        padding-right: 8rem;
        padding-left: 8rem;
    display: flex;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    }
    .hero-section-left{
    width: 50%;
    }
    .webinar-hero-left-heading{
        font-size: 1.75rem;
        background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
        padding-right: 2rem;
        padding-left: 2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-bottom: 1.7rem;
        width: fit-content;
        border-radius: 10px;
        font-weight: 600;
        margin-left: 12px;
        
    }
    .hero-heading{
        font-size: 35px;
        width: 80%;
        font-weight: 800;
    }
    .hero-subheading{
        font-size: 20px;
        width: 80%;
        font-weight: 500;  
        color:white;
    }
    .check-icon-cont{
        background-color: #ec2542;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 5rem;
        
    }
    .feat-checkbox{
        display: flex;
        align-items: center;
        gap:6px;
        font-size: 12px;
    }
    .hero-section-right{
        width: 45%;
        border-radius: 20px;
        padding-right: 4rem;
        padding-left: 4rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
      background:transparent;  
      background-color:rgb(15, 2, 49); 
      backdrop-filter: blur(50px);
      
    }
    .w-input-field2{
        
       
        width: 100%;
        font-size: 1.5rem;
        margin-bottom: 1.4rem;
        padding-left: 0.3rem;
        padding-right: 1.2rem;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        border-radius: 5px;
       
        outline: none;
        }
    
    .training-cont{
        background: linear-gradient(76.8deg, rgb(121, 45, 129) 3.6%, rgb(36, 31, 98) 90.4%);
    }
    .training-highlight-section{
        padding-right: 8rem;
        padding-left: 8rem;
       
        background: rgb(15, 2, 49);
        color:white;
     padding-top: 12rem;
     display: flex;
     justify-content: space-between;
     padding-bottom: 8rem;
     gap:5rem;
    
    }
    .training-highlight-section4{
       
       
       
        color:white;
     padding-top: 5rem;
     display: flex;
     justify-content: center;
     padding-bottom: 5rem;
    
    }
    .training-highlight-sectionin{
       
       
       
        color:white;
     padding-top: 5rem;
     display: flex;
     justify-content: center;
     padding-bottom: 5rem;
    
    }
    .training-highlight-section2{
        padding-right: 8rem;
        padding-left: 8rem;
        
        background: rgb(15, 2, 49);
        color:white;
     padding-top: 12rem;
     display: flex;
     align-items: start  !important;
     justify-content: space-between;
     padding-bottom: 8rem;
     gap: 5rem;
     
    }

    .acw-certificate-container-wrapper{
        padding-right: 8rem;
        padding-left: 8rem;
        
       
        color:white;
     padding-top: 12rem;
     display: flex;
     justify-content: space-between;
     padding-bottom: 8rem;
     gap:4rem;
    }
    .training-highlight-section3{
        padding-right: 8rem;
        padding-left: 8rem;
        
       
        color:white;
     padding-top: 12rem;
     display: flex;
     justify-content: space-between;
     padding-bottom: 8rem;
     gap:4rem;
     
    }
    .training-headings{
        text-align: center;
        font-size:35px;
        font-weight: 700;
    }
    .training-heading{
        font-size: 40px;
     
    }
    .training-heading5{
        font-size: 30px;
        padding-top: 2rem;
        text-align: center;
     
    }
    .training-heading2{
        font-size: 30px;
    }
    .acw-right-section-container-cert{
        width: 50%;
    }
    .training-highlight-section-left{
        width: 50%;
    }
    .training-highlight-section-left2{
        width: 50%;
    }
    .training-desc{
        margin-top: 3rem;
        font-size: 18px;
        text-align: justify;
        
    }
    
    .display-flex{
        display: flex;
        gap:6px;
        margin-bottom: 5px;
      
    
    }
    .padding-top-2{
        padding-top: 8rem;
    }
    .training-list{
        font-size: 15px;
    }
    
    .training-syl{
        background-color: rgb(15, 2, 49);
        padding-left: 8rem;
        padding-right: 8rem;
    }
    .training-syl-padding{
       padding-bottom: 6rem;
    }
    .train-job{
        margin-top: 2rem;
    }
    .train-job-title{
        font-size: 1.5rem;
        font-weight: 500;
    }
    .margin-bottom{
        margin-bottom: 5rem;
    }
    .margin-bottom-2{
        margin-bottom: 2rem;
    }
    .training-desc-week:hover{
     font-weight: 800;   
    }
    .acwp-certificate-right-section-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .training-highlight-section-right{
        
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .training-highlight-section-right4{
        width:50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .hero-section-price{
        font-size: 20px;
        font-weight: 800;
        margin-top: 1.5rem;
        display: flex;
        gap:12px
    }
    .hero-section-price strike{
        color:#a4b1cd;
        font-weight: 600;
      
    }
    .training-desc-week{
        cursor: pointer;
        margin-top: 2rem;
        border-radius: 5px;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 1rem;
       padding-top: 1rem;
        background-color: #e82930;
      width: 80%;
      font-size: 1.6rem;
    }
    .training-desc-week-click{
        font-weight: 800;
        cursor: pointer;
        margin-top: 2rem;
        border-radius: 5px;
        padding-left: 3rem;
        padding-right: 3rem;
        padding-bottom: 1rem;
       padding-top: 1rem;
       background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
      width: 80%;
      font-size: 1.6rem;
    }
    .training-list span{
        color:#e82930;
    }
 }

@media screen and (max-width: 1240px){
    .bg-image-left{
        position: absolute;
        top: 0;
        left: 0;
        width: 24rem;
        height: auto;
    }
    .bg-image-right{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 24rem;
        height: auto;
    }
    .training-highlight-section-left-new{
        width: 70%;
        }
    .training-cont{
        background: linear-gradient(76.8deg, rgb(121, 45, 129) 3.6%, rgb(36, 31, 98) 90.4%);
    }
    .hero-section-right{
        width: 45%;
        border-radius: 20px;
        padding-right: 4rem;
        padding-left: 4rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
      background:transparent;  
      background-color:rgb(15, 2, 49); 
      backdrop-filter: blur(50px);
      
    }
    .w-input-field2{
    
   
        width: 100%;
        font-size: 1.8rem;
        margin-bottom: 1.9rem;
        padding-left: 0.3rem;
        padding-right: 1.2rem;
        padding-bottom: 0.8rem;
        padding-top: 0.8rem;
        border-radius: 5px;
       
        outline: none;
        }
     
        .training-cont{
            background: linear-gradient(76.8deg, rgb(121, 45, 129) 3.6%, rgb(36, 31, 98) 90.4%);
        }
}
@media screen and (max-width: 1050px){
    .bg-image-left{
        position: absolute;
        top: 0;
        left: 0;
        width: 24rem;
        height: auto;
    }
    .bg-image-right{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 24rem;
        height: auto;
    }
    .training-highlight-section-left-new{
        width: 70%;
        }
    .training-cont{
        background: linear-gradient(76.8deg, rgb(121, 45, 129) 3.6%, rgb(36, 31, 98) 90.4%);
    }
    .training-hero-section{
        margin: 0px auto;
        max-width: 2500px;
          padding-right: 1rem;
        padding-left: 1rem;
    display: flex;
    flex-direction: column;
   
    align-items: center;
    padding-top: 3rem;
    }
    .hero-section-left{
        width:70%;
        }


        .hero-section-right{
            width: 70%;
            border-radius: 20px;
            padding-right: 4rem;
            padding-left: 4rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
          background:transparent;  
          background-color:rgb(15, 2, 49); 
          backdrop-filter: blur(50px);
          
        }
     
        .training-cont{
            background: linear-gradient(76.8deg, rgb(121, 45, 129) 3.6%, rgb(36, 31, 98) 90.4%);
        }
        .acw-certificate-container-wrapper{
            padding-right: 8rem;
            padding-left: 8rem;
            
          
            color:white;
         padding-top: 12rem;
         display: flex;
        flex-direction: column-reverse;
         justify-content: space-between;
         padding-bottom: 8rem;
        }
        .acwp-certificate-right-section-container{
            width:95%;
            margin-top: 2.5rem;
        }
        .acw-right-section-container-cert{
            width:95%;
        }
}




@media screen and (max-width: 850px){
    .bg-image-left{
        display: none;
      
    }
    .bg-image-right{
        display: none;
    }
    .training-highlight-section-left-new{
        width: 100%;
        }
        
    .training-cont{
        background: linear-gradient(76.8deg, rgb(121, 45, 129) 3.6%, rgb(36, 31, 98) 90.4%);
    }
    .training-hero-section{
        
       
        padding-right: 1rem;
        padding-left: 1rem;
    display: flex;
    flex-direction: column;
   
    align-items: center;
    padding-top: 3rem;
    }
    .hero-section-left{
        width:90%;
        }
 .hero-section-right{
            width: 90%;
            border-radius: 20px;
            padding-right: 4rem;
            padding-left: 4rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
          background:transparent;  
          background-color:rgb(15, 2, 49); 
          backdrop-filter: blur(50px);
          
        }
        .training-highlight-section{
            padding-right: 8rem;
            padding-left: 8rem;
           
            background: rgb(15, 2, 49);
            color:white;
         padding-top: 12rem;
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         padding-bottom: 8rem;
         margin-bottom:4rem ;
        }
        .training-highlight-section4{
      
            background: rgb(15, 2, 49);
            color:white;
         padding-top: 12rem;
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         padding-bottom: 8rem;
         margin-bottom:4rem ;
        }
        .training-highlight-sectionin{
      
            background: rgb(15, 2, 49);
            color:white;
         padding-top: 12rem;
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         padding-bottom: 8rem;
         margin-bottom:4rem ;
        }
        .acw-right-section-container-cert{
            width:95%;
        }
        .training-highlight-section-left{
            width:95%;
        }   
        .training-highlight-section-left2{
            width:100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }   
        .acwp-certificate-right-section-container{
            width:95%;
            margin-top: 2.5rem;
        }
        .training-highlight-section-right{
            width:95%;
            margin-top: 2.5rem;
        }
      
        .training-highlight-section2{
            padding-right: 8rem;
            padding-left: 8rem;
            
            background: rgb(15, 2, 49);
            color:white;
         padding-top: 12rem;
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         padding-bottom: 8rem;
         
        }
        .acw-certificate-container-wrapper{
            padding-right: 8rem;
            padding-left: 8rem;
            
          
            color:white;
         padding-top: 12rem;
         display: flex;
        
         justify-content: space-between;
         padding-bottom: 8rem;
        }
        .training-highlight-section3{
            padding-right: 8rem;
            padding-left: 8rem;
            
          
            color:white;
         padding-top: 12rem;
         display: flex;
         flex-direction: column-reverse;
         justify-content: space-between;
         padding-bottom: 8rem;
         
        }
        .training-highlight-section4{
          
           
           
            color:white;
         padding-top: 5rem;
         display: flex;
         justify-content: center;
         padding-bottom: 5rem;
         background: linear-gradient(76.8deg, rgb(121, 45, 129) 3.6%, rgb(36, 31, 98) 90.4%);
        
        }
        .training-highlight-sectionin{
          
           
           
            color:white;
         padding-top: 5rem;
         display: flex;
         justify-content: center;
         padding-bottom: 5rem;
         background: rgb(15, 2, 49);
        
        }
        .training-highlight-section-left2{
        
            width:95%;
        }
        .training-desc2{
         
            width:110%;
        }
        .training-highlight-section-right4{
           
           
            margin-top: 3rem;
            width:95%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
}





@media screen and (max-width: 700px){
    .bg-image-left{
        display: none;
      
    }
    .bg-image-right{
        display: none;
    }
    .training-desc2{
         
        width:130%;
    }
    .training-highlight-section-left-new{
        width: 100%
        }
    .training-highlight-section4{
        padding-right: 2px;
        padding-left: 2px;
        color:white;
     padding-top: 5rem;
     display: flex;
     justify-content: center;
     padding-bottom: 5rem;
     background: linear-gradient(76.8deg, rgb(121, 45, 129) 3.6%, rgb(36, 31, 98) 90.4%);
    
    }
    .training-highlight-sectionin{
        padding-right: 2px;
        padding-left: 2px;
        color:white;
     padding-top: 5rem;
     display: flex;
     justify-content: center;
     padding-bottom: 5rem;
     background:rgb(15, 2, 49);
    
    }
    .training-highlight-section-left2{
    
        width:95%;
    }
    .training-desc2{
        padding-left: 3rem;
        width:100%;
    }
    .training-highlight-section-right4{
       
       
        margin-top: 3rem;
        width:80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .training-cont{
        background: linear-gradient(76.8deg, rgb(121, 45, 129) 3.6%, rgb(36, 31, 98) 90.4%);
    }
    .training-hero-section{
      max-width: 2500px;
      margin: 0px auto;
      
        padding-right: 1rem;
        padding-left: 1rem;
    display: flex;
    flex-direction: column;
   
    align-items: center;
    padding-top: 3rem;
    }
    .hero-section-left{
        width:90%;
        }
 .hero-section-right{
            width: 90%;
            border-radius: 20px;
            padding-right: 2rem;
            padding-left: 2rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
          background:transparent;  
          background-color:rgb(15, 2, 49); 
          backdrop-filter: blur(50px);
          
        }
        .training-highlight-section{
            padding-right: 2rem;
            padding-left: 2rem;
           
            background: rgb(15, 2, 49);
            color:white;
         padding-top: 12rem;
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         padding-bottom: 8rem;
         margin-bottom:4rem ;
        }
        .acw-right-section-container-cert{
            width:95%;
        }
        .training-highlight-section-left{
            width:95%;
        }   
        .training-highlight-section-left2{
            width:95%;
        }  
        .training-highlight-section-right{
            width:95%;
            margin-top: 2.5rem;
        }
        .training-highlight-section2{
            padding-right: 2rem;
            padding-left: 2rem;
            
            background: rgb(15, 2, 49);
            color:white;
         padding-top: 12rem;
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         padding-bottom: 8rem;
         
        }
        .training-highlight-section3{
            padding-right: 2rem;
            padding-left: 2rem;
            
           
            color:white;
         padding-top: 12rem;
         display: flex;
         flex-direction: column-reverse;
         justify-content: space-between;
         padding-bottom: 8rem;
         
        }
        .training-heading{
            font-size: 30px;
        }
        .hero-heading{
            font-size: 30px;
            width: 80%;
            font-weight: 800;
        }
}
.blue-bg{
    background-color: #171421 !important;
}
.red-bg{
    background: radial-gradient(circle at 50.4% 50.5%, rgb(251, 32, 86) 0%, rgb(135, 2, 35) 90%);
}
.bg-effect{
    position: relative;
}








