@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
 
background: url(../../../Assets/About\ us/Untitled-1.svg);
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
.spinner-container{
  width: 100%;
  min-height:100vh;
   background: rgba( 249, 245, 245, 0.05 );
   box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
   backdrop-filter: blur( 20.0px );
   -webkit-backdrop-filter: blur( 20.0px );
   border-radius: 10px;
 
   width: 100vw;
}