.bg-body{
    background-color: #050D21;
    color: white;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.verify-certificate-container{

}
.verify-certificate-container .heading{
    text-align: center;
    font-size: 2.75rem;
   
}
.sub-heading{
    text-align: center;
    font-size: 1.5rem;
    margin-top: 1rem;
}