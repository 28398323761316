@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Saira:wght@300&display=swap');



*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 


}
html{
  font-size: 10px;
}
body{
  background-color:#000512!important;
 
}



@media (max-width: 768px) {
 
 html{
   font-size: 55%;
 }
}

@media (max-width: 575px) {
  html{
    font-size:45%;
  }
} 