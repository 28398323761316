@import url('https://fonts.googleapis.com/css2?family=Saira:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Saira&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@700&family=Saira:wght@600&display=swap');

/* blog main page starts */
.Dynamicbackground {
  background-image: url(/src/Assets/Landing\ page/Mask_Group_2.webp);
  height: auto;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding: 6rem 0;
  overflow: hidden;
}

.blog-wrapper {
  width: 65%;
}

.Dynamicbackground2 {
  background-image: url(/src/Assets/Entity/Webfare/hackmaster_banner.png);
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0rem 0;
  overflow: hidden;
  background-size: cover;
}


.section-bg {
  background-color: #f9f9fa;
}

.Heading-main {
  text-align: center;
  padding: 30px 0;
  position: relative;
  z-index: 1;
}

.Heading-main h2 {
  font-size: 12rem;
  font-weight: 600;

  font-family: 'Advent Pro', sans-serif;
  margin-bottom: 2rem;
  padding-bottom: 0;
  color: white;
  position: relative;
  letter-spacing: .3rem;
 
}

.Heading-main h3 {
  font-size: 7rem;
  font-weight: 600;

  font-family: 'Saira', sans-serif;
  margin-bottom: 2rem;
  padding-bottom: 0;
  color: white;
  position: relative;
  letter-spacing: .1rem;
  z-index: 2;
  margin-top: 3rem;
}

.Heading-main span {
  position: absolute;
  top: 10rem;
  color: white;
  font-family: 'Advent Pro', sans-serif;
  opacity: .1;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 600;
  font-size: 25rem;
  text-transform: uppercase;
  line-height: 0;
}

.Heading-main .spanleaderboard {
  position: absolute;
  top: 10rem;
  color: white;
  font-family: 'Advent Pro', sans-serif;
  opacity: .1;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 600;
  font-size: 20rem;
  text-transform: uppercase;
  line-height: 0;
}

.Heading-main .spanleaderboard2 {

  position: absolute;
  top: -9rem;

  color: white;
  font-family: 'Advent Pro', sans-serif;
  opacity: .1;
  left: 0;
  right: 0;
  z-index: .1;

  line-height: 0;



}

.Heading-main .spanleaderboard2 img {
  height: 500px;
  width: auto;
  position: center;
  margin-bottom: 4rem;


}

.Heading-main hr {
  background-color: #e32f1c;
  border-radius: 1rem;
  width: 20rem;
  align-items: center;
  height: .5rem;
}




@media (max-width: 575px) {
  .Heading-main h2 {
    font-size: 8rem;

  }

  .Heading-main span {
    font-size: 16rem;
  }

  .Heading-main .spanleaderboard {
    font-size: 12rem;
  }

  .Heading-main .spanleaderboard2 {
    font-size: 12rem;
  }
}

.mainconsection {
  background-image: radial-gradient(circle, #051025, #050e20, #030b1c, #020817, #000512);
  height: auto;

  width: 100%;
}

.card-upper {
  background-image: radial-gradient(circle, #051025, #050e20, #030b1c, #020817, #000512);
  height: auto;


}

.card-upper img {
  width: 100%;
}

#blogcard {
  box-shadow: 23px 23px 20px 10px #000D1C,
    -23px -23px 20px #000D1C;
  border: none !important;
  border-radius: 2rem;
}

#card-body {
  background: #040f24;
}

.card-upper h1 {
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  font-size: 3rem;
  letter-spacing: .2rem;
  line-height: 4.5rem;
}

#card-body #blog-heading {
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  font-size: 1.3rem;
  margin: 2rem;
  line-height: 2rem;
}

.userimage {
  height: 5rem !important;
  width: 5rem !important;
}

.username_span {
  color: white;
  font-size: 1.5rem;
  font-family: 'Saira', sans-serif;
  margin-left: 2rem;
  font-weight: 600;
}

.userpara {
  color: white;
  opacity: .2;
  margin-left: 8.8rem;
  font-family: 'Saira', sans-serif;
  margin-top: -1.8rem;
}

/* blog page ends */
/* blog page desc starts */
.blogdeschead {
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  font-size: 2.1rem;
  margin-top: 2rem;
}

.blogdescpara {
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  margin-top: 1rem;
  opacity: .3;
  margin-top: auto;
}

.blogdescimage {
  box-shadow: 23px 23px 20px 10px #000D1C,
    -23px -23px 20px #000D1C;
  height: 50vh;
  width: 50%;
}

/* ends */
/* course starts */
.course_para {
  color: white;
  margin-top: 10rem;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  font-size: 2rem;

  justify-content: center;

  line-height: 5rem;
  text-align: center;
}

.course_para1 {
  color: #e32f1c;
  margin-top: 10rem;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 2.5rem;

  justify-content: center;

  line-height: 5rem;
  text-align: center;
}

.intershipdetaildiv {
  background-image: url(../../Assets/Transaction/Rectangle\ 1134.svg);
  height: auto;
  background-size: cover;
  width: 100%;
  padding: 7rem;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 10px 20px 2px rgba(12, 5, 18, .85), 8px -5px 20px 2px rgb(3, 28, 57, 1);
  border-radius: 2rem;
}

.intershipdetaildiv2 {
  background-image: url(../../Assets/Transaction/Rectangle\ 1134.svg);
  height: fit-content;
  background-size: cover;
  width: 100%;
  padding: 3rem;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 10px 20px 2px rgba(12, 5, 18, .85), 8px -5px 20px 2px rgb(3, 28, 57, 1);
  border-radius: 2rem;
}

.coursepagebtn {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  padding: 20px 50px;
  border-radius: 10px;
  border: none;
  font-size: 1.6rem;
  letter-spacing: .2rem;
}

.coursepagebtn2 {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  padding: 20px 30px;
  border-radius: 10px;
  border: none;
  font-size: 1.1rem;
  letter-spacing: .1rem;
}

.enrollbtntwo {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  padding: 10px 10px;
  width: 100%;
  border-radius: 10px;

  border: none;
  font-size: 1.7rem;
  letter-spacing: .1rem;

}

.enrollbtntwo2 {
  background-image: url(../../Assets/Transaction/Rectangle\ 1134.svg);
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  background-size: cover;

  width: 100%;
  border-radius: 5px;
  border: none;
  font-size: 1.7rem;
  transform: scale3d(1.1);
  letter-spacing: .1rem;
  filter: drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 10px 15px 3px rgba(12, 5, 18, .85), -4px 5px 15px 3px rgb(3, 28, 57, 1);

}

.importantdetailshead {
  color: white;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-family: 'Saira', sans-serif;
  font-weight: 500;

  text-transform: capitalize;
}

.importantdetailshead2 {
  color: white;
  font-size: 2rem;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  text-transform: capitalize;
}

.importantdetailspara {
  color: lightgray;
  font-size: 1.5rem;
  font-family: 'Saira', sans-serif;
  font-weight: 600;

  margin-top: -1rem;
  text-transform: capitalize;
}

.coursesection {
  background-image: url(/src/Assets/courses/Mask_Group_29.png);
  width: 100%;
  height: auto;
  background-repeat: no-repeat;


}

.Coursehead {
  color: white;
  font-size: 5rem;
  font-family: 'Advent Pro', sans-serif;
  font-weight: 400;
  letter-spacing: 1rem;
  margin-top: 10rem;
}

.whatwewilllearnhead {
  color: lightgray;
  font-size: 3rem;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-style: bold;
  letter-spacing: 0rem;
  margin-top: 0rem;
}

.internbulletpara {
  color: lightgray;
  opacity: 1;
  font-family: 'Saira', sans-serif;
  font-weight: 500;

  margin-top: 0%;
  text-transform: capitalize;
  line-height: 4rem;
  text-align: justify;

  font-size: 1.55rem;
  justify-content: justify;

}

.Coursehead2 {
  color: white;
  font-size: 5rem;
  font-family: 'Advent Pro', sans-serif;
  font-weight: 400;
  letter-spacing: .5rem;
  margin-top: 5rem;
}

.coursepara {
  color: white;
  opacity: 1;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  padding: 0 2.5rem;
  margin-top: 5%;
  line-height: 4rem;
  text-align: justify;
  letter-spacing: 0.1rem;
  font-size: 1.6rem;
  justify-content: justify;
  margin-left: -3rem;
}

.coursepara1 {
  color: white;
  opacity: 1;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  padding: 0 2.5rem;
  margin-top: 1%;
  line-height: 4rem;
  text-align: justify;
  letter-spacing: 0.1rem;
  font-size: 1.6rem;
  justify-content: justify;
  margin-left: -3rem;
}

.intern_para {
  color: white;
  opacity: 1;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  padding: 0 2.5rem;
  margin-top: 1%;
  line-height: 4rem;
  text-align: justify;

  font-size: 1.6rem;
  justify-content: justify;
  margin-left: -3rem;
}

.intern_para span {
  color: #e32f1c;
  font-size: 2rem;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
}

.coursehr {
  background-color: #e32f1c;
  width: 14rem;
  height: .4rem;
  border-radius: 1rem;
}

#coursevideobg {
  background-image: url(/src/Assets/register/Auth\ Form\ Bg.svg);
  height: auto;
  background-repeat: no-repeat;
  width: 100%;
  border-radius: 2rem;
  margin-top: 10rem;
  margin-bottom: 10rem;
}

#coursedivbg {
  background: url(../../Assets/Transaction/Rectangle\ 1134.svg);
  background-repeat: no-repeat;
  background-size: cover;
  filter: drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 10px 15px 3px rgba(12, 5, 18, .85), -4px 5px 15px 3px rgb(3, 28, 57, 1);
  width: 100%;
  border-radius: 2rem;
  height: auto;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

#courseupper {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  color: white;
  font-family: 'Saira', sans-serif;
  height: auto;
  width: 100%;
  padding: 1rem;
  margin: 2.4rem;
  border-radius: 12px;
}

#courseupper h2 {
  font-size: 4rem;
  text-align: center;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  margin-top: 1.8rem;

}

#courseupper p {
  font-size: 1.3rem;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
}

.courseupper1 {
  /* background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  color:white;
  font-family: 'Saira', sans-serif;
  height: auto;
  width: 100%;
  padding: 2rem;
margin: 1.8rem;
  border-radius: 12px;*/
  background-image: url(../../Assets/Transaction/Rectangle\ 1134.svg);
  background-size: cover;
  height: auto;
  text-align: center;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 10px 20px 2px rgba(12, 5, 18, .85), 8px -5px 20px 2px rgb(3, 28, 57, 1);
  margin: 1.8rem;
  border-radius: 1rem;
  padding: 2rem;
  color: white;
  text-align: center;
  align-items: center;

}

.courseupper1:hover {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  background-size: cover;
  height: auto;
  text-align: center;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 10px 20px 2px rgba(12, 5, 18, .85), 8px -5px 20px 2px rgb(3, 28, 57, 1);
  margin: 1.8rem;
  border-radius: 1rem;
  padding: 2rem;
  color: white;
  text-align: center;
  align-items: center;
}

.courseupper1 h2 {
  font-size: 1.9rem;
  text-align: center;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  margin-top: 15%;

}

.courseupper1 p {
  font-size: 1.3rem;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
}

.courseupper2 {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  background-size: cover;
  height: auto;
  text-align: center;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 10px 20px 5px rgba(12, 5, 18, .85), 8px -5px 20px 5px rgb(3, 28, 57, 1);
  color: white;
  margin: 1.8rem;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  align-items: center;
}

.courseupper2 h2 {
  font-size: 1.9rem;
  text-align: center;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  margin-top: 15%;

}

.courseupper2 p {
  font-size: 1.3rem;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
}

.logoimage {
  height: auto;
  padding: auto;
  padding-top: 2rem;
  padding-bottom: 5rem;
}

#coursedivbg2 {
  background: url(../../Assets/Transaction/Rectangle\ 1134.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 2rem;
  height: auto;

  margin-top: 2rem;
  filter: drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 10px 15px 3px rgba(12, 5, 18, .85), -4px 5px 15px 3px rgb(3, 28, 57, 1);

}

.outlinehead {
  color: white;
  font-family: 'Saira', sans-serif;
  font-size: 3rem;
  letter-spacing: .2rem;
  margin-top: 2rem;
}

.courselist li {
  margin-top: 1rem;
  color: white;
  letter-spacing: 0rem;
  font-size: 1.46rem;
}

.courselist span {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  border-radius: .7rem;
  margin-right: 1rem;
}

/* testimonial starts */
.Test-main {
  text-align: center;
  padding: 30px 0;
  position: relative;
}

.Test-main h2 {
  font-size: 12rem;
  font-weight: 600;

  font-family: 'Advent Pro', sans-serif;
  margin-bottom: 2rem;
  padding-bottom: 0;
  color: white;
  position: relative;
  letter-spacing: .3rem;
  z-index: 2;
}

.Test-main span {
  position: absolute;
  top: 10rem;
  color: white;
  font-family: 'Advent Pro', sans-serif;
  opacity: .1;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 600;
  font-size: 22rem;
  text-transform: uppercase;
  line-height: 0;
}

.Test-main hr {
  background-color: #e32f1c;
  border-radius: 1rem;
  width: 20rem;
  align-items: center;
  height: .5rem;
}



@media (max-width: 575px) {
  .Test-main h2 {
    font-size: 8rem;

  }

  .Test-main span {
    font-size: 16rem;
  }
}

#testwaves {
  background-image: url(/src/Assets/testimonial/Waves_test.svg);
  height: 100vh;
  width: 100%;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

}

#testcontainer {
  background-image: url(/src/Assets/Transaction/Rectangle\ 1134.svg);
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 5px 15px 5px rgba(12, 5, 18, 0.95),
    2px -5px 15px 5px rgb(3, 28, 57, 1);

  border-radius: 1.5rem;
}

.quoteimage {
  height: 100px;
  width: 100px;


}

.testi_head {
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  letter-spacing: .2rem;
  font-size: 2.5rem;
}

.testi_para {
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.7rem;
  font-style: italic;
  line-height: 3rem;
  justify-content: center;
}

.testi_name {
  color: white;
  font-family: 'Saira', sans-serif;
  margin: 5rem;
  font-size: 1.6rem;
  float: left;

}

.testi_data {
  color: white;
  font-family: 'Saira', sans-serif;
  float: right;
  margin: 5rem;
  font-size: 1.6rem;


}

/*leaderboard page*/
#leaderboardrow {
  background: #01152b;
  background: -webkit-radial-gradient(top left, #01152b, #040b18);
  background: -moz-radial-gradient(top left, #01152b, #040b18);
  background: radial-gradient(to bottom right, #01152b, #040b18);
  height: auto;
  width: 100%;


}

#leaderboardrow2 {
  background: url(../../Assets/Transaction/Rectangle\ 1134.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1.5rem;
  margin: 2rem;
  color: white;

  font-family: 'Saira', sans-serif;
  font-weight: 500;
  border-radius: 1.5rem;
  filter: drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 10px 15px 3px rgba(12, 5, 18, .85), -4px 5px 15px 3px rgb(3, 28, 57, 1);
}

#leaderboardrow3 {
  background: url(../../Assets/Transaction/Rectangle\ 1134.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1.5rem;
  margin: 3rem;
  color: white;

  font-family: 'Saira', sans-serif;
  font-weight: 400;
  border-radius: 0rem;
  margin-bottom: 2rem;
  filter: drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 10px 15px 3px rgba(12, 5, 18, .85), -4px 5px 15px 3px rgb(3, 28, 57, 1);
}

.leaderboardrank {
  color: white;
  text-transform: uppercase;
  font-family: 'Saira', sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: bold;
  text-align: center;
}

.leaderboardrank3 {
  color: lightgray;
  text-transform: uppercase;
  font-family: 'Saira', sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  font-style: oblique;
  text-align: left;

}

.form-control2 {
  background: #000D1C;
  padding: 2rem 10rem;
  border: none;
  box-shadow: inset -1.5px -1px 6px .5px rgba(51, 43, 130, .7), inset 4px 4px 4px rgba(0, 0, 0, 0.9);
  border-radius: .8rem;

  color: white;

  font-family: 'Saira', sans-serif;
  resize: none;
  font-size: 1.5rem;
}

.selectrank .option {

  background: #051025;
  background-repeat: no-repeat;

  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 0px -8px 20px 1px rgba(12, 5, 18, .85), 0px -8px 10px 1px rgb(3, 28, 57, .5);
  color: #fff;

  overflow: scroll;
  scroll-behavior: hidden;
}

.selectrank {
  width: 100%;

  padding: 2rem 8rem;
  height: auto;

  margin-bottom: 2rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background: url(../../Assets/Dashboard/down-arrow.png) 90% / 3% no-repeat #eee;
  color: white !important;
  background-color: #000512;
  border: none;
  cursor: pointer;
  box-shadow: inset -1.5px -1px 6px .5px rgba(51, 43, 130, .7), inset 4px 4px 4px rgba(0, 0, 0, 0.9);

  font-family: "Saira", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
}

.form-control2:focus {
  border: 2px solid green !important;
  color: whitesmoke;
  font-family: "Saira", sans-serif;
  font-style: italic;
  font-weight: 600;

  text-transform: capitalize;

}

.form-control option {
  background: #051025;
  background-repeat: no-repeat;

  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 0px -8px 20px 1px rgba(12, 5, 18, .85), 0px -8px 10px 1px rgb(3, 28, 57, .5);
  color: #fff;

  overflow: scroll;
  scroll-behavior: hidden;
}

.leaderbtn {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  padding: 10px 50px;

  border-radius: 5px;

  border: none;
  font-size: 1.5rem;
  text-transform: uppercase;


}

.namerankcountry {
  color: red;
}

/*faqs page */

#faqcards {
  background: url(../../Assets/Transaction//Rectangle\ 1134.svg);
  background: -webkit-radial-gradient(top left, #01152b, #040b18);
  background: -moz-radial-gradient(top left, #01152b, #040b18);
  background: radial-gradient(to bottom right, #01152b, #040b18);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem;
  box-shadow: inset -1.5px -1px 6px .5px rgba(51, 43, 130, .7), inset 4px 4px 4px rgba(0, 0, 0, 0.9);
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));

}

.faqcardshead {
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  font-size: 1.8rem;
}

.mainfaqhead {

  color: lightgray;
  font-size: 3rem;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-style: bold;
  letter-spacing: 0rem;
  position: relative;
  margin-top: 2rem;
}

.mainfaqpara {
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  text-align: center;
  line-height: 2.5rem;

}

.faqhr {
  background-color: #e32f1c;
  width: 6rem;
  height: .4rem;
  border-radius: 1rem;
  transform: translateX(-5.5rem);
  margin-bottom: 3rem;
  margin-top: 3rem;



}

.faqansercard {

  font-size: 1.4rem;
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  text-align: justify;
}

/* Revex Page css */
.revexpara {
  color: white;
  font-size: 2rem;
  margin-top: 3rem;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
}

/* havoc common starts*/
#havoccard {
  background: #01152b;
  background: -webkit-radial-gradient(top left, #01152b, #040b18);
  background: -moz-radial-gradient(top left, #01152b, #040b18);
  background: radial-gradient(to bottom right, #01152b, #040b18);
  padding: 2rem;
  border-radius: .8rem;
  margin: .8rem;
}

#havoccard2 {
  background: #01152b;
  background: -webkit-radial-gradient(top left, #01152b, #040b18);
  background: -moz-radial-gradient(top left, #01152b, #040b18);
  background: radial-gradient(to bottom right, #01152b, #040b18);
  padding: 3rem;
  border-radius: .8rem;
  margin: .8rem;

}

#havocformdiv {
  position: sticky;
  top: 0;
  background: #01152b;
  background: -webkit-radial-gradient(top left, #01152b, #040b18);
  background: -moz-radial-gradient(top left, #01152b, #040b18);
  background: radial-gradient(to bottom right, #01152b, #040b18);
  height: auto;
  color: white;
  padding: 2rem;

  border-radius: .8rem;
}

.havoccardhead {
  color: white;
  text-align: center;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  font-size: 3.5rem;
}

.havochead2 {
  color: white;
  text-align: center;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  font-size: 2rem;
  margin-top: 1.8rem;
  letter-spacing: .05rem;
  text-transform: capitalize;
}

.havocheadpara {
  color: lightgray;
  text-align: center;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  text-transform: capitalize;
  margin-top: 0rem;
  padding-bottom: 2rem;
}

.havocheadpara3 {
  color: lightgray;
  text-align: left;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  text-transform: capitalize;

  margin-top: 0rem;
  padding-bottom: 2rem;
}

.havocheadpara2 {
  color: #1B64A5;
  text-align: center;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  text-transform: capitalize;
  margin-top: 0rem;
  padding-bottom: 2rem;
}

.startdatehead {
  color: #1B64A5;

  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-top: 0rem;

}

.teamcreatebtn {
  background: linear-gradient(90deg, #1B64A5 0%, #1B64A5 100%);
  padding: 1.8rem 7rem;
  color: white;
  margin-top: 3rem;
  border-radius: 1rem;
  font-family: "Saira", sans-serif;
  font-weight: 600;
  font-size: 1.3rem;
  margin-bottom: 3rem;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, .03));
  box-shadow: 2px 10px 20px 5px rgba(12, 5, 18, .85), 8px -5px 20px 5px rgb(3, 28, 57, 1);

}

.startdatepara {
  color: lightgray;

  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  text-transform: uppercase;
  margin-top: 0rem;
}

.havocrightsidecardhead {
  color: #1B64A5;
  text-align: left;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.7rem;
  text-transform: capitalize;
  margin-top: 0rem;
  padding-bottom: 1rem;
}

.havocrightsidecardhead2 {
  color: lightgray;
  text-align: left;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.7rem;
  text-transform: capitalize;
  margin-top: 0rem;
  padding-bottom: 1rem;
}

.havocrightsidecardhead3 {
  color: lightgray;
  text-align: left;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  font-size: 2.6rem;
  text-transform: capitalize;
  margin-top: 0rem;
  padding-bottom: 1rem;
}

.havoccardhead2 {
  color: #1B66A9;
  text-align: center;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  font-size: 1.7rem;
}

.havocheadabout {
  color: #1B66A9;
  text-align: center;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  font-size: 1.8rem;

  text-transform: uppercase;
}

.havocheadabout2 {
  color: white;

  font-family: 'Saira', sans-serif;
  font-weight: 600;
  font-size: 3rem;

  text-transform: capitalize;
}

.havocheadaboutpara {
  color: lightgray;

  font-family: 'Saira', sans-serif;
  font-weight: 400;
  line-height: 3rem;
  font-size: 1.6rem;
  text-align: justify;
}

.havocheadaboutpara2 {
  color: gray;

  font-family: 'Saira', sans-serif;
  font-weight: 600;
  line-height: 3rem;
  font-size: 1.6rem;
  text-align: justify;
}

.havocheadaboutpara3 {
  color: white;

  font-family: 'Saira', sans-serif;
  font-weight: 600;
  line-height: 3rem;
  font-size: 1.6rem;
  text-align: justify;
}

.havocheadaboutpara4 {
  color: white;

  font-family: 'Saira', sans-serif;
  font-weight: 600;
  line-height: 3rem;
  font-size: 1.8rem;
  text-align: justify;
}

.havocheadaboutpara5 {
  color: lightgray;

  font-family: 'Saira', sans-serif;
  font-weight: 400;
  line-height: 3rem;
  font-size: 1.6rem;
  text-align: justify;
}

/* Timeline Container */
.timeline {
  background: none;
  margin: 20px auto;
  width: 100%;

}

/* Outer Layer with the timeline border */
.outer {
  border-left: 2px dashed lightgray;


}

/* Card container */
.cards {
  position: relative;
  margin: 0 0 20px 20px;
  padding: 10px;
  background: #01152b;
  background: -webkit-radial-gradient(top left, #01152b, #040b18);
  background: -moz-radial-gradient(top left, #01152b, #040b18);
  background: radial-gradient(to bottom right, #01152b, #040b18);
  color: gray;
  border-radius: .8rem;

}

.cards ::before {
  content: " ";
  height: 0px;
  position: absolute;
  top: 20px;

  z-index: 1;
  left: -20px;
  border: medium solid #01152b;
  border-width: 10px 10px 10px 10px;
  border-color: transparent #01152b transparent transparent;
}

/* Information about the timeline */
.info {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;

}

.info p {
  color: lightgray;

  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  padding-left: 2rem;

}

/* Title of the card */
.titletimeline {
  color: white;
  position: relative;
  font-family: 'Saira', sans-serif;
  font-size: 2rem;
  font-weight: 600;
  padding-left: 2rem;


}

/* Timeline dot  */
.titletimeline::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  left: -39px;
  margin-top: -1rem;
  border: 4px solid #1B66A9;
  ;

}

#pricedivinf {
  background: #01152b;
  background: -webkit-radial-gradient(top left, #01152b, #040b18);
  background: -moz-radial-gradient(top left, #01152b, #040b18);
  background: radial-gradient(to bottom right, #01152b, #040b18);
  height: auto;
  color: white;
  border-radius: 1rem;

}

.priceupperdiv1 {
  background-color: #FFD700;
  padding: 0rem;
  height: auto;
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.priceupperdiv2 {
  background-color: silver;
  padding: 0rem;
  height: auto;
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.priceupperdiv3 {
  background-color: #CD7F32;
  padding: 0rem;
  height: auto;
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.priceheading1 {
  color: #000512;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  font-size: 3rem;
  margin-top: 1.5rem;
}

.pricedivlist {
  font-size: 1.6rem;
  color: lightgray;
  font-family: 'Saira', sans-serif;
  font-weight: 400;

}

.pricedivlist2 {
  font-size: 1.6rem;
  color: lightgray;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  line-height: 3.4rem;
  text-align: justify;
  padding-left: 1rem;
}

.pricedivlist2 b {
  font-size: 1.6rem;
  color: #1B64A5;
  font-style: bold;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  line-height: 2rem;
  padding-left: 1rem;
}

.pricelist2listhead {
  font-size: 2rem;
  color: #1B64A5;
  font-style: bold;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  padding-right: .5rem;
  line-height: 2rem;


}

.ball {


  animation: smoothbounceball 01s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

@keyframes smoothbounceball {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, 5px, 0);
  }
}


/* university page css*/

.vertical {
  border: 2px solid transparent;
  border-top: none;
  border-bottom: none;
  border-right: none;

  border-image: linear-gradient(60deg,
      #750115,
      #750115,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);

  border-image-slice: 1;
  border-image-width: 3;
  height: 200px;
  position: absolute;
  left: 0%;
  right: 2px;
}

.univerhead {
  margin-left: 5px;
  color: white;
  font-size: 4.2rem;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  text-transform: capitalize;
}

.univerhead b {
  color: #e32f1c;
}

.univerhead2 {

  color: white;
  font-size: 3rem;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  text-transform: capitalize;
}

.univerpara {
  margin-left: 2px;
  color: white;
  font-size: 1.7rem;
  font-family: 'Saira', sans-serif;
  font-weight: 500;

  text-align: justify;
}

.universitydemobtn {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  padding: 12px 12px;

  border-radius: 1rem;

  border: none;
  font-size: 1.4rem;
  text-transform: uppercase;

}

.universitydemobtn2 {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  padding: 20px 20px;

  border-radius: 3rem;
  margin-top: 5rem;
  border: none;
  font-size: 1.4rem;
  text-transform: uppercase;

}

.universityhr {
  background-color: #e32f1c;
  height: 0.6rem;

  border-radius: 10px;
  width: 23rem;
}

#universityadavnatgecard {
  background: url(../../Assets/Entity/University/BackgroundAsset.png);
  background-repeat: no-repeat;
  background-size: cover;

  height: auto;
  border-radius: 2rem;
  cursor: pointer;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 4px 10px 1px rgba(12, 5, 18, .85), 2px -3px 10px 1px rgb(3, 28, 57, 1);
}

#universityadavnatgecard:hover {
  border: 2px solid red;
}

#universityadavnatgecard:hover #uniadvantegphotodic #check {
  transition: 0.70s;
  -webkit-transition: 0.70s;
  -moz-transition: 0.70s;
  -ms-transition: 0.70s;
  -o-transition: 0.70s;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

#uniadvantegphotodic {
  transform: translateY(-5rem);
}

.advantagehead {
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  transform: translateY(-3rem);
}

.advantagepara {
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 400;

  text-align: justify;
  font-size: 1.35rem;
  transform: translateY(-2rem);
  padding: 1rem;
  justify-content: justify;

}

#universitylibrarycard1 {
  background: url(../../Assets/Entity/University/Assetcenterleft.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;

}

#universitylibrarycard1:hover img {
  transform: scale(1.2)
}

#universitylibrarycard2 {
  background: url(../../Assets/Entity/University/Assetcenterright.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;

}

#universitylibrarycard2:hover img {
  transform: scale(1.2)
}

#unilibinchead {
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
}

#unilibincpara {
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  opacity: .8;
}

/*hall of fame css*/
.univerhead3 {
  color: white;
  font-size: 5.5rem;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.univerhead3 span {
  color: #e32f1c;
  font-size: 6rem;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.univerhead4 {
  color: white;
  font-size: 4rem;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.univerhead4 span {
  color: #e32f1c;
  font-size: 4rem;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

#halloffamecard {
  background: url(../../Assets/Entity/University/BackgroundAsset.png);
  background-repeat: no-repeat;
  background-size: cover;

  height: auto;
  border-radius: 2rem;
  margin-bottom: 3rem;
  cursor: pointer;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 4px 10px 1px rgba(12, 5, 18, .85), 2px -3px 10px 1px rgb(3, 28, 57, 1);
}

#halloffamecard2 {
  background: url(../../Assets/Entity/HalloffameUI/green.png);
  background-repeat: no-repeat;
  background-size: cover;

  height: auto;
  border-radius: 2rem;
  margin-bottom: 3rem;
  cursor: pointer;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 4px 10px 1px rgba(12, 5, 18, .85), 2px -3px 10px 1px rgb(3, 28, 57, 1);
}

#halloffamecard3 {
  background: url(../../Assets/Entity/HalloffameUI/yellow.png);
  background-repeat: no-repeat;
  background-size: cover;

  height: auto;
  border-radius: 2rem;
  margin-bottom: 3rem;
  cursor: pointer;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 4px 10px 1px rgba(12, 5, 18, .85), 2px -3px 10px 1px rgb(3, 28, 57, 1);
}

#halloffamecard4 {
  background: url(../../Assets/Entity/HalloffameUI/card.png);
  background-repeat: no-repeat;
  background-size: cover;

  height: auto;
  border-radius: 2rem;
  margin-bottom: 3rem;
  cursor: pointer;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 4px 10px 1px rgba(12, 5, 18, .85), 2px -3px 10px 1px rgb(3, 28, 57, 1);
}

#halloffamecard:hover {
  border: 2px solid red;
}

#halloffamecard:hover #uniadvantegphotodic #check {
  transition: 0.70s;
  -webkit-transition: 0.70s;
  -moz-transition: 0.70s;
  -ms-transition: 0.70s;
  -o-transition: 0.70s;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

#uniadvantegphotodic2 {
  transform: translateY(0rem);
}

.univerpara2 {

  color: white;
  font-size: 1.7rem;
  font-family: 'Saira', sans-serif;
  font-weight: 500;

  text-align: justify;
}

.advantagehead2 {
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  margin-top: 2rem;
}

.advantagehead3 {
  color: grey;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  font-size: 5rem;
  opacity: .2;
  text-align: center;

}

.advantagehead4 {
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 2.5rem;
  letter-spacing: .1rem;

  text-align: center;

}

.advantagepara2 {
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 400;

  text-align: center;
  font-size: 1.6rem;

  padding: 1.2rem;
  text-align: justify;

}

.designationhead {
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  margin: 1rem;

}

.impactdiv {
  border: 2px dashed white;
  height: 3rem;
  width: 8rem;

  margin-top: 1rem;
  text-align: center;

}

.impactdiv p {
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  color: red;
  text-align: center;
  margin-top: .5rem;
}

.impactdiv2 {
  border: 2px dashed white;
  height: 3rem;
  width: 8rem;

  margin-top: 1rem;
  text-align: center;

}

.impactdiv2 p {
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  color: yellow;
  text-align: center;
  margin-top: .5rem;
}

.impactdiv3 {
  border: 2px dashed white;
  height: 3rem;
  width: 8rem;

  margin-top: 1rem;
  text-align: center;

}

.impactdiv3 p {
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  color: green;
  text-align: center;
  margin-top: .5rem;
}

/* webpwn main page start*/
.webpwnmaindiv {
  background-image: url(/src/Assets/Entity/webpwnui/webpwnmain.png);
  height: 100vh;
  height: auto;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

}

.webpwnmaindiv2 {
  background-image: url(/src/Assets/Entity/webpwnui/bg3.png);
  height: 100vh;
  height: auto;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

}

.webpwn-head {
  color: white;
  padding-top: 6rem;
  padding-left: 1rem;
  font-family: 'Advent Pro', sans-serif;
  font-weight: 700;
  font-size: 10rem;
  text-transform: uppercase;
  letter-spacing: .2rem;
}

.webpwn-para {
  color: lightgray;

  padding-left: 2rem;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 2.3rem;
  text-transform: capitalize;

}

.webpwn-para2 {
  color: white;
  width: 100%;
  margin-top: 1.5rem;
  padding-left: 2.3rem;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 3rem;

  text-align: justify;
}

.webpwnbtn {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  padding: 10px 30px;
  border-radius: 5px;
  border: none;
  margin-left: 7rem;
  font-size: 1.6rem;
  letter-spacing: .1rem;
}

.webpwnhead2 {
  color: white;
  font-size: 350%;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 7rem;
}

#firstdivofcard {
  background: url(../../Assets/Entity/webpwnui/icons/asset5.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 200px;
  transform: translateX(5rem);
  overflow: hidden;
  z-index: 32423;
}

#firstdivofcard22 {
  background: url(../../Assets/Entity/webpwnui/icons/asset6.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 200px;
  width: 500px;
  transform: translateX(5rem);
  overflow: hidden;
  z-index: 32423;
  padding: 2rem;
}

#firstdivofcard23 {
  background: url(../../Assets/Entity/webpwnui/icons/asset7.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 200px;
  transform: translateX(5rem);
  overflow: hidden;
  z-index: 32423;
}

#firstdivofcard24 {
  background: url(../../Assets/Entity/webpwnui/icons/asset8.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 200px;
  transform: translateX(5rem);
  overflow: hidden;
  z-index: 32423;
}

#firstdivofcard2 {
  background: url(../../Assets/Entity/webpwnui/icons/bar1.png);
  border-radius: 2rem;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  margin-top: 0rem;
  padding-left: 2rem;
}

#firstdivofcard2 .card-body .card-text {
  color: white;
  padding-left: 1.8rem;

  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  text-align: justify;
  padding-top: 3.5rem;

}

#firstdivofcard3 {
  background: url(../../Assets/Entity/webpwnui/icons/bar3.png);
  border-radius: 2rem;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  margin-top: 0rem;
  padding-left: 2rem;
}

#firstdivofcard3 .card-body .card-text {
  color: white;
  padding-left: 1.8rem;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  text-align: justify;
  padding-top: 3.5rem;
  padding-right: 1rem;
}

#firstdivofcard4 {
  background: url(../../Assets/Entity/webpwnui/icons/bar2.png);
  border-radius: 2rem;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  margin-top: 0rem;
  padding-left: 2rem;
}

#firstdivofcard4 .card-body .card-text {
  color: white;
  padding-left: 1.8rem;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  text-align: justify;
  padding-top: 3.5rem;
  padding-right: 1rem;
}

#firstdivofcard5 {
  background: url(../../Assets/Entity/webpwnui/icons/bar4.png);
  border-radius: 2rem;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  margin-top: 0rem;
  padding-left: 2rem;
  height: 25rem
}

#firstdivofcard5 .card-body .card-text {
  color: white;
  padding-left: 1.8rem;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  text-align: justify;
  padding-top: 3.5rem;
  padding-right: 1rem;
}

.webpwnbulletpara {
  color: lightgray;
  opacity: 1;
  font-family: 'Saira', sans-serif;
  font-weight: 500;

  margin-top: 0%;
  text-transform: capitalize;
  line-height: 4rem;
  text-align: justify;

  font-size: 1.8rem;
  justify-content: justify;
}

#webpwncard1 {
  background: url(../../Assets/Entity/webpwnui/Pricing/webpwncard1.png);
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 6rem;
  box-shadow: 2px 4px 10px 1px #274A00, 2px -3px 10px 1px #78BF06;
  border-radius: 1rem;
  margin: 3rem;

}

#webpwncard2 {
  background: url(../../Assets/Entity/webpwnui/Pricing/webpwncard2.png);
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 6rem;
  box-shadow: 2px 4px 10px 1px #9E4705, 2px -3px 10px 1px #E8A425;
  border-radius: 1rem;
  margin: 3rem;

}

#webpwncard3 {
  background: url(../../Assets/Entity/webpwnui/Pricing/webpwncard3.png);
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 6rem;
  box-shadow: 2px 4px 10px 1px #380004, 2px -3px 10px 1px #A30326;
  border-radius: 1rem;
  margin: 3rem;


}

#webpwnbadge1 {
  background: url(../../Assets/Entity/webpwnui/Pricing/webpwnbadge2.png);
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 2rem;
  margin-top: 1.5rem;


}

#webpwnbadge2 {
  background: url(../../Assets/Entity/webpwnui/Pricing/webpwnbadhe2.png);
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 2rem;
  margin-top: 1.5rem;


}

#webpwnbadge3 {
  background: url(../../Assets/Entity/webpwnui/Pricing/webpwnbadge3.png);
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 2rem;
  margin-top: 1.5rem;


}

#webpwnbadge1 h1 {
  z-index: 234234;
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 2.7rem;
  transform: translateY(-2rem);
}

#webpwnbadge2 h1 {
  z-index: 234234;
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 2.7rem;
  transform: translateY(-2rem);
}

#webpwnbadge3 h1 {
  z-index: 234234;
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 2.7rem;
  transform: translateY(-2rem);
}

.webpwnpricehead {
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 2.7rem;
  color: white;
}

.section-courses {
  background-image: url(/src/Assets/Landing\ page/Mask_Group_2.webp);
}




.course-section {

  padding-top: 4rem;
  padding-bottom: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.course-list {
  position: relative;
  overflow: hidden;

  border-radius: 1rem;
  color: white;
  padding-left: 3.3rem;
  padding-top: 3.8rem;
  padding-bottom: 1rem;
  padding-right: 4.8rem;
  background-color: #1a2332;
  gap: 2rem;

  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Saira', sans-serif;
}

.course-title {
  display: flex;
  gap: 1.3rem;

  align-items: center;
  font-weight: 500;
  font-size: 3rem;

}

.course-content {
  width: 70%;



}

/* .horizontal-line{
   background-color: red;
   margin-left: 1rem;
   width: 75%;
  height: 3px;
  margin-top: -0.5rem;
 } */
.course-desc {
  margin-top: 3rem;
  color: #a4b1cd;
  font-size: 1.9rem;
  text-align: justify;
}

.course-outline {
  margin-top: 2.5rem;
  font-weight: 600;
  color: white;
  font-size: 2.5rem;
  margin-bottom: -0.5rem;
}

.course-outline-list {
  display: flex;

  flex-wrap: wrap;
}

.course-outline-list-item {
  margin-right: 1.5rem;
  margin-bottom: -1.5rem;
  font-size: 1.4rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: #a4b1cd;
}

@keyframes button-animation {
  from {
    opacity: 1;
    background: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  }

  to {
    opacity: 1;
    background: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  }



}

.course-button {
  display: inline-block;
  cursor: pointer;
  margin-bottom: 2rem;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  text-decoration: none;
  color: white;
  border-radius: 0.3rem;
  padding-left: 4rem;
  padding-right: 4rem;
  font-size: 1.5rem;
  margin-top: 2rem;
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: button-animation;


}

@keyframes gradientAnimation {
  from {
    left: 0;
  }

  to {
    left: 100%;
  }
}

.animate-background {

  background: linear-gradient(to right, #e53935, #c62828);
  width: 35rem;
  height: 25rem;
  top: -20%;
  left: 95%;
  filter: blur(180px);
  position: absolute;
  /* animation: gradientAnimation 12s linear 1s infinite alternate; */
}

.animate-background2 {

  background: linear-gradient(to right, #e53935, #c62828);
  width: 25rem;
  height: 25rem;

  top: 75%;
  left: 0%;
  filter: blur(200px);
  position: absolute;
  /* animation: gradientAnimation 12s linear 1s infinite alternate; */
}

.image-shadow {
  padding: 5rem;
  box-shadow: 0 25px 50px -12px rgb(0, 0, 0);

}

.title-tip {
  background: linear-gradient(to right, #e53935, #c62828);
  font-size: 1.5rem;

  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 1.5rem;

}

.background-image {
  position: absolute;
  width: 60rem;
  height: 50rem;
  bottom: 0px;
  right: 0;
  background-repeat: no-repeat;

}

.background-image2 {
  position: absolute;
  width: 45rem;
  height: 50rem;
  bottom: -12px;
  left: 0px;
  transform: rotate(90deg);
  background-repeat: no-repeat;
}

@media (max-width: 1126px) {
  .course-list {


    width: 98%;

  }

  .background-image {

    position: absolute;
    bottom: -2000px;
    right: 0px;
    z-index: 10;
    width: 60rem;
  }


}

@media (max-width: 1320px) {
  .course-list {


    width: 85%;

  }

  .background-image {

    position: absolute;
    bottom: -2000px;
    right: 0px;
    z-index: 10;
    width: 60rem;
  }

}

@media (max-width: 1487px) {
  .course-list {


    width: 85%;

  }

  .background-image {

    position: absolute;
    bottom: -3000px;
    right: 0px;
    z-index: 10;
    width: 60rem;
  }
}

@media (max-width:700px) {
  .image-shadow {
    display: none;
  }

  .image-block {
    display: none;
  }

  .course-content {
    width: 100%;

  }

  .course-list {
    padding-left: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-right: 2rem;
  }

  .background-image {
    display: hidden;
  }

  .background-image2 {
    display: hidden
  }
}

.toggle-button {
  padding: 3rem;
  font-size: 2.2rem;
  width: fit-content;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  justify-items: center;
  color: white;
  background-color: #1a2332;
  font-family: 'Saira', sans-serif;
  font-family: 600;

  padding-top: 0.75rem;
  padding-bottom: 0.3rem;

  border-radius: 5px;
}

.toggle-list {
  cursor: pointer;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: .5rem;
}

.toggle-list-click {
  cursor: pointer;
  border-radius: 3px;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  border-radius: 2rem;
  padding-top: .5rem;
  background: linear-gradient(to right, #e53935, #c62828);
}

.image-block {
  border-radius: 1rem;
  box-shadow: 0 35px 60px -15px rgba(0, 0, 0, 0.3);
  background-color: #131a25;
  padding-right: 4rem;
  padding-left: 4rem;
  padding: 2.6rem;
  border-width: 1px;
  border-color: #e32f1c;
  border-style: solid;

}

.course-sec {
  padding-top: 4rem;
  padding-bottom: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12rem;
  position: relative
}

.text-capital {
  text-transform: uppercase;
}

.text-font-white {
  color: lightgray;
  font-size: 1.25rem;
  font-family: 'Saira', sans-serif;

}

.text-font-white2 {
  color: lightgray;
  opacity: 1;
  font-family: 'Saira', sans-serif;
  font-weight: 500;

  margin-top: 0%;
  text-transform: capitalize;
  line-height: 4rem;
  text-align: justify;

  font-size: 1.55rem;
  justify-content: justify;
}


.webpwnhead10 {
  color: white;
  font-size: 250%;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 7rem;
}

@media screen and (min-width: 350px) {
  .blog-wrapper {
    width: 87%;
  }
}

@media screen and (min-width: 768px) {
  .blog-wrapper {
    width: 80%;
  }

  .webpwnhead2 {
    color: white;
    font-size: 350%;
    font-family: 'Saira', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 7rem;
  }

  .webpwnhead10 {
    color: white;
    font-size: 350%;
    font-family: 'Saira', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 7rem;
  }

  .webpwn-head {
    color: white;
    padding-top: 6rem;
    padding-left: 6rem;
    font-family: 'Advent Pro', sans-serif;
    font-weight: 700;
    font-size: 10rem;
    text-transform: uppercase;
    letter-spacing: .2rem;
  }

  .webpwn-para {
    color: lightgray;

    padding-left: 7rem;
    font-family: 'Saira', sans-serif;
    font-weight: 500;
    font-size: 2.3rem;
    text-transform: capitalize;

  }

  .webpwn-para2 {
    color: white;
    margin-top: 1.5rem;
    padding-left: 7rem;
    font-family: 'Saira', sans-serif;
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 3rem;

    text-align: justify;
  }
}

.course-feature {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 1024px) {
  .blog-wrapper {
    width: 65%;
  }

  .webpwnhead2 {
    color: white;
    font-size: 5rem;
    font-family: 'Saira', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 7rem;
  }

  .webpwnhead10 {
    color: white;
    font-size: 5rem;
    font-family: 'Saira', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 7rem;
  }

  .webpwn-head {
    color: white;
    padding-top: 6rem;
    padding-left: 6rem;
    font-family: 'Advent Pro', sans-serif;
    font-weight: 700;
    font-size: 10rem;
    text-transform: uppercase;
    letter-spacing: .2rem;
  }

  .webpwn-para {
    color: lightgray;

    padding-left: 7rem;
    font-family: 'Saira', sans-serif;
    font-weight: 500;
    font-size: 2.3rem;
    text-transform: capitalize;

  }

  .webpwn-para2 {
    color: white;
    margin-top: 1.5rem;
    padding-left: 7rem;
    font-family: 'Saira', sans-serif;
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 3rem;

    text-align: justify;
  }
}

@media screen and (min-width: 1280px) {
  .webpwnhead2 {
    color: white;
    font-size: 5rem;
    font-family: 'Saira', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 7rem;
  }

  .webpwnhead10 {
    color: white;
    font-size: 5rem;
    font-family: 'Saira', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 7rem;
  }

  .webpwn-head {
    color: white;
    padding-top: 6rem;
    padding-left: 6rem;
    font-family: 'Advent Pro', sans-serif;
    font-weight: 700;
    font-size: 10rem;
    text-transform: uppercase;
    letter-spacing: .2rem;
  }

  .webpwn-para {
    color: lightgray;

    padding-left: 7rem;
    font-family: 'Saira', sans-serif;
    font-weight: 500;
    font-size: 2.3rem;
    text-transform: capitalize;

  }

  .webpwn-para2 {
    color: white;
    margin-top: 1.5rem;
    padding-left: 7rem;
    font-family: 'Saira', sans-serif;
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 3rem;

    text-align: justify;
  }
}



.blog-container {

  display: flex;
  justify-content: center;
  color: white;
  margin-bottom: 4rem;
}

.blog-paragraph a {
  color: red;
  font-weight: 500;
}

.blog-paragraph {
  font-size: 16px;
  margin-top: 1rem;
  text-align: justify;
}

.blog-title-h2 {
  font-size: 30px;
  margin-top: 5rem;
}

.blog-title-h3 {
  font-size: 25px;
  margin-top: 2rem;
}


/*event page */
.eventbackground {
  background-image: radial-gradient(circle, #051025, #050e20, #030b1c, #020817, #000512);
  height: auto;
  margin: 0;
  background-repeat: no-repeat;

}

.eventparagraph {
  font-size: 2rem;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  color: lightgray;
}

.eventparagraph2 {
  font-size: 1.7rem;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  color: lightgray;
  text-align: justify;
}

.eventparaforallevents {
  font-size: 1.5rem;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  color: lightgray;
  text-align: start !important;
}

.eventdateformat {
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  color: white;
}

.eventallsections {
  background: #01152b;
  background: -webkit-radial-gradient(top left, #01152b, #040b18);
  background: -moz-radial-gradient(top left, #01152b, #040b18);
  background: radial-gradient(to bottom right, #01152b, #040b18);
  padding: 2rem;
  border-radius: .5rem;
}

.eventclosehr {
  background-color: #1B64A5;
  width: 100%;
  height: .05rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-align: center;
}

.eventbutton {
  width: 100%;
  text-align: center;
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  padding: 1rem 1.5rem;
  border-radius: 2rem;
  text-transform: uppercase;
}

.eventbutton:hover {
  transform: scale(1.2);
  color: lightcyan;
}

.eventbuttondeactivate {
  width: 100%;
  text-align: center;
  background: #01152b;
  background: -webkit-radial-gradient(top left, #01152b, #040b18);
  background: -moz-radial-gradient(top left, #01152b, #040b18);
  background: radial-gradient(to bottom right, #01152b, #040b18);
  border: 1px solid #1B64A5;
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  padding: 1rem 1.5rem;
  border-radius: 2rem;
  text-transform: uppercase;
}

.eventimage {
  border-radius: .8rem;

}

.eventhead {
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  text-align: start;

}

.Eventsctfbackground {
  background: -webkit-radial-gradient(top left, #01152b, #040b18);
  background: -moz-radial-gradient(top left, #01152b, #040b18);
  background: radial-gradient(to bottom right, #01152b, #040b18);
  height: auto;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;

  overflow: hidden;
}

.eventbtunactive {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  background-size: cover;
  height: auto;
  text-align: center;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 10px 20px 5px rgba(12, 5, 18, .85), 8px -5px 20px 5px rgb(3, 28, 57, 1);
  color: white;
  margin: 1.5rem;
  border-radius: 2rem;
  padding: 1rem;
  text-align: center;
  align-items: center;
}

.eventbtunactive h2 {
  font-size: 1.5rem;
  text-align: center;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  margin-top: 5%;

}

.eventbtunactive p {
  font-size: 1.3rem;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
}

.eventbtndeacti {

  background-image: url(../../Assets/Transaction/Rectangle\ 1134.svg);
  background-size: cover;
  height: auto;
  text-align: center;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 10px 20px 2px rgba(12, 5, 18, .85), 8px -5px 20px 2px rgb(3, 28, 57, 1);
  margin: 1.5rem;

  padding: 1rem;
  color: white;
  text-align: center;
  align-items: center;
  border-radius: 2rem;

}

.eventbtndeacti:hover {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  background-size: cover;
  height: auto;
  text-align: center;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 10px 20px 2px rgba(12, 5, 18, .85), 8px -5px 20px 2px rgb(3, 28, 57, 1);
  margin: 1.5rem;
  border-radius: 1rem;
  padding: 1rem;
  color: white;
  text-align: center;
  align-items: center;
  border-radius: 2rem;
}

.eventbtndeacti h2 {
  font-size: 1.5rem;
  text-align: center;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  margin-top: 5%;

}

.eventbtndeacti p {
  font-size: 1.3rem;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
}

.eventcard {
  background-image: url(../../Assets/Transaction/Rectangle\ 1134.svg);
  height: auto;
  background-size: cover;
  width: 100%;
  padding-left: 1.6rem;
  margin-top: 3rem;
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 10px 10px 2px rgba(12, 5, 18, .85), 2px -5px 10px 2px rgb(3, 28, 57, 1);
  border-radius: 0rem;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  padding-bottom: 3rem;
}

.eventcard.hovered {
  transform: translateY(-5px);
  /* Adjust the value as needed */
  cursor: pointer;
}

.eventcardhead {
  font-size: 2.1rem;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: white;
  margin-top: .3rem;
  padding: 2rem;
}

.eventdetailshead {
  font-size: 2.8rem;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  margin-top: .3rem;
  padding: 2rem;
  text-align: center;
}

.eventdetailshead3 {
  font-size: 2.8rem;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  margin-top: .3rem;
  padding: 2rem;
  text-align: center;
}

.eventdetailsdatehead {
  font-size: 1.4rem;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: lightgray;
  margin-top: .3rem;
  padding: 2rem;
  text-align: center;
}

.eventdetailsdatehead2 {
  font-size: 1.8rem;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: white;
  margin-top: .3rem;
  padding: 2rem;
  text-align: center;
  margin-top: -3rem;
}

.eventcarddate {
  font-size: 1.4rem;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: lightgray;
  padding-left: 2rem;
  margin-top: -1rem;

}

.eventcardplayers {
  font-size: 1.6rem;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: white;
  padding-left: 2rem;
  margin-top: 1.2rem;
}

.eventcardplayers2 {
  font-size: 1.6rem;
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: white;
  padding-left: .5rem;
  margin-top: 3.2rem;
}

.eventleaderhead {
  font-size: 1.6rem;
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: grey;
  text-align: center;
}

.eventleaderhead2 {
  font-size: 1.4rem;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: lightgray;
  text-align: center;
}

.eventleaderhead3 {
  font-size: 1.4rem;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: lightgray;
  text-align: left;
}

#eventcardcontent {
  background: -webkit-radial-gradient(top left, #01152b, #040b18) !important;
  background: -moz-radial-gradient(top left, #01152b, #040b18) !important;
  background: radial-gradient(to bottom right, #01152b, #040b18) !important;
  height: auto;
  width: 100%;
}

.iconsback {
  background: #01152b;
  background: -webkit-radial-gradient(top left, #01152b, #040b18);
  background: -moz-radial-gradient(top left, #01152b, #040b18);
  background: radial-gradient(to bottom right, #01152b, #040b18);
  padding: .5rem 1rem;
  border-radius: 50%;
  border: 1px solid #1B64A5;
  margin-right: .8rem;
}

.iconsback2 {
  background: #01152b;
  background: -webkit-radial-gradient(top left, #01152b, #040b18);
  background: -moz-radial-gradient(top left, #01152b, #040b18);
  background: radial-gradient(to bottom right, #01152b, #040b18);
  padding: .8rem 1rem;
  border-radius: 5px;
  border: 1px solid #1B64A5;
  margin-top: 2rem;
}

.eventsignupbtn {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  background-size: cover;
  height: auto;
  text-align: center;
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  margin-top: 2rem;
  padding: 1rem 3rem;
  font-size: 1.6rem;
}

.viewallbtn {
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  background-size: cover;
  height: auto;
  text-align: center;
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 500;

  padding: .5rem 3rem;
  font-size: 1.4rem;
  margin-left: -1rem;
}

.overvirehead {
  color: #1B66A9;
  text-align: start;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 2rem;
  margin-top: 2rem;
  text-transform: uppercase;
}

#eventdtaildiv {
  border: .5px solid #1B64A5;
  border-radius: 1rem;
  padding: 1rem;


}

#smallcards {
  background: #01152b;
  background: -webkit-radial-gradient(top left, #01152b, #040b18);
  background: -moz-radial-gradient(top left, #01152b, #040b18);
  background: radial-gradient(to bottom right, #01152b, #040b18);
  color: white;
  border: 1px solid #1B64A5;
  border-radius: 1rem;
  margin: 2rem;
  width: 100%;
}

.smallcardhead {
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  margin-top: 1rem;
  text-transform: uppercase;

}

.smallcardhead2 {
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  font-size: 1.3rem;
  margin-top: .4rem;
  text-transform: uppercase;
  color: lightgray;

}

#winnercard {
  position: relative;
  background-image: url(../../Assets/Transaction/Rectangle\ 1134.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0rem;
  border-radius: 1rem;
  margin: 1rem;
  padding: 2rem;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 100%, 0 80%);

}

#winnercardinner {
  padding: 3rem;
  filter: drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 10px 15px 3px rgba(12, 5, 18, 0.85), -4px 5px 15px 3px rgba(3, 28, 57, 1);

}

.winnercardhead {
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  margin-top: 0rem;
  font-weight: bold;
  text-transform: uppercase;
  color: lightgray;
}

.winnercardpara {
  font-family: 'Saira', sans-serif;
  font-weight: 400;
  font-size: 1.3rem;
  margin-top: 0rem;

  text-transform: uppercase;
  color: white;
}

#checkphoto {
  background-image: url(../../Assets/Entity/Website\ Havoc/backgroundsingle.png);
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
}


.zoomed {
  transform: scale(1.2);
  transition: transform 0.3s ease;
}

#eventlist {
  background: url(../../Assets/Transaction/Rectangle\ 1134.svg);
  background-position: center;
  background-size: cover;
  padding: 2rem;
  border-radius: 3rem;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 4px 20px 1px rgba(12, 5, 18, .85), 2px -3px 20px 1px rgb(3, 28, 57, 1);
}

#eventlist:hover {
  filter: drop-shadow(0px 0px 7px #e82930);
  box-shadow: -2px 2px 2px 3px #e32f1c, -2px 2px 2px 3px #e32f1c;
}