/*========== GOOGLE FONTS ==========*/

/*========== GOOGLE FONTS ==========*/
@import url("https://fonts.googleapis.com/css2?family=Saira&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Saira:wght@500&display=swap");


/*========== VARIABLES CSS ==========*/

:root {
  --header-height: 3rem;
  --nav-width: 219px;

  /*========== Colors ==========*/
  --first-color: #6923D0;
  --first-color-light: #F4F0FA;
  --title-color: #19181B;
  --text-color: #58555E;
  --text-color-light: #A5A1AA;
  --body-color: #F9F6FD;
  --container-color: #FFFFFF;

  /*========== Font and typography ==========*/
  --body-font: 'Poppins', sans-serif;
  --normal-font-size: .938rem;
  --small-font-size: .75rem;
  --smaller-font-size: .75rem;

  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========== z index ==========*/
  --z-fixed: 100;
}

@media screen and (min-width: 1024px) {
  :root {
    --normal-font-size: 1rem;
    --small-font-size: .875rem;
    --smaller-font-size: .813rem;
  }
}

/*========== BASE ==========*/
*, ::before, ::after {
  box-sizing: border-box;
}

body {

  padding:  0;
  margin: 0;
  background:url(../../../Assets/Dashboard/Dashboard\ Bg.svg);

}

h3 {
  margin: 0;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*========== HEADER ==========*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  
  width: 100%;
  background:url(/src/Assets/Dashboard/Dashboard\ Bg.svg);
  box-shadow: 0 1px 0 rgba(22, 8, 43, 0.1);
  padding: 3rem;
  z-index: var(--z-fixed);
}

.header__container {
  display: flex;
  align-items: center;
  height: var(--header-height);
  justify-content: space-between;
}

.header__img {
  width: 7rem;
  height: 7rem;
 margin-top: 1rem;
 margin-left: .3rem;
outline: none;
z-index: 1000;
  
}

.header__logo {
  color:white;
  font-weight: var(--font-medium);

}

.header__search {
  display: flex;
  padding: .40rem .75rem;
  background-color: var(--first-color-light);
  border-radius: .25rem;
}

.header__input {
  width: 100%;
  border: none;
  outline: none;
  background-color: var(--first-color-light);
}

.header__input::placeholder {
  font-family: var(--body-font);
  color: var(--text-color);
}

.header__icon, 
.header__toggle {
  font-size: 1.2rem;
}

.header__toggle {
  color: var(--title-color);
  cursor: pointer;
}
.barname {
  color: white;
  font-size: 2.6rem;

  margin-top: 2.5rem;
  font-family: "Saira", sans-serif;
  font-weight: 500;
  letter-spacing: 0.2rem;

}
@media(max-width:768px){
  .barname{
    color: white;
  font-size: 2.3rem;

  margin-top: 2.5rem;
  font-family: "Saira", sans-serif;
  font-weight: 500;
  letter-spacing: 0.2rem;
   }
   .imgbackend{
    background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
    padding:1rem 2rem;
    border-radius: .7rem;
   
  margin-right: 1rem;
    
  }
  .imgbackend img{
    height: 3rem;
  }
}

@media(min-width:768px){
  .barname{
    color: white;
  font-size: 2.6rem;

  margin-top: 2.5rem;
  font-family: "Saira", sans-serif;
  font-weight: 500;
  letter-spacing: 0.2rem;
   }
}

.imgbackend{
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  padding:.1rem 1.1rem;
  border-radius: .7rem;
 
margin-right: 1rem;
  
}
.imgbackend img{
  height: 1.9rem;
}
.header__serach{
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  border-radius: 100%;
  text-align: center;
  filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
  box-shadow:  1px 2px 6px .5px 	rgba(12,5,18,.85),1px -1px 6px .5px rgb(3, 28, 57,1);
 padding: 1rem;
  color: white;
  text-align: center;
 
}
.userimage{
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  margin-left: 2rem!important;

}
.userimagename{

    
  color: white;
  margin-left: 1rem;
text-align: center;

   font-size: 1.5rem;
   text-transform: capitalize;
    border-radius: 50%;
    font-family: "Saira", sans-serif;
    font-weight: 600;

}
.dropdownuser{
  background: url(/src/Assets/Transaction/Circle\ Neumorphism.svg);
  border-radius: 100%;
  text-align: center;
  box-shadow: 3px -4px 2px 5px rgb(12,5,18,.85),
  8px -8px 20px 8px rgb(3, 28, 57,.8);
  width: 1rem;
  height: 1rem;
  color: white;
  text-align: center;
}


.background_dashboard {
  background: #01152b;
  background: -webkit-radial-gradient(top left, #01152b, #040b18);
  background: -moz-radial-gradient(top left, #01152b, #040b18);
  background: radial-gradient(to bottom right, #01152b, #040b18);
  height: auto;
 
 padding-bottom: 8rem;
  border-radius: 8rem;
  margin-bottom: 3rem;
  overflow:hidden;
  width: 97%;
  

  background-repeat: no-repeat;
  
  position: relative;
  
  

 /* box-shadow:  12px -10px 20px 10px 	rgba(12,5,18,.7),9px -5px 10px 10px rgb(3, 28, 57,1);*/
  font-size: 3rem;
margin-left: 2rem;
  margin-top: 10rem;
  
}
.background_dashboardforunauth {
  background: #01152b;
  background: -webkit-radial-gradient(top left, #01152b, #040b18);
  background: -moz-radial-gradient(top left, #01152b, #040b18);
  background: radial-gradient(to bottom right, #01152b, #040b18);
  height: auto;
 

  border-radius: 8rem;
  margin-bottom: 3rem;
  overflow:hidden;
  width: 97%;
  

  background-repeat: no-repeat;
  
  position: relative;
  
  

 /* box-shadow:  12px -10px 20px 10px 	rgba(12,5,18,.7),9px -5px 10px 10px rgb(3, 28, 57,1);*/
  font-size: 3rem;
margin-left: 2rem;
  margin-top: 1rem;
  
}


#set{
  width:min-content;
  background:#051025;
  background-repeat: no-repeat;
 
  filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
  box-shadow:  0px -8px 20px 1px 	rgba(12,5,18,.85),0px -8px 10px 1px rgb(3, 28, 57,.5);
 height:auto;

min-height: 100vh;
border-radius: 10px;
 margin-top: -6.5rem;
 margin-left: -4rem;
 z-index: 6778787;

}
#set2{
  width:min-content;
  background:#051025;
  background-repeat: no-repeat;
 
  filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
  box-shadow:  0px -8px 20px 1px 	rgba(12,5,18,.85),0px -8px 10px 1px rgb(3, 28, 57,.5);
 height:auto;

min-height: 95vh;
border-radius: 10px;
 margin-top: -6.5rem;
margin-left: -4rem;
 z-index: 6778787;

}
#set2 .topbarsearchbtn{
  display: none;
}
#set2 .sidebarnames{
  display: none;
}
#set2 .hackersversionhead{
  display: none;
}
#set2 .sidebariconsarrow{
  display: none;
}
#set .topbarsearchbtn{
  display: none;
}
#set .sidebarnames{
  display: none;
}
#set .hackersversionhead{
  display: none;
}
#set .sidebariconsarrow{
  display: none;
}
.topbarsearchbtn{
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
height: 3.2rem;
width: 3.2rem;
border-radius: 50%;
filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow:  1px 2px 6px .5px 	rgba(12,5,18,.85),1px -2px 6px .5px rgb(3, 28, 57,1);
  margin-top: 3rem;
  cursor: pointer;
 
}
.navact .sidebaricons{
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  height:3.4rem;
  width:3.4rem;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
      box-shadow:  1px 2px 6px .5px 	rgba(12,5,18,.85),1px -1px 6px .5px rgb(3, 28, 57,1);
 

}
.sidebaricons{
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  height:3.4rem;
  width:3.4rem;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
      box-shadow:  1px 2px 6px .5px 	rgba(12,5,18,.85),1px -1px 6px .5px rgb(3, 28, 57,1);
 
}
.sidebaricons2{
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  height:3.4rem;
  width:3.4rem;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
      box-shadow:  1px 2px 6px .5px 	rgba(12,5,18,.85),1px -1px 6px .5px rgb(3, 28, 57,1);
 
}




.active .sidebaricons{
  background-color: red;
}

.sidebariconsarrow{
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  height:1.8rem;
  width:1.8rem;
  border-radius: 50%;
  margin-top: .5rem;
  filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
      box-shadow:  1px 2px 6px .5px 	rgba(12,5,18,.85),1px -1px 6px .5px rgb(3, 28, 57,1);
}
.sidebarnames{
  color: white;
  font-size: 1.4rem;
  font-family: "Saira", sans-serif;
  margin-top: .9rem;
font-weight: 600;
text-align:left;
}
.NavLink{
  text-decoration: none;
}

/* Dropdown */

/* Rotate icon arrow */
.nav__dropdown:hover  {

 color: white;
 transform: scale();
}
.nav__items {
  display: grid;
 row-gap: 2rem;
}
.nav__dropdown-content {
  display: grid;

  row-gap: 2rem;
  padding: 0.75rem 2.5rem 0.75rem 0.8rem;
}
#nav__dropdown-item {
  font-size: 1.4rem;
  font-weight: 400;
  color: white;

  letter-spacing: 0.2rem;
  padding-left: 1rem;
}

#nav__dropdown-item:hover {
  color: white;

  background: linear-gradient(
    300deg,
    #e32f1c,
    #e62b28,
    #e92833,
    #eb263d,
    #ed2547,
    #ee2550,
    #ee265a,
    #ee2963
  );
  padding: 1rem;
  border-radius: 1rem;
}
.nav__name_collaps {
  font-size: 1.3rem;
  font-weight: 200;
  font-family: "Saira", sans-serif;
  white-space: nowrap;
  letter-spacing: 0.2rem;
}
.hackersversionhead{
  color: gray;
  font-size: 1rem;
  font-family: "Saira", sans-serif;
  font-weight: 500;
}
.hackerversionhr{
  background-color: grey;
  width: 4rem;
  height: .02rem;
  justify-content: center;
}
.sidebarnavlinks{
  color: white;
  text-decoration: none!important;
}
#set:hover .sidebarnames {
  display: block;
}
#set:hover .sidebariconsarrow {
  display: block;
}
#set:hover  .topbarsearchbtn{
  display: block;
 
}
#set:hover .hackersversionhead{
  display: block;
}
#set:hover{


  background: rgba( 249, 245, 245, 0.05 );
    
    backdrop-filter: blur( 40.0px );
    -webkit-backdrop-filter: blur( 40.0px );
    border-radius: 10px;
 position:fixed;
   scroll-behavior: auto;
 height:auto;
 min-height: 100vh;

width:auto;
 margin-top: -6.5rem;
 margin-left: -4rem;
 z-index: 6778787;
}
.searchdiv{
display:flexbox;

  background: rgba( 249, 245, 245, 0.05 );
    position: fixed;
    backdrop-filter: blur( 20.0px );
    -webkit-backdrop-filter: blur( 20.0px );
    border-radius: 10px;
    height: auto;
    margin-top: -6.5rem;
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow:  0px -8px 20px 1px 	rgba(12,5,18,.85),0px -8px 10px 1px rgb(3, 28, 57,.5);
}
.searchdivinput{
  display: block;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
border-radius: 3rem;
  padding:1.3rem 5rem;
  box-shadow: inset -1.5px -1px 6px .5px rgba(51, 43, 130,.7), inset 4px 4px 4px rgba(0,0,0,0.9);
  margin-bottom: 2rem;

  cursor: pointer;
  font-family: "Saira", sans-serif;
  color: white !important;
  font-weight: 500;
}
.searchdivinput:focus{
  background-color: transparent;
}
.searchdivhead{
  color: white;
  font-family: "Saira", sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: .05rem;
}
.searchdivheadhr{
  background: red;
  height: .2rem;
  width: 5rem;
  border-radius: 1rem;
  transform: translateX(2rem);

  justify-content: center;
}
.searchresulthead{
  color: white;
  font-family: "Saira", sans-serif;
  font-size: 1.2rem;
  text-align: left;
  text-transform: capitalize;
}
.searchresulspan{
  color: gray;
  font-family: "Saira", sans-serif;
  font-size: .8rem;
  text-align: left;
  font-style: italic;
  text-transform: capitalize;
  

}
.searchresulspan2{
  color: gray;
  font-family: "Saira", sans-serif;
  font-size: .8rem;
  text-align: left;
  font-style: italic;
  text-transform: capitalize;position: absolute;
  transform: translateX(-5rem);

}








.topbtndiv {
  box-shadow: inset 2px 2px 2px #051025, inset -2px -2px 2px #0a0508;
  border-radius: 6rem;
  align-items: center;
  z-index: 5555;
}
#topbarbtn {
  background-color: transparent;
  padding: 1rem 1rem;
  color: white;
}
.box2 {
  width: 210px;
  height: auto;
  border-radius: 10px;
  transition: 0.5s;
position: absolute;
  overflow: none;
  padding: 0px;
 float: right;
right: 5rem;
  margin-top: 25px;
  /* From https://css.glass */
  background: rgba(249, 245, 245, 0.09);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.631);
border-radius: 16px;

backdrop-filter: blur(5.6px);
-webkit-backdrop-filter: blur(5.6px);
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  cursor: context-menu;
}
.box2 ul li .icon {
  font-size: 2.5rem;
  margin: 1rem;
  color: white;
  text-decoration: none!important;
}
.box2 ul li:hover .icon2 {
 color: white!important;
 
}
.box2 ul li:hover {
  background: linear-gradient(
    300deg,
    #e32f1c,
    #e62b28,
    #e92833,
    #eb263d,
    #ed2547,
    #ee2550,
    #ee265a,
    #ee2963
  );
  width: 140%;
  margin-left: -30px;
  padding: none;
  
  justify-content: center;
  text-decoration: none!important;
}
.box2 ul li .NavNAv {
  font-size: 1.5rem;
  margin-top: 5rem;
  font-family: "Saira", sans-serif;
  color: white;
  text-decoration: none;
}
  @media screen and (max-width: 736px){ 
  .box3{
 width: 200px!important;

  }
  #notificationbtn{
    margin-left: 0rem;
    padding: 1.3rem 4rem;
  }
 
}
.box3 {
  width: 350px;
  height: auto;

  transition: 1s;

  overflow: none;
  padding: 0px;
 float: right;
left: -6rem;
  margin-top: 35px;
  z-index: 87878798;
  background: rgba(1, 21, 43, 1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
position:fixed;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  cursor: context-menu;
}
.box3 ul li .icon {
  font-size: 2.5rem;
  margin: 1rem;
  color: white;
  text-decoration: none!important;
}
.box3 ul li:hover {
  background: linear-gradient(
    300deg,
    #e32f1c,
    #e62b28,
    #e92833,
    #eb263d,
    #ed2547,
    #ee2550,
    #ee265a,
    #ee2963
  );
  width: 100%;

  padding: none;
  justify-content: center;
  text-decoration: none!important;
}
.box3 ul li .NavNAv {
  font-size: 1.5rem;
  margin-top: 5rem;
  font-family: "Saira", sans-serif;
  color: white;
  text-decoration: none;
}
.notifichead{
  color: white;
  font-family: "Saira", sans-serif;
  font-weight: 500;
 text-transform: capitalize;
  font-size: 1.5rem;
padding-top:1rem ;
text-overflow: ellipsis;
letter-spacing: 0rem;
float: left!important;
  padding-bottom: 0rem;
}
.notispan{
  color: gray; font-family: "Saira", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  font-style: italic;
  float: left;
  margin-top: .5rem;
  
}

#notificationbtn{
  background-color: #e32f1c;
  color: white;
  padding: 1rem;
  border-radius: 1rem;
  margin-left: 1rem;


}
#notificationbtn .NavLink{
  color: white;
  font-family: "Saira", sans-serif;
  text-decoration: none;


}
#notificationbtn h1{
  font-size: 1.5rem;
  letter-spacing: 0rem;
  font-family: "Saira", sans-serif;
}

.feedback .header__serach{
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  border-radius: 100%;
  text-align: center;
  filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
  box-shadow:  1px 2px 6px .5px 	rgba(12,5,18,.85),1px -1px 6px .5px rgb(3, 28, 57,1);
 padding: .8rem;
  color: white;
  text-align: center;
}
.header__serach2{
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  border-radius: 100%;
  text-align: center;
  filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
  box-shadow:  1px 2px 6px .5px 	rgba(12,5,18,.85),1px -1px 6px .5px rgb(3, 28, 57,1);
 padding: .8rem;
  color: white;
  text-align: center;
}
.logout-topbar-class{
  cursor: pointer;
}