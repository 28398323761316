@import url('https://fonts.googleapis.com/css2?family=Saira:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Saira:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@700&family=Saira:wght@600&display=swap');

.NavLinks {
  text-decoration: none;
  outline: none;
}
video{
  position: relative;
}
video::cue {
  font-size: 16px;  /* Adjust subtitle font size */
  font-weight: bold;  /* Make subtitles bold */
  color: white;  /* Change subtitle text color */
  background: rgba(0, 0, 0, 0.75);  /* Add a semi-transparent background */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);  /* Add text shadow for readability */
position: absolute;
bottom:20rem;
width:50%
}
.Entity_background {
  background: #01152b;
  background: -webkit-radial-gradient(top left, #01152b, #040b18);
  background: -moz-radial-gradient(top left, #01152b, #040b18);
  background: radial-gradient(to bottom right, #01152b, #040b18);
  height: auto;
  min-height: 90vh;
  padding-bottom: 8rem;
  border-radius: 8rem;
  overflow: hidden;
  width: 96%;


  background-repeat: no-repeat;

  position: relative;

  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));


  /* box-shadow:  12px -10px 20px 10px 	rgba(12,5,18,.7),9px -5px 10px 10px rgb(3, 28, 57,1);*/
  font-size: 3rem;
  margin-left: 2rem;
  margin-top: 10rem;
  margin-bottom: 3rem;
}

.Entity_background2 {
  background: url(../../../Assets/Dashboard/Dashboard\ Bg.svg);
  height: auto;
  min-height: 90vh;
  padding-bottom: 8rem;
  border-radius: 8rem;
  overflow: hidden;
  width: 96%;


  background-repeat: no-repeat;

  position: relative;

  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));


  /* box-shadow:  12px -10px 20px 10px 	rgba(12,5,18,.7),9px -5px 10px 10px rgb(3, 28, 57,1);*/
  font-size: 3rem;
  margin-left: 2rem;
  margin-top: 10rem;
  margin-bottom: 3rem;
}

.Entitycommontopper {
  background: url(../../../Assets/Dashboard/Dashboard\ Bg.svg);
  width: 102%;
  height: auto;
  margin-left: -.5rem;
  margin-top: 1rem !important;
  border-top-left-radius: 5rem;
  border-top-right-radius: 6rem;

  overflow: hidden;

  filter: drop-shadow(0px 0px 10px #000512);
  box-shadow: 0px -8px 20px 1px rgba(12, 5, 18, .85), 0px -8px 10px 1px rgb(3, 28, 57, .5);

}

.Entitycommontopper3 {
  background: url(../../../Assets/Entity/Website\ Havoc/backgroundsingle.png);
  width: 102%;
  height: auto;
  margin-left: -.5rem;
  margin-top: 1rem !important;
  border-top-left-radius: 6rem;
  border-top-right-radius: 8rem;

  overflow: hidden;

  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 0px -8px 20px 1px rgba(12, 5, 18, .85), 0px -8px 10px 1px rgb(3, 28, 57, .5);

}

.Entitycommontopper2 {
  background: url(../../../Assets/Dashboard/Dashboard\ Bg.svg);
  width: 102%;
  height: auto;
  margin-left: -.5rem;
  min-height: 30vh;
  margin-top: 1rem !important;
  border-top-left-radius: 5rem;
  border-top-right-radius: 6rem;

  overflow: hidden;

  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 0px -8px 20px 1px rgba(12, 5, 18, .85), 0px -8px 10px 1px rgb(3, 28, 57, .5);

}

.Entitycommonhead {
  color: white;
  padding-top: 4rem;

  font-family: 'Advent Pro', sans-serif;
  font-weight: 700;
  font-size: 4rem;
  letter-spacing: .4rem;
  padding-bottom: .5rem;
  text-transform: uppercase;
}

.Entitycommonheadhr {
  background-color: red;
  height: .2rem;
  justify-content: center;
  width: 12rem;
}

.Entitycommonheadpara {
  color: white;
  text-align: justify;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  opacity: .8;
  font-size: 1.6rem;
  padding-bottom: 2rem;


  line-height: 2.5rem;
}

.Entitycommonheadpara3 {
  color: white;
  text-align: justify;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  opacity: 1;
  font-size: 1.5rem;
  padding-bottom: .4rem;
  text-align: left;

  line-height: 2rem;
}

.Entitycommonheadpara2 {
  color: white;
  text-align: justify;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  opacity: .8;
  font-size: 1.5rem;
  padding-bottom: .8rem;


  line-height: 2rem;
}

.Entitycommonheadpara2 span {
  color: red;
  font-weight: bold;
}

#div2 {
  background: url(../../../Assets/Entity/Mask_Group_46_fo.png);
  height: 50vh;
  z-index: 122;

}

#viewdiv {
  background-image: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  text-align: center;
  width: fit-content;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: .5rem;
  color: white !important;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 0px -8px 20px 1px rgba(12, 5, 18, .85), 0px -8px 10px 1px rgb(3, 28, 57, .5);
}

.btnactive {
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  background-position: center;
  background-size: cover;
  transform: scale(1.2);
}

#Labslider {
  background: url(../../../Assets/Entity/Lab/Lab\ Card.svg);
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  align-items: center;
  filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 10px 28px 10px rgba(12, 5, 18, 0.95),
    8px -5px 10px 10px rgb(3, 28, 57, 1);
  height: auto;
  padding-bottom: 5rem;
  margin-left: 3rem;
  border-radius: 1rem;
}

.entitylabhead {
  font-family: "Saira", sans-serif;
  color: white;
  font-weight: 600;
  font-size: 2.5rem;
  text-align: center;

}

@media screen and(max-width:600px) {
  .Labbtn {
    display: hidden !important;
  }
}

.Labbtn {


  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  background-size: cover;
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.03));
  box-shadow: 1px 2px 6px .5px rgba(12, 5, 18, .85), 1px -2px 6px .5px rgb(3, 28, 57, 1);
  cursor: pointer;
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  text-align: center;
  border: none;

}

.Labbtn2 {


  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  background-size: cover;
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.03));
  box-shadow: 1px 2px 6px .5px rgba(12, 5, 18, .85), 1px -2px 6px .5px rgb(3, 28, 57, 1);
  cursor: pointer;
  border: none;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  text-align: center;
  border: none;

}

.Labbtn2 span {
  margin-top: -3rem;
  position: absolute;
}

.Labbtn span {
  margin-top: -3rem;
  position: absolute;

}

.Enitylabpara {
  color: gray;
  font-size: 1.4rem;
  font-family: "Saira", sans-serif;
}

.btn-slide {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f1f1f1;
  border: 1px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-slide img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}

.prev {
  top: 60%;
  left: 10%;
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg) !important;
  background-size: cover;
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.03));
  box-shadow: 1px 2px 6px .5px rgba(12, 5, 18, .85), 1px -2px 6px .5px rgb(3, 28, 57, 1);

  border: none;
  width: 3.4rem !important;
  height: 3.4rem !important;
  border-radius: 50%;
  text-align: center;
  border: none;
  position: absolute;

}

.next {
  top: 60%;
  right: 10%;
  transform: translateX(100%);
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg) !important;
  background-size: cover;
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.03));
  box-shadow: 1px 2px 6px .5px rgba(12, 5, 18, .85), 1px -2px 6px .5px rgb(3, 28, 57, 1);

  border: none;
  width: 3.4rem !important;
  height: 3.4rem !important;
  border-radius: 50%;
  text-align: center;
}



.container-dots {
  position: relative;
  bottom: 10px;
  left: 45%;
  margin-top: 5rem !important;

  top: 75%;
  display: flex;
}

.dot {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: none;

  margin: 5px 5px;
  background: #031c39;
  opacity: 1;
}

.dot.active {
  background-image: radial-gradient(circle, #e32f12, #e9282b, #ed233f, #ee2451, #ee2963);
}


.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.active-anim {
  opacity: 1;
}

#courselistview {
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  height: auto;
  width: 100%;

  filter: drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 5px 10px 3px rgba(12, 5, 18, .85), -4px 5px 10px 3px rgb(3, 28, 57, 1);
  border-radius: .4rem;
  margin-bottom: 5rem;
  z-index: 0;
  background-size: contain;
  transition: transform .5s;
  /* Animation */
}

.hoverdiv {
  width: 100%;
  min-height: 100vh;
  background: rgba(249, 245, 245, 0.05);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(20.0px);
  -webkit-backdrop-filter: blur(20.0px);
  border-radius: 10px;
  position: absolute;


  overflow-y: hidden;


}

.hoverdiv3 {
  width: 100%;
  min-height: 40vh;
  height: auto;
  background: rgba(249, 245, 245, 0.05);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(20.0px);
  -webkit-backdrop-filter: blur(20.0px);
  border-radius: 10px;
  position: fixed;


  overflow-y: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

}

.hoverdiv2 {
  background-color: red;
  height: auto;
  width: 80%;
  color: white;
  font-size: 1rem;
  position: absolute;
}

#courselistview2 {
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  height: auto;
  width: 100%;
  z-index: 103332;
  filter: drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 5px 10px 3px rgba(12, 5, 18, .85), -4px 5px 10px 3px rgb(3, 28, 57, 1);
  border-radius: .4rem;
  margin-bottom: 5rem;
  transition: transform .5s;
  /* Animation */
}

#Lablistview {
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  height: auto;
  width: 100%;
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 5px 15px 7px rgba(12, 5, 18, .85), -4px 5px 5px 7px rgb(3, 28, 57, 1);
  border-radius: .6rem;
  margin-bottom: 5rem;
  transition: transform .5s;
  /* Animation */
}

#Lablistview:hover {
  transform: scale(1.2);
}

.entitylabhead2 {
  font-family: "Saira", sans-serif;
  color: white;
  font-weight: 600;
  font-size: 2rem;
  text-align: left;
}

.lablistpara {
  font-family: "Saira", sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  text-align: justify;
  color: gray;
}

#coursegridview {
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  height: auto;
  width: 100%;
  filter: drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 10px 15px 3px rgba(12, 5, 18, .85), -4px 5px 15px 3px rgb(3, 28, 57, 1);
  border-radius: 1rem;
  margin-bottom: 3rem;
  background-size: cover;
}

#coursegridview:hover {
  transform: scale(1.2);

}

#coursegridviewsw:hover {
  transform: scale(1.1);
}

#courselistview:hover {
  transform: scale(1.2);
}

.lablogodiv {
  background-color: #021327;
  border-radius: .4rem;
  position: relative;
  padding: .2rem;
  text-align: center;
}

.courselogodiv {
  background-color: #021327;
  border-radius: 2rem;
  position: relative;

  text-align: center;
}
.mastercourseimage{
  position: absolute;
background-size: contain;
width: 100%;
height: auto;

}

.courselogodiv img {
  border-radius: 2rem;
}

.entitycoursegridhead {
  font-family: "Saira", sans-serif;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 2.4rem;
  margin-bottom: 2rem;
  text-align: left;
}

.entitycoursegridpara {
  color: grey;
  margin-top: .5rem;
  font-family: "Saira", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: justify;
  line-height: 1.2rem;

}

.Enitylinepara {
  font-size: 1rem;
  color: white;
  font-family: "Saira", sans-serif;
  font-weight: 600;
}

.Enitylinepara2 {

  color: gray;
  font-size: 1.4rem;
  font-family: "Saira", sans-serif;
  font-weight: 600;
  text-align: center !important;
  margin-top: 1rem;
}

#labdescratingdiv {
  background: lightcyan;
  padding: 3rem;
  margin-top: 2rem;
  padding-bottom: 4rem;
  height: 10vh;
}

.labdescpara {
  color: white;
  font-size: 1.4rem;
  justify-content: justify;
  text-align: justify;
  font-family: "Saira", sans-serif;
  line-height: 3rem;
  font-weight: 500;
  margin-top: -4rem;
}

.labdescip {
  font-size: 5rem;
  font-family: "Saira", sans-serif;
  font-weight: 600;
  color: white;
}

.labnetwrkhead {
  font-family: "Saira", sans-serif;
  color: white;
  font-weight: 600;
  font-size: 2.1rem;
  letter-spacing: .2rem
}

.labreviewshead {
  font-family: "Saira", sans-serif;
  color: white;
  font-weight: 500;
  font-size: 2rem;
  letter-spacing: .1rem
}

.labnetwrkpara {
  color: white;
  font-size: 1.4rem;
  line-height: 1.8rem;


  font-family: "Saira", sans-serif;
  font-weight: 500;
  letter-spacing: 0 !important;

  line-height: 3rem;



}

.labnetwrkpara2 {
  color: white;
  font-size: 1.4rem;
  line-height: 1.8rem;
  list-style-image: url(../../../Assets/images/Activity3.svg);

  font-family: "Saira", sans-serif;
  font-weight: 500;
  letter-spacing: 0 !important;
  margin: 1rem 2rem;
  line-height: 3rem;
  text-align: justify;


}

.labnetwrkpara2 {
  color: white;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-family: "Saira", sans-serif;
  font-weight: 500;
  letter-spacing: 0 !important;

  line-height: 3rem;



}

#netwrkinterfacediv {
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  padding: 2rem;
  height: auto;
  border-radius: 1rem;
  width: 100%;
  background-size: cover;
  filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 10px 28px 10px rgba(12, 5, 18, 1),
    8px -5px 10px 10px rgb(3, 28, 57, 1);
}

.netwrkprehead {
  color: white;
  font-family: "Saira", sans-serif;
  font-weight: 600;
  text-align: center;
  font-size: 3rem;
  letter-spacing: .2rem;
}

.reviewshr {
  width: 100%;
  background-color: red;
  height: .1rem;
  border-radius: .2rem;
}

.reviewshr2 {
  width: 100%;
  background-color: red;
  height: 0.3rem;
  border-radius: .2rem;

}

.labreviewhead {
  color: white;
  font-family: "Saira", sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  letter-spacing: 0rem;
  padding-left: 1rem;
  text-overflow: ellipsis;
  text-transform: capitalize;
  display: inline-block;
}

.labreviewhead2 {
  display: inline-block;
  font-size: 1rem;
  color: gray;
  font-family: "Saira", sans-serif;
  font-weight: 400;
  padding-left: 2rem;
}

.labreviewpara {
  color: gray;
  font-family: "Saira", sans-serif;
  font-weight: 600;
  font-size: 1.2rem;

}

#courseratingdiv {
  background: url(../../../Assets/Entity/Lab/Ratings\ Bg.svg);
  height: auto;
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.03));
  box-shadow: -8px 5px 20px 3px rgba(12, 5, 18, .85), -4px -5px 20px 3px rgb(3, 28, 57, 1);
  padding: 3rem;
  width: 100%;
  margin: 1rem;
  transform: translateY(-50%);
  background-size: cover;
  border-radius: 1.2rem;
}

#coursescorediv {
  background: url(../../../Assets/Entity/Lab/Subscribers.svg);
  height: auto;
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 5px 20px 3px rgba(12, 5, 18, .85), -4px -5px 20px 3px rgb(3, 28, 57, 1);
  padding: 1rem 5rem;
  width: 100%;
  margin: 1rem;
  transform: translateY(-50%);
  background-size: cover;
  border-radius: 1.2rem;
}

#labdescratingdiv2 {
  background: url(../../../Assets/Entity/Lab/Ratings\ Bg.svg);
  height: auto;

  filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.03));
  box-shadow: 8px -5px 10px 10px rgba(12, 5, 18, 0.95),
    8px -5px 10px 10px rgb(3, 28, 57, 1);
  width: 100%;
  position: absolute;

  padding: 3.5rem;
  display: block;
  margin-top: 7rem;
  background-size: cover;
  border-radius: 1rem;
}

.submitflagbtn {

  background-image: linear-gradient(to right bottom,
      #e32f1c,
      #e82930,
      #ec2542,
      #ee2553,
      #ee2963);
  color: white;
  cursor: pointer;
  padding: 1rem;

  border-radius: .7rem;
  font-family: "Saira", sans-serif;
  text-align: center;
  font-weight: 600;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, .03));
  box-shadow: 0px 5px 20px 5px rgba(12, 5, 18, .85), 8px -5px 24px 5px rgba(3, 28, 57, 1);
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: center;
}

.enterpreyhead {
  font-family: "Saira", sans-serif;
  color: white;
  font-weight: 600;
  padding-top: .5rem;
  text-transform: uppercase;
  font-size: 1.3rem;
  letter-spacing: 0rem;
}

.enterpreyhead2 {
  font-family: "Saira", sans-serif;
  color: white;
  font-weight: 600;
  padding-top: .5rem;
  font-size: 1.6rem;
  letter-spacing: 0rem;
}

#writereviewbtn {
  background-image: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  color: white;
  padding: 1rem;
  cursor: pointer;
  border-radius: .15rem;
  font-family: "Saira", sans-serif;
  text-align: center;
  font-weight: 600;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, .03));
  box-shadow: 0px 5px 10px 5px rgba(12, 5, 18, .85), 8px -5px 14px 5px rgba(3, 28, 57, 1);
  font-size: 1.2rem;
  text-transform: uppercase;
}

.ratingdivhead {
  color: white;
  font-family: "Saira", sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  transform: translateY(-70%);
}

.ratingdivhead2 {
  color: white;
  font-family: "Saira", sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  margin-top: .6rem;

}

.text-align-left {
  text-align: left;
}

.Chapterhead1 {
  color: white;
  font-family: "Saira", sans-serif;
  font-weight: 600;
  text-align: left;
  font-size: 2.7rem;
  margin-top: 2rem;


}

.Chapterhead2 {
  color: white;
  font-family: "Saira", sans-serif;
  font-weight: 600;
  text-align: left;
  font-size: 2.7rem;
  margin-top: -8rem;


}

.chapterdescpara {
  color: white;
  font-size: 1.4rem;
  font-family: "Saira", sans-serif;
  text-align: justify;
  font-weight: 500;

  line-height: 2.5rem;
}

.gotochapterbtn {
  padding: 1rem;
  background-image: linear-gradient(to right bottom,
      #e32f1c,
      #e82930,
      #ec2542,
      #ee2553,
      #ee2963);
  color: white;
  font-family: "Saira", sans-serif;
  font-weight: 600;
  border-radius: .7rem;
  text-transform: uppercase;
  font-size: 1rem;
}

.lockspan {
  padding: .1px 1.2rem;
  border-radius: 50%;

  background-image: linear-gradient(to right bottom,
      #e32f1c,
      #e82930,
      #ec2542,
      #ee2553,
      #ee2963);
}

.coursevideodiv {
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  background-position: center;
  background-repeat: repeat;
  height: auto;
  width: 100%;
  background-size: cover;
  margin-top: 7rem;
  margin-bottom: 0rem !important;
  border-radius: 1.4rem !important;
  transform: translateY(-60%);
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 10px 20px 5px rgba(12, 5, 18, .85), 8px -5px 20px 5px rgb(3, 28, 57, 1);
}

#resourcecard {
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  background-position: center;
  background-repeat: repeat;
  height: auto;
  width: auto;
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 5px 20px 3px rgba(12, 5, 18, .85), -4px -5px 20px 3px rgb(3, 28, 57, 1);



  position: relative;
  background-size: cover;
  border-radius: 1rem;
}

#scorecard {
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  background-position: center;
  background-repeat: repeat;
  height: auto;
  width: auto;
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 5px 20px 3px rgba(12, 5, 18, .85), -4px -5px 20px 3px rgb(3, 28, 57, 1);



  position: relative;
  background-size: cover;
  border-radius: 1rem;

}

.resourcecard2 {
  background: url(../../../Assets/Entity//Lab/Mask_Group_43_ef@2x.png);
  background-repeat: no-repeat;
  height: auto;
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 5px 10px 3px rgba(12, 5, 18, .85), -4px 5px 10px 3px rgb(3, 28, 57, 1);

  background-size: cover;
  background-position: right;
  border-radius: 1rem;

}

.scorecard2 {
  background: url(../../../Assets/Entity//Lab/Mask_Group_31@2x.png);
  background-repeat: no-repeat;
  height: auto;
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 5px 10px 3px rgba(12, 5, 18, .85), -4px 5px 10px 3px rgb(3, 28, 57, 1);

  background-size: cover;
  background-position: right;
  border-radius: 1rem;

}

.ratingcard2 {
  background: url(../../../Assets/Entity//Lab/Mask_Group_42_e@2x.png);
  background-repeat: no-repeat;
  height: auto;
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 5px 10px 3px rgba(12, 5, 18, .85), -4px 5px 10px 3px rgb(3, 28, 57, 1);

  background-size: cover;
  background-position: right;
  border-radius: 1rem;

}

.Raviewscard2 {
  background: url(../../../Assets/Entity/Lab/Resources\ Bg.svg);
  background-repeat: no-repeat;
  height: auto;
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 5px 10px 3px rgba(12, 5, 18, .85), -4px 5px 10px 3px rgb(3, 28, 57, 1);
  width: auto;
  background-size: cover;
  background-position: right;
  border-radius: 1rem;
}

.Reviewcard3 {
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  background-repeat: no-repeat;
  height: auto;
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 5px 10px 3px rgba(12, 5, 18, .85), -4px 5px 10px 3px rgb(3, 28, 57, 1);

  background-size: cover;
  background-position: right;
  border-radius: 1rem;
}

.resourcehead {
  color: white;
  letter-spacing: 0rem;
  font-family: "Saira", sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
}

.challscore {
  color: #e9282b;
  font-family: "Saira", sans-serif;
  letter-spacing: 0rem;
  font-size: 2.5rem;

}

.reviewdetail {
  border: 2px solid red;
  width: 100%;
  border-radius: 1rem;
  padding: auto;
}

.reviewchptrname {
  color: white;
  font-size: 1.3rem;
  font-family: "Saira", sans-serif;
  font-weight: 600;
  text-transform: capitalize;
}

.reviewcardspan {
  display: inline;
  color: white;
  text-overflow: ellipsis;
  font-family: "Saira", sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 1.2rem;
}

.reviewpara {
  color: gray;
  font-size: 1rem;
  font-family: "Saira", sans-serif;
  font-weight: 500;
}

.challengegridcard:hover {
  transform: scale(1.2);
}

.challengegridcard {
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  border-radius: 1rem;
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 5px 15px 2px rgba(12, 5, 18, .85), -4px 5px 5px 2px rgb(3, 28, 57, 1);
}

#cardimgback {
  background-color: #021327;
  border-radius: .4rem;
  position: relative;
  padding: .1rem;
  height: auto;
  width: 100%;
  text-align: center;
}

.cardimback2 {
  background: url(../../../Assets/Entity/Challenges/Challenges\ \(1\).svg);
  height: auto;
  background-repeat: no-repeat;
  padding: 7rem;
}

.cardchalhead {
  color: white;
  font-size: 1.7rem;
  text-align: center;
  text-transform: uppercase !important;
  font-family: "Saira", sans-serif;
  font-weight: 600;
  font-style: bold;
  text-overflow: ellipsis;
  font-style: bold;
  margin-top: 1.5rem;
}

.cardchalpara {
  color: grey;
  font-size: 1rem;
  font-family: "Saira", sans-serif;
  font-weight: 600;
}

.challengelistcard {
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  border-radius: 1rem;
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 5px 15px 2px rgba(12, 5, 18, .85), -4px 5px 5px 2px rgb(3, 28, 57, 1);
}

.challengelistcard:hover {
  transform: scale(1.2);
}

.challisthead {
  font-size: 2.3rem;
  color: white;
  font-family: "Saira", sans-serif;
}

.videodiv {
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  width: 100%;
  border-radius: .5rem;
  transform: translateY(-30%);
 position: relative;
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.03));
  box-shadow: 5px 5px 5px 2px rgba(12, 5, 18, .85), 4px -5px 15px 5px rgb(3, 28, 57, 1);
}

.videodiv2 {
  position: absolute;
  height: 70%;
  width: 20%;

  -webkit-animation: x 13s linear infinite alternate;
  animation: x 13s linear infinite alternate;
}

.words {
  color: lightgray;
  z-index: -333;
  opacity: 0.4;
  font-size: 0;
  line-height: 0;

}

.words span {
  font-size: 2.5rem;
  display: inline-block;
  /*animation: move 5s ease-in-out infinite;*/
  -webkit-animation: y 7s linear infinite alternate;
  animation: y 7s linear infinite alternate;
}

@-webkit-keyframes x {
  100% {
    transform: translateX(calc(50vw - 20px));
  }
}

@keyframes x {
  100% {
    transform: translateX(calc(40vw - 30px));
  }
}

@-webkit-keyframes y {
  80% {
    transform: translateY(calc(50vh - 25px));
  }
}

@keyframes y {
  20% {
    transform: translateY(calc(50vh - 30px));
  }
}

@keyframes move {
  0% {
    transform: translateY(-30%, 0);
    transform: scale(1.6);
  }

  50% {
    text-shadow: 0 25px 50px rgba(0, 0, 0, 0.75);
    transform: scale((1.4));
    transform: translateX(-70%, 0);
  }

  100% {
    transform: translate(30%, 0);
    transform: scale(1.2);
  }
}

.words span:nth-child(2) {
  animation-delay: 0.5s;
}

.words span:nth-child(3) {
  animation-delay: 1s;
}

.words span:nth-child(4) {
  animation-delay: 1.5s;
}

.words span:nth-child(5) {
  animation-delay: 2s;
}

.words span:nth-child(6) {
  animation-delay: 2.5s;
}

.words span:nth-child(7) {
  animation-delay: 3s;
}


#chapterdiv2 {
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  overflow-y: scroll;
  width: 100%;
  margin-top: 7rem;
  padding-bottom: 5rem;
  margin-bottom: 4rem;
  border-radius: .5rem;


  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.03));
  box-shadow: 5px 5px 5px 2px rgba(12, 5, 18, .85), 4px -5px 15px 2px rgb(3, 28, 57, 1);
}

#chapterdiv {


  height: fit-content;
  width: 100%;

  padding-bottom: 2rem;
  border-radius: .5rem;
  transform: translateY(-18%);
  position: sticky;


}

#chapterdescresourcediv {
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  background-repeat: no-repeat;
  background-size: cover;
  height: fit-content;

  padding-bottom: 2rem;
  border-radius: .5rem;
  transform: translateY(-100%);

  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.03));
  box-shadow: 5px 5px 5px 2px rgba(12, 5, 18, .85), 4px -5px 15px 5px rgb(3, 28, 57, 1);
}

.chapterdivhead {
  color: white;
  padding: 2rem;
  font-size: 1.6rem;
  font-family: "Saira", sans-serif;
  font-weight: 600;


}

#activechapter {
  background: url("../../../Assets/Transaction/Rectangle\ 1134.svg");
  margin-top: 2rem;
  padding: .2rem 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;



}

.chapternumberinghead {
  color: white;
  font-family: "Saira", sans-serif;
  font-size: 1.7rem;
  font-weight: 500;
  transform: translateY(1.4rem);
}

.chapterhead {
  color: white;
  font-family: "Saira", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  transform: translateY(1.2rem);
  margin-bottom: 2rem;
}

.chapterheadspan {
  font-family: "Saira", sans-serif;
  font-size: 1rem;
  color: gray;
  font-weight: 400;
  font-style: italic;

}

.chapterdot {

  transform: translateY(.5rem);
  height: 2rem;
}

#dactivechapter {
  background: url("../../../Assets/Transaction/Rectangle\ 1134.svg");
  margin-top: 2rem;
  padding: .1rem;
  border-radius: 1rem;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  border: none;
  box-shadow: 2px 10px 20px 2px rgba(12, 5, 18, .85), 8px -5px 20px 2px rgb(3, 28, 57, 1);
}

.chapterdescdiv {
  margin: 0;
  transform: translateY(-10%);

  height: auto;

}

#reddiv {
  background-color: #57111D;
  opacity: .8;
  border-radius: .8rem;
  padding: 1rem;
}

#reddivhead {
  color: white;
  opacity: 3;
  font-size: 1.2rem;
  padding: .5rem;
  margin-top: .4rem;
  text-overflow: ellipsis !important;
  z-index: 44;
  font-family: "Saira", sans-serif;
  font-weight: 600;
}

.descdiv {
  width: 100vw;
  min-height: 100vh;
  background: rgba(249, 245, 245, 0.05);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(20.0px);
  -webkit-backdrop-filter: blur(20.0px);
  border-radius: 10px;

  position: relative;

  overflow-y: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

#desdcdiv2 {
  background-color: red;
  color: white;
  position: fixed;

}


/*slider */
#container-slider {
  width: auto;
  height: 80vh;
  border-radius: 1rem;
  background-repeat: no-repeat;
  background-size: contain;
  background: url("../../../Assets/Entity/Lab/Lab\ Card.svg");
  text-align: center;
  position: relative;
  z-index: 0;

  overflow: hidden;

}

@media screen and (max-width: 700px) {
  #container-slider {
    margin: 100px 10px 0;
  }
}

.slide {
  width: auto;
  height: auto;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 2s;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.active-anim {
  opacity: 1;
}







/* video portion starts*/
.coursenamevideo {
  font-family: "Saira", sans-serif;
  font-weight: 600;
  font-size: 2.5rem;
  color: white;
  padding-left: 3rem;
}

#videocard {
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  height: 40vh;
  border-radius: 3rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 1rem;
  width: 100%;

}

.challengecarimg {
  height: 60rem;
  transform: translateY(15%);
  animation-duration: 1s;
  animation-name: zooming;
  animation-iteration-count: infinite;
}

@keyframes zooming {
  80% {
    transform: rotate(0deg);
  }

  90% {
    transform: scale(1.1) rotate(0deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.coursepaidornothead {
  color: white;
  font-size: 2rem;
  font-family: "Saira", sans-serif;
  font-weight: 600;
  justify-content: center;
  padding-top: .8rem;
}

.coursepaidornothead2 {
  color: white;
  font-size: 1.7rem;
  font-family: "Saira", sans-serif;
  font-weight: 600;
  justify-content: center;
  padding-top: 1rem;
}

/* HAvoc Page css */
.havocbtn {
  background-color: none;
  color: white;
  padding: 1rem;
  cursor: pointer;
  border-radius: .15rem;
  font-family: "Saira", sans-serif;
  text-align: center;
  font-weight: 600;
  border: 2px solid #ee2550;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin: 1rem;
}

.teamprofilephotodiv {
  background-color: none;
  color: white;
  padding: 7rem;
  cursor: pointer;
  border-radius: 1rem;
  font-family: "Saira", sans-serif;
  text-align: center;
  font-weight: 600;
  border: 2px solid #ee2963;

  text-transform: uppercase;
  margin: 0rem;

}

.topbtn {
  padding: 2rem 4rem;
  background: #01152b;
  background: -webkit-radial-gradient(top left, #01152b, #040b18);
  background: -moz-radial-gradient(top left, #01152b, #040b18);
  background: radial-gradient(to bottom right, #01152b, #040b18);
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-family: "Saira", sans-serif;
  cursor: pointer;



}

.topbtn2 {
  padding: 2rem 4rem;
  background-color: none;
  color: #003D69;
  border-bottom: 2px solid #ee2963;
  text-align: center;
  text-transform: uppercase;
  font-family: "Saira", sans-serif;
  cursor: pointer;

}

.topbtn2ctf {
  padding: 2rem 4rem;
  background-color: none;
  color: #003D69;
  border-bottom: 2px solid #0068C6;
  text-align: center;
  text-transform: uppercase;
  font-family: "Saira", sans-serif;
  cursor: pointer;

}

#editteambtn {
  padding: 1rem 1rem;
  background-image: linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);
  color: white;
  width: 100%;
  border-bottom: 2px solid #ee2963;
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-family: "Saira", sans-serif;
  cursor: pointer;

}

.editteambtn {
  padding: 1rem 1rem;
  background-image: linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);
  color: white;
  width: 100%;
  border-bottom: 2px solid #ee2963;
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-family: "Saira", sans-serif;
  cursor: pointer;

}

#editteambtn2 {
  padding: 1rem 1rem;
  background-image: linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);
  color: white;
  width: 100%;
  border-bottom: 2px solid #ee2963;
  text-align: center;
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-family: "Saira", sans-serif;
  cursor: pointer;

}

.havocbtn2 {

  color: white;
  padding: 1rem;
  cursor: pointer;
  border-radius: .15rem;
  font-family: "Saira", sans-serif;
  text-align: center;
  font-weight: 600;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, .03));
  box-shadow: 0px 5px 10px 5px rgba(12, 5, 18, .85), 8px -5px 14px 5px rgba(3, 28, 57, 1);
  font-size: 1.2rem;
  text-transform: uppercase;
  border-bottom: 2px solid #e32f12;
  border-left: 2px solid #e32f12;
  margin: 1rem;

}

.havocbtn3 {
  background-image: linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);
  color: white;
  padding: 1rem;
  cursor: pointer;
  border-radius: .15rem;
  font-family: "Saira", sans-serif;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.2rem;
  border-radius: .4rem;


  margin: 1rem;

}

#havocontainer {
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  padding: 2rem;
  border-radius: 3rem;
  margin-bottom: 4rem;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, .03));
  box-shadow: 0px 5px 10px 5px rgba(12, 5, 18, .85), 8px -5px 14px 5px rgba(3, 28, 57, 1);
}

#havocontainer h1 {
  color: white;
  text-align: center;
  padding-top: 1.2rem;
  font-family: "Saira", sans-serif;
  font-size: 3rem;
  font-weight: 600;
  letter-spacing: .2rem;
}

.teamnamehead {
  color: white;
  text-align: left;
  padding-top: 1.2rem;
  font-family: "Saira", sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0rem;
}

.teamrowhead {
  color: white;
  text-align: left;
  padding-top: 2rem;
  padding-left: 3rem;
  font-family: "Saira", sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0rem;
}

.teamrowhead2 {
  color: grey;
  text-align: left;
  padding: 1rem;
  font-family: "Saira", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0rem;


}

.teamrowhead3 {
  color: grey;
  text-align: left;
  padding-left: 24px;

  padding: 1rem;
  font-family: "Saira", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0rem;


}

.teamnamepara {
  color: gray;
  text-align: left;
  margin-top: -1rem;
  padding-top: .4rem;
  font-family: "Saira", sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0rem;
}

.eventhead {
  color: white;
  text-align: center;
  padding-top: 1.2rem;
  font-family: "Saira", sans-serif;
  font-size: 2rem;
  font-weight: 600;

}

.eventhead2 {
  color: white;
  text-align: left;
  padding: .9rem;
  padding-top: .7rem;
  font-family: "Saira", sans-serif;
  font-size: 2rem;
  font-weight: 600;
}

.eventpara {
  color: white;
  text-align: center;
  padding-top: 0.1rem;
  font-family: "Saira", sans-serif;
  font-size: 2rem;
  font-weight: 500;

}

.eventpara2 {
  color: lightgrey;
  text-align: left;
  padding-top: .3rem;
  padding-left: 4.7rem;
  margin-top: -2rem;
  font-family: "Saira", sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
}

.havoclist {
  color: lightgrey;
  text-align: left;
  font-family: "Saira", sans-serif;
  font-size: 1.7rem;
  font-weight: 500;
}

.overviewhead {
  color: white;
  text-align: center;
  padding-top: 0rem;
  font-family: "Saira", sans-serif;
  font-size: 3rem;

  font-weight: 600;
  letter-spacing: .2rem;
}

.overviewhead2 {
  color: white;
  text-align: left;
  padding-top: 0rem;
  font-family: "Saira", sans-serif;
  font-size: 2.6rem;
  font-weight: 600;
  letter-spacing: .1rem;
}

#havocbountycard {
  background: url("../../../Assets/Transaction/Rectangle\ 1134.svg");
  background-size: cover;
  background-position: center;
  padding: 0rem;
  color: white;
  margin: 2rem;
  border-radius: 1rem;
  border: 4px solid transparent;
  border-top: none;
  border-bottom: none;
  -moz-border-image: -moz-linear-gradient(to top, #003d69, #025e8f, #0080b5, #00a5db, #00ccff);
  -webkit-border-image: -webkit-linear-gradient(to top, #003d69, #025e8f, #0080b5, #00a5db, #00ccff);
  border-image: linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);

  border-image-slice: 1;

}

#havocbountycard2 {
  background: url("../../../Assets/Transaction/Rectangle\ 1134.svg");
  background-size: cover;
  background-position: center;
  padding: 1rem;
  color: white;
  margin: 2.4rem;
  border-radius: .3rem;
  border: 4px solid transparent;


  border-image: linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);

  border-image-slice: 1;




}

.havocpagelinediv {
  background-repeat: no-repeat;
  background-image: url(/src/Assets/Entity/Website\ Havoc/asset.svg);
  height: auto;
  width: 100%;
  background-size: contain;
  background-position-x: right;

  background-position-y: bottom;
}

.havoclinediv {
  background-repeat: no-repeat;
  background-image: url(/src/Assets/Entity/Website\ Havoc/hhlogo2.png);
  height: auto;
  width: 100%;
  background-size: cover;

  background-position-x: right;

  background-position-y: bottom;
}

#havocsubmitbtn {
  text-align: center;
  background-image: linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);

  padding: .5rem;
  border-radius: .4rem;

}

#havocsubmitbtn button {
  text-decoration: none;
  font-family: "Saira", sans-serif;
  font-size: 1.4rem;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
}

.havocpara {
  color: white;
  font-family: "Saira", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  padding: 0rem;
  text-align: justify;
}

.havochr {
  height: .3rem;
  width: 17rem;
  justify-content: center;

  ;
  background: linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);
  border-radius: 1rem;
}

.photodiv {
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  height: auto;
  width: 100%;
  padding: 10rem;
  border-radius: 3rem;
  background-size: cover;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, .03));
  box-shadow: 0px 5px 10px 5px rgba(12, 5, 18, .85), 8px -5px 14px 5px rgba(3, 28, 57, 1);
}

#teamprofilecard {
  background: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;

  padding: 2rem;

  margin-bottom: 4rem;
  border: 2px solid transparent;

  -moz-border-image: -moz-linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);
  -webkit-border-image: -webkit-linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);
  border-image: linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);

  border-image-slice: 1;
  border-radius: 6rem !important;


}

#hoverprofilecard {
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  background-position: center;
  background-repeat: repeat;
  height: auto;
  width: 100%;
  background-size: cover;
  margin-top: 2rem;
  margin-bottom: 3rem;
  border-radius: 2rem !important;
  border: 2px solid #e32f12;
  padding-bottom: 0rem;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 10px 20px 5px rgba(12, 5, 18, .85), 8px -5px 20px 5px rgb(3, 28, 57, 1);

}

#teamrow {
  background: none;

  margin-top: 1rem;
  padding: 1.5rem;
  border: 2px solid transparent;

  -moz-border-image: -moz-linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);
  -webkit-border-image: -webkit-linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);
  border-image: linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);

  border-image-slice: 1;
  border-image-width: 1;

}

#teamrow2 {
  background: none;

  margin-top: 2.5rem;

  border-radius: 1rem;
  border: 1px solid #ee2963;

}

#teamrowhead {
  color: white;
  font-size: 2rem;
  font-family: "Saira", sans-serif;
  font-weight: 600;
  text-align: center;
}

.havoc-input label {
  color: white;
  font-size: 1.3rem;
  font-family: "Saira", sans-serif;
  font-weight: 600;
  margin-bottom: .3rem;
  margin-left: .5rem;
}

.rankteamrow {
  color: white;
  font-size: 1.8rem;
  font-family: "Saira", sans-serif;
  font-weight: 600;
  margin-bottom: .3rem;
  text-align: center;
  text-transform: uppercase;

}

.havoc-input select {
  width: 100%;


  height: auto;

  margin-bottom: 2rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 2rem;

  background: url(../../../Assets/Dashboard/down-arrow.png) 97% / 2% no-repeat #eee;
  color: grey !important;
  background-color: #000512;
  border: 2px solid transparent;
  border-top: none;
  border-bottom: none;
  -moz-border-image: -moz-linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);
  -webkit-border-image: -webkit-linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);
  border-image: linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);

  border-image-slice: 1;
  border-image-width: 3;

  font-family: "Saira", sans-serif;

  cursor: pointer;
  box-shadow: inset -1.5px -1px 6px .5px rgba(51, 43, 130, .7), inset 4px 4px 4px rgba(0, 0, 0, 0.9);


  font-weight: 600;
  font-size: 1.2rem;
}

.havoc-input input[type="text"] {
  display: block;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;

  padding: 2.5rem;
  box-shadow: inset -1.5px -1px 6px .5px rgba(51, 43, 130, .7), inset 4px 4px 4px rgba(0, 0, 0, 0.9);
  margin-bottom: 2rem;

  border: 2px solid transparent;
  border-top: none;
  border-bottom: none;
  -moz-border-image: -moz-linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);
  -webkit-border-image: -webkit-linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);
  border-image: linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);

  border-image-slice: 1;
  border-image-width: 3;

  font-family: "Saira", sans-serif;
  color: white !important;
  font-weight: 500;
}

.havoc-input input[type="text"] :focus {
  font-size: 2rem !important;
}

/*uni details page*/

#unislotscard {
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  background-position: center;
  background-repeat: repeat;
  height: auto;
  width: 100%;
  background-size: cover;
  margin: 3rem;
  border-radius: .1rem !important;
  border: 2px solid transparent;
  border-top: none;
  border-bottom: none;
  -moz-border-image: -moz-linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);
  -webkit-border-image: -webkit-linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);
  border-image: linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);

  border-image-slice: 1;
  border-image-width: 1;
  padding-bottom: 0rem;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.03));
  box-shadow: 2px 10px 20px 5px rgba(12, 5, 18, .85), 8px -5px 20px 5px rgb(3, 28, 57, 1);

}

.unicardphoto {
  background-image: linear-gradient(60deg,
      #ee2963,
      #ee265a,
      #ee2550,
      #ed2547,
      #eb263d,
      #e92833,
      #e62b28,
      #e32f1c);
  color: white;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  transform: translateY(-40%);
}

.unicardphoto h1 {
  position: absolute;
  text-align: center;
}

.unislotshead {
  font-family: "Saira", sans-serif;
  color: white !important;
  font-weight: 500;
  text-align: center;
  font-size: 2.5rem;
  text-transform: uppercase;
}

.unislotshead2 {
  font-family: "Saira", sans-serif;
  color: gray !important;
  font-weight: 500;
  text-align: center;
  font-size: 2.5rem;
  text-transform: uppercase;
  padding-bottom: 3rem;
}

.unislotshead3 {
  font-family: "Saira", sans-serif;
  color: white !important;
  font-weight: 400;
  text-align: center;
  margin-top: 2rem;
  font-size: 1.7rem;
  text-transform: capitalize;
  padding-bottom: 3rem;
  line-height: 3rem;
}

/*chapterdesc css*/
.chapterdesc-container {

  display: flex;

  gap: 1rem;

  justify-content: right;

  font-family: 'Saira', sans-serif;





}

.chapterdescslider {

  width: 30%;

  height: 100vh;

  overflow-y: scroll;

  color: white;

  padding-top: 10rem;

  font-size: 1.5rem;

  font-family: 'Saira', sans-serif;

  padding-bottom: 1rem;

}



.chapter-slider-component {
  display: flex;
  flex-direction: column;
  text-align: left;
  cursor: pointer;
  padding-top: .8rem;

  border-radius: .5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding-bottom: 1rem;
  border: 1px solid gray;
  color: white;
  font-family: 'Saira', sans-serif;
  font-weight: 700;
  font-size: 1.3rem;
  background-color: transparent;
  width: 100%;
}



.chaptervideo-container {





  color: white;

  overflow-y: scroll;

  padding-top: 12rem;

  height: 100vh;

  overflow-x: hidden;

  font-size: 2.5rem;

  margin-left: 5rem;

  padding-right: 2rem;

  width: 70%;





}

.chaptervideo-title {

  font-weight: 600;



}

.border-class {
  border: 1px solid #b71c1c;
}


.chaptervideo-video {

  margin-top: 1rem;

  width: 100%;

  height: 62rem;



}

.chaptervideo-resources {

  justify-content: center;

  margin-top: 1.5rem;

  display: flex;

  align-items: center;

  gap: 6rem;

  font-size: 2rem;

}

.red-underline {

  border-bottom: 2px solid #01152b;

  cursor: pointer;

  padding-bottom: 1rem;

}

.chapter-video-overview {

  padding-bottom: 5rem;

  margin-top: 2rem;
  ;

  width: 100%;

  font-size: 1.6rem;

  text-align: justify;

}

.chapter-video-overview-title {

  font-size: 2.3rem;

  color: #f44336;

  margin-bottom: 3rem;

}

.resource-file-download {



  color: #FFFFFF;

  border-radius: 5px;

  padding-top: 1rem;

  padding-bottom: 1rem;

  padding-right: 2rem;

  padding-left: 2rem;

  background-color: #f44336;

  text-decoration: none;

}

.resource-file-download :hover {

  text-decoration: none;

}

.review-buuton {

  display: inline;

  cursor: pointer;

}

.flex {

  display: flex;

  gap: 4rem
}

.video-component-header {

  display: flex;

  justify-content: space-between;

  align-items: center;

}

.video-component-header-button {

  display: flex;

  gap: 1.5rem;

}

.previous-button {

  cursor: pointer;

  padding-left: 1rem;

  padding-right: 1rem;

  color: white;

  display: flex;

  gap: 0.3rem;

  align-items: center;

  background-color: #ef5350;

  font-size: 2rem;

  border-radius: 2px;

}

.next-button {

  cursor: pointer;

  padding-left: 1rem;

  padding-right: 1rem;

  color: white;

  display: flex;

  gap: 0.3rem;

  align-items: center;

  background-color: #ef5350;

  font-size: 2rem;

  border-radius: 2px;

}

.font-bold {

  font-weight: 900;

}

.slider-background {

  background-image: linear-gradient(to right bottom,
      #e32f1c,
      #e82930,
      #ec2542,
      #ee2553,
      #ee2963);
  border: none;
  width: 100%;
  border-radius: .5rem;
  margin-top: .3rem;
  font-family: 'Saira', sans-serif;

  font-weight: 700;







}

@media (max-width: 1200px) {



  .chaptervideo-container {

    font-size: 1.9rem
  }

  .chaptervideo-title {

    font-size: 1.9rem
  }

  /* Styles for screens between 768px and 1024px */

}

@media (max-width: 1024px) {

  .chaptervideo-container {

    width: 65%;

  }

  .chaptervideo-video {

    height: 40rem;

  }



  /* Styles for screens between 768px and 1024px */

}

@media (max-width: 960px) {

  .chaptervideo-container {

    width: 65%;

  }

  .chaptervideo-video {

    height: 40rem;

  }



  /* Styles for screens between 768px and 1024px */

}

@media (max-width: 886px) {

  .chaptervideo-container {

    width: 93%;

  }

  .chaptervideo-video {

    height: 40rem;

  }

  .chapterdescslider {

    width: 98%;

  }

  .chapterdesc-container {

    display: flex;

    flex-direction: column;

  }




  /* Styles for screens between 768px and 1024px */

}

.nowplayhead {
  color: white;

  font-family: 'Saira', sans-serif;

  font-weight: 500;
  font-size: 2.3rem;
  text-transform: capitalize;
  padding-top: 4rem;
}

.nowplayhead span {
  font-weight: 700;
  font-family: 'Saira', sans-serif;
  color: white;
  font-size: 2.3rem;
}

.nextbtn {
  background-image: linear-gradient(to right bottom,
      #e32f1c,
      #e82930,
      #ec2542,
      #ee2553,
      #ee2963);
  font-weight: 700;
  font-family: 'Saira', sans-serif;
  color: white;
  padding: 1rem 2rem;
  width: 12rem;
  text-transform: uppercase;
}

#stickycoursediv {
  position: sticky !important;
  top: 0;
}

.leaderboardrank {
  text-align: left;
}

.leaderboard-text-right {
  text-align: right;
}

.leaderboard-text-center {
  text-align: center;
}

.course-section {
  color: #FFFFFF;
}

.section-box {
  width: 100%;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 24px;
  border-radius: 6px;

  color: white;
  background-color: #000512;
  padding-top: 1rem;


}

.dash-course-section-heading {
  background-color: #000512;
  padding-bottom: 1.3rem;
  padding-right: 1.2rem;
  padding-left: 1.2rem;
  padding-top: 0.8rem;
  display: flex;
  font-size: 2rem;
  justify-content: space-between;
  align-items: center;
}


.section-box-chapter-link {
  color: gray;

  text-decoration: none;
}

.section-box-chapter-link:hover {
  color: #eeeeee;

  text-decoration: none;
}

.section-chapter-container {
  background-color: #071122;
  height: 100%
}

.section-box-chapter {
  font-size: 1.7rem;
  padding-right: 15px;
  text-decoration: none;
  color: gray;
  padding-left: 0.9rem;
  padding-right: 0.5rem;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  display: flex;
  align-items: center;
  gap: 10px;


}

.section-box-chapter:hover {
  background-color: #000512;
  color: #eeeeee;
  text-decoration: none;
}

.section-heading {
  font-weight: 700;
}

.section-hide {
  display: hidden
}

.course-slider-section-header {
  background-color: red;
}

.section-slider-head {
  font-weight: 900;
  color: white;
  padding-left: 1rem;
}

.section-slider-head-selected {
  font-weight: 900;
  color: red;
  padding-left: 1rem;
}

.section-slider-chapter {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  color: gray;


}

.section-slider-chapter:hover {
  background-color: #151b2d;
}

.video-playback-icon {
  color: gray;
  font-size: 16px;
}

.selected-video-playback-icon {
  color: red;
  font-size: 16px;
}

.selected-section-slider-chapter {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  background-color: #151b2d;
  color: gray;
}


/* ACW course card css */


.course-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  padding: 2.5rem
}



.entitycoursegridheades {
  font-family: "Saira", sans-serif;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 2.4rem;

  text-align: left;
  width: 280px;
  padding: 1.5rem;
}

.entitycoursegridparagrp {
  color: grey;
  margin-top: -2rem;
  font-family: "Saira", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: justify;
  line-height: 1.2rem;
  width: 300px;
  padding: 1.5rem;

}

@media (max-width: 767px) {

  .entitycoursegridparagrp,
  .entitycoursegridheades {
    width: 290px;

  }

}

#coursegridviewsw {
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  height: auto;
  width: 100%;
  filter: drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.03));
  box-shadow: -5px 10px 15px 3px rgba(12, 5, 18, .85), -4px 5px 15px 3px rgb(3, 28, 57, 1);
  border-radius: 1rem;
  margin-bottom: 3rem;
  background-size: cover;
}





.Labbtns {
  background: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  background-size: cover;
  filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.03));
  box-shadow: 1px 2px 6px .5px rgba(12, 5, 18, .85), 1px -2px 6px .5px rgb(3, 28, 57, 1);
  cursor: pointer;
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  text-align: center;
  border: none;
  position: relative;
  left: 28rem;
  bottom: 1rem;
}


/* end here */


.next-video-button {
  position: absolute;
  bottom: 20px; /* Position it near the bottom of the video */
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Fade in effect */
}

.next-video-button.show {
  opacity: 1; /* Make button visible when it's time */
}
