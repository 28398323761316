.trainer-cont{
    padding-top: 5rem;
    padding-bottom: 5rem;
    background: linear-gradient(76.8deg, rgb(121, 45, 129) 3.6%, rgb(36, 31, 98) 90.4%);
}
.trainer-cont2{
    margin-top: 12rem;
    padding-top: 5rem;
    background: linear-gradient(76.8deg, rgb(121, 45, 129) 3.6%, rgb(36, 31, 98) 90.4%); 
}
.training-cont3{
    background-color:rgb(15, 2, 49); 
}

.padding-top{
    padding-top: 8rem;
}
.trainer-component{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap:5rem;
    margin-top: 5rem;
   
}
.trainer-name-cont{
    display: flex;

}
.trainer-card{
    color: #000;
    width: 30rem;
    height: 64rem;
    overflow: hidden;
    border-radius: 10px;
  
background-color: white;
}
.trainer-card-img{
    width: 100%;
    height: 30rem;
}
.trainer-name{
  background-color: #D11543;
    padding-left: 0.7rem;
    font-size: 24px;
    font-weight: 700;
    margin-top: 1.6rem;
    color: white;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.trainer-designation{
    padding-left: 0.9rem;
    font-size: 20px;
    margin-top: 1rem;
    text-align: center;

    
}
.trainer-designation-2{
    padding-left: 1rem;
    font-size: 13px;
    font-weight: 500;
    margin-left: -0.5rem;
    text-align: center;
}
.trainer-desc{
    padding-left: 1rem;
    font-size: 17px;
    font-weight: 500;
    margin-top: 1.2rem;
    text-align: justify;
    padding-left: 1rem;
    padding-right: 2rem;
    color:#ADADAD;
  
}
@media screen and (max-width:760px){
    .trainer-card{
        color: #000;
        width: 45rem;
        height: 57rem;
        overflow: hidden;
        border-radius: 10px;
      
    background-color: white;
    }
}
@media screen and (max-width:600px){
    .trainer-card{
       
        height: 66rem;
       
    }
}
