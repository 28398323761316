@import url("https://fonts.googleapis.com/css2?family=Saira&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Saira:wght@500&display=swap");

.settingdiv1{
    background-image: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
    background-size: cover;
    height: auto;
    text-align: center;
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
    box-shadow:  2px 10px 20px 5px 	rgba(12,5,18,.85),8px -5px 20px 5px rgb(3, 28, 57,1);
 
    border-radius: 1rem;
  
    text-align: center;
    align-items: center;

}
.settingdivchange{
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  background-size: cover;
  height: auto;
  text-align: center;
  filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
  box-shadow:  2px 10px 20px 5px 	rgba(12,5,18,.85),8px -5px 20px 5px rgb(3, 28, 57,1);

  border-radius: 1rem;

  text-align: center;
  align-items: center;

}

.settingdiv2{
  background-image: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
 height:auto;
 background-size: cover;
 width: 100%;
 filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
 box-shadow:  2px 10px 20px 5px 	rgba(12,5,18,.85),8px -5px 20px 5px rgb(3, 28, 57,1);
   border-radius: 2rem;
}
.genraldiv{
    background-color:#322F3C;
    text-align: center;
   height: auto;
   text-align: center;
  width: fit-content;
  height: fit-content;
   border-radius: .5rem;
   padding: 1rem;


}
.genraldiv2{
  background: rgba( 249, 245, 245, 0.2 );
    
  backdrop-filter: blur( 5.0px );
  -webkit-backdrop-filter: blur( 5.0px );
  text-align: center;
 height: auto;
 text-align: center;
width: fit-content;
height: fit-content;
 border-radius: .5rem;
 padding: 1rem;


}
.genraldiv img{
  height: 5rem;
}
.settinghead{
  color: white;
  font-weight: 600;
  cursor: pointer;
  font-family: "Saira", sans-serif;
}
.settinghead2{
  color: red;
}
.setttingdiv2head{
  color: white;
  font-family: "Saira", sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
}
.setttingdiv2para{
  color: gray;
  font-family: "Saira", sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  margin-top: 1rem;
}
.setttingdiv2para1{
  color: white;
  font-family: "Saira", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
justify-content: left!important;
  margin-top: 1rem;
}
.settinghoverbtn {
  background: url("../../../Assets/Transaction/Rectangle\ 1134.svg");
  padding: .2rem 1.4rem;
  border: none;
  float: right;
  border-radius: 50%;
  cursor: pointer;
 
  filter: drop-shadow(0px 0px 3px rgba(255,255,255,0.03));
  box-shadow:  2px 2px 5px 1px 	rgba(12,5,18,.85),2px -2px 5px 1px rgb(3, 28, 57,1);
}
.deactivatebtn {
  padding: 1.5rem 6rem;
  color: white!important;
  float:right;
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  border-radius: .8rem;
  text-align: center;
  font-family: "Saira", sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  
  

}
.deactivatebtn3 {
  padding: 1.5rem 2rem;
  color: white!important;
  float:right;
  background: #08223e;
  background: -webkit-radial-gradient(top left, #01152b, #040b18);
  background: -moz-radial-gradient(top left, #01152b, #040b18);
  background: radial-gradient(to bottom right, #01152b, #040b18);
  border-radius: .8rem;
  border: 1px solid red;
  text-align: center;
  font-family: "Saira", sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  
  

}
.deactivatebtn2{
  padding: 1.5rem 3.8rem;
  color: white!important;
  float:right;
  background-image: linear-gradient(to right bottom, #e32f1c, #e82930, #ec2542, #ee2553, #ee2963);
  border-radius: .8rem;
  text-align: center;
  font-family: "Saira", sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  min-width: 100%;

}

.toggle {
  --width: 5.5rem;
  --height: calc(var(--width) / 2);
  --border-radius: calc(var(--height) / 2);
transition: 0.5s;
  display: inline-block;
  cursor: pointer;
  margin-top: 1rem;
}
.toggle2 {
  --width: 4rem;
  --height: calc(var(--width) / 2);
  --border-radius: calc(var(--height) / 2);

  display: inline-block;
  cursor: pointer;
}

.toggle__input {
  display: none;
}

.toggle__fill {
  position: relative;
  width: var(--width);
  height: var(--height);
  border-radius: var(--border-radius);
  background: #000715;
  transition: 0.5s;
  margin-bottom: 0rem;
  filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
  box-shadow:  2px 5px 10px 5px 	rgba(12,5,18,.85),3px -2px 10px 5px rgb(3, 28, 57,1);
}

.toggle__input:checked ~ .toggle__fill {
 background: #000715;
   
   
}

.toggle__fill::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: var(--height);
  width: var(--height);
  background: linear-gradient(
    60deg,
    #ee2963,
    #ee265a,
    #ee2550,
    #ed2547,
    #eb263d,
    #e92833,
    #e62b28,
    #e32f1c
  );
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: var(--border-radius);
  transition: transform 0.2s;
}

.toggle__input:checked ~ .toggle__fill::after {
  transform: translateX(var(--height));
  background: #9FEF00;
  box-shadow:  0px 8px 40px rgba(0,0,0,0.5)
  inset 0 4px 4px rgba(255,255,255,0.2);
 
  transition: 0.4s;
}
#istancebtndiv{
  background: #01152b;
  background: -webkit-radial-gradient(top left, #01152b, #040b18);
  background: -moz-radial-gradient(top left, #01152b, #040b18);
  background: radial-gradient(to bottom right, #01152b, #040b18);
  margin-bottom: 2rem;
  border-radius: 1rem;
  padding: .5rem;
}
#istancebtndiv2{
  background: #01152b;
  background: -webkit-radial-gradient(top left, #01152b, #040b18);
  background: -moz-radial-gradient(top left, #01152b, #040b18);
  background: radial-gradient(to bottom right, #01152b, #040b18);
  margin-bottom: 2rem;
  border-radius: .3rem;
  padding: 2rem;
}
.instancedivbtn2{
  background-image: url(../../../Assets/Transaction/Rectangle\ 1134.svg);
  background-size: cover;
  height: auto;
  text-align: center;
  filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
  box-shadow:  2px 5px 10px 5px 	rgba(12,5,18,.85),4px -5px 10px 5px rgb(3, 28, 57,1);

  padding: .5rem 2rem;
margin: 1rem;

  color: white;
  font-size: 1.5rem;
  font-family: "Saira", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}
.instancedivbtn{
  background: linear-gradient(
    60deg,
    #ee2963,
    #ee265a,
    #ee2550,
    #ed2547,
    #eb263d,
    #e92833,
    #e62b28,
    #e32f1c
  );
  background-size: cover;
  height: auto;
  text-align: center;
  filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.03));
  box-shadow:  2px 5px 10px 5px 	rgba(12,5,18,.85),4px -5px 10px 5px rgb(3, 28, 57,1);

  padding: .5rem 2rem;
margin: 1rem;

  color: white;
  font-size: 1.5rem;
  font-family: "Saira", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}